<template>
  <div v-if="chartAvailable">
    <div class="LCPcontent">
      <highchart class="LCPcontent" :options="chartConfig" :series-options="chartSeries" key="waterfallChart" />
      <div class="controls">
        <input-wrapper :inputTemplate="getAomRadioInput()" />
      </div>
    </div>
    <div style="padding-top: 10px">
      <lcp-table class="LCPcontent" :config="tableConfig" />
    </div>
  </div>
  <div v-else>
    <vue-markdown class="LCPcontent" :source="chartNotAvailableText" />
  </div>
</template>

<script>

import waterfallChart from '@/components/common/charts/waterfallChart.js';
import table from '@/components/common/tables/table.vue';

function getAttributionValue (array) {
    return array[1] - array[0];
}

export default {
    props: ['config', 'inputs', 'dynamicData'],
    created () {
        this.chartNotAvailableText = this.dynamicData.aomNotAvailableText == null ? 'Analysis of movement is not available for the current selection. Please try an alternative date range and/or speak to your Visualise contact for support.' : this.dynamicData.aomNotAvailableText;
    },
    computed: {
        chartAvailable () {
            if (!this.dynamicData.aomAvailable) return false;
            return this.dynamicData.aomResultByType[this.dynamicData.selectedType].chartSeriesData !== null;
        },
        chartConfig () {
            const additionalOptions = this.getChartOptions();
            const output = waterfallChart.chartOptions(this.selectedChart.scalingType, additionalOptions);

            output.xAxis[0].categories = this.selectedChart.rangeData.xLabels;
            output.xAxis[1].categories = this.selectedChart.rangeData.valuesAsText;
            output.xAxis[1].opposite = true;

            let minValue = 0;
            let maxValue = 0;
            for (let i = 0; i < this.selectedChart.rangeData.data.length; i++) {
                const dataPoint = this.selectedChart.rangeData.data[i];

                for (let j = 0; j < dataPoint.length; j++) {
                    const dataValue = dataPoint[j];
                    maxValue = Math.max(maxValue, dataValue);
                    minValue = Math.min(minValue, dataValue);
                }
            }

            if (minValue >= 0) output.yAxis[0].min = 0;
            if (maxValue <= 0) output.yAxis[0].max = 0;
            return output;
        },
        chartSeries () {
            return waterfallChart.getSeriesDataWithSecondaryLabels(this.selectedChart.rangeData.data, this.selectedChart.colourStrings);
        },
        selectedChart () {
            const chartData = this.dynamicData.aomResultByType[this.dynamicData.selectedType];
            const seriesData = chartData.chartSeriesData;

            // need to reverse series from pro
            const data = [];
            const xLabels = [];
            const colourStrings = [];

            for (let i = seriesData.colourStrings.length - 1; i >= 0; i--) {
                data.push(seriesData.rangeData.data[i]);
                xLabels.push(seriesData.rangeData.xLabels[i]);
                colourStrings.push(seriesData.colourStrings[i]);
            }

            const valuesAsText = this.getValuesAsText(data, chartData.scalingType);

            return {
                rangeData: {
                    data: data,
                    xLabels: xLabels,
                    valuesAsText: valuesAsText
                },
                scalingType: chartData.scalingType,
                colourStrings: colourStrings
            };
        },
        tableConfig () {
            const cellContentByRow = {};

            Object.keys(this.dynamicData.aomResultByType).forEach(key => {
                const chartData = this.dynamicData.aomResultByType[key];
                const seriesData = chartData.chartSeriesData;

                const startValue = getAttributionValue(seriesData.rangeData.data[seriesData.rangeData.data.length - 1]);
                const endValue = getAttributionValue(seriesData.rangeData.data[0]);
                const change = endValue - startValue;

                const row = [
                    table.getCellDefinition(key),
                    table.getCellDefinition(startValue.toScaledValueString(chartData.scalingType)),
                    table.getCellDefinition(endValue.toScaledValueString(chartData.scalingType)),
                    table.getCellDefinition(change.toScaledValueString(chartData.scalingType))
                ];

                cellContentByRow[key] = row;
            });

            return table.getTableConfig(this.getHeaderRow(), cellContentByRow);
        }
    },
    methods: {
        getAomRadioInput () {
            return window.LCP.fn.extend(this.inputs[this.config.aomTypeRadioId], { config: { largerFontSize: true } });
        },
        getHeaderRow () {
            const headerRow = [
                table.getCellDefinition(''),
                table.getCellDefinition(this.dynamicData.startDate.ToDateString()),
                table.getCellDefinition(this.dynamicData.endDate.ToDateString()),
                table.getCellDefinition('Change')
            ];
            return headerRow;
        },
        getValuesAsText (data, scalingType) {
            const output = [];

            for (let i = 0; i < data.length; i++) {
                output[i] = getAttributionValue(data[i]).toScaledValueString(scalingType);
            }

            return output;
        },
        getChartOptions () {
            const chart = this.selectedChart;
            return {
                xAxis: [{
                    labels: {
                        style: {
                            color: 'black'
                        },
                        useHTML: true,
                        formatter (point) {
                            const labelCount = chart.rangeData.xLabels.length;
                            if (chart.rangeData.xLabels.indexOf(point.value) === 0 || chart.rangeData.xLabels.indexOf(point.value) === labelCount - 1) {
                                return `<strong>${point.value}</strong>`;
                            }
                            return `${point.value}`;
                        }
                    }
                },
                {
                    labels: {
                        style: {
                            color: 'black'
                        },
                        useHTML: true,
                        formatter (point) {
                            const labelCount = chart.rangeData.valuesAsText.length;
                            if (chart.rangeData.valuesAsText.indexOf(point.value) === 0 || chart.rangeData.valuesAsText.indexOf(point.value) === labelCount - 1) {
                                return `<strong>${point.value}</strong>`;
                            }
                            return `${point.value}`;
                        }
                    }
                }],
                yAxis: [{
                    plotLines: [{
                        value: 0,
                        width: 2,
                        zIndex: 5,
                        color: '#8DA8AD'
                    }]
                }]
            };
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
