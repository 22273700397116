<template>
    <div v-if="pageAvailable" class="row">
        <div id="mainCard" class="col-md-8 framed partialPageCardLeft">
            <div class="card">
                <div class="card-header">
                    <dropdown-title :dropdownPrefix="header + ' - '" :inputTemplate="inputs[config.accountingPeriodControlId]"/>
                </div>
                <div class="LCPcontent">
                    <div ref="chart"/>
                    <div class="controls">
                        <input-wrapper :inputTemplate="getAccountingMeasureControlInput()"/>
                        <lcp-table :config="tableData" style="margin-top: 10px" responsiveSize="md"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 partialPageCardRight">
            <div class="card separateCard" v-if="config.showCreateReport">
                <download-report v-if="config.showCreateReport" btnText="Download report" :reportType="config.accountingReportId"/>
            </div>
            <div class="card">
                <div class="card-header-with-action">
                    <h1>Assumptions as at {{config.calculationDate.ToDateString()}}</h1>
                    <reset-inputs/>
                </div>
                <div class="col-md-12 input-container">
                    <input-wrapper :key="key" :inputTemplate="input" v-for="(input, key) in getAssumptionInputs(inputs, dynamicOutput.controlsToDisplay)"/>
                </div>
                <vue-markdown v-show="period == config.thisYearPeriodId">The numbers shown are projected from {{config.calculationDate.ToDateString()}} in line with the assumptions and take no account of experience from that date. The actual accounting numbers for the period will be different if experience differs from the assumptions made.</vue-markdown>
                <vue-markdown v-show="period == config.nextYearPeriodId">The numbers shown are illustrative only. They are projected from {{config.calculationDate.ToDateString()}} in line with the assumptions and take no account of experience from that date. The actual accounting numbers for the period will be different if experience differs from the assumptions made.</vue-markdown>
            </div>
        </div>
    </div>
    <div v-else class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <div class="card-header">
                    <h1>{{header}}</h1>
                </div>
                <vue-markdown class="LCPcontent" :source="pageNotAvailableText"/>
            </div>
        </div>
    </div>
</template>

<script>

import chart from './chartAccounting.js';
import table from '@/components/common/tables/table.vue';

import flagAus from '@/assets/images/flags/flag_aus.png';
import flagFr from '@/assets/images/flags/flag_fr.png';
import flagGer from '@/assets/images/flags/flag_ger.png';
import flagIre from '@/assets/images/flags/flag_ire.png';
import flagJap from '@/assets/images/flags/flag_jap.png';
import flagMor from '@/assets/images/flags/flag_mor.png';
import flagNl from '@/assets/images/flags/flag_nl.png';
import flagNz from '@/assets/images/flags/flag_nz.png';
import flagSpa from '@/assets/images/flags/flag_spa.png';
import flagUk from '@/assets/images/flags/flag_uk.png';
import flagUsa from '@/assets/images/flags/flag_usa.png';
import flagEarth from '@/assets/images/flags/planet-earth.png';

function getHeader (item) {
    return item.date != null ? item.date.ToDateString() : item.description;
}

function toString (val, absolute, withUnits) {
    if (absolute === undefined) absolute = false;
    if (withUnits === undefined) withUnits = false;

    if (absolute) {
        if (withUnits) return Math.abs(val).toScaledValueString(window.LCP.con.ACCOUNTINGAMOUNT);
        return Math.abs(val).toScaledValueStringNoUnits(window.LCP.con.ACCOUNTINGAMOUNT);
    }

    if (withUnits) return val.toScaledValueString(window.LCP.con.ACCOUNTINGAMOUNT);
    return val.toScaledValueStringNoUnits(window.LCP.con.ACCOUNTINGAMOUNT);
}

function getHeaderRow (header, data) {
    const rowData = getRow(header, data, node => getHeader(node));
    return table.getRowFromValues(rowData);
}

function getTotalRow (header, data) {
    const headerCell = table.getCellDefinition(header);
    headerCell.isTotal = true;

    return getRow(headerCell, data, node => {
        const cell = table.getCellDefinition(toString(node.value));
        cell.isTotal = true;
        return cell;
    });
}

function getBreakdownRow (key, data, flags, currentNode, rowNameOverride, bold, singleScheme) {
    const rowName = rowNameOverride != null ? rowNameOverride : key;
    return getRow(table.getCellDefinition(rowName, null, null, bold), data, node => {
        const breakdownData = node.valueBreakdownByScheme[key];

        const cell = table.getCellDefinition(toString(breakdownData.value), null, null, bold);
        if (singleScheme) return cell;

        const flag = flags[breakdownData.image];

        if (currentNode === node.description || currentNode === node.date) {
            cell.tableCellStyle.iconClass = 'dummy';
            cell.iconStyle = {
                backgroundImage: flag !== undefined ? 'url(' + flag + ')' : null,
                backgroundColor: flag === undefined ? breakdownData.image : null
            };
        } else if (currentNode !== null) {
            cell.styleOverrides = {
                opacity: 0.3
            };
        }

        return cell;
    });
}

function getRow (header, data, valueMethod) {
    const row = [header];

    if (data.startNode) row.push(valueMethod(data.startNode));

    for (const key in data.nodes) {
        const aosItem = data.nodes[key];
        row.push(valueMethod(aosItem));
    }

    row.push(valueMethod(data.endNode));

    return row;
}

export default {
    data () {
        return {
            period: null,
            view: null,
            currentData: null,
            currentNode: null,
            flagAus,
            flagFr,
            flagGer,
            flagIre,
            flagJap,
            flagMor,
            flagNl,
            flagNz,
            flagSpa,
            flagUk,
            flagUsa,
            flagEarth
        };
    },
    mounted () {
        if (!this.dynamicOutput) return;

        this.flags = [];
        this.flags.Australia = flagAus;
        this.flags.France = flagFr;
        this.flags.Germany = flagGer;
        this.flags.Ireland = flagIre;
        this.flags.Japan = flagJap;
        this.flags.Morocco = flagMor;
        this.flags.Netherlands = flagNl;
        this.flags.NewZealand = flagNz;
        this.flags.Spain = flagSpa;
        this.flags.UK = flagUk;
        this.flags.USA = flagUsa;
        this.flags.RestOfWorld = flagEarth;

        chart.create(this.dynamicOutput.chartData, this.$refs.chart, this.update, toString, this.flags, node => (this.currentNode = node));
    },
    created () {
        this.header = 'Accounting';
        this.pageAvailable = this.$staticStore.state.accounting.pageAvailable;
        this.pageNotAvailableText = this.$staticStore.state.accounting.pageNotAvailableText;

        if (!this.pageAvailable) return;

        this.config = this.$staticStore.state.accounting.config;
        this.inputs = this.$staticStore.state.accounting.inputControls;
        this.period = this.inputs[this.config.accountingPeriodControlId].value;
        this.view = this.inputs[this.config.accountingMeasureControlId].value;
    },
    methods: {
        update (d) {
            this.currentData = Object.assign(d);
        },
        getAssumptionInputs (allInputs, controlsToDisplay) {
            const output = {};

            for (let i = 0; i < controlsToDisplay.length; i++) {
                const control = controlsToDisplay[i];
                output[control] = allInputs[control];
            }

            return output;
        },
        getAccountingMeasureControlInput () {
            return window.LCP.fn.extend(this.inputs[this.config.accountingMeasureControlId], { config: { largerFontSize: true } });
        },
        getSchemeTable (currentData, currentAos, currentNode) {
            if (!currentData || !currentAos) return table.getTableConfig([], {}, true);

            const schemeKey = Object.keys(currentData.endNode.valueBreakdownByScheme)[0];
            const cellContentByRowId = {};

            cellContentByRowId.chosen = getBreakdownRow(schemeKey, currentData, null, currentNode, 'Chosen', true, true);

            let currentAosToUse = currentAos;

            if (currentData.nodeKey != null) {
                const nodekeys = currentData.nodeKey.split('_');
                for (let i = 0; i < nodekeys.length; i++) {
                    const nodes = currentAosToUse.nodes;
                    currentAosToUse = nodes[nodekeys[i]].aos;
                }
            }

            cellContentByRowId.current = getBreakdownRow(schemeKey, currentAosToUse, null, currentNode, 'Current', false, true);

            const headerRow = getHeaderRow('', currentData);

            return table.getTableConfig(headerRow, cellContentByRowId);
        },
        getConsolidatedTable (currentData, currentNode) {
            if (!currentData) return table.getTableConfig([], {}, true);

            const cellContentByRowId = {};

            for (const key in currentData.endNode.valueBreakdownByScheme) {
                cellContentByRowId[key] = getBreakdownRow(key, currentData, this.flags, currentNode);
            }

            cellContentByRowId.total = getTotalRow('Total', currentData);

            const headerRow = getHeaderRow('Scheme', currentData);

            return table.getTableConfig(headerRow, cellContentByRowId);
        }
    },
    computed: {
        dynamicOutput () {
            return this.$store.state.accounting.dynamicOutput;
        },
        tableData () {
            return Object.keys(this.dynamicOutput.chartData.results.startNode.valueBreakdownByScheme).length > 1 ? this.getConsolidatedTable(this.currentData, this.currentNode) : this.getSchemeTable(this.currentData, this.dynamicOutput.currentAos, this.currentNode);
        }
    },
    watch: {
        dynamicOutput: function (newVal, oldVal) {
            if (this.period !== this.inputs[this.config.accountingPeriodControlId].value || this.view !== this.inputs[this.config.accountingMeasureControlId].value) {
                this.period = this.inputs[this.config.accountingPeriodControlId].value;
                this.view = this.inputs[this.config.accountingMeasureControlId].value;
                chart.updateMeasure(newVal.chartData);
            } else {
                chart.updateValues(newVal.chartData);
            }
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
