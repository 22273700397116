<template>

<lcp-table v-if="includeTable" class="LCPcontent" :config="combinedTable"/>

</template>

<script>

import table from '@/components/common/tables/table.vue';

export default {
    props: ['lockTableData', 'tableConfig', 'headers'],
    computed: {
        includeTable () {
            return (Object.keys(this.lockTableData.cellContentByRow).length !== 0) || this.tableConfig;
        },
        combinedTable () {
            const cellContentByRow = {};

            const lockTable = this.lockTableData.cellContentByRow;
            const tableConfig = this.tableConfig;

            Object.keys(lockTable).forEach(key => {
                cellContentByRow[key] = lockTable[key];
            });

            if (tableConfig) {
                Object.keys(tableConfig.cellContentByRowId).forEach(key => {
                    if (key in lockTable) return;

                    cellContentByRow[key] = tableConfig.cellContentByRowId[key];
                });
            }

            return table.getTableConfig(this.lockTableData.headers, cellContentByRow);
        }
    }
};

</script>
