<template>
    <div class="card tile" :class="[clickableClass, id]" :style="{ backgroundColor: tileData.colour }" @click="goTo">
        <div class="card-header tile-header">
            <div :style="{height: headerHeight}">{{tileDescription}}</div>
            <div v-if="looping">
                <div :ref="tileHeaderTestHeightIdPrefix + index" class="testHeightStyle" v-for="(data, index) in loopData" :key="tileHeaderTestHeightIdPrefix + index">
                    {{data.description}}
                </div>
            </div>
            <span v-if="!looping" class="icon" :class="'icon-' + tileData.icon"/>
            <span v-else class="icon" :class="[playState ? 'icon-pause' : 'icon-play']" @click="togglePlayState"/>
        </div>
        <div class="tile-content">
            <div v-if="tileData.metricsNotAvailableText" class="tileRow">{{tileData.metricsNotAvailableText}}</div>
            <component
              v-else :is="tileData.tileDesignType"
              :id="tileData.id"
              :tileData="tileData"
              :loopMetric="loopIndex"
              :style="{height: componentHeight}"/>
            <div v-if="tileData.footnote" class="tileRow">{{tileData.footnote}}</div>
            <div v-if="looping">
                <component
                  :ref="tileDataTestHeightIdPrefix + index"
                  class="testHeightStyle" v-for="(data, index) in loopData"
                  :key="tileDataTestHeightIdPrefix + index"
                  :is="tileData.tileDesignType"
                  :id="tileDataTestHeightIdPrefix + index"
                  :tileData="tileData"
                  :loopMetric="index"/>
            </div>
        </div>
    </div>
</template>

<script>

import StandardMetrics from './standardMetrics.vue';
import Funding from './funding.vue';
import Risk from './risk.vue';
import ClimateRisk from './climateRisk.vue';
import InsurerPricing from './insurerPricing.vue';
import Triggers from './triggers.vue';
import Accounting from './accounting.vue';
import Investments from './investment.vue';
import Markets from './markets.vue';
import SplitByLiability from './cashflows.vue';
import Covenant from './covenant.vue';

export default {
    props: ['id', 'tileData', 'preLinkAction'],
    components: {
        StandardMetrics,
        Funding,
        Risk,
        ClimateRisk,
        InsurerPricing,
        Triggers,
        Accounting,
        Investments,
        Markets,
        SplitByLiability,
        Covenant
    },
    data () {
        return {
            tileDescription: null,
            playState: true,
            looping: false,
            loopIndex: null,
            headerHeight: null,
            componentHeight: null
        };
    },
    created () {
        this.tileDescription = this.tileData.description;
        this.clickableClass = this.tileData.pageForLink == null ? 'notClickable' : 'clickable';
        this.componentLoop = this.tileData.metricsByView != null && this.tileData.metricsByView.length > 0;

        if (this.componentLoop) {
            this.loopData = this.tileData.metricsByView;
            this.updateIndexAndState(0);
            if (this.loopData.length > 1) this.startLoop();
        } else {
            this.loopData = [];
        }

        this.tileHeaderTestHeightIdPrefix = this.id + '_tileHeaderTestHeightId_';
        this.tileDataTestHeightIdPrefix = this.id + '_tileDataTestHeightId_';
    },
    mounted () {
        this.resize();

        window.addEventListener('resize', this.resize);
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.resize);
    },
    methods: {
        resize () {
            if (!this.looping) return;

            let maxHeaderHeight = 0;
            let maxTileDataHeight = 0;

            for (let i = 0; i < this.loopData.length; i++) {
                const tileHeaderTest = this.$refs[this.tileHeaderTestHeightIdPrefix + i][0];
                maxHeaderHeight = Math.max(maxHeaderHeight, tileHeaderTest.clientHeight);

                const tileDataTest = this.$refs[this.tileDataTestHeightIdPrefix + i][0].$el;
                maxTileDataHeight = Math.max(maxTileDataHeight, tileDataTest.clientHeight);
            }

            this.headerHeight = maxHeaderHeight + 'px';
            this.componentHeight = maxTileDataHeight + 'px';
        },
        goTo () {
            if (this.tileData.pageForLink === null) return;
            
            if (this.preLinkAction) this.preLinkAction();
            this.$store.dispatch('routeToSchemePage', { pageName: this.tileData.pageForLink, pageType: this.tileData.pageTypeForLink });
        },
        togglePlayState (event) {
            if (event.srcElement.classList[0] === 'icon') event.stopPropagation();

            this.playState = !this.playState;

            if (this.playState) {
                this.nextLoop();
                this.startLoop();
            } else {
                this.stopLoop();
            }
        },
        updateIndexAndState (index) {
            this.loopIndex = index;
            this.tileDescription = this.loopData[this.loopIndex].description;
        },
        startLoop () {
            this.looping = true;
            this.intervalRef = setInterval(z => { this.nextLoop(); }, 5000);
        },
        nextLoop () {
            this.updateIndexAndState(this.loopIndex === this.loopData.length - 1 ? 0 : this.loopIndex + 1);
        },
        stopLoop () {
            clearInterval(this.intervalRef);
        }
    }
};

</script>

<style scoped lang="scss">
@import 'src/assets/scss/all.scss';

.testHeightStyle {
    position: absolute !important;
    visibility: hidden;
    width: 100%;
}

.tile {
    color: white;
    margin-bottom: 15px;
    user-select: none;
    .tile-header {
        position: relative;
        border-bottom: none;
        font-size: $fontSizeHeader;
        margin-bottom: 10px;
        padding-right: 35px;
        @include responsive ('sm') {
            font-size: $fontSizeHeaderSm;
        }
        span.icon {
            position: absolute;
            top: 6px;
            right: 0px;
        }
    }
    .tile-content {
        font-size: 18px;
        position: relative;
        @include responsive ('sm') {
            font-size: $fontSizeStandard;
        }
    }
}

.clickable {
    cursor: pointer;
}

.notClickable {
    cursor: default;
}

</style>

<style lang="scss">
@import 'src/assets/scss/mixins.scss';
%rowPadding {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 5px;
}

.tileRow {
    @extend %rowPadding;
}

%basicTileMetricTable {
    word-break: break-word;
    text-align: right;
    width: 100%;
    td {
        @extend %rowPadding;
    }
}

%borderBottom {
    border-bottom: 2px solid rgba(255,255,255, 0.2);
}

%borderTop {
    border-top: 2px solid rgba(255,255,255, 0.2);
}

%borderFull {
    border: 2px solid rgba(255,255,255, 0.2);
}

.borderBottom {
    @extend %borderBottom;
}

.borderTop {
    @extend %borderTop;
}

.borderFull {
    @extend %borderFull;
}

%standardValueCellWidth {
    width: 150px
}

%wideValueCellWidth {
    width: 220px
}

.standardValueCellWidth {
    @extend %standardValueCellWidth;
}

.wideValueCellWidth {
    @extend %wideValueCellWidth;
}

.basicTileMetricTable {
    @extend %basicTileMetricTable;
}

.tileMetricTableBoldLastValue {
    @extend %basicTileMetricTable;
    td:last-child{
        font-weight: bold;
        @extend %standardValueCellWidth;
    }
}

.wideValues {
    td:last-child {
        @extend %wideValueCellWidth;
    }
}

.tileMetricTableStartEndFormatting {
    tr:first-child {
        td {
            @extend %borderBottom;
            font-style: normal;
        }
    }

    tr:last-child {
        td {
            @extend %borderTop;
            font-style: normal;
        }
    }

    td {
        font-style: italic;
    }
}

  @include responsive ('xs') {
    tr, td {
      display: block;
      text-align: left;
      width: 100%;
    }
    tr {
      margin-bottom: 10px;
    }

    .Accounting tr:first-child, .Triggers tr:first-child, .Accounting tr:last-child, .SplitByLiability tr:last-child,
    .Markets tr td:nth-child(3), .Markets tr td:nth-child(4), .Markets tr:first-child, .Markets tr:nth-child(2) {
      display: none;
    }
  }
</style>
