var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modalBg" }, [
    _c(
      "div",
      { staticClass: "editModal" },
      [
        _c("div", { staticClass: "modalHeader" }, [
          _vm._v(" " + _vm._s(_vm.headerText) + " "),
          _vm.receivedData
            ? _c(
                "div",
                {
                  staticClass: "closeModal",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("span", { staticClass: "icon icon-x-circle" })]
              )
            : _vm._e(),
        ]),
        !_vm.receivedData
          ? _c("mini-loader", { staticClass: "loading" })
          : _c(
              "div",
              [
                _c("radio", {
                  attrs: {
                    id: _vm.schemeName,
                    inputTemplate: _vm.getRadioInputTemplate(),
                  },
                  on: { currentvalue: _vm.onUserSelect },
                }),
                _vm.tableData
                  ? _c("lcp-table", {
                      staticClass: "LCPcontent",
                      attrs: { config: _vm.tableData },
                    })
                  : _c("vue-markdown", {
                      staticClass: "LCPcontent",
                      attrs: { source: "No client login history to show." },
                    }),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }