<template>
    <div class="card">
        <div class="card-header">
            <h1>{{contactInfo.description}}</h1>
        </div>
        <div class="card-content">
            <vue-markdown :source="contactInfo.name"/>
            <vue-markdown v-if="contactInfo.personDescription !== ''" :source="contactInfo.personDescription"/>
            <vue-markdown v-if="contactInfo.number !== ''" :source="contactInfo.number"/>
            <div v-if="contactInfo.address !== ''">
                <a :href="getHyperlink(contactInfo.address)" style="color: black; float: left; width:15px; margin-right:10px;">
                    <icon name="envelope"/>
                </a>
                <vue-markdown style="float: left; line-height: 16px">[{{contactInfo.address}}]({{getHyperlink(contactInfo.address)}})</vue-markdown>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['contactInfo'],
    methods: {
        getHyperlink (email) {
            return `mailto:${email}?Subject=${this.$store.state.scheme.name.replace(/ /g, '%20')}`;
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.card {
    margin-bottom: 15px;
}

.card-content {
    padding-top:1rem;
}

</style>
