<template>
    <div class="wrapper">
        <table class="tileMetricTableBoldLastValue">
            <tr v-if="headers" class="borderBottom">
                <td v-for="header in headers" :key="header">{{header}}</td>
            </tr>
            <tr class="mainBodyRow" v-for="metric in tileData.metrics" :key="metric.description" :class="chartData && chartData.chartOptions[metric.description] ? 'containsChart' : ''">
                <td v-if="chartData && chartData.chartOptions[metric.description]" class="tableChart">
                    <highchart :options="chartData.chartOptions[metric.description]" :series-options="chartData.chartSeries[metric.description]" :hideHiddenChartText="true" :key="'fundingChart_' + metric.description"/>
                </td>
                <td>{{metric.description}}</td>
                <td>{{getMetricDisplayValue(metric)}}</td>
            </tr>
            <tr v-for="i in dummyMetricRows" :key="i">
                <td>&nbsp;</td>
            <td>&nbsp;</td>
            </tr>
        </table>
        <div v-show="coverText" class="coverText" :style="{background: tileData.colour}">
            <div class="tileRow">{{coverText}}</div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['id', 'tileData', 'headers', 'rowCountOverride', 'coverText', 'chartData'],
    created () {
        this.metricCount = this.rowCountOverride ? this.rowCountOverride : this.tileData.metrics.length;
    },
    computed: {
        dummyMetricRows () {
            return this.rowCountOverride ? this.rowCountOverride - this.tileData.metrics.length : 0;
        }
    },
    methods: {
        getMetricDisplayValue (metric) {
            const aheadBehindMode = metric.valueType === 'AheadBehind';
            const valueToScale = aheadBehindMode ? Math.abs(metric.value) : metric.value;

            let output = valueToScale.toScaledValueString(metric.scalingType, metric.dpsToDisplayOverride);
            if (aheadBehindMode) output += metric.value > 0 ? ' ahead' : ' behind';
            return output;
        }
    }
};

</script>

<style lang="scss" scoped>

@import 'src/assets/scss/mixins.scss';

.wrapper {
    position: relative;
    pointer-events: none;
    .coverText {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
    }
}

.mainBodyRow {
    .tableChart {
        width: 150px;
    }
    &.containsChart {
        @include responsive('xs') {
            tr:first-child {
                width: 0px;
                visibility: hidden;
                :deep(.highcharts-container) {
                    width: 0px !important;
                }
            }
        }
        @media (min-width: 915px) and (max-width: 1105px) {
            td:first-child {
                width: 0px;
                visibility: hidden;
                :deep(.highcharts-container) {
                    width: 0px !important;
                }
            }
        }
        @media (min-width: 915px) and (max-width: 1105px) {
            td:first-child {
                width: 0px;
                visibility: hidden;
                :deep(.highcharts-container) {
                    width: 0px !important;
                }
            }
        }
        @media (min-width: 1450px) and (max-width: 1605px) {
            td:first-child {
                width: 0px;
                visibility: hidden;
                :deep(.highcharts-container) {
                    width: 0px !important;
                }
            }
        }
    }
}

</style>
