<template>
    <div>
        <highchart :options="currentChartOptionsRiskAttribution" :series-options="currentChartSeriesRiskAttribution" key="riskAttribution"/>
        <checkbox v-if="$mq != 'xs'" style="width:180px; margin:10px auto 0px" :inputTemplate="checkboxTemplate" v-on:toggled="showCompare"/>
    </div>
</template>

<script>

import checkbox from '@/components/common/uiInputs/checkbox.vue';
import barChart from '@/components/common/charts/barChart.js';
import waterfallChart from '@/components/common/charts/waterfallChart.js';

const chartOptionsRiskAttribution = {
    yAxis: [{
        min: 0
    }]
};

const chartOptionsRiskAttributionCompare = {
    tooltip: {
        shared: true,
        formatter () {
            let toolTip = '<table><td><b>' + this.x + '</b></td>';
            toolTip += '<tr style=\'color:' + this.points[0].color + '\'><td style=\'padding-right: 10px;\'>Chosen: </td><td style=\'text-align: right\'><b>' + (this.points[0].y).toAmountString() + '</b></td></tr>';
            toolTip += '<tr style=\'color:' + this.points[1].color + '\'><td style=\'padding-right: 10px;\'>Current: </td><td style=\'text-align: right\'><b>' + (this.points[1].y).toAmountString() + '</b></td></tr>';
            toolTip += '</table>';
            return toolTip;
        }
    },
    extend (options) {
        return window.LCP.fn.extend(this, options);
    }
};

export default {
    props: ['config', 'initialData', 'dynamicData'],
    data () {
        return {
            showComparison: false
        };
    },
    created () {
        this.chartOptionsRiskAttribution = waterfallChart.chartOptions(window.LCP.con.AMOUNT, chartOptionsRiskAttribution);
        this.chartOptionsRiskAttributionCompare = barChart.chartOptions(window.LCP.fn.extend(waterfallChart.getCommonChartOverrides(), chartOptionsRiskAttributionCompare), window.LCP.con.AMOUNT);
        this.chartOptionsRiskAttribution.xAxis[0].categories = this.chartOptionsRiskAttributionCompare.xAxis[0].categories = this.config.names;

        this.initialAttributionComparisonSeries = this.getComparisonAttributionSeries(this.initialData.attribution, this.config.coloursDimmed);

        this.checkboxTemplate = checkbox.getDefaultInputTemplate('showComparison', 'Show comparison', this.showComparison, null, null, null, null, true);
    },
    methods: {
        showCompare (compare) {
            this.showComparison = compare.state;
        },
        getComparisonAttributionData (attribution) {
            const dynamicCompareArray = [];
            for (let i = 0; i < attribution.length; i++) {
                const attributionForI = attribution[i];
                dynamicCompareArray.push(attributionForI[1] - attributionForI[0]);
            }
            return dynamicCompareArray;
        },
        getComparisonAttributionSeries (attribution, colours) {
            return waterfallChart.getSeriesData(this.getComparisonAttributionData(attribution), colours);
        }
    },
    computed: {
        currentChartOptionsRiskAttribution () {
            if (this.showComparison) return this.chartOptionsRiskAttributionCompare;
            return this.chartOptionsRiskAttribution;
        },
        currentChartSeriesRiskAttribution () {
            if (this.showComparison) return this.chartSeriesRiskAttributionCompare;
            return this.chartSeriesRiskAttribution;
        },
        chartSeriesRiskAttribution () {
            return [waterfallChart.getSeriesData(this.dynamicData.attribution, this.config.colours)];
        },
        chartSeriesRiskAttributionCompare () {
            return [this.getComparisonAttributionSeries(this.dynamicData.attribution, this.config.colours), this.initialAttributionComparisonSeries];
        }
    }
};

</script>
