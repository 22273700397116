/* eslint-disable */
import $ from "jquery";
import moment from 'moment';

(function () {
    var win = window,

    //internal functions
    math = Math,
    mathAbs = math.abs;

    const NONE = 'none';
    const DATE = 'date';
    const AMOUNT = 'amount';
    const AMOUNT_PA = 'amountPA';
    const ACCOUNTINGAMOUNT = 'accountingAmount';
    const PERCENTAGE = 'percent';
    const PERCENTAGE_PA = 'percentPA';
    const MULTIPLIER = 'multiplier';

    var formatOptions;

    function setFormattingOptions(options){
        formatOptions = options;
    }

    function getFormattingOptionsForType(displayType){
        switch(displayType) {
            case AMOUNT:
                return getAmountFormattingOptions();
            case AMOUNT_PA:
                return formatOptions.amountPaFormatOptions;
            case ACCOUNTINGAMOUNT:
                return formatOptions.accountingAmountFormatOptions;
            case PERCENTAGE:
                return getPercentFormattingOptions();
            case PERCENTAGE_PA:
                return formatOptions.percentagePaFormatOptions;
            case MULTIPLIER:
                return formatOptions.multiplierFormatOptions;
            default:
                return undefined;
        }
    }

    function getAmountFormattingOptions () {
        return formatOptions.amountFormatOptions;
    }

    function getPercentFormattingOptions () {
        return formatOptions.percentageFormatOptions;
    }

    String.prototype.base64ToBlob = function (mime) {
        let bstr = atob(this);
        let ab = bstr.s2ab();
        return new Blob([ab], { type: mime });
    }

    String.prototype.s2ab = function () {
        var buf = new ArrayBuffer(this.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== this.length; ++i) view[i] = this.charCodeAt(i) & 0xFF;
        return buf;
    }

    String.prototype.toSafeString = function () {
        return stringHelper(this);
    }

    String.prototype.removeSpecialCharacters = function () {
        return this.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    }

    Number.prototype.getChangeColour = function (isBigGood) {
        if (isNaN(this) || this.isSameAs(0)) return null;
        // if these colours are changed make sure back end is updated
        if (this < 0) {
            if (isBigGood) return '#FF0000';
            return '#0CCF00';
        }

        if (!isBigGood) return '#FF0000';
        return '#0CCF00';
    }

    Number.prototype.toScaledValue = function (displayType) {
        switch(displayType) {
            case NONE:
                return this;
            case DATE:
                return this;
            default:
                var formatting = getFormattingOptionsForType(displayType);
                return this / formatting.scaleFactor;
        }
    }

    Number.prototype.toScaledValueChangeString = function (displayType, dps) {
        var scaledValue = this.toScaledValueStringNoUnits(displayType, dps);
        if (scaledValue == 0) return "-";

        var standardString = this.toScaledValueString(displayType, dps);
        if (this > 0) return "+" + standardString;

        return standardString;
    }

    Number.prototype.toScaledValueString = function (displayType, dps) {
        switch(displayType) {
            case NONE:
                return this.toAmountStringNoScalingNoUnits(dps);
            case DATE:
                return this.ToDateString();
            default:
                var formatting = getFormattingOptionsForType(displayType);
                return scaledString(this, formatting, dps);
        }
    }

    Number.prototype.toScaledValueStringNoUnits = function (displayType, dps) {
        switch(displayType) {
            case NONE:
                return this.toAmountStringNoScalingNoUnits(dps);
            case DATE:
                return this.ToDateString();
            default:
                var formatting = getFormattingOptionsForType(displayType);
                return scaledStringNoUnits(this, formatting, dps);
        }
    }

    Number.prototype.toValueStringNoUnits = function (displayType, dps) {
        switch(displayType) {
            case NONE:
                return this.toAmountStringNoScalingNoUnits(dps);
            case DATE:
                return this.ToDateString();
            default:
                var formatting = getFormattingOptionsForType(displayType);
                return unscaledStringNoUnits(this, formatting, dps);
        }
    }

    String.prototype.toScaledValueString = function (displayType) {
        switch(displayType) {
            case DATE:
                return this.ToDateString();
            default:
                if (this === "NaN") return "n/a";
                return this;
        }
    }

    Number.prototype.toAmountString = function (dps) {
        return this.toScaledValueString(AMOUNT, dps);
    }

    Number.prototype.toAmountPaString = function (dps) {
        return this.toScaledValueString(AMOUNT_PA, dps);
    }

    Number.prototype.toAccountingAmountString = function (dps) {
        return this.toScaledValueString(ACCOUNTINGAMOUNT, dps);
    }

    Number.prototype.toPercentString = function (dps) {
        return this.toScaledValueString(PERCENTAGE, dps);
    }

    Number.prototype.toPercentPaString = function (dps) {
        return this.toScaledValueString(PERCENTAGE_PA, dps);
    }

    Number.prototype.toAmountStringNoUnits = function (dps) {
        return this.toScaledValueStringNoUnits(AMOUNT, dps);
    }

    Number.prototype.toAmountStringNoScalingNoUnits = function (dps) {
        return this.toValueStringNoUnits(AMOUNT, dps);
    }

    Number.prototype.toPercentStringNoUnits = function (dps) {
        return this.toScaledValueStringNoUnits(PERCENTAGE, dps);
    }

    function scaledString (value, formatting, dps) {
        var decimals = dps == null ? formatting.decimals : dps;
        return numberFormat(value / formatting.scaleFactor, decimals, formatting.prefix, formatting.suffix);
    }

    function scaledStringNoUnits (value, formatting, dps) {
        var decimals = dps == null ? formatting.decimals : dps;
        return numberFormat(value / formatting.scaleFactor, decimals);
    }

    function unscaledStringNoUnits (value, formatting, dps) {
        var decimals = dps == null ? formatting.decimals : dps;
        return numberFormat(value, decimals);
    }

    //change to fixed value
    Number.prototype.toFixed = function (d) {

        var value = "" + Math.round((this + (1 / (Math.pow(10, d + 4)))) * Math.pow(10, d)) / (Math.pow(10, d));

        if (d > 0) {
            var splitValue = value.split(".");

            if (splitValue.length == 1) splitValue.push("0");

            while (splitValue[1].length < d) splitValue[1] += "0";

            value = splitValue[0] + "." + splitValue[1];
        }

        return value;
    };

    var msInHour = 60 * 60 * 1000;
    var msInDay = 24 * msInHour;
    var elevenHoursThroughDay = msInHour * 11;

    String.prototype.ToUtcDate = function(daysToAdd) {
        var newDate = new Date(this);
        return newDate.ToUtcDate(daysToAdd);
    };

    Date.prototype.ToUtcDate = function(daysToAdd) {
        var newDate = this;
        newDate.setTime(newDate.getTime() + elevenHoursThroughDay);
        if (daysToAdd != null) newDate.setTime(newDate.getTime() + daysToAdd * msInDay);
        return Math.round(newDate.getTime() / msInDay, 0) * msInDay;
    };

    String.prototype.ToDateString = function() {
        return getDateString(this);
    };

    Date.prototype.ToDateString = function() {
        return getDateString(this);
    };

    Number.prototype.ToDateString = function() {
        return getDateString(this);
    };

    String.prototype.ToDayAndMonthString = function() {
        return getDateToDayAndMonthString(this);
    };

    Date.prototype.ToDayAndMonthString = function() {
        return getDateToDayAndMonthString(this);
    };

    Number.prototype.ToDayAndMonthString = function() {
        return getDateToDayAndMonthString(this);
    };

    String.prototype.ToDateTimeString = function() {
        return getDateTimeString(this);
    };

    Date.prototype.ToDateTimeString = function() {
        return getDateTimeString(this);
    };

    Number.prototype.ToDateTimeString = function() {
        return getDateTimeString(this);
    };

    function getDateTimeString (value) {
        return moment(value).format('DD/MM/YYYY HH:mm:ss');
    }

    function getDateString (value) {
        return moment(value).format('DD MMM YYYY');
    }

    function getDateToDayAndMonthString (value) {
        return moment(value).format('DD MMMM');
    }

    Date.prototype.ToUrlSafeDateString = function() {
        return moment(this).format('DD-MM-YYYY');
    }

    String.prototype.ToUrlSafeDateString = function() {
        return moment(this).format('DD-MM-YYYY');
    }

    Date.prototype.ToTDateString = function() {
        return moment(this).format('YYYY-MM-DDTHH:mm:ss');
    }

    Date.prototype.IsInvalidSplitDate = function(dateSplit) {
        var day = dateSplit[0];
        var month = dateSplit[1] - 1;
        var year = dateSplit[2];
        return this.getDate() != day || this.getMonth() != month || this.getFullYear() != year;
    }

    String.prototype.ToTDateString = function() {
        return moment(this).format('YYYY-MM-DDTHH:mm:ss');
    }

    String.prototype.FromUrlSafeDateString = function() {
        var dateSplit = this.split('-');
        if (parseInt(dateSplit[1]) === 0 || parseInt(dateSplit[0]) === 0) return null;
        var date = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
        return isNaN(date) ? null : date.ToTDateString();
    }

    Date.prototype.withoutTime = function () {
        var d = new Date(this);
        d.setHours(0, 0, 0, 0, 0);
        return d;
    };

    String.prototype.ToUrlSafeString = function() {
        const output = this.replace(/ /g, '-')
            .replace(/\(/g, '-lb-')
            .replace(/\)/g, '-rb-')
            // .replace(/-/g, '-neg-') // Uncomment if we decide to replace hyphens
            .replace(/\+/g, '-pos-')
            .replace(/&/g, '-and-')
            .replace(/£/g, '-pound-')
            .replace(/\$/g, '-dollar-')
            .replace(/\//g, '-fs-')
            .replace(/\\/g, '-bs-')
            .replace(/"/g, '-qm-')
            .replace(/'/g, '-apos-')
            .replace(/\*/g, '-star-')
            .replace(/!/g, '-bang-')
            .replace(/\[/g, '-srb-')
            .replace(/\]/g, '-slb-')
            .replace(/{/g, '-crb-')
            .replace(/}/g, '-clb-')
            .replace(/%/g, '-perc-')
            .replace(/@/g, '-ats-')
            .replace(/#/g, '-hash-')
            .replace(/~/g, '-approx-')
            .replace(/\./g, '-stop-')
            .replace(/,/g, '-comma-')
            .replace(/\?/g, '-qsmark-')
            .replace(/</g, '-lt-')
            .replace(/>/g, '-mt-')
            .replace(/=/g, '-equals-')
            .replace(/\|/g, '-or-')
            .replace(/\^/g, '-pow-')
            .replace(/:/g, '-colon-')
            .replace(/;/g, '-scolon-');
        return output.toLowerCase();
    }

    Number.prototype.isSameAs = function(comparator) {
        return Math.abs(this - comparator) < 0.000001;
    }

    String.prototype.isSameAs = function(comparator) {
        return this === comparator;
    }

    Date.prototype.isSameAs = function(comparator) {
        return this === comparator;
    }

    function getRatioSafe(numerator, denominator) {
        if (denominator == null || denominator == 0) return 0;
        if (denominator == null || numerator == 0) return 0;
        return numerator / denominator - 1;
    }

    function isMergeableObject(val) {
        var nonNullObject = val && typeof val === 'object'

        return nonNullObject
            && Object.prototype.toString.call(val) !== '[object RegExp]'
            && Object.prototype.toString.call(val) !== '[object Date]'
    }

    function emptyTarget(val) {
        return Array.isArray(val) ? [] : {}
    }

    function cloneIfNecessary(value, optionsArgument) {
        var clone = optionsArgument && optionsArgument.clone === true
        return (clone && isMergeableObject(value)) ? deepmerge(emptyTarget(value), value, optionsArgument) : value
    }

    function defaultArrayMerge(target, source, optionsArgument) {
        var destination = target.slice()
        source.forEach(function(e, i) {
            if (typeof destination[i] === 'undefined') {
                destination[i] = cloneIfNecessary(e, optionsArgument)
            } else if (isMergeableObject(e)) {
                destination[i] = deepmerge(target[i], e, optionsArgument)
            } else if (target.indexOf(e) === -1) {
                destination.push(cloneIfNecessary(e, optionsArgument))
            }
        })
        return destination
    }

    function mergeObject(target, source, optionsArgument) {
        var destination = {}
        if (isMergeableObject(target)) {
            Object.keys(target).forEach(function (key) {
                destination[key] = cloneIfNecessary(target[key], optionsArgument)
            })
        }
        Object.keys(source).forEach(function (key) {
            if (!isMergeableObject(source[key]) || !target[key]) {
                destination[key] = cloneIfNecessary(source[key], optionsArgument)
            } else {
                destination[key] = deepmerge(target[key], source[key], optionsArgument)
            }
        })
        return destination
    }

    function deepmerge(target, source, optionsArgument) {
        var array = Array.isArray(source);
        var options = optionsArgument || { arrayMerge: defaultArrayMerge }
        var arrayMerge = options.arrayMerge || defaultArrayMerge

        if (array) {
            return Array.isArray(target) ? arrayMerge(target, source, optionsArgument) : cloneIfNecessary(source, optionsArgument)
        } else {
            return mergeObject(target, source, optionsArgument)
        }
    }

    /**
    * Returns true if the object is not null or undefined. Like MooTools' $.defined.
    * @param {Object} obj
    */
    function defined(obj) {
        return obj !== undefined && obj !== null;
    }

    /**
    * Return the first value that is defined. Like MooTools' $.pick.
    */
    function pick() {
        var args = arguments,
		i,
		arg,
		length = args.length;
        for (i = 0; i < length; i++) {
            arg = args[i];
            if (typeof arg !== 'undefined' && arg !== null) {
                return arg;
            }
        }
    }

    /**
    * Extend an object with the members of another
    * @param {Object} a The object with defaults
    * @param {Object} b The object to be merged with new one
    */
    function extend(a, b) {
        /* merge defaults and options, without modifying defaults */
        return $.extend(true, {}, a, b);
    }

    /**
    * Is object array?
    * @param {Object} s
    */
    function isArray(s) {
        if (s == null) return false;
        return s.constructor == Array;
    }

    /**
    * Shortcut for parseInt
    * @param {Object} s
    */
    function pInt(s, mag) {
        return parseInt(s, mag || 10);
    }

    /**
    * Shortcut for parseFloat
    * @param {Object} s
    */
    function pFloat(s, mag) {
        return parseFloat(s, mag || 10);
    }

    /**
    * Check for string
    * @param {Object} s
    */
    function isString(s) {
        return typeof s === 'string';
    }

    /**
    * Check for object
    * @param {Object} obj
    */
    function isObject(obj) {
        return typeof obj === 'object';
    }

    /**
    * Check for number
    * @param {Object} n
    */
    function isNumber(n) {
        return typeof n === 'number';
    }

    /**
    * Format a number and return a string based on input settings
    * @param {Number} number The input number to format
    * @param {Number} decimals The amount of decimals
    */
    function numberFormat(number, decimals, prefix, suffix) {
        // http://kevin.vanzonneveld.net/techblog/article/javascript_equivalent_for_phps_number_format/
        var roundedNumber = mathAbs(number || 0).toFixed(decimals);
        var i = String(pInt(roundedNumber));
        var j = i.length > 3 ? i.length % 3 : 0;
        var thousandsSep = ',';

        var valueAsString = (j ? i.substr(0, j) + thousandsSep : "")
                            + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSep)
                            + (decimals > 0 ? '.' + mathAbs(roundedNumber - i).toFixed(decimals).slice(2) : "");

        var pre = prefix === undefined ? "" : prefix;
        var suf = suffix === undefined ? "" : suffix;

        var output = pre + valueAsString + suf;
        if (number > 0 || number.isSameAs(0)) return output;
        return '(' + output + ')';
    }

    /**
    * Based on http://www.php.net/manual/en/function.strftime.php
    * @param {String} format
    * @param {Number} timestamp
    * @param {Boolean} capitalize
    */
     function dateFormat(format, timestamp, capitalize) {
        function pad(number) {
            return number.toString().replace(/^([0-9])$/, '0$1');
        }

        if (!defined(timestamp) || isNaN(timestamp)) {
            return 'Invalid date';
        }
        format = pick(format, '%Y-%m-%d %H:%M:%S');

        var date = new Date(timestamp),
		key, // used in for constuct below
        // get the basic time values
		hours = date["getHours"](),
		day = date["getDay"](),
		dayOfMonth = date["getDate"](),
		month = date["getMonth"](),
		fullYear = date["getFullYear"](),
		langWeekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        langMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'],
        /* // uncomment this and the 'W' format key below to enable week numbers
        weekNumber = function() {
        var clone = new Date(date.valueOf()),
        day = clone[getDay]() == 0 ? 7 : clone[getDay](),
        dayNumber;
        clone.setDate(clone[getDate]() + 4 - day);
        dayNumber = mathFloor((clone.getTime() - new Date(clone[getFullYear](), 0, 1, -6)) / 86400000);
        return 1 + mathFloor(dayNumber / 7);
        },
        */

        // list all format keys
		replacements = {

		    // Day
		    'a': langWeekdays[day].substr(0, 3), // Short weekday, like 'Mon'
		    'A': langWeekdays[day], // Long weekday, like 'Monday'
		    'd': pad(dayOfMonth), // Two digit day of the month, 01 to 31
		    'e': dayOfMonth, // Day of the month, 1 through 31

		    // Week (none implemented)
		    //'W': weekNumber(),

		    // Month
		    'b': langMonths[month].substr(0, 3), // Short month, like 'Jan'
		    'B': langMonths[month], // Long month, like 'January'
		    'm': pad(month + 1), // Two digit month number, 01 through 12

		    // Year
		    'y': fullYear.toString().substr(2, 2), // Two digits year, like 09 for 2009
		    'Y': fullYear, // Four digits year, like 2009

		    // Time
		    'H': pad(hours), // Two digits hours in 24h format, 00 through 23
		    'I': pad((hours % 12) || 12), // Two digits hours in 12h format, 00 through 11
		    'l': (hours % 12) || 12, // Hours in 12h format, 1 through 12
		    'M': pad(date["getMinutes"]()), // Two digits minutes, 00 through 59
		    'p': hours < 12 ? 'AM' : 'PM', // Upper case AM or PM
		    'P': hours < 12 ? 'am' : 'pm', // Lower case AM or PM
		    'S': pad(date.getSeconds()) // Two digits seconds, 00 through  59

		};


        // do the replaces
        for (key in replacements) {
            format = format.replace('%' + key, replacements[key]);
        }

        // Optionally capitalize the string and return
        return capitalize ? format.substr(0, 1).toUpperCase() + format.substr(1) : format;
    };

    function tidy_json_object_str(str) {
        var output = str.replace(/&amp;/g, '&').replace(/&pound;/g, '�').replace(/&\#36;/g, '\$').replace(/&\#37;/g, '%').replace(/&\#44;/g, ',').replace(/quotation_mark_replacer/g, '\\\"').replace(/\\'/g, "'").replace(/&apos;/g, "'");
        return output;
    }

    /**
    * Stringify data if object to string
    * @param {object} a input object
    */
    function stringifyData(a) {
        try {
            if (typeof a === 'string') {
                return a;
            } else if (typeof a === 'object') {
                return win.JSON.stringify(a, function (key, val) {
                    if (typeof val === 'function') {
                        return val + ''; // implicitly `toString` it
                    }

                    return val;
                });
            }
        } catch (err) {
            // report error
            LCP.report.console("stringify JSON Error - " + err.message);
            LCP.report.showError("stringify JSON Error - " + err.message);
            // return false
            return false;
        }
    }

    /**
    * Parse data if object of string to object
    * @param {Object / string} a input object
    */
    function parseData(a) {
        try {
            if (typeof a === 'string') {

                a = tidy_json_object_str(a);

                return win.JSON.parse(a, function (key, value) {
                    var type;
                    if (value && typeof value === 'object') {
                        type = value.type;
                        if (typeof type === 'string' && typeof window[type] === 'function') {
                            return new (window[type])(value);
                        }
                    }

                    if (typeof value === 'string') {

                        if (value.indexOf("function(") != -1 || value.indexOf("function (") != -1 || value.indexOf("function anonymous()") != -1 || value.indexOf("function anonymous ()") != -1) {

                            var fromArgs = value.indexOf("(") + 1;
                            var toArgs = value.indexOf(')');

                            var from = value.indexOf("{") + 1;
                            var to = value.lastIndexOf('}');

                            var args = value.substring(fromArgs, toArgs);
                            var functionInner = value.substring(from, to);

                            if (args.length > 0) {

                                return new Function(args, functionInner);
                            } else {

                                return new Function(functionInner);
                            }

                        }
                    }

                    return value;
                });
            } else if (typeof a === 'object') {
                return a;
            }
        } catch (err) {
            // report error
            LCP.report.console("JSON Error" + err.message);
            //console.log(/JSON Error" + a);
            LCP.report.showError("JSON Error" + err.message);
            // return false
            return a;
        }
    }

    /**
    * Parse functions
    */
    function parseFunctionOnly(a) {
        try {

            for (var n in a) {

                var val = a[n];

                if (typeof val === 'object') {
                    val = parseFunctionOnly(val);
                } else if (typeof val === 'string') {

                    if (val.indexOf("function(") != -1 || val.indexOf("function (") != -1) {

                        var fromArgs = val.indexOf("(") + 1;
                        var toArgs = val.indexOf(')');

                        var from = val.indexOf("{") + 1;
                        var to = val.lastIndexOf('}');

                        var args = val.substring(fromArgs, toArgs);
                        var functionInner = val.substring(from, to);

                        if (args.length > 0) {

                            val = Function(args, functionInner);
                        } else {

                            val = Function(functionInner);
                        }
                    }
                }

                a[n] = val;

            }

            return a;

        } catch (err) {
            // return false
            return a;
        }
    }

    /**
    * Parse data if object of string to object
    * @param {Object / string} a input object
    */
    function parseDataOnly(a) {
        try {
            if (typeof a === 'string') {
                return win.JSON.parse(a, function (key, value) {
                    var type;
                    if (value && typeof value === 'object') {
                        type = value.type;
                        if (typeof type === 'string' && typeof window[type] === 'function') {
                            return new (window[type])(value);
                        }
                    }

                    if (typeof value === 'string') {


                        if (value.indexOf("function(") != -1 || value.indexOf("function (") != -1) {

                            var fromArgs = value.indexOf("(") + 1;
                            var toArgs = value.indexOf(')');

                            var from = value.indexOf("{") + 1;
                            var to = value.lastIndexOf('}');

                            var args = value.substring(fromArgs, toArgs);
                            var functionInner = value.substring(from, to);

                            if (args.length > 0) {

                                return new Function(args, functionInner);
                            } else {

                                return new Function(functionInner);
                            }
                        }
                    }

                    return value;
                });
            } else if (typeof a === 'object') {
                return a;
            }
        } catch (err) {
            // return false
            return a;
        }
    }

    /**
    * Parse data if object of string to object
    * @param {Object / string} a input object
    */
    function parseFunction(a) {
        try {
            if (typeof a === 'string') {

                if (a.indexOf("function(") != -1) {

                    var fromArgs = a.indexOf("(") + 1;
                    var toArgs = a.indexOf(')');

                    var from = a.indexOf("{") + 1;
                    var to = a.lastIndexOf('}');

                    var args = a.substring(fromArgs, toArgs);
                    var functionInner = a.substring(from, to);

                    if (args.length > 0) {

                        return new Function(args, functionInner);
                    } else {

                        return new Function(functionInner);
                    }
                } else {
                    return a;
                }

            } else if (typeof a === 'object') {
                return a;
            }
        } catch (err) {
            // return false
            return a;
        }
    }

    /**
    *  Creates an integer by rounding to 0 dp
    * @param {double} a input value
    */
    function round(a) {
        return Math.round(a);
    }

    function roundTo(a, b) {
        return Math.round(a * Math.pow(10, b)) / Math.pow(10, b);
    }

    /**
    *  returns object len
    * @param {object} a
    */
    function objLen(a) {

        var c = 0;


        if (typeof a != "object") { return c; }

        for (var n in a) {
            c++;
        }

        return c;
    }

    /**
    *  see if object in array?
    * @param {array} a
    * @param {object string} b
    */
    function arrayFind(a, b) {

        for (var i in a) {
            if (a[i] == b) return true;
        }
    }

    /**
    *  see if arrays are equal?
    * @param {array} a
    * @param {array} b
    */
    function arrayEqual(a, b) {

        for (var i in a) {
            if (a[i] != b[i]) return false;
        }

        return true;
    }

    /**
    *  see if object in array?
    * @param {array} a
    * @param {object string} b
    */
    function arrayFindIndex(a, b) {

        var j = 0;

        if (isNumber(b)){
            for (var i in a) {
                if (a[i].isSameAs(b)) return j;
                j++
            }
        }else{
            for (var i in a) {
                if (a[i] == b) return j;

                j++
            }
        }

        return null;
    }

    function arrayFindIndexNearest(options, searchValue, displayType) {

        var a = options;
        var b = searchValue;
        var isDate = false;

        switch(displayType) {
            case DATE:
                isDate = true;
                b = searchValue.ToUtcDate();
                a = [];

                for (var i = 0; i < options.length; i++) {
                    a.push(options[i].ToUtcDate());
                };
        }

        var j = 0;

        if (isDate || isNumber(b)){

            if (b < a[0]) return 0;
            if (b > a[a.length-1]) return a.length -1;

            for (var i in a) {

              if (a[i].isSameAs(b)) return j;
              if (j == a.length - 1) return j;

                var dist = a[j+1] - a[j];

                if (b < a[j+1] && b >= a[j]) {

                    if (b < a[j] + dist/2) return j;

                    return j+1;

                }

                j++
            }
        }else{
            for (var i in a) {
                if (a[i] == b) return j;

                j++
            }
        }
    }

    function doesExist(obj, values) {

        var objCheck = extend({}, obj);
        var returnAns = true;

        $.each(values, function (index, value){

            if (objCheck[value] == undefined) {
                returnAns = false;
                return false;
            }

            objCheck = extend({},objCheck[value]);

        });

        return returnAns;
    }

    function findHeader(strHeader, arrHeader) {

        var retVal = -1;

        $.each(arrHeader, function (index, value) {

            if (value == strHeader) {
                retVal = index;
            }

        });

        return retVal;

    }

    function queryString() {
        // This function is anonymous, is executed immediately and
        // the return value is assigned to QueryString!
        var query_string = {};
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            // If first entry with this name
            if (typeof query_string[pair[0]] === "undefined") {
                query_string[pair[0]] = pair[1];
                // If second entry with this name
            } else if (typeof query_string[pair[0]] === "string") {
                var arr = [query_string[pair[0]], pair[1]];
                query_string[pair[0]] = arr;
                // If third or later entry with this name
            } else {
                query_string[pair[0]].push(pair[1]);
            }
        }
        return query_string;

    }

    function stringHelper(value) {
        return value.replace(/ /g, "-")
            .replace(/\(/g, "-lb-")
            .replace(/\)/g, "-rb-")
            .replace(/\+/g, "-pos-")
            .replace(/\&/g, "-and-")
            .replace(/\�/g, "-pound-")
            .replace(/\$/g, "-dollar-")
            .replace(/\//g, "-fs-")
            .replace(/\\/g, "-bs-")
            .replace(/\\"/g, "-qm-")
            .replace(/\\'/g, "-apos-")
            .replace(/\*/g, "-star-")
            .replace(/\!/g, "-bang-")
            .replace(/\[/g, "-srb-")
            .replace(/\]/g, "-slb-")
            .replace(/\{/g, "-crb-")
            .replace(/\}/g, "-clb-")
            .replace(/\%/g, "-perc-")
            .replace(/\*/g, "-star-")
            .replace(/\@/g, "-ats-")
            .replace(/\#/g, "-hash-")
            .replace(/\~/g, "-approx-")
            .replace(/\./g, "-stop-")
            .replace(/\,/g, "-comma-")
            .replace(/\?/g, "-qsmark-")
            .replace(/\</g, "-lt-")
            .replace(/\>/g, "-mt-")
            .replace(/\=/g, "-equals-")
            .replace(/\|/g, "-or-")
            .replace(/\^/g, "-pow-")
            .replace(/\:/g, "-colon-")
            .replace(/\;/g, "-scolon-")
            .replace(/\"/g, "-dblqmark-")
            .replace(/\'/g, "-sglqmark-");
    }

    function getSortValuesFromKeyedData (keyedData) {
        var sortedKeys = Object.keys(keyedData).sort();
        var output = [];

        for (let i = 0; i < sortedKeys.length; i++) {
            const key = sortedKeys[i];
            const data = keyedData[key];
            output.push(data);
        }

        return output;
    }

    function getDpsRequiredToShowAllValues (values, scalingType) {
        var dps = 0;
        var dpsNotFound = true;

        while (dpsNotFound) {
            var successfulTest = true;

            for (let i = 0; i < values.length; i++) {
                const value = values[i].toScaledValue(scalingType);
                if (!value.isSameAs(value.toFixed(dps))) {
                    successfulTest = false;
                    break;
                }
            }

            if (successfulTest) dpsNotFound = false;
            else dps = dps + 1;
        }

        return dps;
    }

    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    win.LCP = {

    }

    /*Blue*/
    const PRIMARY = 'rgb(0, 163, 199)';
    const PRIMARYTINT1 = 'rgb(0, 176, 207)';
    const PRIMARYTINT2 = 'rgb(94, 191, 216)';
    const PRIMARYTINT3 = 'rgb(149, 209, 227)';
    const PRIMARYTINT4 = 'rgb(201, 230, 238)';
    /*Marine*/
    const SECONDARY = 'rgb(0, 47, 95)';
    const SECONDARYTINT1 = 'rgb(52, 92, 128)';
    const SECONDARYTINT2 = 'rgb(98, 126, 155)';
    const SECONDARYTINT3 = 'rgb(140, 163, 182)';
    const SECONDARYTINT4 = 'rgb(193, 205, 216)';
    /*Grapefruit*/
    const BRIGHT = 'rgb(247, 166, 0)';
    const BRIGHTTINT1 = 'rgb(252, 182, 76)';
    const BRIGHTTINT2 = 'rgb(254, 199, 121)';
    const BRIGHTTINT3 = 'rgb(255, 216, 161)';
    const BRIGHTTINT4 = 'rgb(255, 234, 205)';
    /*Watermelon*/
    const ALTERNATIVE1 = 'rgb(233, 63, 111)';
    const ALTERNATIVE1TINT1 = 'rgb(242, 116, 140)';
    const ALTERNATIVE1TINT2 = 'rgb(245, 149, 163)';
    const ALTERNATIVE1TINT3 = 'rgb(248, 183, 189)';
    const ALTERNATIVE1TINT4 = 'rgb(252, 218, 219)';
    /*Grey*/
    const ALTERNATIVE2 = 'rgb(141, 168, 173)';
    const ALTERNATIVE2TINT1 = 'rgb(146, 176, 181)';
    const ALTERNATIVE2TINT2 = 'rgb(169, 193, 197)';
    const ALTERNATIVE2TINT3 = 'rgb(193, 211, 214)';
    const ALTERNATIVE2TINT4 = 'rgb(220, 230, 231)';
    const DISABLED = 'rgba(211, 211, 211, 1)'
    /*Lime*/
    const ALTERNATIVE3 = 'rgb(212, 220, 92)';
    /*Forest green*/
    const ALTERNATIVE4 = 'rgb(1, 83, 87)';
    /*Aqua*/
    const ALTERNATIVE5 = 'rgb(0, 143, 143)';
    /*Lilac*/
    const ALTERNATIVE6 = 'rgb(89, 89, 154)';
    /*Pale pink*/
    const ALTERNATIVE7 = 'rgb(232, 192, 220)';
    /*Cherry*/
    const ALTERNATIVE8 = 'rgb(130, 26, 77)';

    const LCPColours = [PRIMARY, SECONDARY, ALTERNATIVE2, ALTERNATIVE1, BRIGHT, ALTERNATIVE4, ALTERNATIVE3, ALTERNATIVE5, ALTERNATIVE6, ALTERNATIVE7, ALTERNATIVE8];

    win.LCP.colours = {
        /*Blue*/
        PRIMARY: PRIMARY,
        PRIMARYTINT1: PRIMARYTINT1,
        PRIMARYTINT2: PRIMARYTINT2,
        PRIMARYTINT3: PRIMARYTINT3,
        PRIMARYTINT4: PRIMARYTINT4,
        /*Marine*/
        SECONDARY: SECONDARY,
        SECONDARYTINT1: SECONDARYTINT1,
        SECONDARYTINT2: SECONDARYTINT2,
        SECONDARYTINT3: SECONDARYTINT3,
        SECONDARYTINT4: SECONDARYTINT4,
        /*Grapefruit*/
        BRIGHT: BRIGHT,
        BRIGHTTINT1: BRIGHTTINT1,
        BRIGHTTINT2: BRIGHTTINT2,
        BRIGHTTINT3: BRIGHTTINT3,
        BRIGHTTINT4: BRIGHTTINT4,
        /*Watermelon*/
        ALTERNATIVE1: ALTERNATIVE1,
        ALTERNATIVE1TINT1: ALTERNATIVE1TINT1,
        ALTERNATIVE1TINT2: ALTERNATIVE1TINT2,
        ALTERNATIVE1TINT3: ALTERNATIVE1TINT3,
        ALTERNATIVE1TINT4: ALTERNATIVE1TINT4,
        /*Grey*/
        ALTERNATIVE2: ALTERNATIVE2,
        ALTERNATIVE2TINT1: ALTERNATIVE2TINT1,
        ALTERNATIVE2TINT2: ALTERNATIVE2TINT2,
        ALTERNATIVE2TINT3: ALTERNATIVE2TINT3,
        ALTERNATIVE2TINT4: ALTERNATIVE2TINT4,
        DISABLED: DISABLED,
        /*Lime*/
        ALTERNATIVE3: ALTERNATIVE3,
        /*Forest green*/
        ALTERNATIVE4: ALTERNATIVE4,
        /*Aqua*/
        ALTERNATIVE5: ALTERNATIVE5,
        /*Lilac*/
        ALTERNATIVE6: ALTERNATIVE6,
        /*Pale pink*/
        ALTERNATIVE7: ALTERNATIVE7,
        /*Cherry*/
        ALTERNATIVE8: ALTERNATIVE8,
    };

    win.LCP.con = {
        NONE: NONE,
        DATE: DATE,
        ACCOUNTINGAMOUNT: ACCOUNTINGAMOUNT,
        AMOUNT: AMOUNT,
        AMOUNT_PA: AMOUNT_PA,
        PERCENTAGE: PERCENTAGE,
        PERCENTAGE_PA: PERCENTAGE_PA,
        MULTIPLIER: MULTIPLIER,
        LCPColours: LCPColours
    };

    // Output functions as part of LCP name space
    win.LCP.fn = {
        extend: extend,
        isArray: isArray,
        isStr: isString,
        isNum: isNumber,
        isObj: isObject,
        setFormattingOptions: setFormattingOptions,
        getFormattingOptionsForType: getFormattingOptionsForType,
        getAmountFormattingOptions: getAmountFormattingOptions,
        getPercentFormattingOptions: getPercentFormattingOptions,
        dateFormat: dateFormat,
        numFormat: numberFormat,
        deepmerge: deepmerge,
        JSON: parseData,
        stringify: stringifyData,
        parseFunctionOnly: parseFunctionOnly,
        int: round,
        roundTo: roundTo,
        parseString: parseDataOnly,
        parseFunction: parseFunction,
        arrayFind: arrayFind,
        arrayFindIndex: arrayFindIndex,
        arrayFindIndexNearest: arrayFindIndexNearest,
        arrayEqual: arrayEqual,
        objLen: objLen,
        doesExist: doesExist,
        findHeader: findHeader,
        queryString: queryString,
        stringHelper: stringHelper,
        getSortValuesFromKeyedData: getSortValuesFromKeyedData,
        getDpsRequiredToShowAllValues: getDpsRequiredToShowAllValues,
        validateEmail: validateEmail
    };

})();
