<template>
    <consolidatedDeriskingPage v-if="consolidated"/>
    <deriskingPage v-else/>
</template>

<script>

import deriskingPage from '@/components/pages/schemeDisplays/derisking/pageDerisking.vue';
import consolidatedDeriskingPage from '@/components/pages/schemeDisplays/derisking/pageDeriskingConsolidated.vue';

export default {
    components: {
        deriskingPage,
        consolidatedDeriskingPage
    },
    created () {
        this.consolidated = this.$staticStore.state.derisking.config.consolidated;
    }
};

</script>
