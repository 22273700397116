<template>
    <div class="header dropdownTitle">
        <span class="h1 paddedRight" v-html="title" v-if="title !== noPrefix"/>
        <dropdown class="customDropdown" :inputTemplate="inputTemplate" :valueOverride="valueOverride" @currentvalue="dropdownSelect" title-header v-if="inputTemplate" />
    </div>
</template>

<script>

import dropdown from '@/components/common/uiInputs/dropdown.vue';

export default {
    props: ['inputTemplate', 'valueOverride', 'dropdownPrefix'],
    name: 'dropdownTitle',
    components: {
        dropdown
    },
    created () {
        this.noPrefix = '&nbsp;';
    },
    methods: {
        dropdownSelect (option) {
            this.$emit('currentvalue', option, this.inputTemplate.id);
        }
    },
    computed: {
        title () {
            return this.dropdownPrefix ? this.dropdownPrefix : this.noPrefix;
        }
    },
    getDefaultInputTemplate (id, options, value, resetValue, suppressPageUpdate) {
        return dropdown.getDefaultInputTemplate(id, options, value, resetValue, suppressPageUpdate);
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import 'src/assets/scss/all.scss';

.header {
    margin-top:1.6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .customDropdown {
        margin-bottom: 0;
    }
    .multiselect {
        flex-grow: 1;
    }
    .h1 {
        margin-top:0 !important;
    }
    .paddedRight {
        padding-right:5px;
    }
}
.grow {
    flex-grow:1;
}

</style>
