<template>

<div class="sideNavBar" :class="{'mobileOpen': $store.state.toggleMenu}">
    <ul class="items mobileItems spacer">
        <li @click="backToSchemes">
            <div>
                <span class="navIcon">
                    <i class="icon icon-home"/>
                </span>
                <span class="navBarName">Back to schemes</span>
            </div>
        </li>
    </ul>
    <ul class="items spacer">
        <li @click="navigate(navItem.url)" :key="i" v-for="(navItem, i) in nav">
            <div :class="($store.state.session.pageName === navItem.pageName ? 'activeItem' : '')">
                <span class="navIcon">
                    <i :class="['icon', 'icon-'+navItem.icon]"/>
                </span>
                <span v-if="navItem.additionalOptions" class="settingIconSmall">
                    <i :class="['icon', 'icon-' + navItem.additionalOptions.subIcon]"/>
                </span>
                <span class="navBarName">{{navItem.navBarName}}</span>
                <span v-if="navItem.additionalOptions" class="settingIcon" @click="onClick(navItem.additionalOptions)" :class="($store.state.session.pageName === navItem.pageName ? '' : 'hide')">
                    <i :class="['icon', 'icon-' + navItem.additionalOptions.subIcon]"/>
                </span>
            </div>
        </li>
    </ul>
    <ul class="items mobileItems">
        <li @click="routeToSchemePage('information')">
            <div>
                <span class="navIcon">
                    <i class="icon icon-info"/>
                </span>
                <span class="navBarName">Key information</span>
            </div>
        </li>
        <li @click="routeToSchemePage('contacts')">
            <div>
                <span class="navIcon">
                    <i class="icon icon-info"/>
                </span>
                <span class="navBarName">Contacts</span>
            </div>
        </li>
        <li @click="routeToSchemePage('terms')">
            <div>
                <span class="navIcon">
                    <i class="icon icon-info"/>
                </span>
                <span class="navBarName">Terms</span>
            </div>
        </li>
        <li @click="routeToSchemePage('privacy')">
            <div>
                <span class="navIcon">
                    <i class="icon icon-info"/>
                </span>
                <span class="navBarName">Privacy</span>
            </div>
        </li>
    </ul>
</div>

</template>

<script>

function getNavBarAdditionalOptions (commitMethod, subIcon) {
    const additionalOptions = {
        commitMethod: commitMethod,
        subIcon: subIcon
    };
    return additionalOptions;
}

function addNavBarItem (itemsToAddTo, pageName, navBarName, icon, additionToUrl = null, additionalOptions = null) {
    let pageLinkUrl = pageName;
    if (additionToUrl) pageLinkUrl += `/${additionToUrl}`;

    const navBarItem = {
        pageName: pageName,
        navBarName: navBarName,
        icon: icon,
        url: pageLinkUrl,
        additionalOptions: additionalOptions
    };

    itemsToAddTo.push(navBarItem);
}

function getNavBarItems (config) {
    if (config == null) return null;

    const output = [];
    addNavBarItem(output, 'dashboard', 'Dashboard', 'Dashboard', null, getNavBarAdditionalOptions('editDashboardOpen', 'settings'));
    if (config.historyAndExperienceModule.includeHistoryAndExperienceModule) addNavBarItem(output, 'historyandexperience', 'History and experience', 'History');
    if (config.includeRecoveryPlanModule) addNavBarItem(output, 'recoveryplan', config.recoveryPlanDisplayName, 'Recovery');
    if (config.investmentStrategyModule.includeInvestmentStrategyModule) addNavBarItem(output, 'investmentstrategy', 'Investment strategy', 'Investment');
    if (config.includeSensitivitiesModule) addNavBarItem(output, 'sensitivities', 'What-if?', 'What-if', config.sensitivitiesDefaultFundingMeasure.ToUrlSafeString());
    if (config.includeIrmModule) addNavBarItem(output, 'irm', 'Integrated risk', 'IRM');
    if (config.includeAccountingModule) addNavBarItem(output, 'accounting', 'Accounting', 'Accounting');
    if (config.triggersModule.includeTriggersModule) addNavBarItem(output, 'triggers', 'Triggers', 'Triggers');
    if (config.insurerPricingModule.includeInsurerPricingModule && !config.insurerPricingModule.availableOnRequest) addNavBarItem(output, 'insurerpricing', 'Insurer pricing', 'Insurer-pricing');
    if (config.includeResultsSummaryModule) addNavBarItem(output, 'resultssummary', 'Results summary', 'Review');
    if (config.includeRecoveryRequirementsBySchemeModule) addNavBarItem(output, 'recoveryrequirementsbyscheme', 'Recovery requirements', 'Recovery');
    if (config.includeCashflowsModule) addNavBarItem(output, 'cashflows', 'Cashflows', 'Cashflow');
    if (config.reportingModule.includeReportingModule) addNavBarItem(output, 'reporting', 'Reporting', 'Review');
    if (config.includeMarketDataModule) addNavBarItem(output, 'marketdata', 'Market data', 'Markets');
    return output;
}

export default {
    computed: {
        visualiseAiConfig () {
            return this.$store.state.scheme.visualiseAiConfig;
        },
        nav () {
            return getNavBarItems(this.visualiseAiConfig);
        }
    },
    methods: {
        navigate (page) {
            this.$store.commit('toggleMenu', false);
            this.$store.dispatch('routeToSchemePage', page).then(() => {}).catch(() => {});
        },
        onClick (additionalOptions) {
            this.$store.commit('toggleMenu', false);
            event.stopPropagation();

            if (this.$store.state.pageState !== 'ready') return;

            if (additionalOptions.commitMethod) this.$store.commit(additionalOptions.commitMethod);
        },
        backToSchemes () {
            this.$store.commit('toggleMenu', false);
            this.$router.push('/userschemes');
        },
        backToManageSchemes () {
            this.$store.commit('toggleMenu', false);
            this.$router.push('/manageschemes');
        },
        routeToSchemePage (route) {
            this.$store.commit('toggleMenu', false);
            this.$store.dispatch('routeToSchemePage', route).then(() => {}).catch(() => {});
        }
    }
};

</script>

<style scoped lang="scss">

@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/mixins.scss';
@import 'src/assets/scss/base.scss';

.sideNavBar {
    position: absolute;
    left: 0px;
    width: $condensedNavWidth;
    height: calc(100% - var(--headerHeight));
    color: white;
    background: $LCPMarine;
    background-image: url('../../../assets/images/cross-pattern-fade-bottom-right.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    transition: all 0.5s ease;
    z-index: 102;
    overflow-y: auto;
    overflow-x: hidden;

    .mobileItems{
        display: none;
    }

    %iconSize {
        font-size: $fontSizeHeaderSm;
        @include responsive ('sm') {
            font-size: $fontSizeStandard;
        }
    }

    .items {
        margin: 0;
        padding: 0;
        list-style: none;
        cursor: pointer;
        li {
            position: relative;
            div {
                height: 49px;
                @include responsive ('sm') {
                    height: 40px;
                }
                .navIcon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: $condensedNavWidth;
                    padding: 1rem 0;
                    text-align: center;
                    @extend %iconSize;
                }
                .settingIconSmall {
                    position: absolute;
                    bottom: 0.2rem;
                    left: 4rem;
                    font-size: 1.3rem;
                    color: $LCPLime;
                }
                .settingIcon {
                    display: none;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 2rem;
                    padding: 1.1rem;
                    padding-right: 3rem;
                    color: $LCPLime;
                    @extend %iconSize;
                    &.hide{
                        display: none !important;
                    }
                    @include responsive ('sm') {
                        padding: 1rem 0;
                        padding-right: 3rem;
                    }
                }
                .navBarName {
                    display: none;
                    left: $condensedNavWidth;
                    position: absolute;
                    white-space: pre;
                    padding: 1.3rem 0;
                    vertical-align: center;
                    @include responsive ('sm') {
                        padding: 1.1rem 0;
                    }
                }
                &:hover {
                    background-color: $SECONDARYTINT1;
                }
            }
            .activeItem {
                background-color: $LCPBlue !important;
                &:before {
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 5px 6px 5px 2px;
                    border-color: transparent #f5f5f5 transparent transparent;
                    position: absolute;
                    right: 0;
                    margin: 1.8rem 0rem;
                }
            }
        }
    }

    &:hover {
        width: $sidebarWidth + $condensedNavWidth;
            box-shadow: 5px 0px 20px -4px rgba(0,0,0,.5);
        .settingIconSmall {
            display: none;
        }
        .navBarName, .settingIcon{
            display: block !important;
        }
        .activeItem {
            &:before {
                content: '';
                display: none;
            }
        }
    }

    @include responsive('md') {
        display: none;
        .spacer {
            border-bottom: 2px solid $PRIMARY;
        }
        .items {
            padding: 10px 0px !important;
        }
        .mobileItems {
            display: block;
        }
        &.mobileOpen {
            width: 100% !important;
            display:block;

            .settingIconSmall {
                display: none;
            }

            .navBarName, .settingIcon{
                display: block !important;
            }

            .activeItem {
                &:before {
                    content: '';
                    display: none;
                }
            }
        }
    }
}

</style>
