<template>
    <div class="LCPcontent inputMargin">
        <lcp-button :text="buttonText" :handleClick="onClick"/>
    </div>
</template>

<script>

export default {
    props: ['inputTemplate'],
    name: 'booleanButton',
    data () {
        return {
            state: false,
            buttonText: null
        };
    },
    created () {
        this.config = this.inputTemplate.config;
        this.state = this.inputTemplate.value;
        this.setButtonText(this.state);
    },
    methods: {
        onClick () {
            this.selectValueAndSendUpdate(!this.state);
            if (!this.config.suppressPageUpdate) this.$store.dispatch('updatePageData');
        },
        selectValueAndSendUpdate (newValue) {
            this.state = newValue;
            this.setButtonText(this.state);
            this.sendUpdate(this.state);
            this.$emit('currentvalue', this.state, this.inputTemplate.id);
        },
        sendUpdate (state) {
            const data = {
                id: this.inputTemplate.id,
                value: state,
                index: null
            };

            this.$store.commit('updateInput', data);
        },
        setButtonText (state) {
            this.buttonText = this.state ? this.config.onSetTrueText : this.config.onSetFalseText;
        }
    },
    watch: {
        dataState (newVal, oldVal) {
            if (newVal === 'resetData' && this.inputTemplate.resetValue != null) {
                this.selectValueAndSendUpdate(this.inputTemplate.resetValue);
            }
        }
    }
};

</script>
