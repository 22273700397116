<template>

<div class="mainSection">
    <sidenav/>
    <div class="schemePageView viewScheme">
        <div class="routerViewContainer">
            <div v-if="showPage && caveat">
                <vue-markdown class="card caveatCard" :source="caveat"/>
            </div>
            <router-view v-if="showPage"/>
        </div>
        <mini-loader v-if="showSchemeLoader"/>
    </div>
</div>

</template>

<script>

import sidenav from '@/components/common/uiLayouts/sidenav.vue';
import miniLoader from '@/components/common/uiLayouts/miniLoader.vue';

export default {
    components: {
        sidenav,
        miniLoader
    },
    computed: {
        showPage () {
            return this.$store.state.pageState === 'ready';
        },
        showSchemeLoader () {
            return this.$store.state.pageState === 'loadingPage' || this.$store.state.pageState === 'generatingReport' || this.$store.state.pageState === 'downloadingReport';
        },
        caveat () {
            const staticStore = this.$staticStore.state[this.$store.state.session.pageName];
            if (!staticStore || !staticStore.pageAvailable || !staticStore.caveatSettings || !staticStore.caveatSettings.includeCaveat) return null;
            return staticStore.caveatSettings.caveat;
        }
    }
};

</script>

<style scoped lang="scss">

@import 'src/assets/scss/base.scss';
@import 'src/assets/scss/mixins.scss';

.mainSection {
    height: 100%;
    .schemePageView {
        transition: all .5s ease;
        padding-left: $condensedNavWidth;
        height: 100%;

        @include responsive('md') {
            padding-left: 0;
        }

        .routerViewContainer {
            height: 100%;
            width: 100%;
            padding: 15px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}

</style>
