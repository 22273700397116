<template>
    <div class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <div class="card-header">
                    <h1>Terms &amp; conditions</h1>
                </div>
                <div class="LCPcontent">
                    <terms-wording/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import termsWording from './termsWording.vue';

export default {
    components: {
        termsWording
    }
};

</script>
