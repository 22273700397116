<template>
    <standardMetrics :id="id" :tileData="tileData.metricsByView[loopMetric]" :chartData="chartData"/>
</template>

<script>

import standardMetrics from './standardMetrics.vue';
import barChart from '@/components/common/charts/barChart.js';

const chartOptionsOverrides = {
    chart: {
        height: '30px',
        backgroundColor: null,
        margin: [0, 0, 0, 0]
    },
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    plotOptions: {
        series: {
            borderColor: 'white',
            borderWidth: 2,
            pointWidth: 25
        }
    },
    yAxis: [{
        visible: false
    }],
    xAxis: [{
        visible: false
    }]
};

function getChartSeries (name, colour, value) {
    return {
        id: name,
        name: name,
        color: colour,
        data: [value]
    };
}

export default {
    name: 'funding',
    props: ['id', 'tileData', 'loopMetric'],
    components: {
        standardMetrics
    },
    methods: {
        getBarSeries (metric) {
            return [getChartSeries(metric.description, this.tileData.fundingMeasureColours[metric.description], metric.value)];
        },
        getChartOptions () {
            const options = barChart.chartOptions(chartOptionsOverrides, window.LCP.con.AMOUNT);
            options.yAxis[0].max = this.loopMetricMax;
            options.yAxis[0].min = this.loopMetricMin;
            return options;
        }
    },
    computed: {
        chartData () {
            const chartOptions = {};
            const chartSeries = {};

            const metrics = this.tileData.metricsByView[this.loopMetric].metrics;

            for (let i = 0; i < metrics.length; i++) {
                const element = metrics[i];
                chartOptions[element.description] = this.getChartOptions();
                chartSeries[element.description] = this.getBarSeries(element);
            }

            return { chartOptions: chartOptions, chartSeries: chartSeries };
        },
        loopMetricMax () {
            const metrics = this.tileData.metricsByView[this.loopMetric].metrics;
            let output = metrics[0].value;

            for (let i = 0; i < metrics.length; i++) {
                const element = metrics[i];
                output = Math.max(output, element.value);
            }

            return output < 0 ? 0 : output;
        },
        loopMetricMin () {
            const metrics = this.tileData.metricsByView[this.loopMetric].metrics;
            let output = metrics[0].value;

            for (let i = 0; i < metrics.length; i++) {
                const element = metrics[i];
                output = Math.min(output, element.value);
            }

            return output > 0 ? 0 : output;
        }
    }
};

</script>

<style lang="scss" scoped>

</style>
