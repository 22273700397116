/* eslint-disable */
    import $ from "jquery";

    const m = [20,20,20,40];
    var w, h;
    var x, y, xRange;

    var cfLen,
        curStage,
        inTransition,
        amountFormattingOptions,
        symbols,
        color;

    const duration = 2500;

    const upstage = [
        statusToAllCashflows,
        AllCashflowsToDiscounted,
        discountedToByStatus
    ];

    const downstage = [
        allCashflowsToStatus,
        undiscount,
        byStatusToDiscounted
    ];

    var svg;

    var d3 = require('d3');

    // A line generator, for the dark stroke.
    var line = d3.svg.line().interpolate("basis")
        .x(function (d) { return x(d.date); })
        .y(function (d) { return y(d.price); });

     // A area generator, for the dark stroke.
     var area = d3.svg.area().interpolate("basis")
        .x(function (d) { return x(d.date); })
        .y1(function (d) { return y(d.price); });

    //function for number formatting
    function nf(val) {
        return val.toAmountString();
    }

    function nfScale(val) {
        return val.toAmountStringNoUnits();
    }

    function statusCF() {
        var xMin = d3.max(symbols, function (d) { return d.values[0].date; });
        var xMax = d3.max(symbols, function (d) { return d.values[d.values.length - 1].date; });
        var yMax = d3.max(symbols, function (d) { return d.maxPrice; });

        x = d3.scale.linear().range([0, w - 100]);
        x.domain([xMin, xMax]);

        y = d3.scale.linear().range([h / cfLen - 20, 0]);
        y.domain([0, yMax]);

        for (var i = 10; i < xMax; i=i+10) xRange.push(i);

        var xAxis = d3.svg.axis().scale(x).tickValues(xRange);
        var yAxis = d3.svg.axis().scale(y).orient("left").ticks(2);
        yAxis.tickFormat(nfScale);

        area
          .y0(h / cfLen - 20)
          .y1(function (d) { return y(d.price); })

        var g = svg.selectAll(".symbol")
            .attr("transform", function (d, i) { return "translate(0," + (i * h / cfLen + 10) + ")"; });

        g.each(function (d, i) {
            var e = d3.select(this);

            var gy = e.append("g")
                .attr("class", "y axis statusAxis")
                .call(yAxis);

            gy.select(".tick").remove();

            gy.append("g")
                .append("text")
                .text(amountFormattingOptions.axisTitle)
                .attr("class", "title")
                .attr("transform", "translate(-10," + (h / cfLen - 20 - 6) + ")");

            gy.selectAll("text")
                .attr("x", -10)
                .attr("dy", 5);

            var gx = e.append("g")
                .attr("class", "x axis statusAxis")
                .attr("transform", "translate(0," + (h / cfLen - 20) + ")")
                .call(xAxis);

            gx.append("g")
                .append("text")
                .text("years")
                .attr("class", "title")
                .attr("transform", "translate(0,10)");

            e.selectAll(".area")
                .data(d3.range(1))
                    .enter().insert("svg:path", ".line")
                    .attr("class", "area")
                    .attr("transform", "translate(0,0)")
                    .style("fill", color[i])
                    .style("fill-opacity", 1)
                    .attr("d", area(d.values));

            e.append("svg:rect")
                .attr("class", "legend")
                .attr("x", -5)
                .attr("y", -10)
                .attr("width", 10)
                .attr("height", 10)
                .style("fill", color[i])
                .style("stroke", "#000")
                .style("stroke-width", "2px")
                .transition()
                .duration(0)
                .attr("transform", "translate(" + (w - 100) + "," + (h / cfLen - 20) + ")");

            e.append("svg:text")
                .attr("class", "legendText")
                .attr("opacity", 1)
                .attr("x", 12)
                .text(d.key)
                .transition()
                .duration(0)
                .attr("transform", "translate(" + (w - 100) + "," + (h / cfLen - 20) + ")");

            e.append("svg:text")
                .attr("class", "legendValue")
                .attr("opacity", 1)
                .attr("x", 12)
                .text("")
                .transition()
                .duration(0)
                .attr("transform", "translate(" + (w - 100) + "," + (h / cfLen - 20 + 25) + ")")
                .text(function (d) {
                    return nf(d.sumPrice);
                });
        });

        svg.append("text")
            .attr("class", "legendTotal")
            .attr("opacity", 1e-6)
            .attr("transform", "translate(" + (w - 100) + "," + 10 + ")")
            .text("");

        svg.append("text")
            .attr("class", "legendTotalTitle")
            .attr("opacity", 1e-6)
            .attr("transform", "translate(" + (w - 160) + "," + 10 + ")")
            .text("Total:");
    }

    function statusToAllCashflows(dur) {
        var stack = d3.layout.stack()
            .values(function (d) { return d.values; })
            .x(function (d) { return d.date; })
            .y(function (d) { return d.price; })
            .out(function (d, y0, y) { d.price0 = y0; })
            .order("reverse");

        stack(symbols);

        y
        .domain([0, d3.max(symbols[0].values.map(function (d) { return d.price + d.price0; }))])
        .range([h-20, 0]);

        line
            .y(function (d) { return y(d.price0); });

        area
            .y0(function (d) { return y(d.price0); })
            .y1(function (d) { return y(d.price0 + d.price); });

        svg.selectAll(".statusAxis")
        .attr("opacity", 1e-6);

        if (svg.selectAll(".allCashflowAxis")[0].length == 0) {
            var xAxis = d3.svg.axis().scale(x).tickValues(xRange);
            var yAxis = d3.svg.axis().scale(y).orient("left").ticks(5);

            yAxis.tickFormat(nfScale);

            var gy = svg.append("g")
                .attr("class", "y axis allCashflowAxis")
                .attr("opacity", 1e-6)
                .call(yAxis);

            gy.select(".tick").remove();

            gy.append("g")
                .append("text")
                .text(amountFormattingOptions.axisTitle)
                .attr("class", "title")
                .attr("transform", "translate(-10," + (h - 30) + ")");

            gy.selectAll("text")
                .attr("x", -10)
                .attr("dy", 5);

            var gx = svg.append("g")
                .attr("class", "x axis allCashflowAxis")
                .attr("opacity", 1e-6)
                .attr("transform", "translate(0," + (h - 20) + ")")
                .call(xAxis);

            gx.append("g")
                .append("text")
                .text("years")
                .attr("class", "title")
                .attr("transform", "translate(10,10)");
        }

        svg.selectAll(".allCashflowAxis")
                .transition()
                .delay(dur)
                .attr("opacity", 1);

        var t = svg.selectAll(".symbol").transition()
            .duration(dur)
            .attr("transform", "translate(0,0)")
            .each("end", function () { d3.select(this).attr("transform", null); });

        t.select("path.area")
        .attr("d", function (d) { return area(d.values); });

        t.select(".legendText")
            .attr("transform", function (d, i) {
                i = cfLen - i;
                return "translate(" + (w - 100) + "," + (h - i * 20 - 10) + ")";
            });

        t.select(".legendValue")
            .attr("transform", "translate(" + (w - 100) + "," + 10 + ")")
            .attr("opacity", 1e-6);

        t.select(".legend")
        .attr("transform", function (d, i) {
            i = cfLen - i;
            return "translate(" + (w - 100) + "," + (h - i * 20 - 10) + ")";
        });

        var totalVal = nf(d3.sum(symbols, function (d) { return d.sumPrice; }));

        svg.select(".legendTotal")
            .text(totalVal)
            .transition()
            .duration(dur)
            .attr("opacity", 1);

        svg.select(".legendTotalTitle")
            .transition()
            .duration(dur)
            .attr("opacity", 1);
    }

    function allCashflowsToStatus(dur) {

        var g = svg.selectAll(".symbol");

        y = d3.scale.linear().range([h / cfLen - 20, 0]);

        var maxVal = d3.max(symbols, function (d) { return d.maxPrice; });

        g
        .transition()
            .duration(dur)
            .attr("transform", function (d, i) { return "translate(0," + (i * h / cfLen + 10) + ")"; })
        .each(function (d) {
            y.domain([0, maxVal]);

            area
            .y1(function (d) { return y(d.price); })
            .y0(h / cfLen - 20);

            d3.select(this).select("path.area")
                .transition()
            .duration(dur)
            .attr("d", function (d) { return area(d.values); });

        });

        g.select(".legend")
            .transition()
            .duration(dur)
        .attr("transform", "translate(" + (w - 100) + "," + (h / cfLen - 20) + ")");

        g.select(".legendText")
            .transition()
            .duration(dur)
        .attr("transform", "translate(" + (w - 100) + "," + (h / cfLen - 20) + ")");

        g.select(".legendValue")
            .transition()
            .duration(dur)
        .attr("opacity", 1)
        .attr("transform", "translate(" + (w - 100) + "," + (h / cfLen - 20 + 25) + ")");

        svg.selectAll(".legendTotal, .legendTotalTitle")
            .transition()
            .duration(dur)
            .attr("opacity", 1e-6);

        svg.selectAll(".allCashflowAxis")
            .attr("opacity", 1e-6);

        svg.selectAll(".statusAxis")
            .transition()
            .delay(dur)
            .attr("opacity", 1);
    }

    function AllCashflowsToDiscounted(dur) {

        x = d3.scale.ordinal()
        .domain(symbols[0].values.map(function (d) { return d.date; }))
        .rangeBands([0, w - 100], .1);

        var x1 = d3.scale.ordinal()
            .domain(symbols.map(function (d) { return d.key; }))
            .rangeBands([0, x.rangeBand() * cfLen]);

        var stack = d3.layout.stack()
            .values(function (d) { return d.values; })
            .x(function (d) { return d.date; })
            .y(function (d) { return d.price; })
            .out(function (d, y0, y) { d.price0 = y0; })
            .order("reverse");

        var g = svg.selectAll(".symbol");

        stack(symbols);

        y.domain([0, d3.max(symbols[0].values.map(function (d) { return d.price + d.price0; }))])
            .range([h-20, 0]);

        var t = g.transition()
            .duration(dur / 2);

        t.select(".line")
            .style("stroke-opacity", 1e-6)
            .remove();

        t.select(".area")
            .style("fill-opacity", 1e-6)
            .remove();

        g.each(function (p, j) {
            d3.select(this).selectAll("rect")
                .data(function (d) { return d.values; })
                .enter().append("svg:rect")
                .attr("class", "bars")
                .attr("x", function (d) { return x(d.date) - x1.rangeBand(); })
                .attr("y", function (d) { return y(d.price0 + d.price); })
                .attr("width", x1.rangeBand())
                .attr("height", function (d) { return h - y(d.price)-20; })
                .style("fill", color[j])
                .style("fill-opacity", 1e-6)
                .transition()
                .duration(dur / 2)
                .style("fill-opacity", 1);
        });

        setTimeout(function () {

            discountValues(dur);

        }, dur / 2 + 100);
    }

    function discountValues(dur) {

        var x1 = d3.scale.ordinal()
            .domain(symbols.map(function (d) { return d.key; }))
            .rangeBands([0, x.rangeBand() * cfLen]);

        var stack = d3.layout.stack()
            .values(function (d) { return d.discountedValues; })
            .x(function (d) { return d.date; })
            .y(function (d) { return d.price; })
            .out(function (d, y0, y) { d.price0 = y0; })
            .order("reverse");

        var g = svg.selectAll(".symbol");

        stack(symbols);

        y
            .domain([0, d3.max(symbols[0].values.map(function (d) { return d.price + d.price0; }))])
            .range([h-20, 0]);

        g.each(function (p, j) {
            d3.select(this).selectAll(".bars")
                .data(function (d) { return d.discountedValues; })
                .transition()
                .duration(dur / 2)
                .attr("y", function (d) { return y(d.price0 + d.price); })
                .attr("height", function (d) { return h - y(d.price) -20; });

        });

        svg.select(".legendTotal")
            .transition()
            .duration(dur / 2 - 100)
            .tween("text", function () {
                var totalUndisc = d3.sum(symbols, function (d) { return d.sumPrice; });
                var totalDisc = d3.sum(symbols, function (d) { return d.sumDiscountedValue; });
                var i = d3.interpolate(totalUndisc, totalDisc);
                return function (t) {
                    this.textContent = nf(i(t));
                };
            });
    }

    function undiscount(dur) {

        x = d3.scale.ordinal()
        .domain(symbols[0].values.map(function (d) { return d.date; }))
        .rangeBands([0, w - 100], .1);

        var x1 = d3.scale.ordinal()
            .domain(symbols.map(function (d) { return d.key; }))
            .rangeBands([0, x.rangeBand() * cfLen]);

        var stack = d3.layout.stack()
            .values(function (d) { return d.values; })
            .x(function (d) { return d.date; })
            .y(function (d) { return d.price; })
            .out(function (d, y0, y) { d.price0 = y0; })
            .order("reverse");

        var g = svg.selectAll(".symbol");

        stack(symbols);

        y.domain([0, d3.max(symbols[0].values.map(function (d) { return d.price + d.price0; }))])
            .range([h-20, 0]);

        g.each(function (p, j) {
            d3.select(this).selectAll(".bars")
                .data(function (d) { return d.values; })
                .transition()
                .duration(dur / 2)
                .attr("x", function (d) { return x(d.date); })
                .attr("y", function (d) { return y(d.price0 + d.price); })
                .attr("width", x1.rangeBand())
                .attr("height", function (d) { return h - y(d.price) -20; });
        });

        svg.select(".legendTotal")
            .transition()
            .duration(dur / 2 - 100)
            .tween("text", function () {
                var totalUndisc = d3.sum(symbols, function (d) { return d.sumPrice; });
                var totalDisc = d3.sum(symbols, function (d) { return d.sumDiscountedValue; });
                var i = d3.interpolate(totalDisc, totalUndisc);
                return function (t) {
                    this.textContent = nf(i(t));
                };
            });

        setTimeout(function () {

            discountedToAllCashflow(dur);

        }, dur / 2 + 100);
    }

    function discountedToAllCashflow(dur) {

        var stack = d3.layout.stack()
            .values(function (d) { return d.values; })
            .x(function (d) { return d.date; })
            .y(function (d) { return d.price; })
            .out(function (d, y0, y) { d.price0 = y0; })
            .order("reverse");

        stack(symbols);

        var g = svg.selectAll(".symbol");

        var t = g.transition()
            .duration(dur / 2);

        t.selectAll(".bars")
            .style("fill-opacity", 1e-6)
            .remove();

        area
            .y0(function (d) { return y(d.price0); })
            .y1(function (d) { return y(d.price0 + d.price); });

        g.each(function (d, i) {
            d3.select(this).selectAll(".area")
                .data(d3.range(1))
                    .enter().insert("svg:path", ".line")
                    .attr("class", "area")
                    .attr("transform", function (d, i) { return "translate(0,0)"; })
                    .style("fill", color[i])
                    .style("fill-opacity", 1e-6)
                    .attr("d", area(d.values))
                    .transition()
                    .duration(dur / 2)
                    .style("fill-opacity", 1);
        });
    }

    function discountedToByStatus(dur) {

        x
        .domain(symbols.map(function (d) { return d.key; }))
        .rangeRoundBands([0, w], .2);

        y
        .domain([0, d3.max(symbols.map(function (d) { return d3.sum(d.discountedValues.map(function (d) { return d.price; })); }))])
        .range([h, 40]);

        svg.selectAll(".allCashflowAxis")
            .attr("opacity", 1e-6);

        var stack = d3.layout.stack()
            .x(function (d, i) { return i; })
            .y(function (d) { return d.price; })
            .out(function (d, y0, y) { d.price0 = y0; });

        stack(d3.zip.apply(null, symbols.map(function (d) { return d.discountedValues; }))); // transpose!

        var g = svg.selectAll(".symbol");

        g.each(function (p, j) {
            d3.select(this).selectAll(".bars")
                .data(function (d) {
                    return d.discountedValues;
                })
                .transition()
                .duration(dur)
                .delay(function (d, i) { return i * 10; })
                .attr("y", function (d) { return y(d.price0 + d.price) - 1; })
                .attr("height", function (d) { return h - y(d.price) + 1; })
                .attr("x", function (d) {
                    return x(d.symbol);
                })
                .attr("width", x.rangeBand())
                .style("stroke-opacity", 1);
        });

        svg.selectAll(".legendText")
            .attr("opacity", 1e-6)
            .transition()
            .duration(dur)
            .attr("x", 0)
            .attr("transform", function (d) { return "translate(" + (x(d.key) + x.rangeBand() / 2) + "," + h + ")"; })
            .attr("dy", "1.31em")
            .each("end", function () {
                d3.select(this).attr("x", null).attr("text-anchor", "middle");
                d3.select(this).attr("opacity", 1);
            });

        svg.selectAll(".legendValue")
            .text(function (d) {
                return nf(d.sumDiscountedValue);
            })
            .attr("transform", function (d) {
                return "translate(" + (x(d.key) + x.rangeBand() / 2) + "," + (y(d.sumDiscountedValue) - 30) + ")";
            })
            .transition()
            .duration(dur)
            .attr("dy", "1.31em")
            .each("end", function () {
                d3.select(this).attr("x", null).attr("text-anchor", "middle")
                    .attr("opacity", 1);
            });

        svg.selectAll(".legend")
            .transition()
            .duration(dur)
            .style("fill-opacity", 1e-6)
            .style("stroke-opacity", 1e-6);
    }

    function byStatusToDiscounted(dur) {
        x = d3.scale.ordinal()
        .domain(symbols[0].values.map(function (d) { return d.date; }))
        .rangeBands([0, w - 100], .1);

        var x1 = d3.scale.ordinal()
            .domain(symbols.map(function (d) { return d.key; }))
            .rangeBands([0, x.rangeBand() * cfLen]);

        var stack = d3.layout.stack()
            .values(function (d) { return d.discountedValues; })
            .x(function (d) { return d.date; })
            .y(function (d) { return d.price; })
            .out(function (d, y0, y) { d.price0 = y0; })
            .order("reverse");

        stack(symbols);

        y.domain([0, d3.max(symbols[0].values.map(function (d) { return d.price + d.price0; }))])
            .range([h-20, 0]);

        var g = svg.selectAll(".symbol");

        g.each(function (p, j) {
            d3.select(this).selectAll(".bars")
                .data(function (d) {
                    return d.discountedValues;
                })
                .transition()
                .duration(dur)
                .delay(function (d, i) { return i * 10; })
                .attr("x", function (d) { return x(d.date); })
                .attr("y", function (d) { return y(d.price0 + d.price); })
                .attr("width", x1.rangeBand())
                .attr("height", function (d) { return h - y(d.price)-20; })
                .style("stroke-opacity", 1);
        });

        svg.selectAll(".legendText")
            .attr("opacity", 1e-6)
            .transition()
            .duration(dur)
            .attr("text-anchor", "left")
            .attr("x", 12)
            .attr("dy", 0)
            .attr("transform", function (d, i) {
                i = cfLen - i;
                return "translate(" + (w - 100) + "," + (h - i * 20 - 10) + ")";
            })
            .each("end", function () { d3.select(this).attr("opacity", 1); });

        svg.selectAll(".legendValue")
            .text(function (d) { return nf(d.sumPrice); })
            .attr("opacity", 1e-6)
            .attr("text-anchor", "left")
            .attr("x", 12)
            .attr("dy", 0)
            .attr("transform", "translate(" + (w - 100) + "," + 10 + ")");

        svg.selectAll(".legendTotal, .legendTotalTitle")
            .transition()
            .duration(dur)
            .attr("opacity", 1);

        svg.selectAll(".legend")
            .transition()
            .duration(dur)
            .style("fill-opacity", 1)
        .style("stroke-opacity", 1);

        svg.selectAll(".allCashflowAxis")
        .transition()
            .delay(dur)
            .attr("opacity", 1);
    }

    function add(demographicsdata, sel) {
        var crt = $(sel);

        var crtWidth = crt.width();
        var crtHeight = crt.height();

        w = crtWidth - (m[1] + m[3]);
        h = crtHeight - (m[0] + m[2]);
        xRange = [];

        curStage = 0;
        inTransition = false;
        amountFormattingOptions = window.LCP.fn.getAmountFormattingOptions();
        color = demographicsdata.colours;

        // Nest stock values by symbol.
        symbols = d3.nest()
            .key(function (d) { return d.symbol; })
            .entries(demographicsdata.data);

        cfLen = symbols.length;

        symbols.forEach(function (s) {
            s.values.forEach(function (d) { d.date = d.date; d.price = d.price; });
            s.maxPrice = d3.max(s.values, function (d) { return d.price; });
            s.sumPrice = d3.sum(s.values, function (d) { return d.price; });
            s.discountedValues = s.values.map(function (d) { return { price: d.disPrice, date: d.date, symbol: d.symbol } });
            s.sumDiscountedValue = d3.sum(s.discountedValues, function (d) { return d.price; });
        });

        svg = d3.select(sel).append("svg:svg")
            .attr("width", crtWidth)
            .attr("height", crtHeight)
            .append("svg:g")
            .attr("transform", "translate(" + m[3] + "," + m[0] + ")");

        svg.selectAll("g")
            .data(symbols)
            .enter()
            .append("svg:g")
            .attr("class", "symbol");

        statusCF();
    }

    function goToStage(stage, onStageChange, onComplete) {

        if (inTransition) return;

        inTransition = true;

        var k = 0;

        if (stage > curStage) {

            for (var i = curStage; i < stage; i++) {

                (function (i, k) {
                    setTimeout(function () {

                        onStageChange(i + 1);
                        upstage[i](duration);

                    }, k * duration);
                })(i, k);

                k++;
            }

        } else {

            for (var i = curStage - 1; i > stage - 1; i--) {

                (function (i, k) {
                    setTimeout(function () {

                        onStageChange(i);
                        downstage[i](duration);

                    }, k * duration);
                })(i, k);

                k++;
            }
        }

        setTimeout(function () {

            inTransition = false;
            curStage = stage;
            onComplete();

        }, duration * k + 1000);
    }

    export default {
        add: add,
        goToStage: goToStage
    };
