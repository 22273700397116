
<template>
    <div id="wrapper">
        <img class="animatedLogo" v-bind:src="logo404"/>
        <div class="subText">This page doesn't exist. <a href="/userschemes" class="schemesLink">Take me back to my schemes</a></div>
    </div>
</template>

<script>
import logo404 from '@/assets/svg/v_404_animated.svg';
export default {
    name: 'four-zero-four',
    data () {
        return {
            logo404
        };
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import 'src/assets/scss/all.scss';
    #wrapper{
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: $bodyBg;
        .animatedLogo{
            position: absolute;
            left: 50%;
            margin-left:-25rem;
            top:30%;
            width: 50rem;
        }
        .subText{
            text-align: center;
            width: 100%;
            position: absolute;
            top: calc(30% + 20rem);
        }
    }
</style>
