<template>
    <div class="inputMargin">
        {{ sliderDescription }}
        <div class="stepperWrapper">
            <div class="stepper decrease" :class="(lowerBound && value === lowerBound ? 'disabled' : '')" @click="decrease">-</div>
            <text-input style="display: inline-block"
                :id="id"
                :value="value"
                :displayType="config.displayType"
                :relative="config.relative"
                :relativeTo="config.relativeTo"
                :decimalPlacesOverride="config.decimalPlacesOverride"
                v-on:valueUpdated="onTextValueUpdated"
                :valueChanged="valueChanged"
                :lowerBound="lowerBound"
                :upperBound="upperBound"
            />
            <div class="stepper increase" :class="(upperBound &&  value === upperBound ? 'disabled' : '')" @click="increase">+</div>
        </div>
    </div>
</template>

<script>

import textInput from '@/components/common/uiInputs/textInput.vue';

export default {
    props: ['inputTemplate', 'valueOverride'],
    components: {
        textInput
    },
    data () {
        return {
            value: null
        };
    },
    created () {
        this.config = this.inputTemplate.config;
        this.id = this.inputTemplate.id;
        this.sliderDescription = this.config.label;
        this.increment = this.config.increment;
        this.updateValue(this.inputTemplate.value);
        this.lowerBound = this.config.restrictedByOptions ? this.config.options[0] : this.config.lowerBound;
        this.upperBound = this.config.restrictedByOptions ? this.config.options[this.config.options.length - 1] : this.config.upperBound;
    },
    methods: {
        increase () {
            this.onStepChange(this.value + this.increment);
        },
        decrease () {
            this.onStepChange(this.value - this.increment);
        },
        onStepChange (newValue) {
            if (this.lowerBound != null && newValue < this.lowerBound) return;
            if (this.upperBound != null && newValue > this.upperBound) return;
            this.updateValueAndSendUpdate(newValue, true);
            this.updatePageData();
        },
        updateValueAndSendUpdate (newValue, updateDynamicOutput) {
            this.updateValue(newValue);
            this.sendUpdate(updateDynamicOutput);
            this.$emit('currentvalue', this.value, this.id);
        },
        updateValue (newValue) {
            this.value = newValue;
        },
        sendUpdate (updateDynamicOutput) {
            const data = {
                id: this.id,
                value: this.value
            };

            this.$store.commit('updateInput', data);
        },
        updatePageData () {
            if (!this.config.suppressPageUpdate) this.$store.dispatch('updatePageData');
        },
        onTextValueUpdated (value) {
            this.updateValueAndSendUpdate(value, true);
            this.updatePageData();
        }
    },
    computed: {
        dataState () {
            return this.$store.state.dataState;
        },
        valueChanged () {
            if (this.inputTemplate.resetValue == null) return false;

            return !this.inputTemplate.resetValue.isSameAs(this.value);
        }
    },
    watch: {
        valueOverride (newVal, oldVal) {
            if (newVal == null || newVal.isSameAs(this.value)) return;
            this.updateValueAndSendUpdate(newVal, false);
        },
        dataState (newVal, oldVal) {
            if (newVal === 'resetData' && this.inputTemplate.resetValue != null) {
                this.updateValueAndSendUpdate(this.inputTemplate.resetValue, false);
            }
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import 'src/assets/scss/colors.scss';

.stepperWrapper{
    text-align: center;
    position: relative;
    padding-top: 6px;
    padding-left: 35px;
    padding-right: 35px;
    .stepper{
        background: #00a3c7;
        cursor: pointer;
        color: white;
        border-radius: 6px;
        height: 29px;
        width: 29px;
        font-size: 20px;
        position: absolute;
        top:0;
        user-select: none;
        &.decrease{
            left: 0;
        }
        &.increase{
            right: 0;
        }
        &.disabled{
            background: grey;
            cursor: not-allowed;
        }
    }
}

</style>
