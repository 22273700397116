<template>

<div class="viewNoScheme">
    <div class="card-header-large">
        <h1>Manage schemes ({{selectedSchemeCount}} out of {{schemes.length}} selected)</h1>
        <lcp-button class="refresh" text="Refresh schemes" :handleClick="refreshSchemes"/>
    </div>
    <div class="LCPcontent">
        <div class="table">
            <div class="tr headerRow">
                <div class="td">
                    <lcp-button class="selectAll" :text="toggleText" :handleClick="toggleAll"/>
                </div>
                <div class="td">Login history</div>
                <div class="td" v-for="info in user.loadedFileSystemTypes" :key="info.fileSystem">
                    {{info.fileSystem}}
                    <span class="icon icon-info" v-tooltip="info.description"/>
                </div>
                <div class="td">Days since live setup updated</div>
            </div>
            <div class="tr body schemeRow" v-for="scheme in schemes" :key="scheme.id">
                <div class="td optionWrapper">
                    <checkbox :id="scheme.id" :inputTemplate="getInputTemplate(scheme)" :value-override="scheme.visible" v-on:toggled="toggleSingle"/>
                </div>
                <div class="td">
                    <button class="login-history-button" @click="setLoginData(scheme)">
                        <span class="icon icon-user"/>
                    </button>
                </div>
                <div class="td" v-for="(fileSystemData, fileSystem) in getFileSystemDataForScheme(scheme)" :key="scheme.id + '_' + fileSystem">
                    <lcp-button
                        class="timeStampButton"
                        :class="!fileSystemData || !fileSystemData.showButton ? 'inactive' : ''"
                        :style="{ background: fileSystemData ? fileSystemData.buttonColour : null, borderColor: fileSystemData ? fileSystemData.buttonColour : null }"
                        :disabled="!fileSystemData"
                        :text="fileSystemData ? fileSystemData.timeStamp.ToDateTimeString() : 'n/a'"
                        :handleClick="navigateTo(scheme.id, fileSystem)"/>
                </div>
                <div class="td">{{getDaysSinceLiveSetupUpdated(scheme)}}</div>
            </div>
        </div>
    </div>
    <login-history-modal v-if="selectedSchemeForLoginHistory" :schemeInfo="selectedSchemeForLoginHistory" @close="closeLoginModel"/>
    <copyright colorTheme="dark"/>
</div>

</template>

<script>

import loginHistoryModal from './loginHistoryModal.vue';
import checkbox from '@/components/common/uiInputs/checkbox.vue';
import copyright from '@/components/common/uiLayouts/copyright.vue';

const selectAllText = 'Show all on landing page';
const deselectAllText = 'Hide all on landing page';

export default {
    name: 'manageSchemes',
    data () {
        return {
            toggleText: null,
            selectedSchemeForLoginHistory: null
        };
    },
    components: {
        loginHistoryModal,
        checkbox,
        copyright
    },
    created () {
        this.updateToggleText();
    },
    computed: {
        user () {
            return this.$store.state.session.user;
        },
        schemes () {
            const keyedOutput = {};

            for (const schemeId in this.user.availableSchemes) {
                const scheme = this.user.availableSchemes[schemeId];
                const pendingSchemeData = scheme.availabilityByFileSystem.pending;
                if (!pendingSchemeData) continue;

                const schemeWithName = window.LCP.fn.extend(scheme, { name: pendingSchemeData.displayName });
                const schemeDataToUse = window.LCP.fn.extend(schemeWithName, this.user.store.preferences.byScheme[schemeId]);

                keyedOutput[schemeDataToUse.name + '_' + schemeDataToUse.id] = schemeDataToUse;
            }

            return window.LCP.fn.getSortValuesFromKeyedData(keyedOutput);
        },
        selectedSchemeCount () {
            let count = 0;

            for (const i in this.schemes) {
                if (this.schemes[i].visible) count++;
            }

            return count;
        }
    },
    methods: {
        getInputTemplate (scheme) {
            return checkbox.getDefaultInputTemplate(scheme.id, scheme.name, scheme.visible, 'white', null, null, null, true);
        },
        setLoginData (scheme) {
            this.selectedSchemeForLoginHistory = scheme;
        },
        closeLoginModel () {
            this.selectedSchemeForLoginHistory = null;
        },
        async refreshSchemes () {
            await this.$store.dispatch('loadInternalSessionData');
            this.$store.commit('setReady');
        },
        toggleAll () {
            const req = {
                state: this.toggleText === selectAllText,
                multi: true
            };

            this.toggleSchemes(req);
        },
        toggleSingle (event) {
            const req = {
                scheme: {
                    id: event.id,
                    visible: event.state
                },
                multi: false
            };

            this.toggleSchemes(req);
        },
        toggleSchemes (req) {
            this.$store.dispatch('toggleSchemes', req);
            this.updateToggleText();
        },
        updateToggleText () {
            this.toggleText = this.selectedSchemeCount > (Object.keys(this.schemes).length / 2) ? deselectAllText : selectAllText;
        },
        getFileSystemDataForScheme (scheme) {
            const output = {};

            for (const i in this.user.loadedFileSystemTypes) {
                const info = this.user.loadedFileSystemTypes[i];
                output[info.fileSystem] = scheme.availabilityByFileSystem[info.fileSystem] || null;
            }

            return output;
        },
        getDaysSinceLiveSetupUpdated (scheme) {
            const liveSchemeData = scheme.availabilityByFileSystem.live;
            return liveSchemeData ? liveSchemeData.daysSinceLastUpdate : 'n/a';
        },
        navigateTo (scheme, version) {
            return function () {
                this.$store.dispatch('routeToScheme', {
                    scheme: scheme,
                    fileSystem: version
                }).then(() => {}).catch(() => {});
            };
        }
    }
};
</script>

<style scoped lang="scss">

@import 'src/assets/scss/colors.scss';

.login-history-button {
    background: none;
    border: 0;
    font-size: 2rem;
    margin-right: 1rem;
    &:hover {
        color: $LCPGrapefruit;
    }
}

.selectAll {
    float: left;
    width: 250px;
}

.refresh {
    float: right;
    width: 250px;
    margin-top: 1.6rem !important;
}

.optionWrapper{
    text-align: left!important;
    label {
        margin: 0px;
        margin-top: 3px;
    }
}

.timeStampButton {
    margin-left: 4px !important;
    margin-right: 4px !important;
}

.timeStampButton.inactive {
    border-color: transparent !important;
    background: transparent !important;
}

.table{
    margin-bottom: 0px;
    word-break: break-word;
    .tr{
        display: table;
        width: 100%;
        margin-bottom: 4px;
        .td{
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            width: 170px;
            &:first-child {
                width: auto;
            }
        }
        &.headerRow {
            font-weight: 500;
            margin-bottom: 10px;
            .icon-info {
                cursor: pointer;
            }
        }
        &.schemeRow {
            &:hover{
                background: $SECONDARYTINT2
            }
        }
    }
}

</style>
