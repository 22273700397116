<template>
    <div v-if="pageAvailable" class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <div class="card-header-with-action">
                    <h1>{{header}}</h1>
                    <reset-inputs/>
                </div>
                <div class="col-md-12 LCPcontent">
                    <div class="row">
                        <div class="col-sm-12 col-md-4">
                            <input-wrapper v-if="inputs[config.assetProjectionTypeId].config.options.length > 1" :inputTemplate="inputs[config.assetProjectionTypeId]" v-on:currentvalue="changeAssetProjectionType"/>
                            <input-wrapper v-if="config.isSantander" :inputTemplate="inputs[config.santanderReturnTypeId]" v-show="!santanderReturnsHidden"/>
                            <input-wrapper v-if="!definedStrategyHidden" :inputTemplate="inputs[config.projectedReturnStrategyTypeId]"/>
                            <input-wrapper v-if="!definedReturnHidden" :inputTemplate="inputs[config.projectedReturnNumericTypeId]"/>
                        </div>
                        <div class="col-sm-12 col-md-4" v-if="config.isSantander">
                            <input-wrapper :inputTemplate="inputs[config.santanderReturnContingentPaymentsEnabledId]"/>
                            <input-wrapper :inputTemplate="inputs[config.santanderFundingContingentPaymentsEnabledId]"/>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <input-wrapper :inputTemplate="inputs[config.requiredReturnTypeId]"/>
                        </div>
                    </div>
                    <lcp-table :config="dynamicOutput"/>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <div class="card-header">
                    <h1>{{header}}</h1>
                </div>
                <vue-markdown class="LCPcontent" :source="pageNotAvailableText"/>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data () {
        return {
            santanderReturnsHidden: null,
            definedStrategyHidden: null,
            definedReturnHidden: null
        };
    },
    created () {
        this.header = 'Recovery requirements';
        this.pageAvailable = this.$staticStore.state.recoveryrequirementsbyscheme.pageAvailable;
        this.pageNotAvailableText = this.$staticStore.state.recoveryrequirementsbyscheme.pageNotAvailableText;

        if (!this.pageAvailable) return;

        this.config = this.$staticStore.state.recoveryrequirementsbyscheme.config;
        this.inputs = this.$staticStore.state.recoveryrequirementsbyscheme.inputControls;
        this.changeAssetProjectionType(this.inputs[this.config.assetProjectionTypeId].value);
    },
    methods: {
        changeAssetProjectionType (type) {
            this.santanderReturnsHidden = type !== this.config.santanderAssetProjectionType;
            this.definedStrategyHidden = type !== this.config.strategyAssetProjectionType;
            this.definedReturnHidden = type !== this.config.returnAssetProjectionType;
        }
    },
    computed: {
        dynamicOutput () {
            return this.$store.state.recoveryrequirementsbyscheme.dynamicOutput;
        }
    }
};

</script>
