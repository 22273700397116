<template>

 <div class="viewNoScheme">
    <div class="card-header-large">
        <h1 v-if="!lcpUser">Select scheme</h1>
        <input-component v-else
            v-model="searchText"
            id="searchInput"
            label="Filter your schemes"
            label-hover
            type="text"
            icon-color="white"
            defaultUnderline="white"
            underline-color="white"
            width="350px"
            icon="search"
            :large="true"
        />
    </div>
    <div style="text-align: center;" v-if="selectedCount === 0">{{noSchemesText}}</div>
    <div class="LCPcontent">
        <lcp-button v-bind:key="scheme.id" v-for="scheme in schemeList" class="buttonOverrides" :text="scheme.name" :handleClick="navigateTo(scheme.id)"/>
    </div>
    <copyright colorTheme="dark"/>
</div>

</template>

<script>

import inputComponent from '@/components/common/uiInputs/inputComponent.vue';
import copyright from '@/components/common/uiLayouts/copyright.vue';

export default {
    data () {
        return {
            searchText: ''
        };
    },
    components: {
        inputComponent,
        copyright
    },
    created () {
        if (!this.lcpUser & this.totalCount === 1) this.navigateTo(this.liveSchemes[0]);

        this.noSchemesText = this.lcpUser
            ? 'Search for live scheme using the filter or manage schemes to select schemes to show.'
            : 'You have no schemes available. Please contact your LCP representative.';
    },
    computed: {
        user () {
            return this.$store.state.session.user;
        },
        lcpUser () {
            return this.user.isLcp;
        },
        liveSchemes () {
            return window.LCP.fn.getSortValuesFromKeyedData(this.$store.getters.getLiveSchemeDictionary);
        },
        totalCount () {
            return Object.keys(this.liveSchemes).length;
        },
        selectedCount () {
            return this.schemeList.length;
        },
        schemeList () {
            const lowerCaseSearchText = this.searchText.toLowerCase();
            const filterMode = lowerCaseSearchText.length >= 3;

            const output = [];

            for (let i = 0; i < this.liveSchemes.length; i++) {
                const schemeData = this.liveSchemes[i];

                if (filterMode) {
                    if (!schemeData.name.toLowerCase().includes(lowerCaseSearchText)) continue;
                } else {
                    if (!this.user.store.preferences.byScheme[schemeData.id].visible) continue;
                }

                output.push(schemeData);
            }

            return output;
        }
    },
    methods: {
        navigateTo (schemeId) {
            return function () {
                this.$store.dispatch('routeToScheme', {
                    scheme: schemeId,
                    fileSystem: 'live'
                }).then(() => {}).catch(() => {});
            };
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import 'src/assets/scss/base.scss';

.viewNoScheme {
    .buttonOverrides {
        font-size: 22px;
        text-align: left;
        margin-bottom: 15px;
        padding: 10px 35px;
        border-radius: 6px;
        &:hover {
            background: $PRIMARY
        }
        @include responsive('sm') {
            font-size: $fontSizeStandard;
            padding: 5px 20px;
            margin-bottom: 10px;
        }
    }

    .card-header-large {
        @include responsive('lg') {
            padding: 0 50px;
            >div{
                width: 100% !important;
            }
        }
    }
}

</style>
