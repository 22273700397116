<template>
    <div class="lcpcopyright" :class="[colorTheme]">
        <span v-if="userSession" class="text sessionText">{{userSession}}</span>
        <span class="text copyrightText">All content &copy; Copyright {{(new Date()).getFullYear()}} Lane Clark &amp; Peacock LLP All rights reserved</span>
    </div>
</template>

<script>

export default {
    props: {
        colorTheme: {
            type: String,
            default: 'light'
        }
    },
    computed: {
        userSession () {
            if (!this.$store.state.session.sessionStartTime) return null;
            return this.$store.state.session.sessionStartTime.ToDateTimeString() + ' - ' + this.user.emailAddress;
        },
        user () {
            return this.$store.state.session.user;
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import 'src/assets/scss/base.scss';

.lcpcopyright {
    width: 100%;
    font-size: 1rem;
    z-index: 101;
    float: left;
    &.dark {
        color: white;
    }
    &.light {
        background-color: white;
    }
    .text {
        padding: 3px 10px;
        @include responsive('sm') {
            width: 100%;
            text-align: center;
        }
    }
    .sessionText {
        float: left;
        @include responsive('sm') {
            display: none;
        }
    }
    .copyrightText {
        float: right;
    }
}

</style>
