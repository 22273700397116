<template>
    <div class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <div class="card-header">
                    <h1>Indicative insurer pricing for pensioners</h1>
                </div>
                <div v-if="pageAvailable">
                    <div class="LCPcontent">
                        <vue-markdown>These numbers provide an indication of the attractiveness of a pensioner buy-in for {{schemeDisplayName}} following a competitive tender.</vue-markdown>
                        <vue-markdown>The figures are calculated by LCP using pricing information provided by the participating insurers on a non-binding basis, market pricing information obtained by LCP from analysing actual insurance quotations and scheme information held by LCP.</vue-markdown>
                    </div>
                    <lcp-table class="LCPcontent" :config="tableData"/>
                    <highchart class="LCPcontent" :options="chartOptions" :series-options="chartSeries"/>
                    <div class="LCPcontent insurerLogos">
                        <div class="row">
                            <div class="col-sm-2 col-xs-6"><img :src="aviva" class="img-responsive"/></div>
                            <div class="col-sm-2 col-xs-6"><img :src="just" class="img-responsive"/></div>
                            <div class="col-sm-2 col-xs-6"><img :src="legalandgeneral" class="img-responsive"/></div>
                            <div class="col-sm-2 col-xs-6"><img :src="pic" class="img-responsive"/></div>
                            <div class="col-sm-2 col-xs-6"><img :src="rothesaylife" class="img-responsive"/></div>
                            <div class="col-sm-2 col-xs-6"><img :src="scottishwidows" class="img-responsive"/></div>
                        </div>
                    </div>
                    <vue-markdown class="LCPcontent" :anchorAttributes="{target: '_blank'}">For more information on the de-risking process please visit [our de-risking site](https://www.lcp.uk.com/pensions-benefits/buy-ins-buy-outs-longevity-swaps/).</vue-markdown>
                </div>
                <vue-markdown v-else class="LCPcontent" :source="pageNotAvailableText"/>
            </div>
        </div>
    </div>
</template>

<script>

import lineChart from '@/components/common/charts/lineChart.js';
import aviva from '@/assets/images/insurers/aviva.gif';
import just from '@/assets/images/insurers/just.jpg';
import legalandgeneral from '@/assets/images/insurers/legalandgeneral.jpg';
import pic from '@/assets/images/insurers/pic.png';
import rothesaylife from '@/assets/images/insurers/rothesaylife.png';
import scottishwidows from '@/assets/images/insurers/scottishwidows.jpg';
import table from '@/components/common/tables/table.vue';

const chartOptionsOverride = {
    credits: {
        text: ''
    },
    tooltip: {
        formatter: null,
        pointFormatter: function () {
            const giltsSeries = this.series;
            const giltsValue = this.y;
            const indicativePriceSeries = this.series.chart.series[0];
            const seriesDataForPoint = indicativePriceSeries.points[this.index];

            return '<tr style="color: ' + giltsSeries.color + '"><td style=\'padding-right: 10px;\'>' + giltsSeries.name + ': </td><td style="text-align: right"><b>' + giltsValue.toAmountString() + '</b></td></tr>' +
                    '<tr style="color: ' + indicativePriceSeries.color + '"><td style=\'padding-right: 10px;\'>' + indicativePriceSeries.name + ': </td><td style="text-align: right"><b>' + seriesDataForPoint.options.low.toAmountString() + ' to ' + seriesDataForPoint.options.high.toAmountString() + '</b></td></tr>';
        }
    }
};

function getTableData (config) {
    const headerRow = table.getRowFromValues(['', config.giltMeasureTableNameDisplay, config.rangeDisplayName]);

    const cellContentByRow = {};
    cellContentByRow.latest = getRowContentForSummary(config.latestDate, config.summaryAtLatestDate, config.summaryAtDoc != null);
    if (config.summaryAtDoc) cellContentByRow.current = getRowContentForSummary(config.calculationDate, config.summaryAtDoc, false);

    return table.getTableConfig(headerRow, cellContentByRow);
}

function getRowContentForSummary (date, summaryForDate, bold) {
    return table.getRowFromValues(['Values on ' + date.ToDateString(), summaryForDate.giltPrice.toAmountString(), summaryForDate.minPrice.toAmountString() + ' to ' + summaryForDate.maxPrice.toAmountString()], bold);
}

function getMinMaxRangeSeries (config) {
    const colorToUse = 'rgba(132,0,52,0.3)';
    const series = lineChart.getSeriesDataOnly(config.rangeDisplayName, colorToUse, getMinMaxRangeData(config));
    series.type = 'arearange';
    series.lineWidth = 0;
    series.fillColor = colorToUse;
    series.enableMouseTracking = false;
    series.marker = {
        enabled: false
    };
    return series;
}

function getMinMaxRangeData (config) {
    const data = [];
    for (let i = 0; i < config.minimumPriceData.data.length; i++) {
        data.push([config.minimumPriceData.startDate.ToUtcDate(i), config.minimumPriceData.data[i], config.maximumPriceData.data[i]]);
    };
    return data;
}

export default {
    created () {
        this.pageAvailable = this.$staticStore.state.insurerpricing.pageAvailable;
        this.pageNotAvailableText = this.$staticStore.state.insurerpricing.pageNotAvailableText;

        if (!this.pageAvailable) return;

        this.aviva = aviva;
        this.just = just;
        this.legalandgeneral = legalandgeneral;
        this.pic = pic;
        this.rothesaylife = rothesaylife;
        this.scottishwidows = scottishwidows;

        const config = this.$staticStore.state.insurerpricing.config;
        this.schemeDisplayName = this.$store.state.scheme.name;
        this.tableData = getTableData(config);
        this.chartOptions = lineChart.chartOptions(window.LCP.con.AMOUNT, chartOptionsOverride);
        this.chartSeries = [getMinMaxRangeSeries(config), lineChart.getSeriesFromChartSeriesDataHistory(config.giltsSeriesData)];
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import 'src/assets/scss/mixins.scss';

.insurerLogos{
    margin-top: -40px;
    @include responsive('xs') {
        margin-top: 0px;
    }

    img{
        padding: 20px 5px;
        height: 100px;
        margin: 0px auto;
    }
}

</style>
