/* eslint-disable */
import $ from "jquery";
require('@/components/common/charts/d3.tooltip.js');
import baseChart from '@/components/common/charts/chartSettings.js';

var d3 = require('d3');
var svg;

const margins = [10, 40, 40, 40];
var width, height;
var xRange, yRange, xDomainFunction, yDomainFunction, x, y;
var tipBubble, tipReturn;
var currentModeRef;
var onDrawStart, onDrawEnd;

const defaultTransitionTime = 1000;

var validDataById,
    startDate, endDate,
    bubbleData;

var xAxisRef = d3.svg.axis().orient("bottom");

var yAxisRef = d3.svg.axis().orient("left").tickFormat(function (d) {
    var ticks = yAxisRef.scale().ticks();
    return baseChart.getAxisLabelValue(d, ticks, window.LCP.con.PERCENTAGE);
});

var lineGenerator = d3.svg.line().interpolate("basis")
    .x(function (d) { return x(new Date(d.date)); })
    .y(function (d) { return y(d.totalReturn); });

var chartRef, headerAdjustment;

function add(sel, currentMode, onDrawStartFn, onDrawEndFn, headerAdj) {
    chartRef = sel;
    currentModeRef = currentMode;
    onDrawStart = onDrawStartFn;
    onDrawEnd = onDrawEndFn;
    headerAdjustment = headerAdj;

    /*Add d3 chart*/
    svg = d3.select(sel).append("svg:svg")
        .attr("class", "d3Chart")
        .append("svg:g")
        .attr("transform", "translate(" + margins[3] + "," + margins[0] + ")");

    /*Add xAxis*/
    svg.append("g")
        .attr("class", "xaxis")
        .call(xAxisRef)
        .append("text")
        .attr("class", "xlabel")
        .attr("text-anchor", "middle")
        .attr("y", 40);

    /*Add yAxis*/
    svg.append("g")
        .attr("class", "yaxis")
        .attr("transform", "translate(" + margins[3] + ",0)")
        .call(yAxisRef)
        .append("text")
        .attr("class", "ylabel")
        .attr("text-anchor", "middle")
        .attr("y", -55)
        .attr("transform", "rotate(-90)");

    /*Add holder for risk return bubbles*/
    svg.append("svg:g")
        .attr("class", "circleGroup");

    /*Add holder for asset lines*/
    svg.append("svg:g")
        .attr("class", "pathGroup");

    /*Add overlay for return hover*/
    svg.append("rect")
        .attr("class", "overlay")
        .attr("transform", "translate(" + -margins[3] + "," + -margins[0] + ")")
        .on("mouseout", function () {
            removeTooltips();
        });

    /*Create tooltip for bubble chart*/
    tipBubble = d3.tip()
        .attr('class', 'd3-tip')
        .offset([-5, 0])
        .html(function (d) {
            return "<table style=\"color: " + d.colourString + "\"><td><b>"+ d.displayName + "</b></td>" +
                    "<tr><td style=\'padding-right: 10px;\'>Total return:</td><td style=\"text-align: right\"><b>" + d.returnAndSdOverPeriod.return.toPercentString(1) + "</b></td></tr>" +
                    "<tr><td style=\'padding-right: 10px;\'>Annualised st dev:</td><td style=\"text-align: right\"><b>" + d.returnAndSdOverPeriod.sd.toPercentString(1) + "</b></td></tr></table>";
        });

    /*Create tooltip for return chart*/
    tipReturn = d3.tip()
        .attr('class', 'd3-tip')
        .html(function (d) {

            var sortedPoints = d.dataPoints.sort(function (a, b) {
                return b.totalReturn - a.totalReturn;
            });

            var htmlString = "<table><td><b>Total return at "+ new Date(d.date).ToDateString() + "</b></td>";

            for (let i = 0; i < sortedPoints.length; i++) {
                const point = sortedPoints[i];
                htmlString += "<tr style=\'color:" + point.colour + "\'><td style=\'padding-right: 10px;\'>" + point.displayName + ":</td><td style=\"text-align: right\"><b>" + point.totalReturn.toPercentString(1) + "</b></td></tr>";
            }

            htmlString += "</table>";

            return htmlString;
        });

    /*Adjust for dimensions*/
    setDimensions();

    /*Set initial mode*/
    updateForRiskMode();

    // Redraw based on the new size whenever the browser window is resized.
    window.addEventListener("resize", resize);
}

function removeTooltips() {
    d3.select(".d3-tip").remove();
}

function resize() {
    setDimensions();
    updateAxes(0);
}

function setDimensions() {

    var crt = $(chartRef);
    width = crt.width();
    var widthRangeAllowingForMargins = [margins[3], width - margins[1] - 15];
    xRange = d3.scale.linear().range(widthRangeAllowingForMargins);

    height = 400;

    d3.select('.d3Chart')
        .attr("width", width)
        .attr("height", height);

    svg.selectAll(".overlay")
        .attr("width", width)
        .attr("height", height);
}

function getHeightRange(margin) {
    return [height - margins[2] - margin, margins[0]];
}

function updateAxes(transitionTimeOverride) {

    if (validDataById == null) return;

    var transitionTime = transitionTimeOverride === undefined ? defaultTransitionTime : transitionTimeOverride;

    x = xRange.domain(xDomainFunction(validDataById));
    xAxisRef.scale(x);

    y = yRange.domain(yDomainFunction(validDataById));
    yAxisRef.scale(y);

    svg.selectAll(".xlabel")
        .transition()
        .duration(transitionTime)
        .attr("x", width / 2);

    svg.selectAll(".xaxis")
        .transition()
        .duration(transitionTime)
        .attr("transform", "translate(0," + y(0) + ")")
        .call(xAxisRef);

    svg.selectAll(".ylabel")
        .transition()
        .duration(transitionTime)
        .attr("x", (margins[3] - height) / 2);

    svg.selectAll(".yaxis")
        .transition()
        .duration(transitionTime)
        .call(yAxisRef);

        if (currentModeRef.inReturn) {
            currentModeRef.selectedAssetIds.forEach(assetId => {
                const dataForAsset = validDataById[assetId];
                dataForAsset.drawing = false;
                svg.selectAll(".assetLine" + dataForAsset.initial)
                    .transition()
                    .duration(transitionTime)
                    .attr("d", drawLineFunction(dataForAsset.totalReturnData));
            });

            svg.selectAll('.assetTickerGroup')
                .transition()
                .duration(transitionTime)
                .attr("transform", bubbleTransformForReturnMode())
                .attr("opacity", bubbleOpacityForReturnMode())
        }
        else {
            transitionBubbles(transitionTime);
        }
}

function updateForRiskMode() {

    currentModeRef.inReturn = false;
    currentModeRef.selectedAssetIds = [];

    svg.selectAll(".xlabel")
        .text("Annualised standard deviation (%)");

    svg.selectAll(".ylabel")
        .text("Total return values over period (%)");

    removeTooltips();
    svg.selectAll('.overlay').attr('display', 'none');
    svg.selectAll('.returnLabel').remove();
    svg.selectAll('.assetLine').remove();

    xAxisRef = xAxisRef.innerTickSize(6).outerTickSize(6).tickFormat(function (d) {
        var ticks = xAxisRef.scale().ticks();
        return baseChart.getAxisLabelValue(d, ticks, window.LCP.con.PERCENTAGE);
    });

    yRange = d3.scale.linear().range(getHeightRange(30));
    xDomainFunction = xDomainFunctionRiskMode;
    yDomainFunction = yDomainFunctionRiskMode;
    updateAxes();
}

function xDomainFunctionRiskMode(data) {

    var dataValuesArray = Object.values(data);
    var dataFunction = function (d) { return d.returnAndSdOverPeriod.sd; };

    return [
        Math.floor(d3.min(dataValuesArray, dataFunction) * 500) / 500,
        Math.ceil(d3.max(dataValuesArray, dataFunction) * 500) / 500
    ];
}

function yDomainFunctionRiskMode(data) {

    var dataValuesArray = Object.values(data);
    var dataFunction = function (d) { return d.returnAndSdOverPeriod.return; };

    return [
        Math.floor(d3.min(dataValuesArray, dataFunction) * 500) / 500,
        Math.ceil(d3.max(dataValuesArray, dataFunction) * 500) / 500
    ];
}

function xDomainFunctionReturnMode(data) {
    return [startDate, endDate];
}

function yDomainFunctionReturnMode(data) {

    var allReturnDataForSelectedAssets = [];

    currentModeRef.selectedAssetIds.forEach(id => {
        allReturnDataForSelectedAssets = allReturnDataForSelectedAssets.concat(data[id].totalReturnData);
    });

    var dataFunction = function (d) { return d.totalReturn; };

    return [
        Math.floor(d3.min(allReturnDataForSelectedAssets, dataFunction) * 500) / 500,
        Math.ceil(d3.max(allReturnDataForSelectedAssets, dataFunction) * 500) / 500
    ];
}

function updateForReturnMode() {

    if (!currentModeRef.inReturn) {
        currentModeRef.inReturn = true;

        xAxisRef = xAxisRef.innerTickSize(0).outerTickSize(0).tickFormat(function (d) {
            var tickValues = xAxisRef.scale().ticks();
            return baseChart.getAxisDateLabelValue(d, tickValues);
        });

        svg.selectAll('.overlay').attr('display', null);
        svg.selectAll(".xlabel").text("");
        removeTooltips();

        updateForReturnModeOnDataUpdate();
    }

    yRange = d3.scale.linear().range(getHeightRange(0));
    xDomainFunction = xDomainFunctionReturnMode;
    yDomainFunction = yDomainFunctionReturnMode;
    updateAxes();
}

function updateForReturnModeOnDataUpdate() {
    svg.selectAll(".ylabel")
        .text("Total return since " + startDate.ToDateString() + ' (%)');
}

function updateData(data, resultsStartDate, resultsEndDate, assetDefinitions) {

    validDataById = data;
    startDate = new Date(resultsStartDate);
    endDate = new Date(resultsEndDate);

    updateBubbleData();
    if (currentModeRef.inReturn) updateReturnData(assetDefinitions);
}

function updateBubbleData() {

    var data = Object.values(validDataById);

    bubbleData = svg.selectAll(".circleGroup")
        .selectAll(".assetTickerGroup")
        .data(data, function (d) { return d.displayName; });

    bubbleData.exit().remove();

    var bubbles = bubbleData.enter()
        .append("g")
        .attr("class", function (d) { return "assetTickerGroup touchPointer assetTickerGroup" + d.initial; })
        .attr("opacity", currentModeRef.inReturn ? 0 : 1)
        .on('mouseover', function (d) {
            svg.call(tipBubble);
            tipBubble.show(d);
        })
        .on('mouseout', function (d) {
            removeTooltips();
        })
        .on("click", function (d) {
            drawReturn(d);
        });

    bubbles.append("svg:circle")
        .attr("r", 15)
        .attr("fill", function(d) { return d.colourString; });

    bubbles.append("svg:text")
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "central")
        .attr("fill", "white")
        .text(function(d) { return d.initial; });

    if (!currentModeRef.inReturn) updateAxes();
}

function transitionBubbles(transitionTimeOverride) {
    var transitionTimeToUse = transitionTimeOverride === undefined ? defaultTransitionTime : transitionTimeOverride;
    bubbleData.transition()
        .duration(transitionTimeToUse)
        .attr("transform", function(d, i) {
            return "translate(" + x(d.returnAndSdOverPeriod.sd) + "," + y(d.returnAndSdOverPeriod.return) + ")";
        })
        .attr("opacity", 1);
}

function onAssetSelected(asset) {
    var assetData = validDataById[asset];

    if (currentModeRef.selectedAssetIds.includes(asset)) {

        if (currentModeRef.selectedAssetIds.length === 1) {
            updateForRiskMode();
            endDrawingData(assetData);
            return;
        }

        currentModeRef.selectedAssetIds.splice(currentModeRef.selectedAssetIds.indexOf(asset), 1);
        svg.selectAll('.assetLine' + assetData.initial).remove();

        updateAxes();
        endDrawingData(assetData);
        return;
    }

    drawReturn(assetData);
}

function endDrawingData (data) {
    data.drawing = false;

    var elements = [];

    Object.values(validDataById).forEach(element => {
      if (element.drawing) elements.push(element.displayName);
    });

    if (elements.length == 0) onDrawEnd();
}

function drawReturn(data) {
    onDrawStart();

    currentModeRef.selectedAssetIds.push(data.displayName);

    updateForReturnMode();

    svg.selectAll(".overlay")
      .on("mousemove", mousemove());

    svg.selectAll(".pathGroup")
        .append("svg:path")
        .attr("class", 'assetLine assetLine' + data.initial)
        .attr("opacity", 0.5)
        .style("stroke", data.colourString)
        .style("fill", "transparent")
        .style("stroke-width", "2px")
        .data(data);

    if (currentModeRef.selectedAssetIds.includes(data.displayName)) data.drawing = true;

    svg.selectAll('.assetTickerGroup')
        .transition()
        .duration(defaultTransitionTime)
        .attr("transform", bubbleTransformForReturnMode())
        .attr("opacity", bubbleOpacityForReturnMode())
        .each("end", function(d, i) {

            if (data.displayName !== d.displayName) return;

            var lenRet = d.totalReturnData.length,
                keepLenRet = lenRet,
                reduce = Math.max(1, Math.round(lenRet / 100));

            d3.timer(function () {
                if (!d.drawing || !currentModeRef.selectedAssetIds.includes(d.displayName)) {
                    endDrawingData(d);
                    return true;
                }

                lenRet = lenRet - reduce;

                var dataToUse = lenRet < 0 ? d.totalReturnData : d.totalReturnData.slice(lenRet, keepLenRet);
                svg.selectAll(".assetLine" + d.initial)
                    .attr("d", drawLineFunction(dataToUse));

                if (lenRet < 0) {
                    endDrawingData(d);
                    return true;
                }
            });
        });
}

function bubbleTransformForReturnMode () {
    return function (d) {

        var tx, ty;

        if (currentModeRef.selectedAssetIds.includes(d.displayName)) {
            tx = x(endDate);
            ty = y(d.totalReturnData[d.totalReturnData.length - 1].totalReturn);
        }
        else tx = ty = 0;

        return "translate(" + tx + "," + ty + ")";
    }
}

function bubbleOpacityForReturnMode () {
    return function (d) {
        return currentModeRef.selectedAssetIds.includes(d.displayName) ? 1 : 0;
    }
}

function drawLineFunction (dataToUse) {
    return function () {
        return lineGenerator(dataToUse);
    }
}

function mousemove() {

    return function () {

        var mousePosition = d3.mouse(this);
        var mouseValue = new Date(x.invert(mousePosition[0] - margins[3]));
        var bisectDate = d3.bisector(function(d) { return new Date(d.date); }).left;

        var tooltipData = {
            date: null,
            dataPoints: []
        };

        currentModeRef.selectedAssetIds.forEach(assetId => {

            var data = validDataById[assetId];
            var i = bisectDate(data.totalReturnData, mouseValue, 0, data.totalReturnData.length - 1);

            var dataPointToUse;

            if (data.totalReturnData.length - 1 == i) dataPointToUse = data.totalReturnData[i];
            else {
                var dataPointForI = data.totalReturnData[i];
                var dataPointAfterI = data.totalReturnData[i + 1];

                dataPointToUse = mouseValue - new Date(dataPointForI.date) > new Date(dataPointAfterI.date) - mouseValue ? dataPointAfterI : dataPointForI;
            }

            svg.selectAll('.assetTickerGroup' + data.initial)
                .attr("transform", function () {
                    return "translate(" + x(new Date(dataPointToUse.date)) + "," + y(dataPointToUse.totalReturn) + ")";
                });

            tooltipData.date = dataPointToUse.date;
            tooltipData.dataPoints.push({
                colour: data.colourString,
                displayName: data.displayName,
                totalReturn: dataPointToUse.totalReturn
            });
        });

        showReturnTooltip(tooltipData, mousePosition);
    }
}

function showReturnTooltip(data, mousePosition) {
    svg.call(tipReturn);
    tipReturn.show(data);

    tipReturn
        .style("top", (mousePosition[1] + 165 + headerAdjustment) + "px")
        .style("left", mousePosition[0] + "px")
}

function updateReturnData(assetDefinitions) {

    var validAssetIds = [];

    for (let i = 0; i < currentModeRef.selectedAssetIds.length; i++) {
        const assetId = currentModeRef.selectedAssetIds[i];
        const dataForAsset = validDataById[assetId];

        if (dataForAsset != null) validAssetIds.push(assetId);
        else svg.selectAll('.assetLine' + assetDefinitions[assetId].initial).remove();
    }

    if (validAssetIds.length === 0) {
        updateForRiskMode();
        return;
    }

    currentModeRef.selectedAssetIds = validAssetIds;

    removeTooltips();
    updateForReturnModeOnDataUpdate();
    updateAxes();

    svg.selectAll(".overlay")
        .on("mousemove", mousemove());
}

function onDestroy () {
    removeTooltips();
    window.removeEventListener("resize", resize);
}

export default {
    add: add,
    updateData: updateData,
    onAssetSelected: onAssetSelected,
    selectRiskMode: updateForRiskMode,
    onDestroy: onDestroy
};
