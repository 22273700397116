<template>
    <div v-if="pageAvailable" class="row derisking">
        <div id="mainCard" class="col-md-8 framed partialPageCardLeft">
            <div class="card">
                <div :class="inputs[calcSuspendedId].value ? 'disabledBackground' : ''"/>
                <toggle-header :tabs="resultsTabs" :viewOverride="currentResultsView" :title="dropdownTitle == null ? 'Derisking' : null" :dropdownTitle="dropdownTitle" @currentView="onResultsViewChange" responsiveSize="sm"/>
                <lcp-table v-if="currentResultsView === summaryView" class="LCPcontent" :config="summaryTableWithIcons" :id="summaryView"/>
                <derisking-Table v-show="!tabsToRemoveLockTable.includes(currentResultsView)" :lockTableData="lockTableData" id="lockTable"/>
                <progress-tab v-if="config.interfaceOptions.showProgressTab && currentResultsView === progressView" :lockTableData="lockTableData" :config="config.progressConfig" :dynamicResult="dynamicOutput.progressResult"/>
                <headroom-tab v-if="config.interfaceOptions.showHeadroomTab && currentResultsView === headroomView" :config="config.headroomConfig" :dynamicResult="dynamicOutput.headroomResult"/>
                <risk-tab v-if="config.interfaceOptions.showRiskTab && currentResultsView === riskView" :lockTableData="lockTableData" :config="config.riskConfig" :dynamicResult="dynamicOutput.riskResult"/>
                <hedging-tab v-if="config.interfaceOptions.showHedgingTab && currentResultsView === hedgingView" :config="config.hedgingConfig" :dynamicResult="dynamicOutput.hedgingResult"/>
                <pricing-tab v-if="config.interfaceOptions.showPricingTab && currentResultsView === pricingView" :config="config.pricingConfig"/>
                <collateral-overtime-tab v-if="config.interfaceOptions.showCollateralTab && currentResultsView === collateralOvertimeView" :dynamicOutput="dynamicOutput.collateralOvertimeResult"/>
                <collateral-shocks-tab v-if="currentResultsView === collateralShocksView" :config="config.collateralShocksConfig" :dynamicResult="dynamicOutput.collateralShocksResult"/>
                <derisking-Table v-if="currentResultsView === accountingView" :lockTableData="lockTableData" :tableConfig="dynamicOutput.accountingResultsTable" :id="accountingView"/>
                <scenario-comparison-tab v-if="currentResultsView === scenarioComparisonView" :config="config.scenarioComparisonConfig" :dynamicResult="dynamicOutput.scenarioComparisonResult"/>
            </div>
        </div>
        <div class="col-md-4 partialPageCardRight">
            <div class="card">
                <toggle-header :tabs="sliderTabs" :dropdownTitle="scenarioDropdown" :title-value-override="scenarioId" :includeReset="true" @currentView="onSliderViewChange" @dropdownChange="scenarioUpdated"/>
                <input-wrapper :inputTemplate="inputs[calcSuspendedId]"/>
                <lcp-table v-if="dynamicOutput.headlineResults.warningTable" :config="this.dynamicOutput.headlineResults.warningTable" class="col-md-12 removeXsPadding"/>
                <lcp-table v-if="dynamicOutput.headlineResults.limitTable" :config="this.dynamicOutput.headlineResults.limitTable" class="col-md-12 removeXsPadding"/>
                <lcp-table :config="matchingPortfolioTableData" class="col-md-12 removeXsPadding" :formattingOverrides="tableFormattingOverrides"/>
                <div v-if="currentSliderView === assetSliderView" class="col-md-12 input-container">
                    <input-wrapper :key="key" :inputTemplate="input" :value-override="inputOverrides(key)" @currentvalue="inputsChanged" v-for="(input, key) in assetWithBuyinSliderInputs"/>
                </div>
                <div v-if="currentSliderView === hedgingSliderView" class="col-md-12 input-container">
                    <input-wrapper :key="key" :inputTemplate="input" :value-override="inputOverrides(key)" @currentvalue="inputsChanged" v-for="(input, key) in hedgingSliderInputs"/>
                </div>
                <div v-if="currentSliderView === buyinSliderView">
                    <lcp-table :config="buyinLiabilitySummaryData" class="col-md-12 removeXsPadding" style="margin-top: 10px" :formattingOverrides="tableFormattingOverrides"/>
                    <div class="col-md-12 input-container">
                        <input-wrapper :key="key" :inputTemplate="input" :value-override="inputOverrides(key)" @currentvalue="inputsChanged" v-for="(input, key) in buyinSliderInputs"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else id="mainCard" class="col-md-12 framed">
        <div class="card">
            <div class="card-header">
                <h1>{{header}}</h1>
            </div>
            <vue-markdown class="LCPcontent" :source="pageNotAvailableText"/>
        </div>
    </div>
</template>

<script>

import inputWrapper from '@/components/common/uiInputs/inputWrapper.vue';
import table from '@/components/common/tables/table.vue';
import deriskingTable from './deriskingTable.vue';
import progressTab from './deriskingProgressTab.vue';
import headroomTab from './deriskingHeadroomTab.vue';
import riskTab from './deriskingRiskTab.vue';
import hedgingTab from './deriskingHedgingTab.vue';
import pricingTab from './deriskingPricingTab.vue';
import collateralOvertimeTab from './deriskingCollateralOverTimeTab.vue';
import collateralShocksTab from './deriskingCollateralShocksTab.vue';
import scenarioComparisonTab from './deriskingScenarioComparisonTab.vue';
import dropdownTitle from '@/components/common/uiInputs/dropdownTitle.vue';

const customScenarioId = 'Custom';
const buyinLiabilitySummaryHeaderRow = table.getRowFromValues(['Liability', 'Uninsured', 'Insured']);

export default {
    components: {
        inputWrapper,
        deriskingTable,
        progressTab,
        headroomTab,
        riskTab,
        hedgingTab,
        pricingTab,
        collateralOvertimeTab,
        collateralShocksTab,
        scenarioComparisonTab
    },
    data () {
        return {
            currentResultsView: null,
            currentSliderView: null,
            scenarioId: null,
            suspendCalculationButtonText: null
        };
    },
    props: ['configProp', 'inputControlsProp', 'dynamicOutputProp', 'dropdownTitle'],
    created () {
        this.summaryView = 'Summary';
        this.progressView = 'Progress';
        this.headroomView = 'Headroom';
        this.riskView = 'Risk';
        this.hedgingView = 'Hedging';
        this.pricingView = 'Pricing';
        this.collateralOvertimeView = 'Collateral over time';
        this.collateralShocksView = 'Collateral shocks';
        this.accountingView = 'Accounting';
        this.scenarioComparisonView = 'Scenarios';
        this.assetSliderView = 'Assets';
        this.hedgingSliderView = 'Hedging';
        this.buyinSliderView = 'Buy-ins';
        this.tabsToRemoveLockTable = [this.summaryView, this.progressView, this.riskView, this.accountingView];

        this.header = 'Derisking';
        this.pageAvailable = this.$staticStore.state.derisking.pageAvailable;
        this.pageNotAvailableText = this.$staticStore.state.derisking.pageNotAvailableText;

        this.calcSuspendedId = this.config.calculationSuspendedId;

        const scenarioOptions = [customScenarioId].concat(Object.keys(this.config.scenarioInputs));

        this.scenarioDropdown = {
            prefix: 'Strategy - ',
            inputTemplate: dropdownTitle.getDefaultInputTemplate('scenarioDropdown', scenarioOptions, customScenarioId, customScenarioId)
        };

        this.tableFormattingOverrides = {
            TextAlign: 'right'
        };

        this.currentResultsView = (this.$store.state.derisking.selectedTabSingle != null && this.resultsTabs.includes(this.$store.state.derisking.selectedTabSingle))
            ? this.$store.state.derisking.selectedTabSingle
            : null;

        this.headers = this.getHeaderRow();
    },
    methods: {
        getResultsTabs (interfaceOptions) {
            const output = [];

            output.push(this.summaryView);
            if (interfaceOptions.showProgressTab) output.push(this.progressView);
            if (interfaceOptions.showHeadroomTab) output.push(this.headroomView);
            if (interfaceOptions.showRiskTab) output.push(this.riskView);
            if (interfaceOptions.showHedgingTab) output.push(this.hedgingView);
            if (interfaceOptions.showPricingTab) output.push(this.pricingView);
            if (interfaceOptions.showCollateralTab) output.push(this.collateralOvertimeView);
            if (interfaceOptions.showLeverageTab) output.push(this.collateralShocksView);
            if (interfaceOptions.showAccountingTab) output.push(this.accountingView);
            if (interfaceOptions.showComparisonTab) output.push(this.scenarioComparisonView);

            return output;
        },
        getSliderTabs (config) {
            const output = [];
            output.push(this.assetSliderView);
            if (config.hedgingSliderKeys.length !== 0) output.push(this.hedgingSliderView);
            if (config.buyinSliderKeys.length !== 0) output.push(this.buyinSliderView);
            return output;
        },
        onResultsViewChange (view) {
            this.currentResultsView = view;
            this.$store.state.derisking.selectedTabSingle = this.currentResultsView;
        },
        onSliderViewChange (view) {
            this.currentSliderView = view;
        },
        scenarioUpdated (val) {
            this.scenarioBeingUpdated = true;
            this.scenarioId = val;

            if (this.scenarioId === customScenarioId) {
                this.scenarioBeingUpdated = false;
                return;
            }

            const newInputs = this.config.scenarioInputs[this.scenarioId];
            const newInputsKeys = Object.keys(newInputs);

            for (let i = 0; i < newInputsKeys.length; i++) {
                const key = newInputsKeys[i];
                this.inputs[key].value = newInputs[key];
            }

            this.$nextTick(() => {
                this.scenarioBeingUpdated = false;
                this.$store.dispatch('updatePageData');
            });
        },
        inputsChanged () {
            if (!this.scenarioBeingUpdated) this.scenarioId = customScenarioId;
        },
        inputOverrides (key) {
            if (this.scenarioId === null || this.scenarioId === customScenarioId) return null;
            return this.config.scenarioInputs[this.scenarioId][key];
        },
        getAmountValueString (value) {
            return this.inputs[this.calcSuspendedId].value ? 'n/a' : value.toAmountString();
        },
        getHeaderRow () {
            const output = [];

            for (let i = 0; i < this.summaryTable.headerRow.length; i++) {
                output.push(this.summaryTable.headerRow[i]);
            }

            return output;
        },
        toggleLock (key) {
            this.$store.commit('updateTableLockArray', key);
            const cellsForRow = this.summaryTable.cellContentByRowId[key];
            cellsForRow[cellsForRow.length - 1].tableCellStyle.iconClass = this.lockObject[key] ? 'lock icon icon-Lock' : 'lock icon icon-Unlock';
        }
    },
    computed: {
        config () {
            return this.configProp ? this.configProp : this.$staticStore.state.derisking.config;
        },
        inputs () {
            return this.inputControlsProp ? this.inputControlsProp : this.$staticStore.state.derisking.inputControls;
        },
        dynamicOutput () {
            return this.dynamicOutputProp ? this.dynamicOutputProp : this.$store.state.derisking.dynamicOutput;
        },
        resultsTabs () {
            return this.getResultsTabs(this.config.interfaceOptions);
        },
        sliderTabs () {
            return this.getSliderTabs(this.config);
        },
        assetWithBuyinSliderInputs () {
            const output = {};

            for (const key in this.inputs) {
                if (key === this.config.deriskingConsolidatedSchemeChoiceId || key === this.calcSuspendedId) continue;
                if (this.config.hedgingSliderKeys.includes(key)) continue;

                output[key] = this.inputs[key];
            }

            return output;
        },
        hedgingSliderInputs () {
            const output = {};

            for (let i = 0; i < this.config.hedgingSliderKeys.length; i++) {
                const key = this.config.hedgingSliderKeys[i];
                output[key] = this.inputs[key];
            }

            return output;
        },
        buyinSliderInputs () {
            const output = {};

            for (let i = 0; i < this.config.buyinSliderKeys.length; i++) {
                const key = this.config.buyinSliderKeys[i];
                output[key] = this.inputs[key];
            }

            return output;
        },
        matchingPortfolioTableData () {
            const cellContentByRow = {};
            cellContentByRow.matching = table.getRowFromValues(['Matching portfolio', this.getAmountValueString(this.dynamicOutput.matchingBondAssets)]);
            return table.getTableConfig(['', ''], cellContentByRow, true);
        },
        buyinLiabilitySummaryData () {
            const cellContentByRow = {};

            for (const key in this.config.buyInLiabilitySummaryConfig.configByBuyin) {
                const configForBuyin = this.config.buyInLiabilitySummaryConfig.configByBuyin[key];
                const dynamicResultForBuyin = this.dynamicOutput.buyinLiabilitySummaryResult.resultsByBuyin[key];

                const rowContent = [
                    table.getCellDefinition(key, configForBuyin.colourOverride),
                    table.getCellDefinition(this.getAmountValueString(dynamicResultForBuyin.uninsured), configForBuyin.colourOverride),
                    table.getCellDefinition(this.getAmountValueString(dynamicResultForBuyin.insured), configForBuyin.colourOverride)
                ];

                const isTotal = key === 'Total';

                if (isTotal) {
                    for (let i = 0; i < rowContent.length; i++) {
                        rowContent[i].isTotal = true;
                    }
                }

                cellContentByRow[key] = rowContent;
            }

            return table.getTableConfig(buyinLiabilitySummaryHeaderRow, cellContentByRow);
        },
        summaryTableWithIcons () {
            const output = this.summaryTable;

            Object.keys(output.cellContentByRowId).forEach(key => {
                const cellsForRow = output.cellContentByRowId[key];
                cellsForRow[cellsForRow.length - 1].tableCellStyle.iconClass = this.lockObject[key] ? 'lock icon icon-Lock' : 'lock icon icon-Unlock';

                const toggleLock = this.toggleLock;

                cellsForRow[cellsForRow.length - 1].tableCellStyle.clicked = function () {
                    toggleLock(key);
                };
            });

            return output;
        },
        summaryTable () {
            return this.dynamicOutput.headlineResults.headlineResultsTable;
        },
        lockObject () {
            return this.$store.state.derisking.selectedTableLocks;
        },
        lockTableData () {
            const cellContentByRow = {};

            Object.keys(this.lockObject).forEach(key => {
                if (this.lockObject[key] && (key in this.summaryTableWithIcons.cellContentByRowId)) cellContentByRow[key] = this.summaryTableWithIcons.cellContentByRowId[key];
            });

            const output = { headers: this.headers, cellContentByRow: cellContentByRow };
            return output;
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import 'src/assets/scss/mixins.scss';

.disabledBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.4);
    z-index: 1;
}

.removeXsPadding {
    @include responsive ('xs') {
        padding-left: 0px;
        padding-right: 0px;
    }
}

</style>
