<template>
    <div id="schemeSelecter" class="row full-height">
        <div class="card-container">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div>
                            <div class="card-header row">
                                <div class="col-md-12 col-xs-12">
                                    <div class="col-md-8 col-xs-8">
                                        <h1>Visualise Status</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <div class="card-content col-md-8 col-xs-12">
                                        <div class="helpLink message">
                                            <p>A problem has occurred on this web site. Please try again. If this error continues, please contact support</p>
                                        </div>
                                    </div>
                                    <div class="card-content col-md-8 col-xs-12">
                                        <div class="return">
                                            <router-link to="dashboard">Return to site <icon class="icon" name="arrow-right"></icon></router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12 help-support">
                                    <div class="col-md-1 empty">&nbsp;</div>
                                    <div class="card-content col-md-5 col-xs-12">
                                        <div class="helpLink card-1">
                                            <div class="card-header row">
                                                <div class="col-md-12 col-xs-12 email-head">
                                                    <h1>Scheme Related Enquires</h1>
                                                </div>
                                            </div>
                                            <div class="card-content email-content">
                                                <p>Visualise Helpdesk</p>
                                                <a><icon name="envelope"></icon> visualiseenquires@lcp.uk.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-content col-md-5 col-xs-12">
                                        <div class="helpLink card-1">
                                            <div class="card-header row">
                                                <div class="col-md-12 col-xs-12 email-head">
                                                    <h1>Technical Problems</h1>
                                                </div>
                                            </div>
                                            <div class="card-content email-content">
                                                <p>Visualise Support</p>
                                                <a> <icon name="envelope"></icon> visualisesupport@lcp.uk.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1 empty">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import logo from '@/assets/images/visualise_logo_transparent.png';
import lcpLogo from '@/assets/images/LCP_standard_CMYK_one colour.png';

export default {
    data () {
        return {
            logo,
            lcpLogo
        };
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
   @import 'src/assets/scss/all.scss';
    .full-height {
        height: 100%;
    }
    .card-container {
        margin-top: 30px;
    }
    .empty {
        content: '&nbsp;';
    }
    #schemeSelecter{
        .logo-banner--background {
            background: white;
        }
        .help-support {
            margin-top: 35px;
        }
        .helpLink{
            // background-image: linear-gradient(to right, rgba(0,163,199, 0.9), rgba(0,100,123, 1));
            padding: 0px 20px;
            margin: 10px 0px 5px 0px;
            width: 100%;
            .card-header {
                text-align: center;
                background: linear-gradient(to right, rgba(0,163,199, 0.9), rgba(0,100,123, 1));
                margin: 0px -20px;
            }
            &.message {
                padding: 0px;
            }
            .email-head {
                h1 {
                    font-style: normal;
                    font-family: 'Montserrat', sans-serif !important;
                }
            }
            .email-content {
                text-align: center;
                padding: 20px 0px;
            }
            h1 {
                display: block;
                font-size: 25px;
                color: white;
                overflow: hidden;
            }
            a, p{
                display: block;
                font-size: 22px;
                text-decoration: none;
                cursor: pointer;
                overflow: hidden;
            }
            p {
                width: auto;
            }
        }
        .return {
            text-align: left;
            font-size: 20px;
            .icon {
                width: 15px;
                height: 15px;
                line-height: 20px;
            }
        }
    }
    #logo{
        width: 225px;
        text-align: center;
        display: block;
        margin: 0 auto;
        img{
            width: 100%;
        }
    }
    .background-container {
        z-index: -1;
        position: fixed;
        height: 100%;
        width: 101%;
        // background-image: linear-gradient(to right, rgba(0,163,199, 0.5), rgba(0,100,123, 0.3)), url('./../../../assets/images/ep_naturalblack.png');
    }
</style>
