<template>
    <div style="position: relative">
        <vue2-slider
            ref="slider"
            style="padding-left: 0px; padding-right: 0px"
            :real-time="true"
            :show="true"
            :height="10"
            :clickable="!processDragable"
            :processStyle="{'backgroundColor': colourToUse}"
            :bgStyle="{
                'backgroundColor': '#fff',
                'boxShadow': 'inset 0.5px 0.5px 3px 1px rgba(0,0,0,.1)',
                'border': '0.1px solid #a6a6a6',
                'borderRadius': '12px'
            }"
            :sliderStyle="{
                'backgroundColor': colourToUse,
                'borderRadius': '12px',
                'width': '24px',
                'height': '24px',
                'top': '-6px',
                'position': 'absolute'
            }"
            :speed="speed"
            :min="minIndex"
            :max="maxIndex"
            :value="value"
            :tooltip="tooltip"
            :formatter="tooltipFormatter"
            :disabled="disabled"
            :processDragable="processDragable"
            @drag-start="onDragStart"
            @drag-end="onDragEnd"
            @callback="changed"
        />
        <div v-if="includeStartingPoint" class="startingDot" :style="[{'left': startingPoint + 'px'}, {'background': startPointColour}]"></div>
    </div>
</template>

<script>

import vue2Slider from 'vue-slider-component';

export default {
    components: {
        vue2Slider
    },
    props: {
        speed: {
            type: Number
        },
        minIndex: {
            type: Number
        },
        maxIndex: {
            type: Number
        },
        value: {
            type: [Number, Array],
            required: true
        },
        resetValue: {
            type: Number
        },
        minIndexAllowed: {
            type: [Number, Array]
        },
        maxIndexAllowed: {
            type: [Number, Array]
        },
        tooltip: {
            default: false,
            validator: function (value) {
                return ['hover', 'always', false].indexOf(value) !== -1;
            }
        },
        tooltipFormatter: {
            type: [String, Function]
        },
        disabled: {
            type: Boolean,
            default: false
        },
        processDragable: {
            type: Boolean,
            default: false
        },
        onDragStart: {
            type: Function
        },
        onDragEnd: {
            type: Function,
            required: true
        },
        changed: {
            type: Function,
            required: true
        },
        colourOverride: {
            type: String
        }
    },
    data () {
        return {
            startingPoint: null
        };
    },
    created () {
        this.includeStartingPoint = this.resetValue != null && this.resetValue.constructor === Number;
    },
    mounted () {
        if (!this.includeStartingPoint) return;
        this.updateStartingPoint();
        window.addEventListener('resize', this.updateStartingPoint);
    },
    beforeDestroy () {
        if (this.includeStartingPoint) window.removeEventListener('resize', this.updateStartingPoint);
    },
    computed: {
        valueToUse () {
            return this.value;
        },
        colourToUse () {
            return this.colourOverride == null ? '#00a4c8' : this.colourOverride;
        },
        startPointColour () {
            return this.colourOverride == null ? window.LCP.colours.SECONDARY : 'black';
        }
    },
    methods: {
        updateStartingPoint () {
            const sliderWidth = this.$refs.slider.$el.clientWidth;
            const range = this.maxIndex - this.minIndex;
            this.startingPoint = (sliderWidth / range) * (this.resetValue - this.minIndex);
        },
        setBasedOnLimits (val, i) {
            const min = this.minIndexAllowed == null ? null : this.minIndexAllowed[i];
            const max = this.maxIndexAllowed == null ? null : this.maxIndexAllowed[i];

            if (min != null && val < min) return min;
            if (max != null && val > max) return max;

            return val;
        }
    },
    watch: {
        valueToUse (val) {
            let valueToOverride = null;

            if (Array.isArray(val)) {
                valueToOverride = new Array(val.length);
                for (let i = 0; i < val.length; i++) {
                    valueToOverride[i] = this.setBasedOnLimits(val[i], i);
                }
            } else {
                if (this.maxIndexAllowed !== undefined && val > this.maxIndexAllowed) {
                    valueToOverride = this.maxIndexAllowed;
                } else if (this.minIndexAllowed !== undefined && val < this.minIndexAllowed) {
                    valueToOverride = this.minIndexAllowed;
                }
            }

            if (valueToOverride != null) this.$refs.slider.setValue(valueToOverride);
        }
    }
};

</script>

<style lang="scss" scoped>

@import 'src/assets/scss/colors.scss';

.startingDot{
    position: absolute;
    top: 8px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    pointer-events: none;
    z-index: 5;
}

</style>

<style lang="scss">

@import 'src/assets/scss/base.scss';

.vue-slider-component .vue-slider-tooltip {
    @extend %standardFontSize;
}

</style>
