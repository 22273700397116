<template>
    <highchart class="LCPcontent" :options="chartOptions" :series-options="chartSeries" key="collateralOverTime"/>
</template>

<script>

import barChart from '@/components/common/charts/barChart.js';

const additionalChartOptionsCollateralOvertime = {
    chart: {
        type: 'column'
    },
    tooltip: {
        formatter () {
            let s = '<b>' + this.x + '</b><table>';
            s += '<tr style=\'color:' + this.color + '\'><td style=\'padding-right: 10px;\'>' + this.series.name + ': </td><td style=\'text-align: right\'><b>' + this.y.toAmountString() + '</b></td></tr>';
            s += '</table>';
            return s;
        }
    }
};

export default {
    props: ['dynamicOutput'],
    computed: {
        chartOptions () {
            const options = barChart.chartOptions(additionalChartOptionsCollateralOvertime, window.LCP.con.AMOUNT);
            options.xAxis[0].categories = this.dynamicOutput.allSeriesData[0].data.xLabels;
            return options;
        },
        chartSeries () {
            return barChart.getSeriesCollectionFromChartSeriesProjectedXyDoubleCollection(this.dynamicOutput.allSeriesData, 'collateral');
        }
    }
};

</script>
