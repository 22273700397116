<template>
    <div class="rangeWrapper">
        <div class="slideTextInputWrapper">
            <text-input class="rangeInput" style="padding-right: 10px;"
                :id="id+'start'"
                :value="value[0]"
                :displayType="config.displayType"
                :lowerBound="config.minimumArray != null ? config.minimumArray[0] : config.options[0]"
                :upperBound="config.maximumArray != null ? config.maximumArray[0] : config.options[config.options.length - 1]"
                v-on:valueUpdated="onTextValueUpdatedStart"
            />
            <div style="padding-top: 5px;">to</div>
            <text-input class="rangeInput" style="padding-left: 10px;"
                :id="id+'end'"
                :value="value[1]"
                :displayType="config.displayType"
                :lowerBound="config.minimumArray != null ? config.minimumArray[1] : config.options[0]"
                :upperBound="config.maximumArray != null ? config.maximumArray[1] : config.options[config.options.length - 1]"
                v-on:valueUpdated="onTextValueUpdatedEnd"
            />
        </div>
        <basicSlider class="sliderContainer" style="margin-top: 5px"
            :changed="changed"
            :disabled="lockedState"
            :maxIndex="config.options.length - 1"
            :maxIndexAllowed="maxIndexArray"
            :minIndex="0"
            :minIndexAllowed="minIndexArray"
            :onDragEnd="endSlide"
            :onDragStart="startSlide"
            :processDragable="true"
            :speed="0.3"
            :tooltipFormatter="sliderFormatter"
            :value="indexArray"
        />
    </div>
</template>

<script>

import textInput from '@/components/common/uiInputs/textInput.vue';
import basicSlider from '@/components/common/uiInputs/basicSlider.vue';

export default {
    props: ['inputTemplate', 'valueOverride'],
    components: {
        basicSlider,
        textInput
    },
    data () {
        return {
            value: null,
            indexArray: null,
            locked: false
        };
    },
    created () {
        this.id = this.inputTemplate.id;
        this.config = this.inputTemplate.config;
        this.locked = this.inputTemplate.locked;
        this.updateValue(this.inputTemplate.value);
        this.minIndexArray = this.config.minimumArray == null ? null : this.getSliderIndexArray(this.config.minimumArray);
        this.maxIndexArray = this.config.maximumArray == null ? null : this.getSliderIndexArray(this.config.maximumArray);
    },
    mounted () {
        this.updateValue(this.inputTemplate.value);
    },
    methods: {
        sliderFormatter (index) {
            if (index === undefined) return '';

            const value = this.getValueForIndex(index);

            switch (this.config.displayType) {
            case window.LCP.con.AMOUNT:
            case window.LCP.con.AMOUNT_PA:
                return value.toScaledValueString(this.config.displayType);
            case window.LCP.con.PERCENTAGE:
            case window.LCP.con.PERCENTAGE_PA:
                return value.toScaledValueString(this.config.displayType, 2);
            case window.LCP.con.DATE:
                return value.ToDateString();
            default:
                return value;
            }
        },
        getSliderIndexArray (value) {
            const startIndex = value[0] === null ? null : window.LCP.fn.arrayFindIndexNearest(this.config.options, value[0], this.config.displayType);
            const endIndex = value[1] === null ? null : window.LCP.fn.arrayFindIndexNearest(this.config.options, value[1], this.config.displayType);
            return [startIndex, endIndex];
        },
        getValueForIndex (index) {
            return this.config.options[index];
        },
        startSlide (event) {

        },
        endSlide (event) {
            if (!this.slideChange) return;

            this.slideChange = false;
            this.updatePageDataIfRequired();
        },
        changed (val) {
            this.slideChange = true;

            const indexArray = [this.getValueForIndex(val[0]), this.getValueForIndex(val[1])];
            this.updateValueAndSendUpdate(indexArray, true);
        },
        onTextValueUpdatedStart (value) {
            this.onTextValueUpdated(value, this.value[1]);
        },
        onTextValueUpdatedEnd (value) {
            this.onTextValueUpdated(this.value[0], value);
        },
        onTextValueUpdated (desiredStart, desiredEnd) {
            const startToUse = desiredStart < desiredEnd ? desiredStart : desiredEnd;
            const endToUse = desiredStart < desiredEnd ? desiredEnd : desiredStart;
            const valueSend = [startToUse, endToUse];
            this.updateValueAndSendUpdate(valueSend, true);
            this.updatePageDataIfRequired();
        },
        updateValueAndSendUpdate (newValue, updateDynamicOutput) {
            this.value = newValue;
            this.indexArray = this.getSliderIndexArray(this.value);
            this.sendUpdate(updateDynamicOutput);
            this.$emit('currentvalue', this.value, this.inputTemplate.id);
        },
        updateValue (newValue) {
            this.value = newValue;
            this.indexArray = this.getSliderIndexArray(this.value);
        },
        sendUpdate (updateDynamicOutput) {
            const data = {
                id: this.inputTemplate.id,
                value: this.value,
                locked: this.locked
            };

            this.$store.commit('updateInput', data);
        },
        updatePageDataIfRequired () {
            if (this.config.suppressPageUpdate) return;

            this.$store.dispatch('updatePageData');
            this.$emit('updatePageProcess', this.value);
        }
    },
    computed: {
        dataState () {
            return this.$store.state.dataState;
        },
        lockedState () {
            return this.locked || this.dataState !== 'loadedData';
        }
    },
    watch: {
        valueOverride (newVal, oldVal) {
            if (newVal == null || newVal === this.value) return;
            this.updateValueAndSendUpdate(newVal, false);
        },
        dataState (newVal, oldVal) {
            if (newVal === 'resetData' && this.inputTemplate.resetValue != null) {
                this.locked = this.inputTemplate.resetLocked;
                this.updateValueAndSendUpdate(this.inputTemplate.resetValue, false);
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import 'src/assets/scss/all.scss';

.rangeWrapper{

    .slideTextInputWrapper{
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .rangeInput{
        width: 120px;
        :deep(.sliderEdit) {
            text-align: center;
            background-color: $PRIMARY;
            border: 1px solid $PRIMARY;
            padding: 5px;
            color: white;
            border-radius: 5px;
             &:focus{
                background-color: white;
                border: 1px solid $PRIMARY;
                outline: none;
                color: $PRIMARY;
            }
        }
    }
}

</style>
