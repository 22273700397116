/* eslint-disable */
import table from '@/components/common/tables/table.vue';


function getTableCell (value, textColour, backColour, asset, event, textAlign, width) {
    var cell = table.getRowCellWithColour(value);
    cell.styleOverrides = {
        textAlign: textAlign,
        width: width,
        cursor: event ? 'pointer' : 'default',
        'pointer-events': asset.available ? '' : 'none'
    };

    if (textColour) cell.styleOverrides.color = textColour;
    if (backColour) cell.styleOverrides.backgroundColor = backColour;

    if (!event) return cell;

    let onAssetSelected = event;
    cell.clicked = function () {
        onAssetSelected(asset.displayName);
    };

    return cell;
}

function getPercentageString (asset) {
    return asset.available ? asset.returnAndSdOverPeriod.return.toScaledValueString(window.LCP.con.PERCENTAGE, 1) : 'n/a';
}

export default {
    getMappedResults (assets, results) {
        var output = {};

        for (const id in assets) {
            const assetResults = results[id];

            let available = assetResults.returnAndSdOverPeriod != null;
            let assetObject = window.LCP.fn.extend(assets[id], { available: available });
            output[id] = window.LCP.fn.extend(assetObject, assetResults);
        }

        return output;
    },
    getTableData (currentMode, mappedResults, includeAssetInitial, size, event, textColour) {
        var headerRowValues = ['', ''];
        if (size !== 'xs') headerRowValues.push('');

        var headerRow = table.getRowFromValues(headerRowValues);

        var cellContentByRowId = {};

        for (const key in mappedResults) {
            const asset = mappedResults[key];

            var backColour;
            if (currentMode == null) backColour = null
            else backColour = (!currentMode.inReturn && asset.available) || (currentMode.inReturn && currentMode.selectedAssetIds.includes(asset.displayName)) ? asset.colourString : window.LCP.colours.DISABLED;

            var content = [];
            if (includeAssetInitial && size !== 'xs') content.push(getTableCell(asset.initial, textColour, backColour, asset, event, null, '10%'));
            content.push(getTableCell(asset.displayName, textColour, backColour, asset, event, 'left', '70%'));
            content.push(getTableCell(getPercentageString(asset), textColour, backColour, asset, event, 'right', '20%'));

            cellContentByRowId[key] = content;
        }

        return table.getTableConfig(headerRow, cellContentByRowId, true);
    },
};
