
<template>
    <div id="wrapper">
        <img class="animatedLogo" v-bind:src="logo500"/>
        <div class="subText">
            The development and support team have been notified of this error. Please contact visualisesupport@lcp.uk.com in the meantime if you require immediate assistance.
            <br><br>
            <a href="/userschemes" class="schemesLink">Take me back to my schemes.</a>
        </div>
    </div>
</template>

<script>
import logo500 from '@/assets/svg/v_500_animated.svg';
export default {
    name: 'five-zero-zero',
    data () {
        return {
            logo500
        };
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import 'src/assets/scss/all.scss';
    #wrapper{
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: $bodyBg;
        .animatedLogo{
            position: absolute;
            left: 50%;
            margin-left:-25rem;
            top:30%;
            width: 50rem;
            @include responsive('xs'){
                margin-left:-15rem;
                top:30%;
                width: 30rem;
            }
        }
        .subText{
            text-align: center;
            width: 100%;
            position: absolute;
            top: 55rem;
            font-size: $fontSizeHeaderSm;
            @include responsive('sm') {
                font-size: $fontSizeStandard;
                padding: 5px 20px;
                margin-bottom: 10px;
                top: 45rem;
            }
        }
    }
</style>
