<template>
    <label class="control" :class="[inputWrapperClass, config.radioMode ? 'radioMode' : 'checkboxMode', config.largerFontSize ? 'largerFontSize' : '']" :style="{color: labelColour}">{{label}}
        <input type="checkbox" :checked="checked" @change="onCheckChange" :disabled="config.radioMode && checked"/>
        <div class="checkmark"/>
    </label>
</template>

<script>

export default {
    props: ['inputTemplate', 'valueOverride'],
    name: 'checkbox',
    data () {
        return {
            checked: false
        };
    },
    created () {
        this.config = this.inputTemplate.config;
        this.id = this.inputTemplate.id;
        this.label = this.config.label;
        this.labelColour = this.config.labelColour !== undefined ? this.config.labelColour : 'black';
        this.inputWrapperClass = this.config.includeInputWrapper !== undefined && !this.config.includeInputWrapper ? '' : 'inputMargin';
        this.checked = this.inputTemplate.value;
    },
    methods: {
        onCheckChange (event) {
            this.updateValueAndSendUpdate(!this.checked, true);
            this.$emit('toggled', { id: this.id, state: this.checked });
            if (!this.config.suppressPageUpdate) this.$store.dispatch('updatePageData');
        },
        updateValueAndSendUpdate (newValue, updateDynamicOutput) {
            this.checked = newValue;
            this.sendUpdate(updateDynamicOutput);
            this.$emit('currentvalue', this.checked, this.id);
        },
        sendUpdate (updateDynamicOutput) {
            const data = {
                id: this.id,
                value: this.checked,
                index: null
            };

            this.$store.commit('updateInput', data);
        }
    },
    computed: {
        dataState () {
            return this.$store.state.dataState;
        }
    },
    watch: {
        valueOverride (newVal, oldVal) {
            if (newVal == null || newVal === this.checked) return;
            this.updateValueAndSendUpdate(newVal, false);
        },
        dataState (newVal, oldVal) {
            if (newVal === 'resetData' && this.inputTemplate.resetValue != null) {
                this.updateValueAndSendUpdate(this.inputTemplate.resetValue, false);
            }
        }
    },
    getDefaultInputTemplate (id, label, selected, labelColour, suppressPageUpdate, includeInputWrapper, radioMode, largerFontSize) {
        return {
            config: {
                label: label,
                labelColour: labelColour != null ? labelColour : 'black',
                suppressPageUpdate: suppressPageUpdate != null ? suppressPageUpdate : true,
                includeInputWrapper: includeInputWrapper != null ? includeInputWrapper : false,
                radioMode: radioMode != null ? radioMode : false,
                largerFontSize: largerFontSize != null ? largerFontSize : false
            },
            id: id,
            value: selected
        };
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    @import 'src/assets/scss/base.scss';

    label{
        font-weight: normal
    }

    /* Customize the label (the container) */
    .control {
        display: block;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        user-select: none;
    }

    .largerFontSize {
        font-size: 16px;
        @include responsive('sm') {
            font-size: $fontSizeStandard;
        }
    }

    /* Hide the browser's default checkbox */
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0px;
        left: 0;
        height: 20px;
        width: 20px;
        background: #e6e6e6;
    }

    /*Radio formatting*/
    .radioMode .checkmark {
        border-radius: 50%;
    }

    /* When the checkbox is checked, add a blue background */
    .control input:checked ~ .checkmark {
        background: $LCPBlue;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .control input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkboxMode .checkmark:after {
        left: 8px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    /* Style the checkmark/indicator */
    .radioMode .checkmark:after {
       border: none;
    }

    // .container input:disabled ~ .checkmark {
    //     background: #e6e6e6;
    //     opacity: 0.6;
    //     pointer-events: none;
    // }

    // .container--checkbox input:disabled ~ .checkmark:after {
    //     border-color: #7b7b7b;
    // }

</style>
