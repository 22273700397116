<template>
    <div>
        <div class="covenant-component-dial">
            <highchart class="covenant-component-dial-chart" :options="chartOptions" :series-options="chartSeries" :showXsChart="true"/>
            <div class="covenant-component-dial-text">{{strength}}</div>
        </div>
        <div class="tileRow">The {{assessedByDisplayText}} assessed the employer covenant as <b>{{strength}}</b> as at <b>{{dateOfAssessment}}</b>.</div>
    </div>
</template>

<script>

import baseChart from '@/components/common/charts/chartSettings.js';
import chartSettings from './covenantChartSettings.js';

export default {
    name: 'covenant',
    props: ['id', 'tileData'],
    created () {
        this.labelCollection = this.tileData.labelCollection;
        this.categories = this.labelCollection.map(a => a.id);
        this.dateOfAssessment = this.tileData.dateOfAssessment;
        this.type = this.tileData.type;
        this.strength = this.tileData.strength;
        this.assessedByDisplayText = this.tileData.assessedBy + (this.tileData.assessedBy === 'Trustee' ? ' has' : ' have');
        this.chartOptions = this.getChartOptions();
        this.chartSeries = this.getChartSeries();
    },
    methods: {
        getChartOptions () {
            const options = baseChart.getChartOptions(chartSettings);
            options.yAxis.categories = this.categories;
            options.yAxis.max = this.categories.length;
            options.yAxis.plotBands = this.getPlotBands();
            return options;
        },
        getChartSeries () {
            const output = [];
            if (this.type === 'Default' && this.strength === 'TBC') output.push({ data: [this.categories.length / 2] });
            else {
                const index = window.LCP.fn.arrayFindIndex(this.categories, this.strength);
                output.push({ data: [index + 0.5] });
            }
            return output;
        },
        getPlotBands () {
            const plotBands = [];

            for (let i = 0; i < this.categories.length; i++) {
                plotBands.push({
                    from: i,
                    to: i + 1,
                    color: this.labelCollection[i].colourString,
                    thickness: '50%',
                    borderColor: 'white',
                    borderWidth: 2
                });
            }

            return plotBands;
        }
    }
};

</script>

<style scoped lang="scss">
@import 'src/assets/scss/mixins.scss';

.covenant-component-dial {
    float: left;
    @include responsive('xs') {
        float: none;
    }
}

.covenant-component-dial .covenant-component-dial-chart {
    width: 200px;
    height: 100px;
    margin: 0 auto;
}

.covenant-component-dial-text {
    width: 200px;
    margin: 0 auto;
    text-align: center;
    font-weight: bold;
}

</style>
