<template>
    <div class="body-adjustment">
        <div v-for="(info, i) in contactItems" :key="i" class="col-md-4 col-sm-12 item">
            <contact-tile :contactInfo="info"/>
        </div>
    </div>
</template>

<script>

import contactTile from './contactTile.vue';

export default {
    components: {
        contactTile
    },
    created () {
        this.contactItems = this.$staticStore.state.contacts.config.contactInformationItems;
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

   .body-adjustment{
       margin-left: -7.5px;
       margin-right: -7.5px;
    }

   .item {
        padding-left:7.5px!important;
        padding-right:7.5px!important;
    }

</style>
