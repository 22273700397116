<template>
    <table class="tileMetricTableBoldLastValue wideValues">
        <tr>
            <td>{{currentData.mainValue.description}}</td>
            <td>{{getValueString(currentData.mainValue)}}</td>
        </tr>
        <tr>
            <td>{{tileData.rangeDisplayName}}</td>
            <td>{{getValueString(currentData.minRangeValue)}} to {{getValueString(currentData.maxRangeValue)}}</td>
        </tr>
    </table>
</template>

<script>

export default {
    name: 'insurerPricing',
    props: ['id', 'tileData', 'loopMetric'],
    computed: {
        currentData () {
            return this.tileData.metricsByView[this.loopMetric];
        }
    },
    methods: {
        getValueString (value) {
            return value.value.toScaledValueString(value.scalingType);
        }
    }
};

</script>

<style lang="scss" scoped>
</style>
