<template>
    <div>
        <div class="tileRow">
            <vue-markdown :source="tileData.riskDescription"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'risk',
    props: ['id', 'tileData']
};

</script>

<style lang="scss" scoped>
</style>
