<template>
    <div>
        <div v-if="currentView === pricingView">
            <highchart class="LCPcontent" :options="pricingOptions" :series-options="pricingSeries" key="pricing"/>
            <vue-markdown class="LCPcontent" v-if="config.pricingDescription" :source="config.pricingDescription"/>
        </div>
        <highchart v-if="currentView === longevityRiskReductionView" class="LCPcontent" :options="longevityRiskReductionOptions" :series-options="longevityRiskReductionSeries" key="longevityRiskReduction"/>
        <highchart v-if="currentView === diversifiedRiskReductionView" class="LCPcontent" :options="diversifiedRiskReductionOptions" :series-options="diversifiedRiskReductionSeries" key="diversifiedRiskReduction"/>
        <div class="LCPcontent">
            <div class="row">
                <div v-for="(option, key) in viewOptions" :key="key" class="col-md-4">
                    <lcp-button :key="key" :id="option" :text="option" :handleClick="onViewChange" :active="currentView === option"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import barChart from '@/components/common/charts/barChart.js';
import lineChart from '@/components/common/charts/lineChart.js';

const additionalStackedBarChartOptions = {
    tooltip: {
        formatter () {
            let s = '<b>' + this.x + '</b><table>';
            s += '<tr style=\'color:' + this.color + '\'><td style=\'padding-right: 10px;\'>' + this.series.name + ': </td><td style=\'text-align: right\'><b>' + this.y.toPercentString(1) + '</b></td></tr>';
            s += '</table>';
            return s;
        }
    }
};

const additionalPricingOptions = {
    plotOptions: {
        series: {
            grouping: false
        }
    },
    tooltip: {
        formatter () {
            let s = '<table>';
            s += '<tr style=\'color:' + this.color + '\'><td style=\'padding-right: 10px;\'>' + this.series.name + ': </td><td style=\'text-align: right\'><b>' + this.y.toPercentString(1) + '</b></td></tr>';
            s += '</table>';
            return s;
        }
    }
};

function getPricingOptions (oldStyle, guidePriceRanges, yAxisTitle) {
    let options;

    if (oldStyle) {
        options = barChart.chartOptions(additionalPricingOptions, window.LCP.con.PERCENTAGE);
        options.xAxis[0].categories = guidePriceRanges[0].data.xLabels;
    } else {
        options = barChart.chartOptions(additionalStackedBarChartOptions, window.LCP.con.PERCENTAGE);
        options.chart.type = 'column';
        options.xAxis[0].categories = guidePriceRanges[0].data[0].xLabels;
    }

    options.yAxis[0].title.text = yAxisTitle;

    return options;
}

function getPricingSeries (oldStyle, rangeSeries, priceSeries) {
    if (oldStyle) return getOldStylePricingSeries(rangeSeries, priceSeries);
    return getNewStylePricingSeries(rangeSeries, priceSeries);
}

function getOldStylePricingSeries (rangeSeries, priceSeries) {
    const output = barChart.getSeriesCollectionFromChartSeriesProjectedXyDoubleCollection(rangeSeries, 'guides');
    for (let i = 0; i < output.length; i++) output[i].enableMouseTracking = false;

    const priceSeriesToAdd = barChart.getSeriesCollectionFromChartSeriesProjectedXyDoubleCollection(priceSeries);

    for (let j = 0; j < priceSeriesToAdd.length; j++) {
        const seriesToAdd = priceSeriesToAdd[j];
        seriesToAdd.pointPadding = 0.4;
        output.push(seriesToAdd);
    };

    return output;
}

function getNewStylePricingSeries (rangeSeries, priceSeries) {
    const output = lineChart.getSeriesFromChartSeriesDataMultipleProjectedXyCollection(rangeSeries, false);
    for (let i = 0; i < output.length; i++) output[i].enableMouseTracking = false;

    const priceSeriesToAdd = barChart.getSeriesCollectionFromChartSeriesProjectedXyDoubleCollection(priceSeries);

    for (let i = 0; i < priceSeriesToAdd.length; i++) {
        const seriesToAdd = priceSeriesToAdd[i];
        seriesToAdd.type = 'scatter';
        output.push(seriesToAdd);
    }

    return output;
}

export default {
    props: ['config'],
    data () {
        return {
            currentView: null
        };
    },
    created () {
        this.pricingView = 'Pricing';
        this.longevityRiskReductionView = 'Risk';
        this.diversifiedRiskReductionView = 'Risk diversified';
        this.viewOptions = [this.pricingView, this.longevityRiskReductionView, this.diversifiedRiskReductionView];
        this.currentView = this.viewOptions[0];
    },
    computed: {
        pricingOptions () {
            return getPricingOptions(this.config.oldStyle, this.config.guidePriceRanges, this.config.yAxisTitlePricing);
        },
        pricingSeries () {
            return getPricingSeries(this.config.oldStyle, this.config.guidePriceRanges, this.config.insurerPrice);
        },
        longevityRiskReductionOptions () {
            const options = barChart.chartOptions(additionalStackedBarChartOptions, window.LCP.con.PERCENTAGE);
            options.xAxis[0].categories = this.config.riskReductionPreDiversification[0].data.xLabels;
            options.yAxis[0].title.text = this.config.yAxisTitlePricingRiskPreDiversification;
            return options;
        },
        longevityRiskReductionSeries () {
            return barChart.getSeriesCollectionFromChartSeriesProjectedXyDoubleCollection(this.config.riskReductionPreDiversification, 'longevity');
        },
        diversifiedRiskReductionOptions () {
            const options = barChart.chartOptions(additionalStackedBarChartOptions, window.LCP.con.PERCENTAGE);
            options.xAxis[0].categories = this.config.riskReductionDiversified[0].data.xLabels;
            options.yAxis[0].title.text = this.config.yAxisTitlePricingRiskDiversified;
            return options;
        },
        diversifiedRiskReductionSeries () {
            return barChart.getSeriesCollectionFromChartSeriesProjectedXyDoubleCollection(this.config.riskReductionDiversified, 'diversifed');
        }
    },
    methods: {
        onViewChange (view) {
            this.currentView = view;
        }
    }
};

</script>
