<template>
    <div :class="absolute ? 'headerIconAbsolute' : ''" class=" icon icon-rotate-cw" @click="$store.dispatch('resetPageData')" title="Reset inputs"/>
</template>

<script>

export default {
    props: {
        absolute: {
            type: Boolean,
            default: true
        }
    }
};

</script>
