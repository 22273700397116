<template>
    <highchart :options="chartOptionsRiskReturn" :series-options="chartSeriesRiskReturn" key="riskReturn"/>
</template>

<script>
import $ from 'jquery';
import scatterChart from '@/components/common/charts/scatterChart.js';

const chartOptionsRiskReturn = {
    tooltip: {
        formatter: function () {
            return '<table style="color: ' + this.point.color + '"><td><b>' + this.series.name + '</b></td>' +
                    '<tr><td style=\'padding-right: 10px;\'>Risk:</td><td style="text-align: right"><b>' + this.point.x.toAmountString() + '</b></td></tr>' +
                    '<tr><td style=\'padding-right: 10px;\'>Return:</td><td style="text-align: right"><b>' + this.point.y.toPercentPaString(1) + '</b></td></tr></table>';
        }
    },
    xAxis: [{
        min: 0,
        labels: {
            formatter: function () {
                return this.value.toAmountStringNoUnits(0);
            }
        }
    }],
    yAxis: [{
        min: 0,
        labels: {
            formatter: function () {
                return this.value.toPercentStringNoUnits(1);
            }
        }
    }]
};

export default {
    props: ['config', 'dynamicOutput', 'possibleOptionsData'],
    created () {
        this.chartOptionsRiskReturn = scatterChart.chartOptions(chartOptionsRiskReturn, window.LCP.con.AMOUNT, window.LCP.con.PERCENTAGE_PA);
        this.chartOptionsRiskReturn.yAxis[0].title.text = this.config.expectedReturnDescription + ' (' + this.chartOptionsRiskReturn.yAxis[0].title.text + ')';
        this.chartOptionsRiskReturn.xAxis[0].title.text = this.config.riskDescription + ' (' + this.chartOptionsRiskReturn.xAxis[0].title.text + ')';
        this.chartOptionsRiskReturn.xAxis[0].plotBands = this.getPlotBands(this.config);
        this.chartOptionsRiskReturn.chart.events.load = this.chartOptionsRiskReturn.chart.events.redraw = this.getFrontierRenderFunction(this.config);

        this.initialRiskReturnPointSeries = scatterChart.getPointSeries('Current', this.config.currentColor, this.config.initialResult.risk, this.config.initialResult.return, 'diamond');
        this.boundarySeries = this.getBoundarySeries();
    },
    methods: {
        getPlotBands (config) {
            if (config.irmPlotBandData === null) return null;

            const plotBands = [];

            config.irmPlotBandData.forEach(data => {
                plotBands.push({
                    color: data.colour,
                    from: data.lowerBand,
                    to: Number.MAX_VALUE,
                    zIndex: -1
                });
            });

            return plotBands;
        },
        getFrontierRenderFunction (config) {
            return function () {
                const pts = this.series[0].data;

                const pathToRender = [];
                for (let i = 0; i < pts.length; i++) {
                    const point = pts[i];

                    if (i === 0) pathToRender.push('M');
                    else pathToRender.push('L');

                    pathToRender.push(point.plotX + this.plotLeft);
                    pathToRender.push(point.plotY + this.plotTop);
                }

                pathToRender.push('Z');

                // eslint-disable-next-line
                if ($('#myPath') !== null) $('#myPath').remove();
                this.renderer.path(pathToRender).attr({
                    id: 'myPath',
                    fill: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0.85,
                            y2: 0.5
                        },
                        stops: [[0, config.riskVReturnAreaColor], [1, 'rgba(255,255,255,0)']]
                    }
                }).add();
            };
        },
        getBoundarySeries () {
            const boundarySeries = scatterChart.getSeries('Boundary series', this.config.riskVReturnAreaColor, this.config.boundaryData, '');
            boundarySeries.marker.enabled = false;
            return boundarySeries;
        }
    },
    computed: {
        chartSeriesRiskReturn () {
            return [
                this.boundarySeries,
                this.dynamicSeriesOnSlide,
                scatterChart.getPointSeries('Chosen', this.config.alternativeColor, this.dynamicOutput.risk, this.dynamicOutput.return, 'circle'),
                this.initialRiskReturnPointSeries
            ];
        },
        dynamicSeriesOnSlide (data) {
            return {
                name: 'dynamic',
                color: this.config.alternativeColor,
                data: this.possibleOptionsData,
                marker: {
                    symbol: 'circle',
                    radius: 2
                }
            };
        }
    }
};

</script>
