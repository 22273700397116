<template>

<highchart class="LCPcontent" :options="chartOptions" :series-options="chartSeries" key="headroom"/>

</template>

<script>

import barChart from '@/components/common/charts/barChart.js';

const additionalChartOptionsHeadroom = {
    tooltip: {
        formatter () {
            let s = '<b>' + this.series.name + '</b><table>';
            for (let n = 0; n < this.series.yData.length; n++) {
                s += '<tr style=\'color:' + this.color + '\'><td style=\'padding-right: 10px;\'>' + this.series.xAxis.categories[n] + ': </td><td style=\'text-align: right\'><b>' + this.series.yData[n].toAmountString() + '</b></td></tr>';
            }
            s += '</table>';
            return s;
        }
    }
};

export default {
    props: ['config', 'dynamicResult'],
    computed: {
        chartOptions () {
            const options = barChart.chartOptions(additionalChartOptionsHeadroom, window.LCP.con.AMOUNT);
            options.xAxis[0].categories = this.config.initialResult.expectedReturnSeriesData.data.xLabels;
            return options;
        },
        chartSeries () {
            return [
                barChart.getSeriesFromChartSeriesProjectedXyDouble(this.dynamicResult.expectedReturnSeriesData, 1),
                barChart.getSeriesFromChartSeriesProjectedXyDouble(this.dynamicResult.requiredReturnSeriesData, 2),
                barChart.getSeriesFromChartSeriesProjectedXyDouble(this.dynamicResult.headroomReturnSeriesData, 2)
            ];
        }
    }
};

</script>
