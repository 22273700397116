<template>
    <div class="row">
        <div id="mainCard" class="col-md-8 framed partialPageCardLeft">
            <div class="card" style="position: relative;">
                <div v-if="manualStrategyEnabled" class="disabledBackground"></div>
                <div class="card-header">
                    <h1>Portfolio analysis</h1>
                </div>
                <lcp-table class="LCPcontent" :config="tableData"/>
                <toggle-header :tabs="resultTabs" :viewOverride="currentResultsView" @currentView="onResultsViewUpdate"/>
                <investment-pie-chart class="LCPcontent" v-if="includeAssetPieCharts && currentResultsView === assetHoldingsView" :config="pieConfig" :dynamicData="dynamicOutput.allocation"/>
                <risk-return-chart class="LCPcontent" v-if="currentResultsView === riskReturnView" :config="config" :dynamicOutput="dynamicOutput" :possibleOptionsData="possibleOptionsForSliderData"/>
                <risk-attribution-chart class="LCPcontent" v-if="currentResultsView === riskAttributionView" :config="config.riskAttributionChartConfig" :initialData="config.initialResult.attributionData" :dynamicData="dynamicOutput.attributionData" key="investmentRisk"/>
                <liquidity-chart class="LCPcontent" v-if="currentResultsView === liquidityView" :dynamic-data="dynamicOutput.liquidityChartData"/>
                <vue-markdown class="LCPcontent" :source="config.riskDefinition"/>
                <vue-markdown class="LCPcontent" v-if="config.includeHedgingMetricsInSummary">The estimated interest rate hedge ratio and inflation hedge ratio indicate what proportion of the change in the {{config.riskReferenceLiabilityDisplayName}} is matched by the investment strategy for a 0.01% pa move in long-term interest rate and inflation expectations respectively.</vue-markdown>
                <vue-markdown class="LCPcontent" v-if="$mq != 'xs' && config.covenantRiskVReturnDescriptionShown && currentResultsView == riskReturnView" :source="config.covenantRiskVReturnDescription"/>
                <vue-markdown class="LCPcontent" v-if="$mq != 'xs' && config.covenantRiskAttributionDescriptionShown && currentResultsView === riskAttributionView" :source="config.covenantRiskAttributionDescription"/>
                <div class="LCPcontent" v-if="config.climateRiskTileShown">
                    Systemic climate risk is not shown in the above – this can be much bigger than the Value at Risk due to investment and longevity factors. Please click <a @click="navigateToSensitivityPage">here</a> to see how this might affect your scheme.
                </div>
            </div>
        </div>
        <div class="col-md-4 partialPageCardRight">
            <div class="card">
                <toggle-header :tabs="sliderTabs" title="Strategy" :includeReset="true" @currentView="onSliderViewUpdate"/>
                <div class="col-md-12 input-container" :class="{ 'manual-mode-active': manualStrategyEnabled }">
                    <div v-if="showManualStrategyOption && currentSliderView !== hedgingInputView" class="strategy-controls">
                        <button
                            class="manual-strategy-button"
                            :disabled="manualStrategyEnabled && !canCalculate"
                            @click="toggleManualStrategyOrCalculate">
                            {{ manualStrategyEnabled ? 'Calculate' : 'Enter manual strategy' }}
                        </button>
                    </div>
                    <input-wrapper :inputTemplate="input" :value-override="inputOverrides(key)" @currentvalueAndId="inputsChanged" :key="key" v-for="(input, key) in currentSliderInputs" @currentId="setCurrentSlide"/>
                    <div v-if="showTotalSum" class="total-calculate-container">
                        <div v-if="!canCalculate" class="total-display">
                            Total: {{ (totalSum * 100).toFixed(0) }}<span class="percentage-symbol">%</span> - Must equal 100%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import table from '@/components/common/tables/table.vue';
import riskReturnChart from './riskReturnChart.vue';
import investmentPieChart from './investmentPieChart.vue';
import riskAttributionChart from '@/components/common/charts/riskAttributionChart.vue';
import liquidityChart from './liquidityChart.vue';
import swal from '@/components/common/swalWrapper.js';
import localStorageManager from '@/store/localStorageManager.js';

function getPv01Ie01String (percent) {
    if (isNaN(percent)) return 'n/a';
    return percent.toPercentString(0);
};

function getLeverageString (leverage) {
    if (isNaN(leverage)) return 'n/a';
    return leverage.toScaledValueString(window.LCP.con.MULTIPLIER);
};

export default {
    components: {
        riskReturnChart,
        riskAttributionChart,
        investmentPieChart,
        liquidityChart
    },
    data () {
        return {
            showManualStrategyOption: false,
            currentResultsView: null,
            currentSliderView: null,
            currentSliderInputs: null,
            manualStrategyEnabled: false,
            canCalculate: false
        };
    },
    created () {
        this.config = this.$staticStore.state.investmentstrategy.config;
        this.inputs = this.$staticStore.state.investmentstrategy.inputControls;
        this.hedgingSliderInputs = this.getSliderInputs(true, false);

        this.assetHoldingsView = 'Asset holdings';
        this.riskReturnView = 'Risk v return';
        this.riskAttributionView = 'Risk attribution';
        this.liquidityView = 'Liquidity';

        this.resultTabs = [];
        this.resultTabs.push(this.riskReturnView);
        this.resultTabs.push(this.riskAttributionView);

        this.includeAssetPieCharts = this.config.includeAssetPieCharts;
        if (this.includeAssetPieCharts) this.resultTabs.push(this.assetHoldingsView);

        this.includeLiquidityChart = this.config.includeLiquidityChart;
        if (this.includeLiquidityChart) this.resultTabs.push(this.liquidityView);

        this.assetInputView = 'Assets';
        this.hedgingInputView = 'Hedging';
        this.sliderTabs = [this.assetInputView];
        if (this.config.includeHedgingSliders) this.sliderTabs.push(this.hedgingInputView);
        if (this.config.enableManualStrategyOption) this.showManualStrategyOption = true;

        this.tableHeader = this.getHeaderRow(this.config.riskDescription, this.config.expectedReturnDescription);
        this.currentTableRow = this.getResultRow('Current', this.config.initialResult, this.config.currentColor);

        /* Feels like this should be in data but causes issues so needs to stay here */
        this.possibleOptionsForSliderData = null;

        this.pieConfig = { initialData: this.config.assetAllocation, colours: this.config.assetColours };

        this.currentResultsView = this.$store.state.investmentstrategy.selectedTab != null ? this.$store.state.investmentstrategy.selectedTab : null;

        this.snapshotDynamicOutput = this.$store.state.investmentstrategy.dynamicOutput;

        // Subscribe to the 'setDataReset' mutation and store the unsubscribe function returned by $store.subscribe
        if (this.showManualStrategyOption) {
            this.unsubscribeResetFunction = this.$store.subscribe((mutation, _) => {
                if (mutation.type === 'setDataReset') {
                    this.$nextTick(this.handleStoreReset);
                }
            });
        }
    },
    methods: {
        setCurrentSlide (slide) {
            if (this.manualStrategyEnabled) return;

            const data = [];

            if (slide !== '') {
                const outputs = this.inputs[slide].outputs;

                for (let i = 0; i < outputs.length; i = i + 2) {
                    const outputForI = outputs[i];
                    data.push([outputForI.risk, outputForI.return]);
                }
            }

            this.possibleOptionsForSliderData = data;
        },
        inputOverrides (key) {
            if (this.dynamicOutput.inputValues === null) return null;
            return this.dynamicOutput.inputValues[key];
        },
        onResultsViewUpdate (view) {
            if (this.manualStrategyEnabled) return;

            this.currentResultsView = view;
            this.$store.state.investmentstrategy.selectedTab = this.currentResultsView;
            this.onViewUpdated();
        },
        onSliderViewUpdate (view) {
            this.currentSliderView = view;
            this.onViewUpdated();
        },
        onViewUpdated () {
            if (this.currentSliderView === this.hedgingInputView) {
                this.currentSliderInputs = this.hedgingSliderInputs;
                return;
            }

            this.currentSliderInputs = this.getSliderInputs(false, this.currentResultsView === this.assetHoldingsView);
        },
        inputsChanged () {
            if (localStorageManager.getStoredValue(localStorageManager.investmentPagePopUpStorageKey) === 'true') return;

            swal.showPopup({
                title: 'Did you know?',
                text: '<p>Changing your investments may impact your funding or contribution plans.</p><p>Please contact your LCP consultant for a chat – it’s all part of our service.</p><p>Indeed, before making a change to investments, trustees must obtain and consider proper written advice.</p><p>As part of an Integrated Risk Management approach, it is also important to consider the investment risk in the context of the strength of the sponsor\'s covenant.</p>'
            });

            localStorageManager.storeValue(localStorageManager.investmentPagePopUpStorageKey, true);
        },
        getSliderInputs (hedging, includeColour) {
            const inputsForCurrentView = {};

            for (const key in this.inputs) {
                const indexOfHegdingSliderKey = key.indexOf(this.config.hedgingSliderKey);
                if ((!hedging && indexOfHegdingSliderKey === -1) || (hedging && indexOfHegdingSliderKey !== -1)) {
                    const input = this.inputs[key];
                    input.colourOverride = includeColour ? this.config.assetColours[key] : null;

                    inputsForCurrentView[key] = input;
                }
            }

            return inputsForCurrentView;
        },
        getHeaderRow (riskDescription, returnDescription) {
            const row = [
                table.getHeaderCellNoColour('')
            ];

            row.push(table.getHeaderCellNoColour(riskDescription));
            row.push(table.getHeaderCellNoColour(returnDescription));

            if (this.config.includeHedgingMetricsInSummary) {
                row.push(table.getHeaderCellNoColour('Interest rate hedging'));
                row.push(table.getHeaderCellNoColour('Inflation rate hedging'));
            }

            if (this.config.includeLeverageMetrics) {
                row.push(table.getHeaderCellNoColour('Leverage'));
            }

            return row;
        },
        getResultRow (header, result, colour) {
            const row = [
                table.getRowCellWithColour(header, colour)
            ];

            row.push(table.getRowCellWithColour(result.risk.toAmountString(), colour));
            row.push(table.getRowCellWithColour(result.return.toPercentPaString(2), colour));

            if (this.config.includeHedgingMetricsInSummary) {
                row.push(table.getRowCellWithColour(getPv01Ie01String(result.pv01), colour));
                row.push(table.getRowCellWithColour(getPv01Ie01String(result.ie01), colour));
            }

            if (this.config.includeLeverageMetrics) {
                row.push(table.getRowCellWithColour(getLeverageString(result.leverage), colour));
            }

            return row;
        },
        navigateToSensitivityPage () {
            this.$store.state.sensitivities.selectedScenario = this.config.climateScenarioId;
            this.$store.dispatch('routeToSchemePage', { pageName: this.config.sensitivityPageLink, pageType: this.config.riskFundingMeasureUrl }).then(() => {}).catch(() => {});
        },
        toggleManualStrategyOrCalculate () {
            if (!this.showManualStrategyOption) return;

            if (this.manualStrategyEnabled) {
                if (this.canCalculate) {
                    this.$store.commit('setCalcsSuspended', false);

                    this.$store.dispatch('updatePageData').then(() => {
                        this.manualStrategyEnabled = false;
                        this.$nextTick(() => {
                            this.onViewUpdated();
                        });
                    });
                }
            } else {
                this.manualStrategyEnabled = true;
                this.snapshotDynamicOutput = this.$store.state.investmentstrategy.dynamicOutput;
                this.$store.commit('setCalcsSuspended', true);

                this.checkSliderSum();
            }
        },
        checkSliderSum () {
            this.canCalculate = Math.abs(this.totalSum - 1) < 0.00001;
        },
        handleStoreReset () {
            if (this.manualStrategyEnabled) {
                this.manualStrategyEnabled = false;
                this.$store.commit('setCalcsSuspended', false);

                this.$store.dispatch('resetPageData').then(() => {
                    this.$nextTick(() => {
                        this.onViewUpdated();
                    });
                });
            } else {
                this.$nextTick(() => {
                    this.onViewUpdated();
                });
            }
        }
    },
    watch: {
        currentSliderInputs: {
            handler: 'checkSliderSum',
            deep: true
        }
    },
    computed: {
        dynamicOutput () {
            if (this.manualStrategyEnabled) {
                return this.snapshotDynamicOutput;
            }
            return this.$store.state.investmentstrategy.dynamicOutput;
        },
        tableData () {
            const cellContentByRow = {};
            cellContentByRow.chosen = this.getResultRow('Chosen', this.dynamicOutput, this.config.alternativeColor);
            cellContentByRow.current = this.currentTableRow;

            return table.getTableConfig(this.tableHeader, cellContentByRow);
        },
        showTotalSum () {
            return this.showManualStrategyOption && this.manualStrategyEnabled && this.currentSliderView !== this.hedgingInputView;
        },
        totalSum () {
            if (!this.showTotalSum || !this.currentSliderInputs || Object.keys(this.currentSliderInputs).length === 0) {
                return 0;
            }

            let sum = 0;
            for (const key in this.currentSliderInputs) {
                const value = parseFloat(this.currentSliderInputs[key].value) || 0;
                sum += value;
            }

            return sum;
        }
    },
    destroyed () {
        if (this.unsubscribeResetFunction) {
            this.unsubscribeResetFunction();
        }
    }
};

</script>

<style scoped lang="scss">
@import '@/assets/scss/colors.scss';

.disabledBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.4);
    z-index: 1;
}

:deep(.manual-mode-active .lock) {
  display: none !important;
}

.strategy-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding-bottom: 10px;
}

.total-calculate-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.total-display {
    font-weight: bold;
    margin-left: auto;
    color: red;

    .percentage-symbol {
        margin-left: 2px;
    }
}

.manual-strategy-button {
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    background-color: $LCPBlue;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
</style>
