<template>
    <div>
        <div :id="'TextInputWidthId' + id" class="TextInputWidthIdStyle" :style="[{'width': textMode ? '220px': 'auto'}]">{{upperBound != null ? getInputValueStrForValue(upperBound, relative) : inputValue}}</div>
        {{(showRelativeTo) ? prefix + getInputValueStrForValue(relativeTo) + suffixNoPa : ''}}
        {{prefix}}
        <input :class="[textMode ? 'textEdit': 'sliderEdit']"
            type="text"
            v-model="inputValue"
            v-on:blur="validateInput"
            v-on:keyup.enter="validateInput"
            v-on:focus="selectAll"
            :disabled="locked"
            :style="[lockedState ? {'background-color': 'transparent', 'color': 'grey'} : '', {width: inputWidth}, valueChanged ? {'font-weight': 'bold'} : '']"
        >
        {{suffixToUse}}
    </div>
</template>

<script>

export default {
    props: ['id', 'value', 'getValueAdditionalChecks', 'displayType', 'relative', 'relativeTo', 'decimalPlacesOverride', 'locked', 'lockedState', 'lowerBound', 'upperBound', 'valueChanged', 'textMode'],
    data () {
        return {
            inputValue: null,
            inputWidth: null
        };
    },
    created () {
        this.formattingOptions = window.LCP.fn.getFormattingOptionsForType(this.displayType);
        this.dps = this.getDecimalPlacesToUse();
        this.prefix = this.getPrefix();
        this.suffix = this.getSuffix(true);
        this.suffixNoPa = this.getSuffix(false);
        this.inputValue = this.getInputValueStr();
    },
    mounted () {
        const test = document.getElementById('TextInputWidthId' + this.id);
        this.inputWidth = (test.clientWidth + 15) + 'px';
    },
    methods: {
        getDecimalPlacesToUse () {
            if (this.decimalPlacesOverride != null) return this.decimalPlacesOverride;

            switch (this.displayType) {
            case window.LCP.con.PERCENTAGE:
            case window.LCP.con.PERCENTAGE_PA:
                return 2;
            case window.LCP.con.DATE:
            case window.LCP.con.NONE:
                return null;
            }

            return this.formattingOptions.decimals;
        },
        getInputValueStr () {
            return this.getInputValueStrForValue(this.value, this.relative);
        },
        getInputValueStrForValue (value, relative) {
            switch (this.displayType) {
            case window.LCP.con.AMOUNT:
            case window.LCP.con.AMOUNT_PA:
                return value.toScaledValueStringNoUnits(this.displayType, this.dps);
            case window.LCP.con.PERCENTAGE:
            case window.LCP.con.PERCENTAGE_PA:
                if (!relative) {
                    return value.toScaledValueStringNoUnits(this.displayType, this.dps);
                } else {
                    return ((value < 0 && !value.isSameAs(0)) ? '-' : '+') + Math.abs(value).toScaledValueStringNoUnits(this.displayType, this.dps);
                }
            case window.LCP.con.DATE:
                return value.ToDateString();
            default:
                return value;
            }
        },
        getActualValue (scaledValue) {
            switch (this.displayType) {
            case window.LCP.con.AMOUNT:
            case window.LCP.con.AMOUNT_PA:
            case window.LCP.con.PERCENTAGE:
            case window.LCP.con.PERCENTAGE_PA: {
                const scaledValueFormatted = scaledValue.replace(',', '').replace('(', '-').replace(')', '');
                return parseFloat(scaledValueFormatted).toFixed(this.dps) * this.formattingOptions.scaleFactor;
            }
            case window.LCP.con.DATE: {
                let dateSplit = this.inputValue.split('/');
                if (dateSplit.length !== 3) dateSplit = this.inputValue.split('-');

                let date;

                if (dateSplit.length === 3) {
                    date = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
                    if (date.IsInvalidSplitDate(dateSplit)) return undefined;
                } else {
                    if (dateSplit.length !== 1) return undefined;
                    date = new Date(this.inputValue);
                    if (isNaN(date)) return undefined;
                }

                return date.ToTDateString();
            }
            default:
                return scaledValue;
            }
        },
        getPrefix () {
            switch (this.displayType) {
            case window.LCP.con.AMOUNT:
            case window.LCP.con.AMOUNT_PA:
            case window.LCP.con.PERCENTAGE:
            case window.LCP.con.PERCENTAGE_PA:
                return this.formattingOptions.prefix;
            default:
                return '';
            }
        },
        getSuffix (includePa) {
            switch (this.displayType) {
            case window.LCP.con.AMOUNT:
            case window.LCP.con.PERCENTAGE:
                return this.formattingOptions.suffix;
            case window.LCP.con.AMOUNT_PA:
            case window.LCP.con.PERCENTAGE_PA:
                return includePa ? this.formattingOptions.suffix : window.LCP.fn.getFormattingOptionsForType(this.displayType === window.LCP.con.AMOUNT_PA ? window.LCP.con.AMOUNT : window.LCP.con.PERCENTAGE).suffix;
            default:
                return '';
            }
        },
        validateInput () {
            const value = this.getActualValue(event.target.value);
            if (value === undefined || value.isSameAs(this.value)) {
                this.inputValue = this.getInputValueStr();
                return;
            }

            const adjustedValue = this.getValidValue(value);
            if (adjustedValue.isSameAs(this.value)) {
                this.inputValue = this.getInputValueStr();
                return;
            }

            this.$emit('valueUpdated', adjustedValue);
        },
        getValidValue (value) {
            let lower = this.lowerBound;
            let upper = this.upperBound;
            let compareValue = value;

            switch (this.displayType) {
            case window.LCP.con.AMOUNT:
            case window.LCP.con.AMOUNT_PA:
            case window.LCP.con.PERCENTAGE:
            case window.LCP.con.PERCENTAGE_PA:
                if (isNaN(compareValue)) return this.value;
                break;
            case window.LCP.con.DATE:
                compareValue = compareValue.ToUtcDate();
                if (isNaN(compareValue)) return this.value;
                if (lower != null) lower = lower.ToUtcDate();
                if (upper != null) upper = upper.ToUtcDate();
                break;
            default:
                if (isNaN(compareValue)) return value;
                break;
            }

            if (lower != null && compareValue < lower) return this.lowerBound;
            if (upper != null && compareValue > upper) return this.upperBound;
            return this.getValueAdditionalChecks !== undefined ? this.getValueAdditionalChecks(value) : value;
        },
        selectAll () {
            event.target.select();
        }
    },
    computed: {
        showRelativeTo () {
            return this.relative && this.relativeTo != null && !this.relativeTo.isSameAs(0);
        },
        suffixToUse () {
            if (this.showRelativeTo) {
                let output = this.suffixNoPa;
                output += ' = ';
                output += this.getInputValueStrForValue(this.value + this.relativeTo, false);
                output += this.suffix;
                return output;
            }

            return this.suffix;
        }
    },
    watch: {
        value (newVal, oldVal) {
            this.inputValue = this.getInputValueStr();
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import 'src/assets/scss/colors.scss';

.sliderEdit{
    border: none;
    border-bottom: 1px dashed lightgrey;
    text-align: right;
    &:hover, &:focus{
        outline: none;
        color: $PRIMARY;
    }
}

.textEdit{
    border: 2px solid $LCPBlue;
    text-align: left;
    &:hover, &:focus{
        outline: none;
    }
}

.TextInputWidthIdStyle {
    position: absolute;
    visibility: hidden;
    height: auto;
    white-space: nowrap;
    font-weight: bold;
}

</style>
