<template>
    <div class="card tile" :style="{ backgroundColor: 'beige', color: 'black'}">
        <div class="card-header tile-header">
            <div>&nbsp;</div>
            <span class="clickable icon icon-Delete" style="right: 70px" @click="remove"/>
            <span class="clickable icon icon-plus" style="right: 35px" @click="add"/>
            <span class="clickable icon icon-edit" @click="edit"/>
        </div>
        <div class="tile-content">
            <vue-markdown v-show="!editMode" :source="text" class="tileRow"/>
            <div v-show="editMode" class="tileRow">
                <textarea name="textarea" class="textBox" v-model="text"></textarea>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['id', 'tileData'],
    data () {
        return {
            editMode: false,
            text: 'Click icon to edit text'
        };
    },
    created () {
    },
    methods: {
        add () {
            this.$emit('newNote');
        },
        remove () {
            this.$emit('removeNote', this.id);
        },
        edit () {
            this.editMode = !this.editMode;
        }
    }
};

</script>

<style scoped lang="scss">

@import 'src/assets/scss/colors.scss';

.textBox{
    width: 100%;
    height: 90px;
    padding: 1rem;
    // border: 2px solid white;
    border-radius: 3px;
    // background-color: #eee;
    // color: $LCPMarine;
    &:focus{
        outline: none;
        // border: 2px solid $LCPBlue;
    }
}

.tile {
    color: white;
    margin-bottom: 15px;
    user-select: none;
    .tile-header {
        position: relative;
        border-bottom: none;
        font-size: 28px;
        margin-bottom: 10px;
        span.icon {
            position: absolute;
            top: 6px;
            right: 0px;
        }
    }
    .tile-content {
        font-size: 18px;
    }
}

.clickable {
    cursor: pointer;
}

</style>

<style lang="scss">

    %rowPadding{
        padding-top: 3px;
        padding-bottom: 3px;
        padding-right: 5px;
    }

    .tileRow {
        @extend %rowPadding;
    }

    %basicTileMetricTable{
        text-align: right;
        width: 100%;
        td{
            @extend %rowPadding;
        }
    }

    %borderBottom{
        border-bottom: 2px solid rgba(255,255,255, 0.2);
    }

    %borderTop{
        border-top: 2px solid rgba(255,255,255, 0.2);
    }

    %borderFull{
        border: 2px solid rgba(255,255,255, 0.2);
    }

    .borderBottom {
        @extend %borderBottom;
    }

    .borderTop {
        @extend %borderTop;
    }

    .borderFull {
        @extend %borderFull;
    }

    %standardValueCellWidth{
        width: 150px
    }

    %wideValueCellWidth{
        width: 220px
    }

    .standardValueCellWidth{
        @extend %standardValueCellWidth;
    }

    .wideValueCellWidth{
        @extend %wideValueCellWidth;
    }

    .basicTileMetricTable{
        @extend %basicTileMetricTable;
    }

    .tileMetricTableBoldLastValue{
        @extend %basicTileMetricTable;
        td:last-child{
            font-weight: bold;
            @extend %standardValueCellWidth;
        }
    }

    .wideValues{
        td:last-child{
            @extend %wideValueCellWidth;
        }
    }

    .tileMetricTableStartEndFormatting{
        tr:first-child {
            td {
                @extend %borderBottom;
                font-style: normal;
            }
        }

        tr:last-child {
            td {
                @extend %borderTop;
                font-style: normal;
            }
        }

        td {
            font-style: italic;
        }
    }

</style>
