import store from './index.js';
import moment from 'moment';

const sessionIdStorageKey = 'sessionId';
const directRouteKey = 'directRoute';

export default {
    sensitivitiesPagePopUpStorageKey: 'sensitivitiesPagePopUp',
    investmentPagePopUpStorageKey: 'investmentPagePopUp',
    storeValue (storageKey, value) {
        localStorage.setItem(storageKey, value);
    },
    getStoredValue (storageKey) {
        return localStorage.getItem(storageKey);
    },
    storeSessionId (sessionId) {
        this.storeValue(sessionIdStorageKey, sessionId);
    },
    storeDirectRoute (routeToStore) {
        const objectToStore = {
            route: routeToStore,
            requestTime: moment()
        };

        const objectAsString = JSON.stringify(objectToStore);
        this.storeValue(directRouteKey, objectAsString);
    },
    getDirectRoute () {
        const storedValue = this.getStoredValue(directRouteKey);
        if (storedValue == null) return null;

        const storedObject = JSON.parse(storedValue);

        if (moment(storedObject.requestTime).add(10, 'minutes') > moment()) {
            return storedObject.route;
        }

        this.clearDirectRoute();
        return null;
    },
    clearDirectRoute () {
        localStorage.removeItem(directRouteKey);
    },
    getStoredSessionId () {
        const storedSessionId = this.getStoredValue(sessionIdStorageKey);
        return storedSessionId;
    },
    clearSession () {
        store.commit('clearSessionId');
        localStorage.removeItem(sessionIdStorageKey);
    },
    clear () {
        localStorage.removeItem('hello');
        this.clearDirectRoute();
        this.clearSession();
        localStorage.removeItem(this.sensitivitiesPagePopUpStorageKey);
        localStorage.removeItem(this.investmentPagePopUpStorageKey);
    }
};
