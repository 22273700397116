
<template>
    <component :line-height=20 :is="inputTemplate.type" :inputTemplate="inputTemplate" :value-override="valueOverride" :dynamic-label="dynamicLabel" @currentId="emitCurrentId" @currentvalue="emitCurrentValue" @updatePageProcess="emitUpdatePageProcess"/>
</template>

<script>

import singleSlider from '@/components/common/uiInputs/singleSlider.vue';
import rangeSlider from '@/components/common/uiInputs/rangeSlider.vue';
import dropdown from '@/components/common/uiInputs/dropdown.vue';
import radioButtons from '@/components/common/uiInputs/radio.vue';
import checkbox from '@/components/common/uiInputs/checkbox.vue';
import datePicker from '@/components/common/uiInputs/datepicker.vue';
import stepper from '@/components/common/uiInputs/stepper.vue';
import booleanButton from '@/components/common/uiInputs/booleanButton.vue';

export default {
    props: ['inputTemplate', 'valueOverride', 'dynamicLabel'],
    name: 'inputWrapper',
    components: {
        singleSlider,
        rangeSlider,
        dropdown,
        radioButtons,
        checkbox,
        datePicker,
        stepper,
        booleanButton
    },
    methods: {
        emitCurrentValue (value, id) {
            this.$emit('currentvalue', value);
            this.$emit('currentvalueAndId', value, id);
        },
        emitCurrentId (value) {
            this.$emit('currentId', value);
        },
        emitUpdatePageProcess (value) {
            this.$emit('updatePageProcess', value);
        }
    }
};

</script>
