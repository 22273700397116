<template>
    <div class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <div class="card-header">
                    <h1>Cashflow analysis</h1>
                </div>
                <div class="cashflowchart LCPcontent" ref="chart"/>
                <div class="LCPcontent">
                    <div class="row">
                        <div v-for="(option, key) in displayOption" :key="key" class="col-md-3">
                            <lcp-button :key="key" :id="option" :text="option" :handleClick="toggleOption" :active="activeKey === option" :disabled="inTransition"/>
                        </div>
                    </div>
                </div>
                <vue-markdown class="LCPcontent" :source="config.info"/>
            </div>
        </div>
    </div>
</template>

<script>
import chart from './chartCashflows.js';

export default {
    data () {
        return {
            activeKey: null,
            inTransition: false
        };
    },
    created () {
        this.config = this.$staticStore.state.cashflows.config;
        this.displayOption = ['Separated cashflows', 'Combined cashflows', 'Discounted cashflows', 'Discounted values'];
        this.activeKey = this.displayOption[0];
    },
    mounted () {
        chart.add(this.config.chartData, this.$refs.chart);
    },
    methods: {
        toggleOption (key) {
            this.inTransition = true;
            chart.goToStage(this.displayOption.indexOf(key), this.onStageChange, this.onTransitionComplete);
        },
        onStageChange (stage) {
            this.activeKey = this.displayOption[stage];
        },
        onTransitionComplete () {
            this.inTransition = false;
        }
    }
};
</script>

<style lang="scss">

    .cashflowchart
    {
        height: 400px;
        fill: rgb(0, 47, 95);

        svg {
            font-family: "Helvetica Neue", Helvetica;
        }

        .line {
            fill: none;
            stroke: #000;
            stroke-width: 2px;
        }

        .legendTotal, .legendTotalTitle {
            font-size: 16px;
        }

        .axis {
            shape-rendering: crispEdges;
            font-size: 10px;
        }

        .x.axis text {
            fill: rgb(200, 200, 200);
        }

        .x.axis line{
            shape-rendering: crispEdges;
            stroke:rgb(200, 200, 200);
        }

        .x.axis path {
            display: none;
        }

        .y.axis text {
            fill: rgb(200, 200, 200);
        }

        .y.axis line{
            shape-rendering: crispEdges;
            stroke:rgb(200, 200, 200);
        }

        .y.axis path {
            display: none;
        }
    }

</style>
