<template>

<div>
    <div class="chart" :id="id" :class="[showXsChart === true ? '' : 'xsResponsiveChart']"/>
    <div class="hiddenLabel" :class="[showXsChart === true || hideHiddenChartText === true ? '' : 'responsive']">
        If viewing on a phone, please rotate your screen in order to view this chart.
    </div>
</div>

</template>

<script>

import highcharts from 'highcharts/highcharts';
import More from 'highcharts/highcharts-more';
import baseChart from './chartSettings.js';

More(highcharts);

export default {
    props: ['options', 'seriesOptions', 'loadingText', 'showXsChart', 'hideHiddenChartText'],
    data () {
        return {
            id: null,
            chart: undefined
        };
    },
    created () {
        this.id = 'chartId' + this._uid;

        if (this.loadingText) {
            highcharts.setOptions({
                lang: {
                    loading: this.loadingText
                }
            });
        }
    },
    mounted () {
        this.updateOptions(this.$mq === 'xs' || this.$mq === 'sm');
    },
    methods: {
        updateOptions (xsView) {
            this.highChartOptions = window.LCP.fn.extend(xsView ? baseChart.chartOptionsFontSizeXs : baseChart.chartOptionsFontSize, this.options);

            this.highChartOptions.chart.renderTo = this.id;
            if (this.options.series === undefined || this.options.series.length === 0) {
                this.updateSeries(false);
            }

            if (this.chart !== undefined) this.chart.destroy();
            this.chart = highcharts.chart(this.id, this.highChartOptions);
            if (this.loadingText) this.chart.showLoading();
        },
        updateSeries (updateChart) {
            this.highChartOptions.series = [];
            for (const s in this.seriesOptions) {
                this.highChartOptions.series.push(this.seriesOptions[s]);
            }
            if (updateChart) this.chart.update(this.highChartOptions, true, true, false);
        }
    },
    watch: {
        options () {
            this.updateOptions();
        },
        seriesOptions () {
            this.updateSeries(true);
        },
        '$mq' (to, from) {
            this.updateOptions(to === 'xs' || to === 'sm');
        }
    }
};

</script>

<style lang="scss">

@import 'src/assets/scss/mixins.scss';

.highcharts-loading {
    opacity: 1 !important;
    font-size: 60px;
    color: rgba(0,0,0, 0.5);
    background-color: rgba(255,255,255,0) !important;
}

.hiddenLabel {
    font-weight: bold;
    display: none;
    text-align: center;
    &.responsive {
        @include responsive ('xs') {
            display: block;
        }
    }
}

</style>
