<template>
    <div>
        <highchart :options="chartOptions" :series-options="chartSeries" key="liquidity"/>
        <checkbox v-if="$mq != 'xs'" style="width:180px; margin:10px auto 0px" :inputTemplate="checkboxTemplate" v-on:toggled="showCompare"/>
    </div>
</template>

<script>

import checkbox from '@/components/common/uiInputs/checkbox.vue';
import barChart from '@/components/common/charts/barChart.js';

const baseChartOptions = {
    chart: {
        defaultSeriesType: 'column'
    },
    plotOptions: {
        series: {
            animation: false
        }
    },
    tooltip: {
        formatter () {
            let s = '<b>' + this.series.options.stack + '</b></div><table>';
            s += '<tr style=\'color:' + this.color + '\'><td style=\'padding-right: 10px;\'>' + this.series.name + ': </td><td style=\'text-align: right\'><b>' + this.y.toPercentString() + '</b></td></tr>';
            s += '</table>';
            return s;
        }
    },
    xAxis: [{
        title: {
            text: 'Business days to realise'
        }
    }],
    yAxis: [{
        min: 0,
        max: 1
    }]
};

export default {
    props: ['dynamicData'],
    data () {
        return {
            showComparison: false
        };
    },
    created () {
        this.checkboxTemplate = checkbox.getDefaultInputTemplate('showComparison', 'Show comparison', this.showComparison, null, null, null, null, true);
    },
    methods: {
        showCompare (compare) {
            this.showComparison = compare.state;
        }
    },
    computed: {
        chartOptions () {
            const axisLabels = this.showComparison ? this.dynamicData.comparisonXAxisLabels : this.dynamicData.chosenXAxisLabels;

            const chartOptions = barChart.chartOptions(baseChartOptions, window.LCP.con.PERCENTAGE);
            chartOptions.yAxis[0].title.text = 'Percentage of portfolio (' + window.LCP.fn.getFormattingOptionsForType(window.LCP.con.PERCENTAGE).axisTitle + ')';
            chartOptions.xAxis[0].categories = axisLabels;
            return chartOptions;
        },
        chartSeries () {
            if (this.dynamicData == null) return null;

            if (this.showComparison) {
                const chosenForComparison = barChart.getSeriesCollectionFromChartSeriesProjectedXyDoubleCollection(this.dynamicData.chosenSeriesDataForComparison, 'Chosen');
                const currentForComparison = barChart.getSeriesCollectionFromChartSeriesProjectedXyDoubleCollection(this.dynamicData.currentSeriesDataForComparison, 'Current');
            
                const comparisonSeries = [];

                for (let i = 0; i < chosenForComparison.length; i++) comparisonSeries.push(chosenForComparison[i]);

                for (let i = 0; i < currentForComparison.length; i++) {
                    currentForComparison[i].showInLegend = false;
                    comparisonSeries.push(currentForComparison[i]);
                }

                return comparisonSeries;
            }

            const chosenOnly = barChart.getSeriesCollectionFromChartSeriesProjectedXyDoubleCollection(this.dynamicData.chosenSeriesData, 'Chosen');
            return chosenOnly;
        }
    }
};

</script>
