<template>
    <div class="center">
        <div class="radioWrapper inputMargin" v-for="option in options" :key="option">
            <checkbox class="checkbox" :id="option" :inputTemplate="getInputTemplate(option)" :value-override="selected === option" v-on:toggled="radioSelect"/>
        </div>
    </div>
</template>

<script>

import checkbox from '@/components/common/uiInputs/checkbox';

export default {
    props: ['inputTemplate', 'valueOverride'],
    name: 'radioButtons',
    components: {
        checkbox
    },
    data () {
        return {
            selected: null
        };
    },
    created () {
        this.config = this.inputTemplate.config;
        this.options = this.config.options;
        this.selected = this.inputTemplate.value;
    },
    methods: {
        getInputTemplate (key) {
            return checkbox.getDefaultInputTemplate(key, key, this.selected === key, null, null, null, true, this.config.largerFontSize);
        },
        radioSelect (option) {
            this.updateValueAndSendUpdate(option.id, true);
            if (!this.config.suppressPageUpdate) this.$store.dispatch('updatePageData');
        },
        updateValueAndSendUpdate (newValue, updateDynamicOutput) {
            this.selected = newValue;
            this.sendUpdate(updateDynamicOutput);
            this.$emit('currentvalue', this.selected, this.inputTemplate.id);
        },
        sendUpdate (updateDynamicOutput) {
            const data = {
                id: this.inputTemplate.id,
                value: this.selected,
                index: null
            };

            this.$store.commit('updateInput', data);
        }
    },
    computed: {
        dataState () {
            return this.$store.state.dataState;
        }
    },
    watch: {
        valueOverride (newVal, oldVal) {
            if (newVal == null || newVal === this.selected) return;
            this.updateValueAndSendUpdate(newVal, false);
        },
        dataState (newVal, oldVal) {
            if (newVal === 'resetData' && this.inputTemplate.resetValue != null) {
                this.updateValueAndSendUpdate(this.inputTemplate.resetValue, false);
            }
        }
    },
    getDefaultInputTemplate (id, label, selected, labelColour, suppressPageUpdate, options) {
        return {
            config: {
                label: label,
                labelColour: labelColour != null ? labelColour : 'black',
                suppressPageUpdate: suppressPageUpdate != null ? suppressPageUpdate : true,
                options: options
            },
            id: id,
            value: selected
        };
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'src/assets/scss/mixins.scss';

.center {
    text-align: center;
}

.radioWrapper {
    display: inline-block;
}

.checkbox {
    margin: 0px 10px;
}

</style>
