/* eslint-disable */
import baseChart from './chartSettings.js';

const scatterChartOverrides = {
    chart: {
        defaultSeriesType: "scatter"
    }
};

export default {
    chartOptions (additionalOptions, scalingTypeX, scalingTypeY) {
        var output = baseChart.getChartOptions(scatterChartOverrides);
        if (scalingTypeX != null) {
            output.xAxis[0].title.text = window.LCP.fn.getFormattingOptionsForType(scalingTypeX).axisTitle;
            output.xAxis[0].labels.formatter = baseChart.getAxisLabelFormatter(scalingTypeX);
        }
        if (scalingTypeY != null) {
            output.yAxis[0].title.text = window.LCP.fn.getFormattingOptionsForType(scalingTypeY).axisTitle;
            output.yAxis[0].labels.formatter = baseChart.getAxisLabelFormatter(scalingTypeY);
        }
        if (additionalOptions != null) output = window.LCP.fn.extend(output, additionalOptions);
        return output;
    },
    getPointSeries (name, color, xValue, yValue, symbol, zValue) {
        return this.getSeries(name, color,[{x: xValue, y: yValue, z: zValue}], symbol);
    },
    getSeries (name, color, data, symbol) {
        return {
            name: name,
            color: color,
            data: data,
            marker: {
                symbol: symbol
            }
        };
    },
    getPointSeriesFromChartSeriesDataPoint (seriesData) {
        var series = this.getPointSeries(seriesData.name, seriesData.colourString, seriesData.x, seriesData.y, 'circle', seriesData.size);
        if (seriesData.isBreach) {
            series.marker.lineColor = seriesData.markerLineColourOverride;
            series.marker.lineWidth = 3;
        } else {
            series.marker.lineColor = null;
            series.marker.lineWidth = 1;
        }
        return series;
    },
    getSeriesFromChartSeriesDataPoints (chartSeriesDataPoints) {
        var output = [];

        for (var i = 0; i < chartSeriesDataPoints.length; i++) {
            var seriesData = chartSeriesDataPoints[i];
            var series = this.getPointSeriesFromChartSeriesDataPoint(seriesData);
            output.push(series);
        }

        return output;
    }
};