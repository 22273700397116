/* eslint-disable */
const defaultFontStyle = "Montserrat,sans-serif";
const defaultHeaderSize = "20px";
const defaultHeaderSizeSm = "18px";
const defaultFontSize = "14px";
const defaultFontSizeSm = "12px";
const defaultChartColor = "rgb(141, 168, 173)";
const defaultAxisLineColor = "rgb(141, 168, 173)";

const chartOptionsFontSize = getFontSizeObject(defaultFontSize, defaultHeaderSize);
const chartOptionsFontSizeSm = getFontSizeObject(defaultFontSizeSm, defaultHeaderSizeSm);

const chartOptions = {
    "chart": {
        "animation": false,
        "events": {
            "load": null,
            "redraw": null
        },
        "spacingBottom": 40,
        "spacingTop": 20,
        "style": {
            "fontFamily": defaultFontStyle
        }
    },
    "credits": {
        "href": "",
        "text": "Source: LCP Visualise",
        "style": {
            "color": defaultChartColor,
            "cursor": "default"
        }
    },
    "exporting": {
        "enabled": false
    },
    "legend": {
        "borderRadius": 6,
        "borderWidth": 1,
        "enabled": false,
        "itemStyle": {
            "color": defaultChartColor,
            "fontWeight": "normal"
        }
    },
    "plotOptions": {
        "line": {
            "marker": {
                "enabled": false
            }
        },
        scatter: {
            marker: {
                radius: 8,
            },
            stickyTracking: false
        },
        "series": {
            "events": {
                "legendItemClick": function (e) {
                    e.preventDefault();
                }
            }
        }
    },
    "series": [

    ],
    "title": {
        "style": {
            "color": defaultChartColor
        },
        "text": ""
    },
    "tooltip": {
        "backgroundColor": "White",
        "borderRadius": 15,
        "borderWidth": 1,
        "followPointer": true,
        outside: true,
        "style": {
            "color": defaultChartColor
        },
        "useHTML": true
    },
    "xAxis": [{
        "labels": {
            "style": {
                "color": defaultChartColor,
                textOverflow: 'none'
            }
        },
        "lineColor": defaultAxisLineColor,
        "lineWidth": '2',
        "title": {
            "align": "middle",
            "style": {
                "color": defaultChartColor
            },
            "text": ""
        },
    },
    {
        "labels": {
            "style": {
                "color": defaultChartColor,
                textOverflow: 'none'
            }
        },
        "lineColor": defaultAxisLineColor,
        "lineWidth": '0',
        "title": {
            "align": "middle",
            "style": {
                "color": defaultChartColor
            },
            "text": ""
        },
    }],
    "yAxis": [{
        "labels": {
            "style": {
                "color": defaultChartColor,
                textOverflow: 'none'
            }
        },
        "lineColor": defaultAxisLineColor,
        "title": {
            "style": {
                "color": defaultChartColor
            },
            "text": ""
        }
    },
    {
        "labels": {
            "style": {
                "color": defaultChartColor,
                textOverflow: 'none'
            }
        },
        "lineColor": defaultAxisLineColor,
        "title": {
            "style": {
                "color": defaultChartColor
            },
            "text": ""
        }
    }]
}

function getAxisLabelFormatter (scalingType) {
    return function () {
        return getAxisLabelValue(this.value, this.axis.tickPositions, scalingType);
    }
}

function getAxisLabelValue (value, tickValues, scalingType) {
    var dps = window.LCP.fn.getDpsRequiredToShowAllValues(tickValues, scalingType);
    return value.toScaledValueStringNoUnits(scalingType, dps);
}

function getAxisDateLabelValue (value, tickValues) {
    var format = '%b %y';

    for (let i = 0; i < tickValues.length - 1; i++) {
        const thisTickValue = window.LCP.fn.dateFormat(format, tickValues[i]);
        const nextTickValue = window.LCP.fn.dateFormat(format, tickValues[i + 1]);
        if (!thisTickValue.isSameAs(nextTickValue)) continue;

        format = '%d %b %y';
        break;
    }

    return window.LCP.fn.dateFormat(format, value);
}

function getFontSizeObject (fontSize, headerFontSize) {
    return {
        "credits": {
            "style": {
                "fontSize": fontSize
            }
        },
        "legend": {
            "itemStyle": {
                "fontSize": fontSize
            }
        },
        "title": {
            "style": {
                "fontSize": headerFontSize
            }
        },
        "tooltip": {
            "style": {
                "fontSize": fontSize
            }
        },
        "xAxis": [{
            "labels": {
                "style": {
                    "fontSize": fontSize
                }
            },
            "title": {
                "style": {
                    "fontSize": fontSize
                }
            },
        },
        {
            "labels": {
                "style": {
                    "fontSize": fontSize
                }
            },
            "title": {
                "style": {
                    "fontSize": fontSize
                }
            },
        }],
        "yAxis": [{
            "labels": {
                "style": {
                    "fontSize": fontSize
                }
            },
            "title": {
                "style": {
                    "fontSize": fontSize,
                }
            }
        },
        {
            "labels": {
                "style": {
                    "fontSize": fontSize
                }
            },
            "title": {
                "style": {
                    "fontSize": fontSize
                }
            }
        }]
    }
}

export default {
    chartOptionsFontSize: chartOptionsFontSize,
    chartOptionsFontSizeSm: chartOptionsFontSizeSm,
    getChartOptions (additionalChartOptions) {
        if (additionalChartOptions === undefined) return chartOptions;
        return window.LCP.fn.extend(chartOptions, additionalChartOptions);
    },
    getAxisLabelFormatter (scalingType) {
        return getAxisLabelFormatter(scalingType);
    },
    getAxisLabelValue (value, tickValues, scalingType) {
        return getAxisLabelValue(value, tickValues, scalingType);
    },
    getAxisDateLabelValue (value, tickValues) {
        return getAxisDateLabelValue(value, tickValues);
    },
    getSeriesWithDummySeriesForSecondaryAxisLabels (primarySeries) {
        return [
            primarySeries,
            {
                data: new Array(primarySeries.data.length).fill(0),
                xAxis: 1,
                color: 'transparent',
                dataLabels: {
                    enabled: false
                },
                type: 'line',
                enableMouseTracking: false
            }
        ];
    }
}
