<template>
    <div>
        <div class="tileRow">
            <vue-markdown :source="climateRiskDescription"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'climateRisk',
    props: ['id', 'tileData'],
    computed: {
        climateRiskDescription () {
            const climateRiskValueAmount = this.tileData.climateRiskValue.toAmountString();
            const description = `<p>The illustrated climate risk shock is <b>${climateRiskValueAmount}</b>.</p>` +
                `The impact of <b>${climateRiskValueAmount}</b> is the potential impact on the Scheme's funding level (on a ${this.visualiseAiConfig.riskFundingMeasureDisplayName} basis) ` +
                'in a scenario of the more severe consequences of climate change over a longer term (eg financial market turmoil if asset prices respond to sudden changes in perceptions of ' +
                'climate transition and physical risks, and where economic norms no longer apply). Climate risk may occur over longer time horizons but could affect the Scheme very rapidly ' +
                'at any point and could dwarf other risks. It is hard to estimate when the risks might crystallise, but recent events suggest that climate impacts are unfolding more quickly than expected.';
            return description;
        },
        visualiseAiConfig () {
            return this.$store.state.scheme.visualiseAiConfig;
        }
    }
};

</script>

<style lang="scss" scoped>
</style>
