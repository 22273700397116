// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store/index.js';
import staticStore from './staticStore';
import VueMq from 'vue-mq';
import axios from 'axios';
import VueMarkdown from '@adapttive/vue-markdown';

import VTooltip from 'v-tooltip';

import Siema from 'vue2-siema';

import { VueMasonryPlugin } from 'vue-masonry';

import downloadReport from '@/components/common/uiInputs/downloadReport.vue';

import dropdownTitle from '@/components/common/uiInputs/dropdownTitle.vue';

import lcpButton from '@/components/common/uiInputs/lcpButton.vue';

import resetInputs from '@/components/common/uiInputs/resetInputs.vue';

import inputWrapper from '@/components/common/uiInputs/inputWrapper.vue';

import lcpTable from '@/components/common/tables/table.vue';

import highchart from '@/components/common/charts/highchart.vue';

import toggleHeader from '@/components/common/uiInputs/toggleHeader.vue';

import checkbox from '@/components/common/uiInputs/checkbox.vue';

import radio from '@/components/common/uiInputs/radio.vue';

import sliderWithPeriods from '@/components/common/uiInputs/sliderWithPeriods.vue';

// Bootstrap //
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// util functions
import './assets/lcp/js/utils.js';

// images
// import './assets/images/worn_dots.png';

// Icons //
import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/bootstrap-lcp/css/fonts.css';
import '@/assets/bootstrap-lcp/css/icons-new.css';
// animations
import '@/assets/bootstrap-lcp/css/animations.css';

// Flatpickr //
import '@/assets/bootstrap-lcp/css/flatpickr.min.css';

// LCP styles //
import './assets/scss/all.scss';

// multiselect //
import 'vue-multiselect/dist/vue-multiselect.min.css';

// Font Awesome Icons //
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons';

Vue.config.productionTip = false;

Vue.use(VueMq, {
    breakpoints: { // default breakpoints - customize this
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1200
    }
});
Vue.component('vue-markdown', VueMarkdown);
Vue.use(VTooltip);
Vue.use(Siema);
Vue.use(VueMasonryPlugin);
Vue.component('download-report', downloadReport);
Vue.component('dropdown-title', dropdownTitle);
Vue.component('lcp-button', lcpButton);
Vue.component('reset-inputs', resetInputs);
Vue.component('input-wrapper', inputWrapper);
Vue.component('lcp-table', lcpTable);
Vue.component('highchart', highchart);
Vue.component('toggle-header', toggleHeader);
Vue.component('checkbox', checkbox);
Vue.component('radio', radio);
Vue.component('slider-with-periods', sliderWithPeriods);
Vue.component('icon', Icon);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

axios.defaults.timeout = 1800000;

// axios interceptor
axios.interceptors.response.use(
    function (response) {
        // continue sending response to the then() method
        return response;
    },
    function (error) {
        // request is rejected and will direct logic to the catch() method
        // eslint-disable-next-line prefer-promise-reject-errors
        if (axios.isCancel(error)) return Promise.reject('CancelledToken');
        return Promise.reject(error);
    }
);

axios.interceptors.request.use(
    async function (config) {
        if (store.state.lastRequestedCancellationToken !== null) store.state.lastRequestedCancellationToken.cancel();
        store.state.lastRequestedCancellationToken = axios.CancelToken.source();
        config.cancelToken = store.state.lastRequestedCancellationToken.token;
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

new Vue({
    router,
    store,
    staticStore,
    render: h => h(App)
}).$mount('#app');
