import baseChart from '@/components/common/charts/chartSettings.js';

const investmentChartOverrides = {
    chart: {
        type: 'pie',
        backgroundColor: null,
        margin: [-15, 0, 0, 0]
    },
    plotOptions: {
        pie: {
            allowPointSelect: false,
            borderColor: 'white',
            borderWidth: 2,
            innerSize: '60%',
            dataLabels: {
                enabled: false
            }
        },
        series: {
            states: {
                hover: {
                    enabled: true,
                    halo: {
                        size: 0
                    }
                }
            }
        }
    }
};

function getCenterXText (i, pieChartCount) {
    return 100 / pieChartCount / 2 * i + '%';
}

export default {
    chartOptions (additionalChartOptions) {
        const output = baseChart.getChartOptions(investmentChartOverrides);
        if (additionalChartOptions === undefined) return output;
        return window.LCP.fn.extend(output, additionalChartOptions);
    },
    getChartSeries (chartData, center, colours) {
        const data = [];

        for (const key in chartData) {
            const value = chartData[key];
            const colourToUse = chartData.applyOpacity ? colours[key] + '50' : colours[key];
            data.push({ name: key, y: value, color: colourToUse });
        }

        return {
            data: data,
            center: center == null ? [null, null] : center
        };
    },
    chartSeriesMultiple (chartDataMultiple, colours) {
        const output = [];
        let i = 1;
        const count = Object.keys(chartDataMultiple).length;

        for (const key in chartDataMultiple) {
            const center = [getCenterXText(i, count), '50%'];
            output.push(this.getChartSeries(chartDataMultiple[key], center, colours));
            i += 2;
        }

        return output;
    }
};
