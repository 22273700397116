<template>
    <div id="app">
        <header-section ref="headerSection" v-if="!showShemeLoader"/>
        <div v-if="!showShemeLoader" class="routerView">
            <router-view v-if="termsUpToDate"/>
            <agreeTerms v-if="!termsUpToDate" class="terms"/>
            <termsRead v-if="!termsUpToDate"/>
        </div>
        <scheme-loader v-if="showShemeLoader"/>
    </div>
</template>

<script>

import schemeLoader from '@/components/common/uiLayouts/Loader.vue';
import headerSection from '@/components/common/uiLayouts/headerSection.vue';
import agreeTerms from '@/components/pages/legal/termsLanding.vue';
import termsRead from '@/components/pages/legal/termsRead.vue';
import cssVars from 'css-vars-ponyfill';

export default {
    components: {
        schemeLoader,
        headerSection,
        agreeTerms,
        termsRead
    },
    computed: {
        showShemeLoader () {
            return this.$store.state.pageState === 'loadingScheme' || this.$store.state.pageState === 'loadingSession' || this.$store.state.pageState === 'Queuing setup for live update';
        },
        termsUpToDate () {
            return this.$store.getters.termsUpToDate;
        },
        toggleMenu () {
            return this.$store.state.toggleMenu;
        }
    },
    mounted () {
        window.addEventListener('resize', this.setHeaderHeight);
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.setHeaderHeight);
    },
    methods: {
        setHeaderHeight () {
            const headerSectionRef = this.$refs.headerSection;
            cssVars({
                variables: {
                    '--headerHeight': headerSectionRef ? headerSectionRef.$el.clientHeight + 'px' : '0px'
                },
                watch: true
            });
        }
    },
    watch: {
        toggleMenu (newVal, oldVal) {
            this.setHeaderHeight();
        },
        showShemeLoader (newVal, oldVal) {
            this.setHeaderHeight();
        },
        $route (to, from) {
            this.setHeaderHeight();
        }
    }
};

</script>

<style scoped lang="scss">

@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/mixins.scss';

#app {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .routerView {
        width: 100%;
        flex-grow: 1;
        overflow: auto;

        .terms {
            padding-bottom: 60px;
            @include responsive('sm') {
                padding-bottom: 90px;
            }
        }
    }
}

</style>

<style lang="scss">

@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/mixins.scss';

%backgroundImage {
    background-image: url('assets/images/cross-pattern-fade-bottom-right-wide.png');
    background-position: right bottom;
    background-repeat: no-repeat;
}

.viewNoScheme {
    @extend %backgroundImage;
    background-color: $LCPMarine;
    color: white;
    height: 100%;
    overflow: auto;
    padding: 50px 120px 0px 120px;
    @include responsive('lg') {
        padding: 30px 20px 0px 20px;
    }
    @include responsive('sm') {
        padding: 20px 20px 0px 20px;
    }
}

.viewScheme {
    @extend %backgroundImage;
}

</style>
