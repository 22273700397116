<template>
   <div class="acceptTermsBar">
        <checkbox class="termsCheckbox" :inputTemplate="checkboxTemplate" @toggled="toggleCheck"/>
        <div class="buttonwrapper">
            <lcp-button class="button" text="Accept" :disabled="!read" :handleClick="saveTerms"/>
            <lcp-button class="button" v-if="!read" text="Logout" :handleClick="signOut"/>
        </div>
    </div>
</template>

<script>

import checkbox from '@/components/common/uiInputs/checkbox.vue';

export default {
    data () {
        return {
            read: false
        };
    },
    created () {
        this.checkboxTemplate = checkbox.getDefaultInputTemplate('CheckboxId', 'I have read and agreed to the terms and conditions of use', false, null, null, null, null, true);
    },
    methods: {
        saveTerms () {
            if (!this.read) return;
            this.$store.dispatch('updateTermsAgreed');
        },
        toggleCheck (val) {
            this.read = val.state;
        },
        signOut () {
            this.$store.dispatch('signout');
        }
    }
};

</script>

<style scoped lang="scss">

@import 'src/assets/scss/colors.scss';

.acceptTermsBar {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 10px;
    background: white;

    .termsCheckbox {
        float: left;
        margin-right: 10px;
        margin-top: 8px;
    }

    .buttonwrapper {
        float: right;
        .button {
            display: inline-block;
            width: fit-content;
            vertical-align: bottom;
        }
    }
}

</style>
