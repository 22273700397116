<template>
    <div :style="chartOptions ? {minHeight: '90px'} : {}">
        <highchart class="investment-component-pie-chart" v-if="chartOptions" :options="chartOptions" :series-options="chartSeries" :showXsChart="true"/>
        <standardMetrics :id="id" :tileData="dynamicTableData" :class="chartOptions ? 'metric': ''"/>
    </div>
</template>

<script>

import investmentChartSettings from './investmentChartSettings.js';
import standardMetrics from './standardMetrics.vue';

const chartOverrides = {
    tooltip: {
        enabled: false
    },
    credits: {
        enabled: false
    }
};

export default {
    name: 'investment',
    props: ['id', 'tileData'],
    components: {
        standardMetrics
    },
    data () {
        return {
            currentAssetHover: null
        };
    },
    created () {
        if (this.tileData.chartData == null) return;

        this.chartOptions = this.getChartOptions();
        this.chartSeries = [investmentChartSettings.getChartSeries(this.tileData.chartData, null, this.tileData.colours)];
    },
    computed: {
        dynamicTableData () {
            const output = window.LCP.fn.extend({}, this.tileData);

            if (this.currentAssetHover != null) {
                output.metrics[0] = {
                    description: this.currentAssetHover,
                    value: this.tileData.chartData[this.currentAssetHover].toPercentString(0),
                    scalingType: window.LCP.con.AMOUNT
                };
            }

            return output;
        }
    },
    methods: {
        updateCurrentAssetHover (name) {
            this.currentAssetHover = name;
        },
        getChartOptions () {
            const options = investmentChartSettings.chartOptions(chartOverrides);

            const updateCurrentAssetHover = this.updateCurrentAssetHover;

            const optionsToUse = window.LCP.fn.extend(options, {
                plotOptions: {
                    series: {
                        point: {
                            events: {
                                mouseOver: function () {
                                    updateCurrentAssetHover(this.name);
                                },
                                mouseOut: function () {
                                    updateCurrentAssetHover(null);
                                }
                            }
                        }
                    }
                }
            });

            return optionsToUse;
        }
    }
};

</script>

<style lang="scss" scoped>
@import 'src/assets/scss/mixins.scss';

.investment-component-pie-chart {
    :deep(.chart) {
        width: 105px;
        height: 105px;
        margin: 0 auto;
        position: absolute;
        @include responsive('xs') {
            position: relative;
        }
    }
}

.metric {
    padding-left: 105px;
    @include responsive('xs') {
        padding-left: 0;
    }
}

</style>
