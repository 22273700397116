<template>
    <div>
        <table class="basicTileMetricTable marketsTable">
            <tr>
                <td/>
                <td/>
                <td colspan="2">{{'since ' + tileData.dateFrom}}</td>
            </tr>
            <tr>
                <td/>
                <td class="borderBottom">now</td>
                <td class="borderBottom borderTop">low</td>
                <td class="borderBottom">high</td>
            </tr>
            <tr v-for="item in tileData.data" :key="item.description">
                <td>{{item.description}} <span v-if="item.source !== ''" class="icon icon-info" v-tooltip="item.source"/></td>
                <td style="font-weight:bold">{{getScaledValueString(item.value, item.scalingType)}}</td>
                <td>{{getScaledValueString(item.yearLow, item.scalingType)}}</td>
                <td>{{getScaledValueString(item.yearHigh, item.scalingType)}}</td>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    name: 'markets',
    props: ['id', 'tileData'],
    methods: {
        getScaledValueString (value, scalingType) {
            return value.toScaledValueString(scalingType, this.getDps(value, scalingType));
        },
        getDps (value, scalingType) {
            switch (scalingType) {
            case window.LCP.con.NONE:
                if (value < 10) return 3;
                if (value < 100) return 2;
                if (value < 1000) return 1;
                return 0;

            case window.LCP.con.PERCENTAGE:
            case window.LCP.con.PERCENTAGE_PA:
                return 2;
            }
        }
    }
};

</script>

<style lang="scss" scoped>

@import 'src/assets/scss/base.scss';

.marketsTable {
    font-size: 16px;
    word-break: normal !important;
    @include responsive ('sm') {
        font-size: $fontSizeStandard;
    }
}

</style>
