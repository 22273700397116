<template>

<div :class="columnLimiter">
    <div class="table" :key="id">

        <div v-if="!responsive">
            <div v-show="!config.excludeHeader" class="tr headerRow ">
                <div class="td fadeIn animated" v-for="(headerCell, headerIndex) in config.headerRow" :key="'header' + headerIndex + headerCell.text" :style="getCellStyle(headerCell, true)" :class="[toggleHeaderClass, {'open' : !condensed}]" @click="toggleCondensed">
                    <span v-html="headerCell.text"> </span>
                    <span v-if="headerCell.tableCellStyle && headerCell.tableCellStyle.iconClass" :class="[headerCell.iconFormatOverride ? '' : 'iconFormat', headerCell.tableCellStyle.iconClass]" :style="[headerCell.iconStyle, {'cursor': headerCell.tableCellStyle.clicked ? 'pointer' : 'default'}]" @click="onClick(headerCell.tableCellStyle.clicked)" v-tooltip="{content: headerCell.tableCellStyle.tooltipText, placement: headerCell.tableCellStyle.tooltipPlacement, classes: headerCell.tableCellStyle.tooltipClass}"/>
                </div>
            </div>
            <div v-if="config.subHeaderRow" class="tr headerRow">
                <div class="td fadeIn animated" v-for="(headerCell, headerIndex) in config.subHeaderRow" :key="'subheaderRow' + headerIndex + headerCell.text" :style="getCellStyle(headerCell, true)" :class="toggleHeaderClass" @click="toggleCondensed">{{headerCell.text}}</div>
            </div>
            <div class="tr body" v-for="(cellsForRow, rowKey, rowIndex) in config.cellContentByRowId" :key="'row' + rowIndex" v-show="getShowRow(condensed, cellsForRow[0].isTotal)">
                <div class="td" v-for="(rowCell, tdIndex) in cellsForRow" :key="'row' + rowIndex +' cell' + tdIndex" :style="[getCellStyle(rowCell), {'cursor': rowCell.clicked ? 'pointer' : 'default'}]" @click="onClick(rowCell.clicked)">
                    <div v-if="rowCell.inputTemplate" class="inputWrapper" :key="rowCell.inputTemplate.id + '_input'">
                        <input-wrapper :inputTemplate="rowCell.inputTemplate" @currentvalueAndId="emitCurrentValueAndId"/>
                    </div>
                    <div v-else class="fadeIn animated" :key="rowCell.text" >
                        <span v-html="rowCell.text"> </span>
                        <span v-if="rowCell.tableCellStyle && rowCell.tableCellStyle.iconClass" :class="[rowCell.iconFormatOverride ? '' : 'iconFormat', rowCell.tableCellStyle.iconClass]" :style="[rowCell.iconStyle, {'cursor': rowCell.tableCellStyle.clicked ? 'pointer' : 'default'}]" @click="onClick(rowCell.tableCellStyle.clicked)" v-tooltip="{content: rowCell.tableCellStyle.tooltipText, placement: rowCell.tableCellStyle.tooltipPlacement, classes: rowCell.tableCellStyle.tooltipClass}"/>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="flat" v-for="(cellsForRow, rowKey, rowIndex) in config.cellContentByRowId" :key="`row${rowIndex}`">
                <div class="tr" v-for="(rowCell, tdIndex) in cellsForRow" :key="`row${rowIndex} cell${tdIndex}`"
                :class="[tdIndex > 0 ? 'body' : 'headerRow', flatHeaderOnly(cellsForRow) === -1  ? 'headerOnly' : 'dataOnly']"
                :style="flatHeaderOnly(cellsForRow) === -1  ? getCellStyle(rowCell, tdIndex === 0 ? true : false, true) : ''">
                    <div class="td fadeIn animated" :class="{'tdThird': config.subHeaderRow}" v-if="!config.excludeHeader && config.subHeaderRow">
                        {{tdIndex !== 0 && (tdIndex - 1) % 2 === 0 ? flatHeader(rowKey)  : ''}}
                    </div>
                    <div class="td fadeIn animated" v-if="!config.subHeaderRow">
                        {{config.headerRow[tdIndex].text}}
                    </div>
                    <div class="td fadeIn animated" :class="{'tdThird': config.subHeaderRow}" v-if="config.subHeaderRow">
                        {{config.subHeaderRow[tdIndex].text}}
                    </div>
                    <div class="td fadeIn animated" :class="{'tdThird': config.subHeaderRow}" :style="[getCellStyle(rowCell, tdIndex === 0 ? true : false, true), {'cursor': rowCell.clicked ? 'pointer' : 'default'}]" @click="onClick(rowCell.clicked)">
                        <div class="fadeIn animated" :key="rowCell.text">
                            {{rowCell.text}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

</template>

<script>

import { responsiveCheck } from '@/jsMixins/mixins';

export default {
    getCellDefinition (text, foreColourOverride, backgroundColourOverride, bold, inputTemplate) {
        const tableCellStyle = {
            foreColourString: foreColourOverride,
            backColourString: backgroundColourOverride,
            bold: bold
        };

        return {
            text: text,
            tableCellStyle: tableCellStyle,
            inputTemplate: inputTemplate
        };
    },
    getHeaderCellNoColour (value) {
        return this.getCellDefinition(value, 'black', 'white');
    },
    getRowCellWithColour (value, colour) {
        return this.getCellDefinition(value, 'white', colour);
    },
    getRowFromValues (values, bold) {
        const output = [];
        const boldIsArray = Array.isArray(bold);

        for (let i = 0; i < values.length; i++) {
            output.push(this.getCellDefinition(values[i], null, null, boldIsArray ? bold[i] : bold));
        }

        return output;
    },
    getTableConfig (headerRow, cellContentByRowId, excludeHeader) {
        return {
            headerRow: headerRow,
            cellContentByRowId: cellContentByRowId,
            excludeHeader: excludeHeader
        };
    },
    props: {
        id: String,
        config: Object,
        formattingOverrides: Object,
        allowCondense: {
            type: Boolean,
            default: false
        },
        initialCondensed: {
            type: Boolean,
            default: false
        },
        responsiveSize: {
            type: String,
            default: 'sm'
        }
    },
    mixins: [responsiveCheck],
    data () {
        return {
            condensed: this.initialCondensed,
            headerCounts: {}
        };
    },
    created () {
        this.setupHeaderCounts();
    },
    methods: {
        onClick (event) {
            if (event) event();
        },
        toggleCondensed () {
            if (this.allowCondense && this.config.includesTotals) this.condensed = !this.condensed;
        },
        getCellStyle (cell, header = false, flat = false) {
            const cellStyle = cell.tableCellStyle;

            const defaultStyle = {
                backgroundColor: cellStyle && cellStyle.backColourString ? cellStyle.backColourString : (cell.isTotal ? window.LCP.colours.ALTERNATIVE2TINT2 : (header ? window.LCP.colours.ALTERNATIVE1 : null)),
                color: cellStyle && cellStyle.foreColourString ? cellStyle.foreColourString : (cell.isTotal ? 'white' : null),
                fontWeight: (cellStyle && cellStyle.bold) || (cell.isTotal && !header) ? 'bold' : '',
                width: flat ? '100%' : (this.columnWidth * (cellStyle && cellStyle.columnSpan ? cellStyle.columnSpan : 1)) + '%',
                textAlign: this.formattingOverrides ? this.formattingOverrides.textAlign : null
            };

            if (!cell.styleOverrides) {
                return defaultStyle;
            } else {
                if (flat && !header) return;
                const styleOverride = { ...cell.styleOverrides };
                if (flat) styleOverride.width = '100%';
                return window.LCP.fn.extend(defaultStyle, styleOverride);
            }
        },
        getShowRow (condensed, isTotal) {
            return !condensed || isTotal;
        },
        flatHeaderOnly (row) {
            const result = row.findIndex(i => i.tableCellStyle.backColourString !== null && i.tableCellStyle.backColourString !== undefined);
            const totalCheck = row.findIndex(i => i?.isTotal && i.isTotal);
            return totalCheck !== -1 ? 1 : result;
        },
        flatHeader (rowKey) {
            if (this.headerCounts[rowKey] < this.config.headerRow.length) {
                this.headerCounts[rowKey]++;
                return this.config.headerRow[this.headerCounts[rowKey]].text;
            }
        },
        setupHeaderCounts () {
            if (this.config.subHeaderRow) {
                this.headerCounts = {};
                for (const row in this.config.cellContentByRowId) {
                    this.headerCounts[row] = 0;
                }
            }
        },
        emitCurrentValueAndId (value, id) {
            this.$emit('currentvalueAndId', value, id);
        }
    },
    computed: {
        responsive () {
            return this.config.headerRow.length > 2 && this.matchesResponsive(this.responsiveSize);
        },
        dataLength () {
            const rowKeys = Object.keys(this.config.cellContentByRowId);
            return rowKeys.length;
        },
        toggleHeaderClass () {
            return this.allowCondense && this.config.includesTotals && this.dataLength > 1 ? 'toggleAvailable' : '';
        },
        columnWidth () {
            if (this.config.subHeaderRow) return 100 / this.config.subHeaderRow.length;
            return 100 / this.config.headerRow.length;
        },
        columnLimiter () {
            return this.config.headerRow.length > 3 ? 'scrolling' : '';
        }
    },
    watch: {
        id () {
            this.setupHeaderCounts();
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/base.scss';

%circle {
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
        margin: 0 auto;
}
.circle{
    @extend %circle;
}

.circleArrow {
    @extend %circle;
}

.circleArrow{
    &::after {
        content: '';
        display: inline-block;
        // margin-top: 13px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;
        cursor: pointer !important;
        transition: transform .3s ease-in-out;
    }
}
.open{
    .circleArrow{
        &::after{
                transform: rotate(180deg);

        }
    }
}

.iconFormat {
    font-size: 20px;
    float: right;
    margin-right: -20px;
    position: relative;
    right: 15%;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    border-radius: 50%;
    background-size: 100%;

    @include responsive ('md'){
        float: none;
        margin-right: 0;
        position: relative;
        right: unset;
        width: unset;
        display: block;
    }
}

.table {
    margin-bottom: 0px;
    word-break: break-word;
    .tr {
        display: table;
        width: 100%;
        margin-bottom: 4px;
        border-radius: 6px;
        box-shadow: 0 0px 5px rgba(0, 0, 0, 0.03), 0 5px 5px rgba(0, 0, 0, 0.03);
        .td {
            padding: 10px;
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            &:nth-child(1) {
                text-align: left !important;
            }
            &:first-child {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }
            &:last-child {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }
        &.headerRow {
            margin-bottom: 0;
            box-shadow: none;
            color: white;
            font-weight: 500;
            float: left;
        }
        &.body:nth-child(odd) {
            background-color: #f5f5f5;
        }
    }
}

.flat {
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.03), 0 5px 5px rgba(0, 0, 0, 0.03);
    margin-bottom: 10px;
    .tr {
        margin-bottom: 0px;
        box-shadow: none;
        border-radius: 0px;
        &.dataOnly {
            .td:last-child {
                border-radius: 0px;
            }
            &:first-child {
                .td:last-child {
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
            }
            &:last-child {
                .td:last-child {
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }
        }
        .td {
            width: 50%;
            .iconFormat {
                margin-right: 0px;
                position: initial;
            }
            &.tdThird {
              width: 33.3333333333%;
            }
        }
        &.headerRow {
            margin-bottom: 0;
            box-shadow: none;
            color: white;
            font-weight: 500;
            border-radius: 0px;
        }
        &.headerOnly {
            border-radius: 6px;
        }
        &.body:nth-child(odd) {
            background-color: #f5f5f5;
        }
    }
}

.toggleAvailable {
    cursor: pointer;
}

.scrolling {
    position: relative;
}

.inputWrapper {
    display: inline-block;
    vertical-align: top;
    :deep(.inputMargin) {
        margin-bottom: 0px;
    }
}

</style>
