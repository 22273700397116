<template>
    <lcpButton :handleClick="downloadReport" :text="btnString" iconToUse="icon-download-cloud"/>
</template>

<script>

import lcpButton from '@/components/common/uiInputs/lcpButton.vue';

export default {
    props: ['reportType', 'btnText'],
    name: 'downloadReport',
    components: {
        lcpButton
    },
    created () {
        this.btnString = this.btnText ? this.btnText : 'Download';
    },
    methods: {
        downloadReport () {
            this.$store.dispatch('getReport', { reportType: this.reportType, pageState: 'generatingReport' }).then((response) => {}).catch(() => {});
        }
    }
};

</script>
