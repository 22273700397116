<template>
    <div class="inputMargin">
        <div :id="'DatePickerWidthId' + id" class="DatePickerWidthIdStyle" :style="valueChanged">{{date.ToDateString()}}</div>
        {{config.label}}
        <date-control
            :value="date"
            :disabled-dates="disabledDates"
            :style="[{float: float}, {width: inputWidth}]"
            @selected="onValueChanged"
        />
    </div>
</template>

<script>

import dateControl from 'vuejs-datepicker';

export default {
    props: ['inputTemplate', 'valueOverride'],
    name: 'datepicker',
    components: {
        dateControl
    },
    data () {
        return {
            date: null,
            inputWidth: null
        };
    },
    created () {
        this.config = this.inputTemplate.config;
        this.id = this.inputTemplate.id;
        this.date = new Date(this.inputTemplate.value);
        this.disabledDates = {
            to: new Date(this.config.minPermissibleDate),
            from: new Date(this.config.maxPermissibleDate)
        };
        this.float = this.inputTemplate.float == null ? 'right' : this.inputTemplate.float;
    },
    computed: {
        dataState () {
            return this.$store.state.dataState;
        },
        dateString () {
            return this.date.ToTDateString();
        },
        valueChanged () {
            if (this.inputTemplate.resetValue == null) return '';

            return new Date(this.inputTemplate.resetValue) === this.date ? '' : 'font-weight: bold';
        }
    },
    mounted () {
        const test = document.getElementById('DatePickerWidthId' + this.id);
        this.inputWidth = (test.clientWidth + 30) + 'px';
    },
    methods: {
        onValueChanged (value) {
            if (value.ToTDateString() === this.dateString) return;
            this.selectValueAndSendUpdate(value);
            if (!this.config.suppressPageUpdate) this.$store.dispatch('updatePageData');
        },
        selectValueAndSendUpdate (value) {
            if (value.ToTDateString() === this.dateString) return;
            this.date = value;
            this.sendUpdate(this.dateString);
            this.$emit('currentvalue', this.dateString, this.id);
        },
        sendUpdate (dateString) {
            const data = {
                id: this.id,
                value: dateString
            };

            this.$store.commit('updateInput', data);
        }
    },
    watch: {
        valueOverride (newVal, oldVal) {
            if (newVal !== null) this.selectValueAndSendUpdate(new Date(newVal));
        },
        dataState (newVal, oldVal) {
            if (newVal === 'resetData' && this.inputTemplate.resetValue != null) {
                this.selectValueAndSendUpdate(new Date(this.inputTemplate.resetValue));
            }
        }
    },
    getDefaultInputTemplate (id, label, maxDate, minDate, value, resetValue, suppressPageUpdate, float) {
        return {
            config: {
                label: label,
                maxPermissibleDate: maxDate,
                minPermissibleDate: minDate,
                suppressPageUpdate: suppressPageUpdate != null ? suppressPageUpdate : true
            },
            id: id,
            value: value,
            resetValue: resetValue,
            float: float
        };
    }
};

</script>

<style lang="scss" scoped>

.DatePickerWidthIdStyle {
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
}

</style>

<style lang="scss">

@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/mixins.scss';

.vdp-datepicker {

    input {
        border: none;
        border-bottom: 1px dashed lightgrey;
        cursor: pointer;
        text-align: right;
        width: 100%;
        &:hover, &:focus {
            outline: none;
            color: $PRIMARY;
        }
        @include responsive('md') {
            text-align: center !important;
        }
    }

    .vdp-datepicker__calendar {
        font-size: 16px;
        .prev {
            &::after {
                border-right: 10px solid $PRIMARY;
            }
        }
        .next {
            &::after {
                border-left: 10px solid $PRIMARY;
            }
        }
        .cell {
            &.selected {
                background: $PRIMARY;
                color: white;
            }
            &:hover {
                border: 1px solid $PRIMARY!important;
            }
        }
        @include responsive('sm') {
            width: 100vw;
            left: 0;
            position: fixed;
        }
    }
}

</style>
