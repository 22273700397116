<template>

<div class="viewNoScheme">
    <div class="card-header lightBorder">
        <h1>LCP Visualise privacy policy</h1>
    </div>
    <privacy-wording class="LCPcontent"/>
    <copyright colorTheme="dark"/>
</div>

</template>

<script>

import privacyWording from './privacyWording.vue';
import copyright from '@/components/common/uiLayouts/copyright.vue';

export default {
    components: {
        privacyWording,
        copyright
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import 'src/assets/scss/colors.scss';

.lightBorder {
    border-color: $PRIMARY;
}

</style>
