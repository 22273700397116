<template>
    <div v-if="pageAvailable" class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <toggle-header :tabs="tabs" :viewOverride="currentView" :title="header" @currentView="onCurrentViewChange"/>
                <lcp-table v-if="includeHeadlineSummary && currentView === headlinesView" class="LCPcontent" :config="config.headlineSummaryPageData" :id="headlinesView"/>
                <lcp-table v-if="includeProgress && currentView === progressView" class="LCPcontent" :config="config.recoveryProgressPageData" :id="progressView"/>
            </div>
        </div>
    </div>
    <div v-else class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <div class="card-header">
                    <h1>{{header}}</h1>
                </div>
                <vue-markdown class="LCPcontent" :source="pageNotAvailableText"/>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data () {
        return {
            currentView: null
        };
    },
    created () {
        this.header = 'Results summary';
        this.pageAvailable = this.$staticStore.state.resultssummary.pageAvailable;
        this.pageNotAvailableText = this.$staticStore.state.resultssummary.pageNotAvailableText;

        if (this.pageNotAvailableText) return;

        this.config = this.$staticStore.state.resultssummary.config;
        this.includeHeadlineSummary = this.config.headlineSummaryPageData;
        this.includeProgress = this.config.recoveryProgressPageData;

        this.headlinesView = 'Headlines';
        this.progressView = 'Progress';

        this.tabs = [];
        if (this.includeHeadlineSummary) this.tabs.push(this.headlinesView);
        if (this.includeProgress) this.tabs.push(this.progressView);

        this.currentView = this.$store.state.resultssummary.selectedTab != null ? this.$store.state.resultssummary.selectedTab : null;
    },
    methods: {
        onCurrentViewChange (view) {
            this.currentView = view;
            this.$store.state.resultssummary.selectedTab = this.currentView;
        }
    }
};

</script>
