const chartData = {
    chart: {
        type: 'gauge',
        backgroundColor: null,
        width: null,
        height: '100%',
        margin: [0, 0, 10, 0]
    },
    credits: {
        enabled: false
    },
    pane: {
        startAngle: -90,
        endAngle: 90,
        center: [100, 90],
        background: null
    },
    plotOptions: {
        gauge: {
            dataLabels: {
                enabled: false
            },
            dial: {
                baseLength: '0%',
                baseWidth: 10,
                radius: '45%',
                rearLength: '0%',
                topWidth: 1
            }
        },
        series: {
            animation: {
                duration: 2000
            }
        }
    },
    tooltip: {
        enabled: false
    },
    yAxis: {
        labels: {
            enabled: false,
            style: {
                color: 'black',
                fontWeight: 'bold'
            }
        },
        minorTickLength: 0,
        min: 0,
        tickLength: 0
    }
};

export default chartData;
