<template>
    <div class="inputMargin">
        <div style="overflow-y: auto; overflow-x: hidden;">
            <span v-if="lockable" style="display: inline-block;" class="lock icon" :class="lockIcon" @click="toggleLock"/>
            <div style="display: inline-block; margin-right: 1rem; ">{{ sliderDescription }}</div>
            <text-input :class="alignInput"
                :id="id"
                :value="value"
                :getValueAdditionalChecks="getValueWithChecks"
                :displayType="config.displayType"
                :relative="config.relative"
                :relativeTo="config.relativeTo"
                :decimalPlacesOverride="config.decimalPlacesOverride"
                :locked="locked"
                :lockedState="lockedState"
                :lowerBound="lowerBound"
                :upperBound="upperBound"
                v-on:valueUpdated="onTextValueUpdated"
                :valueChanged="valueChanged"
            />
        </div>
        <basicSlider
            :speed="0"
            :minIndex="minIndex"
            :maxIndex="maxIndex"
            :minIndexAllowed="minIndexAllowed"
            :maxIndexAllowed="maxIndexAllowed"
            :value="sliderIndex"
            :resetValue="resetIndex"
            :disabled="lockedState"
            :onDragStart="startSlide"
            :changed="changed"
            :onDragEnd="endSlide"
            :colourOverride="colourOverride"
        />
    </div>
</template>

<script>

import textInput from '@/components/common/uiInputs/textInput.vue';
import basicSlider from '@/components/common/uiInputs/basicSlider.vue';

export default {
    props: ['inputTemplate', 'valueOverride', 'dynamicLabel'],
    components: {
        textInput,
        basicSlider
    },
    data () {
        return {
            value: null,
            sliderIndex: null,
            locked: false
        };
    },
    created () {
        this.config = this.inputTemplate.config;
        this.id = this.inputTemplate.id;
        this.lockable = this.inputTemplate.lockable;
        this.align = this.inputTemplate.align;
        this.minIndex = this.minIndexAllowed = 0;
        this.maxIndex = this.maxIndexAllowed = this.config.options.length - 1;
        this.resetIndex = this.inputTemplate.resetValue != null ? this.getIndexForSlider(this.inputTemplate.resetValue) : null;
        this.locked = this.inputTemplate.locked;
        this.lowerBound = this.config.restrictedByOptions ? this.config.options[0] : this.config.lowerBound;
        this.upperBound = this.config.restrictedByOptions ? this.config.options[this.config.options.length - 1] : this.config.upperBound;
        this.setAllowedRange();
        this.updateValue(this.inputTemplate.value);
    },
    methods: {
        startSlide (event) {
            this.dragging = true;
            this.$emit('currentId', this.id);
        },
        endSlide (event) {
            this.dragging = false;
            this.updatePage();
        },
        changed (sliderIndex, arg) {
            this.updateValueAndSendUpdate(this.config.options[sliderIndex], true);
            if (!this.dragging) this.updatePage();
        },
        updatePage () {
            this.updatePageData();
            this.$emit('currentId', '');
        },
        getIndexForSlider (val) {
            return window.LCP.fn.arrayFindIndexNearest(this.config.options, val, this.config.displayType);
        },
        updateValueAndSendUpdate (newValue, updateDynamicOutput) {
            this.updateValue(newValue);
            this.sendUpdate(updateDynamicOutput);
            this.$emit('currentvalue', this.value, this.id);
        },
        updateValue (newValue) {
            this.value = newValue;
            this.sliderIndex = this.getIndexForSlider(this.value);
        },
        sendUpdate (updateDynamicOutput) {
            const data = {
                id: this.id,
                value: this.value,
                index: updateDynamicOutput ? window.LCP.fn.arrayFindIndex(this.config.options, this.value) : null,
                locked: this.locked
            };

            this.$store.commit('updateInput', data);
        },
        updatePageData () {
            if (!this.config.suppressPageUpdate) this.$store.dispatch('updatePageData');
        },
        toggleLock () {
            this.locked = !this.locked;
            this.sendUpdate(false);
            this.updatePageData();
        },
        onTextValueUpdated (value) {
            this.updateValueAndSendUpdate(value, true);
            this.updatePageData();
        },
        getValueWithChecks (value) {
            if (!this.config.restrictedByOptions) return value;

            const index = window.LCP.fn.arrayFindIndex(this.config.options, value);
            if (index === null || this.minIndexAllowed > index || index > this.maxIndexAllowed) return this.value;
            return value;
        },
        setAllowedRange () {
            if (this.inputTemplate.outputs == null) return;

            const min = this.inputTemplate.outputs[0].index;
            const max = this.inputTemplate.outputs[this.inputTemplate.outputs.length - 1].index;

            if (min !== undefined) this.minIndexAllowed = parseInt(min);
            if (max !== undefined) this.maxIndexAllowed = parseInt(max);
        }
    },
    computed: {
        dataState () {
            return this.$store.state.dataState;
        },
        lockedState () {
            return this.locked || this.dataState !== 'loadedData';
        },
        lockIcon () {
            return this.locked ? 'icon-Lock' : 'icon-Unlock';
        },
        colourOverride () {
            return this.inputTemplate.colourOverride;
        },
        alignInput () {
            return this.align === 'right' ? 'alignRight' : 'alignLeft';
        },
        valueChanged () {
            if (this.inputTemplate.resetValue == null) return false;

            return !this.inputTemplate.resetValue.isSameAs(this.value);
        },
        sliderDescription () {
            return this.dynamicLabel ? this.dynamicLabel : this.config.label;
        }
    },
    watch: {
        valueOverride (newVal, oldVal) {
            if (newVal == null || newVal.isSameAs(this.value)) return;
            if (this.locked) this.locked = false;
            this.updateValueAndSendUpdate(newVal, false);
        },
        dataState (newVal, oldVal) {
            if (newVal === 'resetData') {
                if (this.inputTemplate.resetValue != null) {
                    this.locked = false;
                    this.updateValueAndSendUpdate(this.inputTemplate.resetValue, false);
                }
            } else if (newVal === 'loadedData') {
                this.setAllowedRange();
            }
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import 'src/assets/scss/colors.scss';

.lock{
    cursor: pointer;
    color: white;
    padding: 4px;
    font-size: 12px;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    margin-right: 0.5rem;
    &.icon-Unlock{
        background: $LCPGreen;
    }
    &.icon-Lock{
        background: $LCPCherry;
    }
}

.alignRight{
    float: right;
}

</style>
