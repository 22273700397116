<template>
    <div class="dropdownIconWrapper">
        <div class="iconWrapper" :class="[additionalIconWrapperClass, {'active': showDropdown}]" @click="mainClick" v-tooltip="toolTip" v-click-outside="onClickOutside">
            <span class="icon" :class="[iconString]"></span>
        </div>
        <div v-if="showDropdown" class="dropdown" :class="additionalDropdownClass">
            <ul>
                <li v-for="(item,i) in subItems" :key="i" @click="onSubItemClick(item)">
                    {{item.text}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import ClickOutside from 'vue-click-outside';

export default {
    directives: {
        ClickOutside
    },
    props: {
        toolTip: String,
        icon: String,
        actionIfNoSubItems: Function,
        subItems: Array,
        classType: {
            type: String,
            default: 'headerSection'
        },
        // showDropdown: Boolean,
        suppressClickOutside: Boolean
    },
    data () {
        return {
            showDropdown: false
        };
    },
    name: 'dropdownIcon',
    methods: {
        mainClick () {
            if (this.subItems == null) this.actionIfNoSubItems();
            else this.toggleOpen();
        },
        onClickOutside () {
            if (this.suppressClickOutside) return;
            this.hideDropdown();
        },
        hideDropdown () {
            this.setShowDropdown(false);
        },
        onSubItemClick (item) {
            item.action();
            this.hideDropdown();
        },
        toggleOpen () {
            this.setShowDropdown(!this.showDropdown);
        },
        setShowDropdown (value) {
            this.showDropdown = value;
            // this.$emit('updateShowDropdown', value);
        }
    },
    computed: {
        iconString () {
            return `icon-${this.icon}`;
        },
        additionalIconWrapperClass () {
            switch (this.classType) {
            case 'headerSection':
                return 'iconWrapperHeaderSection';
            case 'dropdownTitle':
                return 'iconWrapperDropdownTitle';
            default:
                return '';
            }
        },
        additionalDropdownClass () {
            switch (this.classType) {
            case 'headerSection':
                return 'dropdownHeaderSection';
            case 'dropdownTitle':
                return 'dropdownDropdownTitle';
            default:
                return '';
            }
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import 'src/assets/scss/base.scss';

.dropdownIconWrapper{
    position: relative;
    vertical-align: bottom;

    .iconWrapper{
        cursor: pointer;
        text-align: center;
        border-radius: 20px;
        transition: 0.5s ease;
        &.active, &:hover {
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
        }
    }

    .iconWrapperHeaderSection{
        width: 40px;
        height: 40px;
        margin: 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.active, &:hover {
            color: white;
            background: $LCPMarine;
        }
    }

    .iconWrapperDropdownTitle {
        width: 27px;
        height: 27px;
        margin: 0px 2px;
        color: white;
        background: $LCPMarine;
        @include responsive ('sm') {
            width: 22px;
            height: 22px;
        }
        .icon:before {
            line-height: 30px;
            @include responsive ('sm') {
                line-height: 25px;
            }
        }
    }

    .dropdownHeaderSection{
        right: 10px;
    }

    .dropdownDropdownTitle{
        right: -4px;
    }

    .dropdown{
        position: absolute;
        font-size: 20px;
        margin-top: 9px;
        color: white;
        background: $LCPMarine;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
        z-index: 100;
        ul{
            margin: 0;
            padding: 0;
            li{
                padding: 5px 10px;
                list-style: none;
                white-space: nowrap;
                text-align: right;
                cursor: pointer;
                &:hover{
                    background-color: $SECONDARYTINT1;
                }
            }
        }
        &:after{
            content: '';
            position: absolute;
            right: 10px;
            top: -10px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $LCPMarine;
            clear: both;
        }
    }
}

</style>
