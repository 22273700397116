<template>
    <standardMetrics :id="id" :tileData="tileDataToShow" :headers="['Trigger point', 'Status']" :rowCountOverride="maxRows" :coverText="coverText" class="wideValues"/>
</template>

<script>

import standardMetrics from './standardMetrics.vue';

export default {
    name: 'triggers',
    props: ['id', 'tileData', 'loopMetric'],
    components: {
        standardMetrics
    },
    created () {
        let maxRows = 0;
        for (const key in this.tileData.metricsByView) maxRows = Math.max(maxRows, this.tileData.metricsByView[key].metrics.length);

        this.maxRows = maxRows;
    },
    computed: {
        currentData () {
            return this.tileData.metricsByView[this.loopMetric];
        },
        tileDataToShow () {
            return window.LCP.fn.extend(this.currentData, { Colour: this.tileData.colour });
        },
        coverText () {
            return this.tileData.metricsByView[this.loopMetric].metrics.length === 0 ? this.tileData.noCurrentlyActiveTriggerPointsTextWhenMultipleTriggers : null;
        }
    }
};

</script>

<style lang="scss" scoped>
</style>
