<template>
    <div class="modalBg">
        <div class="editModal">
            <div class="modalHeader">
                {{headerText}}
                <div v-if="receivedData" class="closeModal" @click="$emit('close')">
                    <span class="icon icon-x-circle"/>
                </div>
            </div>
            <mini-loader v-if="!receivedData" class="loading"/>
            <div v-else>
                <radio :id="schemeName" :inputTemplate="getRadioInputTemplate()" @currentvalue="onUserSelect"/>
                <lcp-table v-if="tableData" class="LCPcontent" :config="tableData"/>
                <vue-markdown v-else class="LCPcontent" source="No client login history to show."/>
            </div>
        </div>
    </div>
</template>

<script>

import table from '@/components/common/tables/table.vue';
import radio from '@/components/common/uiInputs/radio.vue';
import miniLoader from '@/components/common/uiLayouts/miniLoader.vue';

const LcpLabel = 'LCP';
const ClientLabel = 'Client';

function getCellDefinition (value, width) {
    const cell = table.getCellDefinition(value, null, null, null, null);
    cell.styleOverrides = {
        width: width
    };
    return cell;
}

const widthByColumn = ['auto', '205px', '205px', '205px'];

export default {
    props: ['schemeInfo'],
    created () {
        this.schemeId = this.schemeInfo.id;
        this.schemeName = this.schemeInfo.name;

        this.$store.dispatch('getUserStats', this.schemeInfo.id).then(response => {
            this.loginList = response.data.userLatestStats;
            this.receivedData = true;
        });
    },
    data () {
        return {
            lcpLoginTable: false,
            receivedData: false
        };
    },
    components: {
        miniLoader
    },
    computed: {
        headerText () {
            let header = 'Login history';
            if (this.schemeName) header += ' for ' + this.schemeName;
            return header;
        },
        tableData () {
            return this.getTableData(this.lcpLoginTable);
        }
    },
    methods: {
        onUserSelect (value) {
            this.lcpLoginTable = value === LcpLabel;
        },
        getTableData (lcpLoginTable) {
            const array = this.loginList;

            const cellContentByRow = {};

            for (let i = 0; i < array.length; i++) {
                const login = array[i];

                if (login.isLcp === lcpLoginTable) {
                    const row = [];
                    row.push(getCellDefinition(login.emailAddress, widthByColumn[row.length]));
                    row.push(getCellDefinition(login.lastLoginDate ? login.lastLoginDate.ToDateTimeString() : 'n/a', widthByColumn[row.length]));
                    row.push(getCellDefinition(login.schemeLastViewed ? login.schemeLastViewed.ToDateTimeString() : 'n/a', widthByColumn[row.length]));

                    cellContentByRow[i] = row;
                }
            }

            if (Object.keys(cellContentByRow).length === 0) return null;

            return table.getTableConfig(this.getHeaderRow(), cellContentByRow);
        },
        getRadioInputTemplate () {
            const options = [ClientLabel, LcpLabel];
            return radio.getDefaultInputTemplate('UserView', 'User login history', ClientLabel, null, true, options);
        },
        getHeaderRow () {
            const headerRow = [];
            headerRow.push(getCellDefinition('Email address', widthByColumn[headerRow.length]));
            headerRow.push(getCellDefinition('Last logged into Visualise', widthByColumn[headerRow.length]));
            headerRow.push(getCellDefinition('Last viewed this scheme', widthByColumn[headerRow.length]));
            return headerRow;
        }
    }
};

</script>

<style lang="scss" scoped>

.modalBg{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,.4);
    z-index: 10;
}

.editModal{
    position: absolute;
    width: 900px;
    max-height: 75%;
    overflow: auto;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    color: black;
    box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06) !important;
    border-radius: 6px;
    padding: 10px;
    z-index: 3;
    .modalHeader{
        position: relative;
        margin-bottom:10px;
        font-size: 16px;
        padding: 0 8px;
        .closeModal{
            float: right;
            font-size: 20px;
            cursor: pointer;
        }
    }
    .loading {
        position: relative;
        height: 120px;
        margin: 3rem;
        background: transparent !important;
    }
}

</style>
