<template>
    <div class="row">
        <div :class="[config.includeMarketData ? 'col-md-8 partialPageCardLeft' : 'col-md-12', 'framed']">
            <div class="card">
                <toggle-header :tabs="tabs" :viewOverride="currentView" :dropdownTitle="dropDownTitle" @currentView="onCurrentViewChange"/>
                <div v-if="currentView === historyView">
                    <div v-if="config.buyoutEstimateSelectable" class="LCPcontent">
                        <vue-markdown>The indicative buy-out estimate reflects possible pricing from a competitive, well-prepared insurance process with engaged insurers.</vue-markdown>
                    </div>
                    <lcp-table class="LCPcontent" :config="tableDataNew"/>
                    <highchart class="LCPcontent" :options="chartOptions" :series-options="chartSeries"/>
                </div>
                <div v-if="currentView === aomView">
                    <aom-tab :config="config" :inputs="inputs" :dynamicData="dynamicOutput.aomData"/>
                </div>
                <slider-with-periods :dateInputs="dateInputs"/>
            </div>
        </div>
        <div v-if="config.includeMarketData" class="col-md-4 partialPageCardRight">
            <div class="card">
                <div class="card-header">
                    <h1>Market returns over period</h1>
                </div>
                <lcp-table class="col-md-12 input-container" :config="assetMarketTableConfig" responsiveSize='xs'/>
                <div class="card-header">
                    <h1>Rates over period</h1>
                </div>
                <lcp-table class="col-md-12 input-container" :config="ratesTableConfig" responsiveSize='xs'/>
            </div>
        </div>
    </div>
</template>

<script>

import aomTab from './fundingAom.vue';
import table from '@/components/common/tables/table.vue';
import lineChart from '@/components/common/charts/lineChart.js';
import marketPerformanceAssetTable from '@/components/pages/schemeDisplays/marketPerformance/marketPerformanceAssetTable.js';

export default {
    components: {
        aomTab
    },
    data () {
        return {
            currentView: null
        };
    },
    created () {
        this.config = this.$staticStore.state.historyandexperience.config;
        this.inputs = this.$staticStore.state.historyandexperience.inputControls;
        this.dateInputs = this.inputs[this.config.dateSliderId];

        this.historyView = 'History';
        this.aomView = 'Experience';
        this.tabs = [this.historyView];
        if (this.config.includeAom) this.tabs.push(this.aomView);
    },
    methods: {
        getChangeString (startValue, endValue, scalingType) {
            if (scalingType === window.LCP.con.PERCENTAGE || scalingType === window.LCP.con.PERCENTAGE_PA) return (endValue - startValue).toPercentString();

            const amountChangeString = (endValue - startValue).toAmountString();

            if (this.inputs[this.config.availableResultsMetricsId].value === this.config.surplusDeficitName) return amountChangeString;

            const percentageChangeString = ((endValue - startValue) / startValue).toPercentString();
            return amountChangeString + ' / ' + percentageChangeString;
        },
        getTableCell (displayName, value, colour) {
            const cell = table.getRowCellWithColour(value, colour);

            const onMeasureSelected = this.onMeasureSelected;
            cell.clicked = function () {
                onMeasureSelected(displayName);
            };
            return cell;
        },
        onMeasureSelected (displayName) {
            this.$store.commit('updateTableLockArray', displayName);
        },
        updateSliderEndPosition (rangeData) {
            this.currentSliderPositionEndDate = rangeData[1];
        },
        onCurrentViewChange (view) {
            this.currentView = view;
        }
    },
    computed: {
        dynamicOutput () {
            return this.$store.state.historyandexperience.dynamicOutput;
        },
        dropDownTitle () {
            const prefix = this.currentView === this.historyView ? 'History - ' : 'Experience - ';
            const inputTemplate = this.currentView === this.historyView ? this.inputs[this.config.availableResultsMetricsId] : this.inputs[this.config.fundingDropdownId];

            return {
                prefix: prefix,
                inputTemplate: inputTemplate
            };
        },
        excludedMeasures () {
            return this.$store.state.historyandexperience.selectedTableLocks;
        },
        chartOptions () {
            const chart = lineChart.chartOptions(this.dynamicOutput.scalingType);
            chart.legend.enabled = this.config.grossBuyinMode;
            return chart;
        },
        chartSeries () {
            const chartData = this.dynamicOutput.chartSeriesData;

            const output = [];

            for (let i = 0; i < chartData.length; i++) {
                const chartSeries = lineChart.getSeriesFromChartSeriesDataHistory(chartData[i]);
                chartSeries.visible = !this.excludedMeasures[chartData[i].name];
                output.push(chartSeries);
            };

            return output;
        },
        tableDataNew () {
            const headerRow = [
                table.getHeaderCellNoColour(''),
                table.getHeaderCellNoColour('Value on ' + this.chartSeries[0].pointStart.ToDateString()),
                table.getHeaderCellNoColour('Value on ' + this.chartSeries[0].pointEnd.ToDateString()),
                table.getHeaderCellNoColour('Change')
            ];

            const cellContentByRowId = {};

            for (let i = 0; i < this.chartSeries.length; i++) {
                const chartSeries = this.chartSeries[i];

                const startValue = chartSeries.data[0];
                const endValue = chartSeries.data[chartSeries.data.length - 1];

                const displayName = chartSeries.name;
                const colourToUse = this.excludedMeasures[displayName] ? window.LCP.colours.DISABLED : chartSeries.color;

                const changeCell = this.getTableCell(displayName, this.getChangeString(startValue, endValue, this.dynamicOutput.scalingType), colourToUse);
                changeCell.tableCellStyle.iconClass = startValue > endValue ? 'fa fa-arrow-circle-o-down' : 'fa fa-arrow-circle-o-up';

                const row = [
                    this.getTableCell(displayName, displayName, colourToUse),
                    this.getTableCell(displayName, startValue.toScaledValueString(this.dynamicOutput.scalingType), colourToUse),
                    this.getTableCell(displayName, endValue.toScaledValueString(this.dynamicOutput.scalingType), colourToUse),
                    changeCell
                ];

                cellContentByRowId[chartSeries.name] = row;
            }

            return table.getTableConfig(headerRow, cellContentByRowId);
        },
        ratesTableData () {
            return this.dynamicOutput.ratesTableData;
        },
        mappedResults () {
            return marketPerformanceAssetTable.getMappedResults(this.config.assets, this.dynamicOutput.marketTableData);
        },
        assetMarketTableConfig () {
            return marketPerformanceAssetTable.getTableData(null, this.mappedResults, false, this.$mq, null, 'black');
        },
        ratesTableConfig () {
            const headerRow = [
                table.getCellDefinition(''),
                table.getCellDefinition(this.chartSeries[0].pointStart.ToDateString()),
                table.getCellDefinition(this.chartSeries[0].pointEnd.ToDateString()),
                table.getCellDefinition('Change')
            ];

            const cellContentByRow = {};

            for (let i = 0; i < this.ratesTableData.length; i++) {
                const data = this.ratesTableData[i];

                const startValue = data.startValue;
                const endValue = data.endValue;
                const change = endValue - startValue;

                const row = [
                    table.getCellDefinition(data.description),
                    table.getCellDefinition(startValue.toScaledValueString(data.scalingType, 2)),
                    table.getCellDefinition(endValue.toScaledValueString(data.scalingType, 2)),
                    table.getCellDefinition(change.toScaledValueString(data.scalingType, 2))
                ];

                row[0].tableCellStyle.iconClass = 'icon icon-info';
                row[0].iconStyle = {
                    position: 'inherit'
                };
                row[0].tableCellStyle.tooltipText = data.source;

                cellContentByRow[data.description] = row;
            }

            return table.getTableConfig(headerRow, cellContentByRow);
        }
    }
};

</script>

<style lang="scss">

</style>
