<template>
    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 LCPcontent">
            <input-wrapper :inputTemplate="dateInputs" :value-override="overrideValue" @updatePageProcess="updateSliderEndDate"/>
        </div>
        <div class="col-xs-10 col-xs-offset-1">
            <div class="row">
                <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(sinceValuationDatePeriod)" :text="sinceValuationDateKey"/></div>
                <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(previousYearPeriod)" :text="previousYearKey"/></div>
                <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(previousQuarterPeriod)" :text="previousQuarterKey"/></div>
                <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(previousMonthPeriod)" :text="previousMonthKey"/></div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment';

function getDateRange (startDate, endDate) {
    return {
        startDate: startDate,
        endDate: endDate
    };
}

function getDate (moment) {
    return moment.format('YYYY-MM-DDTHH:mm:ss');
}

export default {
    props: ['dateInputs'],
    name: 'sliderWithPeriods',
    data () {
        return {
            currentSliderPositionEndDate: null,
            overrideValue: null
        };
    },
    created () {
        const dateOptions = this.dateInputs.config.options;

        this.valnDateAsMoment = moment(dateOptions[0]);
        this.valnDate = getDate(this.valnDateAsMoment);
        this.currentSliderPositionEndDate = getDate(moment(this.dateInputs.value[1]));

        this.previousMonthKey = 'Month';
        this.previousQuarterKey = 'Quarter';
        this.previousYearKey = 'Year';
        this.sinceValuationDateKey = 'Since ' + this.valnDate.ToDateString();
    },
    methods: {
        onPeriodSelect (dateRange) {
            const periodOverride = this.periodOverride;
            return function () {
                periodOverride(dateRange);
            };
        },
        getStartOrValnDate (possibleStartDate) {
            const startDateOrValnDate = moment.max(possibleStartDate, this.valnDateAsMoment);
            return getDate(startDateOrValnDate);
        },
        periodOverride (dateRange) {
            this.overrideValue = [dateRange.startDate, dateRange.endDate];

            /* Use promises? */
            setTimeout(() => {
                this.$store.dispatch('updatePageData');
            }, 300);
        },
        updateSliderEndDate (rangeData) {
            this.currentSliderPositionEndDate = rangeData[1];
        }
    },
    computed: {
        previousMonthPeriod () {
            const startDate = this.getStartOrValnDate(moment(this.currentSliderPositionEndDate).add(-1, 'M'));
            const endDate = this.currentSliderPositionEndDate;
            return getDateRange(startDate, endDate);
        },
        previousQuarterPeriod () {
            const startDate = this.getStartOrValnDate(moment(this.currentSliderPositionEndDate).add(-3, 'M'));
            const endDate = this.currentSliderPositionEndDate;
            return getDateRange(startDate, endDate);
        },
        previousYearPeriod () {
            const startDate = this.getStartOrValnDate(moment(this.currentSliderPositionEndDate).add(-1, 'Y'));
            const endDate = this.currentSliderPositionEndDate;
            return getDateRange(startDate, endDate);
        },
        sinceValuationDatePeriod () {
            const startDate = this.valnDate;
            const endDate = this.currentSliderPositionEndDate;
            return getDateRange(startDate, endDate);
        }
    }
};

</script>
