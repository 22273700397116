<template>

<highchart class="LCPcontent" :options="chartOptions" :series-options="chartSeries" key="scenarios"/>

</template>

<script>

import scatterChart from '@/components/common/charts/scatterChart.js';

const additionalBubbleOptions = {
    chart: {
        type: 'bubble'
    },
    xAxis: [{
        min: 0
    }],
    legend: {
        enabled: true
    },
    tooltip: {
        formatter () {
            let s = '<b>' + this.series.name + '</b><table>';
            s += '<tr style=\'color:' + this.color + '\'><td style=\'padding-right: 10px;\'>Headroom used: </td><td style=\'text-align: right\'><b>' + this.point.y.toAmountString(0) + '</b></td></tr>';
            s += '<tr style=\'color:' + this.color + '\'><td style=\'padding-right: 10px;\'>VaR reduction: </td><td style=\'text-align: right\'><b>' + this.point.x.toPercentString(1) + '</b></td></tr>';
            s += '<tr style=\'color:' + this.color + '\'><td style=\'padding-right: 10px;\'>Transaction size: </td><td style=\'text-align: right\'><b>' + this.point.z.toAmountString(0) + '</b></td></tr>';
            s += '</table>';
            return s;
        }
    }
};

export default {
    props: ['config', 'dynamicResult'],
    methods: {
        getPlotBands (config) {
            if (config.plotBandData === null) return null;

            const plotBands = [];

            plotBands.push({
                color: config.plotBandData.colour,
                from: config.plotBandData.lowerBand,
                to: Number.MAX_VALUE,
                label: {
                    text: '<b>Above available headroom</b>',
                    align: 'center'
                },
                zIndex: -1
            });

            return plotBands;
        }
    },
    computed: {
        plotBands () {
            return this.getPlotBands(this.config);
        },
        chartOptions () {
            const options = scatterChart.chartOptions(additionalBubbleOptions, window.LCP.con.PERCENTAGE, window.LCP.con.AMOUNT);

            options.xAxis[0].title.text = this.config.xAxisTitle;
            options.xAxis[0].softMax = this.config.maxRiskReduction;

            options.yAxis[0].plotBands = this.plotBands;
            options.yAxis[0].softMin = this.config.minHeadRoomUsed;
            options.yAxis[0].softMax = this.config.maxHeadRoomUsed;
            options.yAxis[0].title.text = this.config.yAxisTitle + ' (' + options.yAxis[0].title.text + ')';

            return options;
        },
        chartSeries () {
            const output = scatterChart.getSeriesFromChartSeriesDataPoints(this.config.scenarioSeriesData);
            const currentSeries = scatterChart.getPointSeriesFromChartSeriesDataPoint(this.dynamicResult.currentSeriesData);
            output.push(currentSeries);
            return output;
        }
    }
};

</script>
