<template>
    <table class="basicTileMetricTable cashflowTable">
        <tr v-for="(metric, mainIndex) in currentData.metrics" :key="mainIndex">
            <td>{{metric.description}}</td>
            <td>{{percentage(metric.value)}}</td>
            <td>{{getValueStringFromMetric(metric)}}</td>
        </tr>
        <tr>
            <td/>
            <td class="borderTop"/>
            <td class="borderTop">{{getValueStringFromMetric(currentData.total)}}</td>
        </tr>
    </table>
</template>

<script>

export default {
    name: 'cashflows',
    props: ['id', 'tileData', 'loopMetric'],
    computed: {
        currentData () {
            return this.tileData.metricsByView[this.loopMetric];
        }
    },
    methods: {
        getValueStringFromMetric (metric) {
            return metric.value.toScaledValueString(metric.scalingType, metric.dpsToDisplayOverride);
        },
        percentage (val) {
            if (val === 'NaN') return 'n/a';
            return (val / this.currentData.total.value).toPercentString(0);
        }
    }
};

</script>

<style lang="scss" scoped>

.cashflowTable {
    td:nth-child(2) {
        font-weight: bold;
        width: 70px
    }
    td:last-child {
        width: 150px
    }
}

</style>
