<template>
    <div class="buttonStyle" :class="{ active: active, disabled: disabled }" @click="handleClickEvent">
        {{text}}
        <span v-if="iconToUse" class="icon" :class="iconToUse" style="margin-left: 5px"/>
    </div>
</template>

<script>

export default {
    name: 'lcpButton',
    props: {
        id: {
            type: String
        },
        text: {
            type: String,
            required: true
        },
        handleClick: {
            type: Function,
            required: true
        },
        active: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        iconToUse: {
            type: String
        }
    },
    methods: {
        handleClickEvent () {
            if (this.disabled) return;
            this.handleClick(this.id);
        }
    }
};

</script>

<style lang="scss" scoped>
    @import 'src/assets/scss/colors.scss';

    .buttonStyle {
        padding: 6px;
        color:white;
        border: 2px solid $PRIMARY;
        border-radius: 3px;
        background: $PRIMARY;
        cursor: pointer;
        margin: 2px 0px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        &:hover, &.active{
            background: $SECONDARY;
        }
        &.disabled{
            cursor: default
        }
        &:not(.active) {
            &.disabled {
                background: $SECONDARYTINT4;
            }
        }
    }

</style>
