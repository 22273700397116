<template>
    <standardMetrics :id="id" :tileData="tileData" class="tileMetricTableStartEndFormatting"/>
</template>

<script>

import standardMetrics from './standardMetrics.vue';

export default {
    name: 'accounting',
    props: ['id', 'tileData'],
    components: {
        standardMetrics
    }
};

</script>

<style lang="scss" scoped>
</style>
