/* eslint-disable */
import baseChart from './chartSettings.js';

const barChartOverrides = {
    chart: {
        defaultSeriesType: 'bar'
    },
    legend: {
        enabled: true
    },
    plotOptions: {
        series: {
            borderColor: 'transparent',
            borderWidth: 0,
            groupPadding: 0.2,
            pointPadding: 0
        }
    },
    yAxis: [{
        reversedStacks: false,
        labels: {
            formatter: null
        }
    }]
};

export default {
    chartOptions (additionalOptions, scalingType) {
        var output = baseChart.getChartOptions(barChartOverrides);
        output.yAxis[0].labels.formatter = baseChart.getAxisLabelFormatter(scalingType);
        output.yAxis[0].title.text = window.LCP.fn.getFormattingOptionsForType(scalingType).axisTitle;
        if (additionalOptions != null) output = window.LCP.fn.extend(output, additionalOptions);
        return output;
    },
    getSeriesFromChartSeriesProjectedXyDouble (chartSeriesDataProjectedXyDouble, stackgroup) {
        var data = [];

        for (var i = 0; i < chartSeriesDataProjectedXyDouble.data.yValues.length; i++) {
            var xValue = chartSeriesDataProjectedXyDouble.data.xValues[i];
            var yValue = chartSeriesDataProjectedXyDouble.data.yValues[i];
            data.push([xValue, yValue]);
        };

        return {
            name: chartSeriesDataProjectedXyDouble.name,
            color: chartSeriesDataProjectedXyDouble.colourString,
            data: data,
            stack: stackgroup,
            stacking: stackgroup === undefined ? undefined : 'normal'
        };
    },
    getSeriesCollectionFromChartSeriesProjectedXyDoubleCollection (chartSeriesDataProjectedXyDoubleCollection, stackGroup) {
        var output = [];

        for (var i = 0; i < chartSeriesDataProjectedXyDoubleCollection.length; i++) {
            var series = this.getSeriesFromChartSeriesProjectedXyDouble(chartSeriesDataProjectedXyDoubleCollection[i], stackGroup);
            output.push(series);
        }

        return output;
    }
};