/* eslint-disable */
import $ from "jquery";

var d3 = require('d3');
var svg,
    svgProjectedToYearEnd,
    svgProjectedFromYearEnd;

const barPadding = 20,
      paddingTop = 30,
      duration = 2500;

const m = [25, 20, -20, 20];
var w,
    h;

const red = "#bc001a",
      green = "rgb(0,66,28)";

var flagMappings,
    upColor,
    downColor,
    goodUp,
    mainAos;

var dataIndex = [],
    x = [],
    y = [],
    label = [];

var level,
    inNode,
    inAnim,
    transitionCancelled,
    nodeChartNodes;

var chartRef,
    updateExternalTableFunction = null,
    updateExternalTableForNode = null,
    nf = null;

function replaceStrings(string, value) {

    return string.replace(/loss\/gain/g, getUpDownString(value, "gain", "loss"))
        .replace(/gain\/loss/g, getUpDownString(value, "gain", "loss"))
        .replace(/credit\/debit/g, getUpDownString(value, "credit", "debit"))
        .replace(/debit\/credit/g, getUpDownString(value, "credit", "debit"))
        .replace(/credit\/cost/g, getUpDownString(value, "credit", "cost"))
        .replace(/cost\/credit/g, getUpDownString(value, "credit", "cost"))
        .replace(/gains\/losses/g, getUpDownString(value, "gains", "losses"))
        .replace(/losses\/gains/g, getUpDownString(value, "gains", "losses"));
}

function getUpDownString(value, upGood, downGood) {

    if (goodUp) return value >= 0 ? upGood : downGood;
    return value >= 0 ? downGood : upGood;
}

function create(accData, sel, fn, numberFormatFn, flags, onNodeUpdate) {

    chartRef = sel;
    updateExternalTableFunction = fn;
    nf = numberFormatFn;
    flagMappings = flags;
    updateExternalTableForNode = onNodeUpdate;
    inNode = false;
    inAnim = false;

    svg = d3.select(chartRef)
        .append("svg:svg")
        .attr("class", "d3Chart")
        .append("svg:g")
        .attr("transform", "translate(" + m[3] + "," + m[0] + ")");

    svg.append("svg:g")
        .append("svg:text")
        .attr("class", "headerContainer")
        .style({
            "font-size": "22px",
            fill: window.LCP.colours.PRIMARY
        });

    svgProjectedToYearEnd = svg.append("svg:g")
        .attr("class", "hidden");

    svgProjectedToYearEnd
        .append("svg:text")
        .attr("class", "")
        .style({
            "font-size": "18px",
            "fill": window.LCP.colours.PRIMARY
        })
        .attr("transform", "translate(0,25)")
        .text("Results projected from " + accData.projectedFrom + " to year-end");

    svgProjectedFromYearEnd = svg.append("svg:g")
        .attr("class", "hidden");

    svgProjectedFromYearEnd
        .append("svg:text")
        .attr("class", "")
        .style({
            "font-size": "18px",
            "fill": window.LCP.colours.PRIMARY
        })
        .attr("transform", "translate(0,25)")
        .text("Results projected from " + accData.projectedFrom);

    updateEngineData(accData);

    /*Adjust for dimensions*/
    setDimensions();

    plotChart(mainAos);

    // Redraw based on the new size whenever the browser window is resized.
    window.addEventListener("resize", resize);
}

function setDimensions() {
    var crt = $(chartRef);
    w = crt.width() - (m[1] + m[3]);
    h = 500 + 5 - (m[0] + m[2]);

    d3.select('.d3Chart')
        .attr("width", w)
        .attr("height", h);
}

function resize() {
    setDimensions();
    updateChartLevel(level);
    if (inNode) resizeNodeChart();
}

function updateMeasure(data) {

    onRemoveNode();
    updateEngineData(data);
    plotChart(mainAos);
}

function removeChart() {

    d3.select(".headerContainer").selectAll("tspan").remove();

    for (var n = 0; n < level + 1; n++) {
        d3.select(".level" + n).remove();
    }
}

function getInitialEndValueTransition(data) {
    return function() {
        var i = [];
        var start = data.start;
        var aosLen = data.nodes.length;
        for (var n = 0; n < aosLen; n++) {
            i[n] = d3.interpolate(start, start + data.nodes[n].value);
            start += data.nodes[n].value;
        }
        return function(t) {
            if (transitionCancelled) return;
            var n = Math.max(Math.floor(t * aosLen - 0.00001), 0);
            var intp = t * aosLen - n;
            this.textContent = nf(i[n](intp), true);
        };
    }
}

function plotChart(data) {

    transitionCancelled = false;

    removeChart();

    updateLevel(0, data, 0);
    updateExternalTableFunction(data);

    addToHeaderContainer("linkText touch headerText headerLevel" + level, label[level].labelName, label[level].labelColor);

    var svgLevel = svg.append("svg:g").attr("class", "level" + level);

    createStartNode(svgLevel, data, getStartNodeTransformation(level), 1, window.LCP.colours.PRIMARY, getHalfPaddedWidth(level), getAxisLineWidth(level));
    createEndNode(svgLevel, data, getEndNodeTransformation(level, data), 1, nf(data.start), window.LCP.colours.PRIMARY, getHalfPaddedWidth(level));

    var initialTransformation = function(d, i) {
        return "translate(" + (x[level](i + 1)) + "," + y[level](d.barStart) + ")";
    };
    var aosBarWidth = getPaddedWidth(level);
    var aosBarHeight = 0;
    var aosBardx = getHalfPaddedWidth(level);
    var aosBardy = 0;
    var aosBarColour = getAosBarColourFillFn;

    createAosBars(svgLevel, level, data, initialTransformation, aosBarWidth, aosBarHeight, aosBardx, aosBardy, aosBarColour, 1);

    var endNode = svgLevel.select(".endNode");

    endNode.select(".nodeValueBehind")
        .transition()
        .duration(duration)
        .tween("text", getInitialEndValueTransition(data));

    endNode.select(".nodeValue")
        .transition()
        .duration(duration)
        .tween("text", getInitialEndValueTransition(data));

    var barDur = duration / data.nodes.length;

    var aosBarsTransition = svgLevel.selectAll(".aosBars");

    aosBarsTransition
        .transition()
        .duration(barDur)
        .delay(function(d, i) {
            return i * barDur;
        })
        .attr("transform", getTransformationForAosBar(level));

    aosBarsTransition.select(".barlabelsText")
        .transition()
        .duration(barDur)
        .delay(function(d, i) {
            return i * barDur;
        })
        .attr("dy", getDyForAosBar(level))
        .tween("text", function(d) {
            var i = d3.interpolate(0, d.value);
            return function(t) {
                this.textContent = nf(i(t));
            };
        });

    aosBarsTransition.select(".bar")
        .transition()
        .duration(barDur)
        .delay(function(d, i) {
            return i * barDur;
        })
        .attr("height", getHeightForAosBar(level));

    aosBarsTransition.select(".clipBar")
        .transition()
        .duration(barDur)
        .delay(function(d, i) {
            return i * barDur;
        })
        .attr("height", getHeightForAosBar(level));

    aosBarsTransition.select(".barlabelsTextWhite")
        .transition()
        .duration(barDur)
        .delay(function(d, i) {
            return i * barDur;
        })
        .attr("dy", getDyForAosBar(level))
        .tween("text", function(d) {
            var i = d3.interpolate(0, d.value);
            return function(t) {
                this.textContent = nf(i(t));
            };
        });

    aosBarsTransition.select(".barDescText")
        .attr("opacity", 1e-6)
        .transition()
        .duration(barDur)
        .delay(function(d, i) {
            return i * barDur;
        })
        .each("end", function() {
            d3.select(this)
                .attr("opacity", 1);
        });
}

function openNode(dataShow, label, nodeId, columnHeader) {

    if (LCP.fn.objLen(dataShow) < 2) return;

    inNode = true;
    inAnim = true;

    setTimeout(function() {
        inAnim = false;
    }, duration);

    hideCurrentLevel(hideNode);

    addToHeaderContainer("headerText nodesplit", " " + label);
    createNodeChart(dataShow, nodeId, columnHeader);
}

function createNodeChart(dataIn, nodeId, columnHeader) {

    var nodes = [],
        vis, circles, bubble, radius_scale;

    var data = [];
    var deficitC = 0,
        totalC = 0,
        positive = 0,
        negative = 0;

    $.each(dataIn, function(i, item) {

        var obj = {
            label: item.label,
            alias: item.alias,
            value: item.value,
            surplus_deficit: item.value >= 0 ? "surplus" : "deficit",
            imgpath: flagMappings[item.image] !== undefined ? flagMappings[item.image] : "",
            colours: flagMappings[item.image] === undefined ? item.image : ""
        };

        data.push(obj);

        if (item.value < 0) deficitC++;
        totalC += item.value;

        positive = positive + (item.value > 0 ? item.value : 0);
        negative = negative - (item.value < 0 ? item.value : 0);
    });

    var sum_amount = d3.sum(data, function(d) {
        return Math.abs(parseInt(d.value, 10));
    });

    radius_scale = d3.scale.pow().exponent(0.5).domain([0, sum_amount]).range([2, 100]);

    data.forEach(function(d) {
        var node = {
            id: d.label,
            radius: radius_scale(parseInt(Math.abs(d.value), 10)),
            value: d.value,
            name: d.label,
            x: Math.random() * 900,
            y: Math.random() * 800,
            group: d.surplus_deficit,
            flags: d.imgpath,
            colour: d.colours
        }
        nodes.push(node);
    });

    nodes.sort(function(a, b) {
        return b.value - a.value;
    });

    var surplusDeficitHeaderText;

    if (deficitC > 0) {
        surplusDeficitHeaderText = !goodUp
            ? (level < 1 ? "Deficit" : "Loss") + " " + nf(positive, true, true)
            : (level < 1 ? "Surplus" : "Gain") + " " + nf(positive, true, true);
    } else if (level > 0) {
        surplusDeficitHeaderText = goodUp
            ? (totalC < 0 ? "Loss" : "Gain") + " " + nf(positive, true, true)
            : (totalC < 0 ? "Gain" : "Loss") + " " + nf(positive, true, true);
    } else if (level == 0) {
        surplusDeficitHeaderText = "Total " + nf(positive, true, true);
    }

    var surplus_deficit_header = d3.select("svg g").append("g")
        .attr("transform", "translate(0," + (h - 40) + ")")
        .attr("class", "surplus_deficit_header nodesplit");

    surplus_deficit_header.append("text")
        .attr("class", "header_l")
        .attr("text-anchor", "middle")
        .append("tspan")
        .style({ "font-size": "22px", fill: window.LCP.colours.PRIMARY })
        .text(surplusDeficitHeaderText);

    if (deficitC > 0) {

        surplus_deficit_header.append("text")
            .attr("class", "header_r")
            .attr("text-anchor", "middle")
            .append("tspan")
            .style({ "font-size": "22px", fill: window.LCP.colours.PRIMARY })
            .text(goodUp
                    ? (level < 1 ? "Deficit" : "Loss") + " " + nf(negative, true, true)
                    : (level < 1 ? "Surplus" : "Gain") + " " + nf(negative, true, true));
    }

    vis = svg.append("svg:g").attr("class", "nodeGroup");

    if (nodes[0].flags == "") {

        bubble = vis.selectAll("circle")
            .data(nodes, function(d) {
                return d.id;
            });

        bubble.enter().append("circle")
            .attr("r", 0)
            .attr("class", "touch")
            .attr("fill", function(d) {
                return d.colour;
            })
            .attr("stroke-width", 2)
            .attr("id", function(d) {
                return "bubble_" + d.id;
            });

        bubble.transition().duration(2000).attr("r", function(d) {
            return d.radius;
        });

    } else {

        bubble = vis.selectAll(".bubble")
            .data(nodes, function(d) {
                return d.id;
            })
            .enter().append("g")
            .attr("class", "bubble touch")
            .attr("transform", function(d) {
                return "translate(" + d.x + "," + d.y + ")";
            })
            .attr("id", function(d) {
                return "bubble_" + d.id;
            });

        var defs = bubble.append("defs")
            .append("clipPath")
            .attr("id", function(d, i) {
                return "path" + i;
            });

        circles = defs.append("circle")
            .attr("r", 0)
            .attr("stroke-width", 2)
            .attr("id", function(d) {
                return "bubble_" + d.id;
            });

        bubble.append("image")
            .attr("x", function(d) {
                return -d.radius;
            })
            .attr("y", function(d) {
                return -d.radius;
            })
            .attr("width", function(d) {
                return d.radius * 2;
            })
            .attr("height", function(d) {
                return d.radius * 2;
            })
            .style("clip-path", function(d, i) {
                return "url(#path" + i + ")";
            })
            .attr("xlink:href", function(d) {
                return d.flags;
            });

        circles.transition().duration(2000).attr("r", function(d) {
            return d.radius;
        });
    }

    nodeChartNodes = nodes;
    resizeNodeChart();
    updateExternalTableForNode(columnHeader);
}

function getSurplusDeficitCenters(twoColumns) {
    if (twoColumns) {
        return {
            "surplus": {
                x: w / 3,
                y: h * 0.45
            },
            "deficit": {
                x: 2 * w / 3,
                y: h * 0.45
            }
        };
    } else {
        return {
            "surplus": {
                x: w / 2,
                y: h * 0.45
            },
        };
    }
}

function getSurplusDeficitHeaderTranslation(twoColumns) {
    if (twoColumns) {
        return w / 3;
    } else {
        return w / 2;
    }
}

function resizeNodeChart() {
    var surplusDeficitHeader = d3.select(".surplus_deficit_header");

    var twoColumns = false;

    var rightHeader = surplusDeficitHeader.select(".header_r");
    if (rightHeader[0][0] != null) {
        twoColumns = true;
        rightHeader.attr("transform", "translate(" + (2 * w / 3) + "," + (-40) + ")");
    }

    surplusDeficitHeader.select(".header_l")
        .attr("transform", "translate(" + getSurplusDeficitHeaderTranslation(twoColumns) + "," + (-40) + ")");

    var bubbles = d3.select(".nodeGroup").selectAll("circle");
    var surplus_deficit_centers = getSurplusDeficitCenters(twoColumns);

    var force = d3.layout.force()
        .nodes(nodeChartNodes)
        .size([w, h]);

    force.gravity(-0.01)
        .charge(function(d) {
            return -Math.pow(d.radius, 2.0) / 4.5;
        })
        .friction(0.9)
        .on("tick", function(e) {
            bubbles.each(move_towards_split(e.alpha))
                .attr("transform", function(d) {
                    return "translate(" + d.x + "," + d.y + ")";
                });
        });

    force.start();

    function move_towards_split(alpha) {
        return function(d) {
            var target = surplus_deficit_centers[d.group];
            d.x = d.x + (target.x - d.x) * 0.12 * alpha * 1.1;
            d.y = d.y + (target.y - d.y) * 0.12 * alpha * 1.1;
        };
    }
}

function hideNode() {

    onRemoveNode();

    d3.select(".level" + level).attr("opacity", 1);
    onShowCurrentLevel();
    removeLinkFromHeader();
}

function onRemoveNode () {
    inNode = false;
    d3.selectAll(".nodeGroup").remove();
    d3.selectAll(".nodesplit").remove();
    updateExternalTableForNode(null);
}

function drillOut(i, data) {

    if (inAnim) return;

    inAnim = true;
    level--;

    if (inNode) hideNode();
    else removeLinkFromHeader();

    var xVal = getTitleX();

    d3.select(".headerLevel" + (level - 1))
        .transition()
        .duration(duration / 2)
        .attr("opacity", 1)
        .each("end", function() {
            d3.select(".headerText")
                .transition()
                .duration(duration / 2)
                .attr("dx", xVal);
        });

    d3.selectAll(".headerLevel" + (level + 1))
        .transition()
        .duration(duration / 2)
        .attr("y", -200)
        .each("end", function() {
            d3.select(this).remove();
        });

    //Show level
    d3.select(".level" + level)
        .attr("opacity", 1e-6)
        .transition()
        .duration(duration)
        .attr("opacity", 1)
        .each("end", function() {
            inAnim = false;
            onShowCurrentLevel();
        });

    /*Hide previous level*/
    var svgLevel = d3.select(".level" + (level + 1));

    svgLevel
        .attr("opacity", 1)
        .transition()
        .duration(duration)
        .attr("opacity", 1e-6)
        .each("end", function() {
            d3.select(this).remove();
        });

    var transformTransition = "translate(" + (x[level](i + 1) + getHalfPaddedWidth(level)) + "," + y[level](data.barStart + data.value / 2) + ")";
    addDrillOutStartEndNodeTransitions("startNode", svgLevel, transformTransition, getPaddedWidth(level));
    addDrillOutStartEndNodeTransitions("endNode", svgLevel, transformTransition);
    addDrillOutTransitionsForAosBar(svgLevel, data, i);

    var tableData = getData(level);
    updateExternalTableFunction(tableData);
}

function addDrillOutStartEndNodeTransitions(nodeRef, svgLevel, transformTransition, startLineTransitionWidth) {

    var node = svgLevel.selectAll("." + nodeRef);

    node
        .transition()
        .duration(duration)
        .attr("transform", transformTransition);

    node.select(".startLine")
        .transition()
        .duration(duration)
        .attr("width", startLineTransitionWidth);

    node.select(".mainCircle")
        .transition()
        .duration(duration)
        .attr({
            r: getHalfPaddedWidth(level)
        });

    node.select(".clipCircle")
        .transition()
        .duration(duration)
        .attr({
            r: getHalfPaddedWidth(level)
        });
}

function addDrillOutTransitionsForAosBar(svgLevel, data, i) {

    var aosBars = svgLevel.selectAll(".aosBars");

    aosBars
        .transition()
        .duration(duration)
        .attr("transform", getTransformationForAosBarForData(level, data, i));

    aosBars
        .select(".barlabelsText")
        .transition()
        .duration(duration)
        .attr("dy", getDyForAosBarFromData(level, data))
        .attr("dx", getHalfPaddedWidth(level))
        .style({
            fill: getColour(data.value)
        });

    aosBars
        .select(".bar")
        .transition()
        .duration(duration)
        .attr("width", getPaddedWidth(level))
        .attr("height", getHeightForAosBarFromData(level, data))
        .attr("fill", getColour(data.value));

    aosBars
        .select(".clipBar")
        .transition()
        .duration(duration)
        .attr("width", getPaddedWidth(level))
        .attr("height", getHeightForAosBarFromData(level, data));

    aosBars
        .select(".barlabelsTextWhite")
        .transition()
        .duration(duration)
        .attr("dy", getDyForAosBarFromData(level, data))
        .attr("dx", getHalfPaddedWidth(level));
}

function drillIn(i, data) {

    inAnim = true;

    setTimeout(function() {
        inAnim = false;
    }, duration);

    hideCurrentLevel(() => drillOut(i, data));
    updateLevel(level + 1, data.aos, i);

    var svgLevel = svg.append("svg:g").attr("class", "level" + level);

    //add link to upper
    var xVal = getTitleX();

    d3.select(".headerText")
        .transition()
        .duration(duration / 2)
        .attr("dx", xVal)
        .each("end", function() {
            d3.select(".headerLevel" + (level - 2))
                .transition()
                .duration(duration / 2)
                .attr("opacity", 1e-6);
        });

    addToHeaderContainer("headerText headerLevel" + level, " > ");
    addToHeaderContainer("linkText touch headerText headerLevel" + level, label[level].labelName, label[level].labelColor);

    var startEndInitialCircleRadius = getHalfPaddedWidth(level - 1);
    var startEndInitialTransformation = "translate(" + (x[level - 1](i + 1) + startEndInitialCircleRadius) + "," + y[level - 1](data.barStart + data.value / 2) + ")";
    var startEndColour = getColour(data.value);

    createStartNode(svgLevel, data.aos, startEndInitialTransformation, 1e-6, startEndColour, startEndInitialCircleRadius, getPaddedWidth(level - 1));

    var startNodeTransition = "translate(" + (x[level](0) + getHalfPaddedWidth(level)) + "," + y[level](0) + ")";
    var startLineWidthTransition = getAxisLineWidth(level);
    addDrillInStartEndNodeTransitions("startNode", svgLevel, startNodeTransition, startLineWidthTransition);

    createEndNode(svgLevel, data.aos, startEndInitialTransformation, 1e-6, startEndNodeValueFormatterForLevel(level), startEndColour, startEndInitialCircleRadius);

    var endNodeTransition = "translate(" + (x[level](data.aos.nodeCount - 1) + getHalfPaddedWidth(level)) + "," + y[level](data.value) + ")";
    addDrillInStartEndNodeTransitions("endNode", svgLevel, endNodeTransition);

    var initialTransformation = getTransformationForAosBarForData(level - 1, data, i);
    var aosBarWidth = getPaddedWidth(level - 1);
    var aosBarHeight = getHeightForAosBarFromData(level - 1, data);
    var aosBardx = getHalfPaddedWidth(level - 1);
    var aosBardy = getDyForAosBarFromData(level - 1, data);
    var aosBarColour = getColour(data.value);

    createAosBars(svgLevel, level, data.aos, initialTransformation, aosBarWidth, aosBarHeight, aosBardx, aosBardy, aosBarColour, 1e-6);
    addDrillInTransitionsForAosBar(svgLevel);

    updateExternalTableFunction(data.aos);
}

function addDrillInStartEndNodeTransitions(nodeRef, svgLevel, transformTransition, startLineTransitionWidth) {

    var node = svgLevel.selectAll("." + nodeRef);

    node
        .transition()
        .duration(duration)
        .attr("transform", transformTransition)
        .attr("opacity", 1);

    node.select(".startLine")
        .transition()
        .duration(duration)
        .attr("width", startLineTransitionWidth);

    node.select(".mainCircle")
        .transition()
        .duration(duration)
        .attr({
            r: getHalfPaddedWidth(level)
        });

    node.select(".clipCircle")
        .transition()
        .duration(duration)
        .attr({
            r: getHalfPaddedWidth(level)
        });
}

function addDrillInTransitionsForAosBar(svgLevel) {

    var aosBars = svgLevel.selectAll(".aosBars");

    aosBars
        .transition()
        .duration(duration)
        .attr("transform", getTransformationForAosBar(level))
        .attr("opacity", 1);

    aosBars
        .select(".barlabelsText")
        .text(function(d) {
            return nf(d.value);
        })
        .transition()
        .duration(duration)
        .attr("dy", getDyForAosBar(level))
        .attr("dx", getHalfPaddedWidth(level))
        .style({
            fill: getAosBarColourFillFn
        })
        .text(function(d) {
            return nf(d.value);
        });

    aosBars
        .select(".bar")
        .transition()
        .duration(duration)
        .attr("width", getPaddedWidth(level))
        .attr("height", getHeightForAosBar(level))
        .attr("fill", getAosBarColourFillFn);

    aosBars
        .select(".clipBar")
        .transition()
        .duration(duration)
        .attr("width", getPaddedWidth(level))
        .attr("height", getHeightForAosBar(level));

    aosBars
        .select(".barlabelsTextWhite")
        .text(function(d) {
            return nf(d.value);
        })
        .transition()
        .duration(duration)
        .attr("dy", getDyForAosBar(level))
        .attr("dx", getHalfPaddedWidth(level))
        .text(function(d) {
            return nf(d.value);
        });

    aosBars
        .select(".barDescText")
        .attr("dy", 0)
        .attr("dx", getHalfPaddedWidth(level))
        .text(function (d) {
          return replaceStrings(d.description, d.value);
        })
        .call(wrap, getPaddedWidth(level))
        .transition()
        .duration(duration)
        .style({
            fill: getAosBarColourFillFn
        });
}

function findMax(d) {
    var max = 0;
    var val = 0;

    d.forEach(function(s) {

        val += s.value;

        if (val > max) {
            max = val;
        }
    });

    return max;
}

function findMin(d) {
    var min = 0;
    var val = 0;

    d.forEach(function(s) {

        val += s.value;

        if (val < min) {
            min = val;
        }
    });

    return min;
}

function getMappedNodes(d, baseNodeKey) {
    var arr = [];
    var valAll = 0;
    var nodeKey = 0;

    d.forEach(function(s) {

        var overrides =  {
            barStart: valAll,
            barEnd: valAll + s.value
        };

        var node = window.LCP.fn.extend(s, overrides);;

        if (s.aos != null) {

            var aosOverrides = {
                aos: window.LCP.fn.extend(getMappedAos(s.aos, s.description, baseNodeKey == null ? nodeKey.toString() : baseNodeKey + '_' + nodeKey.toString()))
            }

            node = window.LCP.fn.extend(node, aosOverrides);
        }

        valAll += s.value;
        nodeKey ++;

        arr.push(node);
    });

    return arr;
}

function updateEngineData(data) {

    svgProjectedToYearEnd.attr('class', data.showProjectedToYearEndText ? '' : 'hidden');
    svgProjectedFromYearEnd.attr('class', data.showProjectedFromYearEndText ? '' : 'hidden');

    goodUp = data.goodUp;
    upColor = data.goodUp ? green : red;
    downColor = data.goodUp ? red : green;
    mainAos = getMappedAos(data.results, data.chartTypeDescription);
}

function getMappedAos(aos, aosDescription, mapping) {

    var  additionalData = {
        label: aosDescription,
        start: aos.startNode == null ? 0 : aos.startNode.value,
        end: aos.endNode == null ? 0 : aos.endNode.value,
        nodeCount: aos.nodes.length > 0 ? 2 + aos.nodes.length : undefined,
        min: aos.nodes.length > 0 ? findMin(aos.nodes) : undefined,
        max: aos.nodes.length > 0 ? findMax(aos.nodes) : undefined,
        nodeKey: mapping,
        nodes: getMappedNodes(aos.nodes, mapping)
    };

    return window.LCP.fn.extend(aos, additionalData);
}

function wrap(text, width) {
    text.each(function() {
        var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            lineHeightPx = 13,
            y = text.attr("y"),
            dx = text.attr("dx"),
            tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", 0).attr("dx", dx),
            toptspan = tspan;
        while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                ++lineNumber;
                toptspan.attr("dy", -(lineNumber * lineHeightPx) + 0);
                tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", Math.min(lineNumber, 1) * lineHeight + "em").attr("dx", dx).text(word);
            }
        }
    });
}

function getTitleX() {

    var retVal = 0;

    if (level > 1) {

        for (var n = level - 2; n > -1; n--) {
            var titleLevel = d3.selectAll(".headerLevel" + n);

            titleLevel.each(function() {

                var tspan = d3.select(this);

                retVal -= tspan.node().getComputedTextLength();

            });
        }
    }

    return retVal;
}

function updateValues(newData) {
    transitionCancelled = true;

    updateEngineData(newData);
    if (inNode) hideNode();
    updateChartLevel(level);

    var data = getData(level);
    updateExternalTableFunction(data);
}

function createStartEndNode(dataForLevel, isStartNode, svgLevel, initialTransform, initialOpacity, initialText, colour, initialCircleRadius, initialStartLineWidth) {

    var data = isStartNode ? dataForLevel.startNode : dataForLevel.endNode;
    var nodeRef = isStartNode ? "startNode" : "endNode";
    var drillNodeLabel = isStartNode ? "by scheme at start of period" : "by scheme at end of period";
    var nodeId = isStartNode ? 100 : 200;

    var node = svgLevel.selectAll("." + nodeRef).data([data]);

    var nodeEnter = node.enter()
        .append("g")
        .attr("class", nodeRef + " touch")
        .attr("transform", initialTransform)
        .attr("opacity", initialOpacity)
        .attr("level", level)
        .attr("text-anchor", "middle")
        .attr("cursor", getCursorFn)
        .on("click", onDrillClickEvent(drillNodeLabel, nodeId));

    if (initialStartLineWidth != undefined) {

        nodeEnter.append("rect")
            .attr("class", "startLine")
            .attr("width", initialStartLineWidth)
            .attr("height", 2)
            .attr("fill", window.LCP.colours.ALTERNATIVE2);
    }

    if (data == undefined || data == null) return;

    nodeEnter.append("svg:text")
        .attr("class", "nodeValueBehind")
        .attr("dy", "10")
        .style({
            fill: colour,
            "font-size": "22px"
        })
        .text(initialText);

    nodeEnter.append('circle')
        .attr("class", "mainCircle")
        .attr({
            r: initialCircleRadius,
            fill: colour
        });

    nodeEnter.append("clipPath")
        .attr("id", "clip_" + level)
        .append("circle")
        .attr("class", "clipCircle")
        .attr({
            r: initialCircleRadius
        });

    nodeEnter.append("svg:text")
        .attr("class", "nodeValue")
        .attr("clip-path", "url(#clip_" + level + ")")
        .attr("dy", "10")
        .style({
            fill: "white",
            "font-size": "22px"
        })
        .text(initialText);

    if (data.date != null) {

        nodeEnter.append("svg:text")
            .attr("class", "label")
            .attr("dy", getDyForStartEndNodeLabel(level))
            .style({
                fill: colour,
                "font-size": "18px"
            })
            .text(data.date.ToDateString());

        nodeEnter.append("svg:text")
            .attr("class", "labelDescription")
            .attr("dy", getDyForStartEndNodeLabelDescription(level))
            .style({
                fill: colour,
                "font-size": "14px"
            })
            .text(data.description);
    } else {

        nodeEnter.append("svg:g")
            .attr("class", "thisDesc")
            .attr("transform", "translate(0," + (-x[level].rangeBand() / 2) + ")")
            .append("svg:text")
            .attr("class", "thisDescText")
            .style({
                "text-anchor": "middle",
                fill: colour
            })
            .text(function(d) {
                return replaceStrings(d.description, d.value);
            })
            .call(wrap, getPaddedWidth(level));
    }

    node.exit().remove();
}

function createStartNode(svgLevel, data, initialTransform, initialOpacity, colour, initialCircleRadius, initialStartLineWidth) {
    return createStartEndNode(data, true, svgLevel, initialTransform, initialOpacity, startEndNodeValueFormatterForLevel(level), colour, initialCircleRadius, initialStartLineWidth);
}

function createEndNode(svgLevel, data, initialTransform, initialOpacity, initialText, colour, initialCircleRadius) {
    return createStartEndNode(data, false, svgLevel, initialTransform, initialOpacity, initialText, colour, initialCircleRadius);
}

function createAosBars(svgLevel, updateLevel, data, initialTransformation, aosBarWidth, aosBarHeight, aosBardx, aosBardy, aosBarColour, initialOpacity) {

    var aosBars = svgLevel.selectAll(".aosBars").data(data.nodes);

    var aosBarsEnter = aosBars.enter()
        .append("svg:g")
        .attr("class", "aosBars touch")
        .attr("transform", initialTransformation)
        .attr("level", updateLevel)
        .attr("opacity", initialOpacity)
        .attr("cursor", getCursorFn)
        .on("click", onDrillClickEvent("by scheme"));

    aosBarsEnter.append("svg:text")
        .attr("class", "barlabelsText")
        .attr("dy", aosBardy)
        .attr("dx", aosBardx)
        .style({
            "text-anchor": "middle",
            fill: aosBarColour,
            "font-size": "22px"
        });

    aosBarsEnter.append("rect")
        .attr("class", "bar")
        .attr("width", aosBarWidth)
        .attr("height", aosBarHeight)
        .attr("fill", aosBarColour);

    aosBarsEnter.append("clipPath")
        .attr("id", function(d, i) {
          return "clip" + i + "_" + updateLevel;
        })
        .append("rect")
        .attr("class", "clipBar")
        .attr("width", aosBarWidth)
        .attr("height", aosBarHeight);

    aosBarsEnter.append("svg:g")
        .attr("clip-path", function(d, i) {
          return "url(#clip" + i + "_" + updateLevel + ")";
        })
        .append("svg:text")
        .attr("class", "barlabelsTextWhite")
        .attr("dy", aosBardy)
        .attr("dx", aosBardx)
        .style({
            "text-anchor": "middle",
            fill: "white",
            "font-size": "22px"
        })
        .text(0);

    aosBarsEnter.append("svg:g")
        .attr("transform", "translate(0,-20)")
        .append("svg:text")
        .attr("class", "barDescText")
        .attr("dy", aosBardy)
        .attr("dx", aosBardx)
        .style({
            "text-anchor": "middle",
            fill: aosBarColour
        })
        .text(function(d) {
            return replaceStrings(d.description, d.value);
        })
        .call(wrap, aosBarWidth);

    aosBars.exit().remove();
}

function getData(atLevel) {
    if (atLevel == 0) return mainAos;
    return mainAos.nodes[dataIndex[atLevel]].aos;
}

function updateChartLevel(updateLevel) {

    var data = getData(updateLevel);

    updateDataForLevel(updateLevel, data);

    if (updateLevel > 0) {

        d3.select(".linkText.headerLevel" + updateLevel)
            .text(label[updateLevel].labelName)
            .style({
                fill: label[updateLevel].labelColor
            });
    }

    var svgLevel = svg.select(".level" + updateLevel);
    updateStartNodesForSensitivity(svgLevel, data, updateLevel);
    updateEndNodesForSensitivity(svgLevel, data, updateLevel);
    updateAosBarsForSensitivity(svgLevel, data, updateLevel);

    if (updateLevel > 0) updateChartLevel(updateLevel - 1);
}

function onShowCurrentLevel() {
    d3.select(".level" + level).selectAll(".touch").attr("cursor", getCursorFn);;
}

function hideCurrentLevel(headerFnBackToLevel) {

    d3.select(".linkText.headerLevel" + level)
        .attr("text-decoration", "underline")
        .attr("cursor", "pointer")
        .on("click", headerFnBackToLevel);

    var d3Level = d3.select(".level" + level);
    d3Level.attr("opacity", 1e-6);
    d3Level.selectAll(".touch").attr("cursor", "default");
}

function getCursorFn(d) {
    if (d != null && (d.aos != null || (d.valueBreakdownByScheme != null && LCP.fn.objLen(d.valueBreakdownByScheme) > 1))) return "pointer";
    return "default";
}

function removeLinkFromHeader() {
    d3.select(".linkText.headerLevel" + level)
        .attr("text-decoration", "none")
        .attr("cursor", "default")
        .on("click", () => {});
}

function getAxisLineWidth(updateLevel) {
    return w - x[updateLevel].rangeBand();
}

function getStartNodeTransformation (updateLevel) {
    return "translate(" + (x[updateLevel](0) + getHalfPaddedWidth(updateLevel)) + "," + y[updateLevel](0) + ")";
}

function getEndNodeTransformation (updateLevel, data) {
    return "translate(" + (x[updateLevel](data.nodeCount - 1) + getHalfPaddedWidth(updateLevel)) + "," + y[updateLevel](data.end - data.start) + ")";
}

function updateStartNodesForSensitivity(svgLevel, data, updateLevel) {
    return updateStartEndNodeForSensitivity("startNode", svgLevel, data, updateLevel, true);
}

function updateEndNodesForSensitivity(svgLevel, data, updateLevel) {
    return updateStartEndNodeForSensitivity("endNode", svgLevel, data, updateLevel, false);
}

function updateStartEndNodeForSensitivity(nodeRef, svgLevel, allData, updateLevel, isStartNode) {

    var data = isStartNode ? allData.startNode : allData.endNode;

    var node = svgLevel.select("." + nodeRef).data([data]);
    node.attr("transform", isStartNode ? getStartNodeTransformation(updateLevel) : getEndNodeTransformation(updateLevel, allData));

    node.select(".startLine")
        .attr("width", getAxisLineWidth(updateLevel));

    if (data == null) return;

    if (data.date != null) {

        node.select(".label")
            .attr("dy", getDyForStartEndNodeLabel(updateLevel))
            .text(data.date.ToDateString());

        node.select(".labelDescription")
            .attr("dy", getDyForStartEndNodeLabelDescription(updateLevel))
            .text(data.description);
    } else {

        node.select(".thisDesc")
            .attr("transform", "translate(0," + (-x[updateLevel].rangeBand() / 2) + ")")

        node.select(".thisDescText")
            .text(replaceStrings(data.description, data.value))
            .call(wrap, getPaddedWidth(updateLevel));
    }

    node.select(".nodeValueBehind")
        .text(startEndNodeValueFormatterForLevel(updateLevel));

    node.select(".nodeValue")
        .text(startEndNodeValueFormatterForLevel(updateLevel));

    node.select(".mainCircle")
        .attr('r', getHalfPaddedWidth(updateLevel));

    node.select(".clipCircle")
        .attr('r', getHalfPaddedWidth(updateLevel));

    if (updateLevel != 0) {

        var colour = getColour(data.value);

        node.select(".mainCircle")
            .style({
                fill: colour
            });

        node.select(".nodeValueBehind")
            .style({
                fill: colour
            });

        node.select(".label")
            .style({
                fill: colour
            });

        node.select(".labelDescription")
            .style({
                fill: colour
            });

        node.select(".thisDescText")
            .style({
                fill: colour
            });
    }
}

function updateAosBarsForSensitivity(svgLevel, aosData, updateLevel) {

    svgLevel.selectAll(".aosBars").remove();

    var initialTransformation = getTransformationForAosBar(updateLevel);
    var aosBarWidth = getPaddedWidth(updateLevel);
    var aosBarHeight = getHeightForAosBar(updateLevel);
    var aosBardx = getHalfPaddedWidth(updateLevel);
    var aosBardy = getDyForAosBar(updateLevel);
    var aosBarColour = getAosBarColourFillFn;
    createAosBars(svgLevel, updateLevel, aosData, initialTransformation, aosBarWidth, aosBarHeight, aosBardx, aosBardy, aosBarColour, 1);

    var aosBars = svgLevel.selectAll(".aosBars");

    aosBars.select(".barlabelsText")
      .text(function (d) {
        return nf(d.value);
      });

    aosBars.select(".barlabelsTextWhite")
      .text(function (d) {
        return nf(d.value);
      });
}

function updateLevel(newLevel, dataForLevel, dataIndexForLevel) {
    level = newLevel;
    dataIndex[level] = dataIndexForLevel;
    updateDataForLevel(level, dataForLevel);
}

function updateDataForLevel(levelToUpdate, dataForLevel) {

    x[levelToUpdate] = d3.scale.ordinal().rangeBands([0, w], barPadding / w / dataForLevel.nodeCount).domain(d3.range(dataForLevel.nodeCount));
    y[levelToUpdate] = d3.scale.linear().range([h - (x[levelToUpdate].rangeBand() / 2 + 2 * paddingTop), (x[levelToUpdate].rangeBand() / 2 + 2 * paddingTop)]).domain([dataForLevel.min, dataForLevel.max]);

    if (levelToUpdate == 0) {

        label[levelToUpdate] = {
            labelName: dataForLevel.label + " (" + LCP.fn.getFormattingOptionsForType(LCP.con.ACCOUNTINGAMOUNT).axisTitle + ")",
            labelColor: window.LCP.colours.PRIMARY
        };
    } else {

        label[levelToUpdate] = {
            labelName: replaceStrings(dataForLevel.label, dataForLevel.end),
            labelColor: getColour(dataForLevel.end)
        };
    }
}

function addToHeaderContainer(classId, text, colourOverride) {
    var newLabel = d3.select(".headerContainer")
        .append("tspan")
        .attr("class", classId)
        .text(text)
        .style({
            fill: colourOverride
        });

    if (level != 0) {
        newLabel
            .attr("y", -200)
            .transition()
            .duration(duration / 2)
            .attr("y", 0);
    }
}

function onDrillClickEvent(labelToAdd, nodeIdOverride) {
    return function(d, i) {
        if (inNode || inAnim) return;

        var thisLevel = d3.select(this).attr("level");
        if (thisLevel != level) return;

        if (d.aos != null) drillIn(i, d);
        else if (d.valueBreakdownByScheme != null) {
            var additionalLabel = labelToAdd;
            if (nodeIdOverride == undefined) additionalLabel = "> " + d.description + " " + labelToAdd;
            openNode(d.valueBreakdownByScheme, additionalLabel, nodeIdOverride || i, d.description ? d.description : d.date);
        }
    }
}

function getColour(value) {
    if (value > 0) return upColor;
    return downColor;
}

function getAosBarColourFillFn(d) {
    return getColour(d.value);
}

function getDyForStartEndNodeLabel(i) {
    return (-x[i].rangeBand() - barPadding) / 2;
}

function getDyForStartEndNodeLabelDescription(i) {
    return getDyForStartEndNodeLabel(i) + 14;
}

function getHeightForAosBar(i) {
    return function(d) {
        return getHeightForAosBarFromData(i, d);
    }
}

function getDyForAosBar(i) {
    return function(d) {
        return getDyForAosBarFromData(i, d);
    }
}

function getHeightForAosBarFromData(i, d) {
    if (d.value > 0) {
        return y[i](d.barStart) - y[i](d.barEnd);
    } else {
        return y[i](d.barEnd) - y[i](d.barStart);
    }
}

function getTransformationForAosBar(updateLevel) {
  return function(d, i) {
    return getTransformationForAosBarForData(updateLevel, d, i);
  }
}

function getTransformationForAosBarForData(updateLevel, data, i) {
  if (data.value > 0) {
    return "translate(" + (x[updateLevel](i + 1)) + "," + y[updateLevel](data.barEnd) + ")";
  } else {
    return "translate(" + x[updateLevel](i + 1) + "," + y[updateLevel](data.barStart) + ")";
  }
}

function getDyForAosBarFromData(i, d) {
    return getHeightForAosBarFromData(i, d) / 2 + 10;
}

function getPaddedWidth(i) {
    return x[i].rangeBand() - barPadding;
}

function getHalfPaddedWidth(i) {
    return getPaddedWidth(i) / 2;
}

function startEndNodeValueFormatterForLevel(currentLevel) {
    return function(d) {
        if (currentLevel == 0) return nf(d.value, true);
        else return nf(d.value);
    }
}

export default {
    create: create,
    updateValues: updateValues,
    updateMeasure: updateMeasure
};
