<template>
<div class="row">
    <div class="col-md-8 framed partialPageCardLeft">
        <div class="card">
            <div class="card-header">
                <h1>Market performance</h1>
            </div>
            <div v-show="$mq !== 'xs'" class="col-md-12 LCPcontent">
                <div ref="marketdatachart"></div>
            </div>
            <div class="row">
                <div class="col-xs-10 col-xs-offset-1 LCPcontent">
                    <input-wrapper :inputTemplate="sliderInput" :value-override="overrideValue" @updatePageProcess="updateSliderEndDate"/>
                </div>
                <div class="col-xs-10 col-xs-offset-1">
                    <div class="row">
                        <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(previous3YearsPeriod)" :text="previous3YearsKey" :disabled="drawing"/></div>
                        <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(previousYearPeriod)" :text="previousYearKey" :disabled="drawing"/></div>
                        <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(previousQuarterPeriod)" :text="previousQuarterKey" :disabled="drawing"/></div>
                        <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(previousMonthPeriod)" :text="previousMonthKey" :disabled="drawing"/></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(lastCalendarYearMinus2Period)" :text="lastCalendarYearMinus2Key" :disabled="drawing"/></div>
                        <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(lastCalendarYearMinus1Period)" :text="lastCalendarYearMinus1Key" :disabled="drawing"/></div>
                        <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(lastCalendarYearPeriod)" :text="lastCalendarYearKey" :disabled="drawing"/></div>
                        <div class="col-sm-3"><lcp-button :handleClick="onPeriodSelect(yearToDatePeriod)" :text="yearToDateKey" :disabled="drawing"/></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 partialPageCardRight">
        <div class="card">
            <div class="card-header-with-action">
                <h1>Returns over period</h1>
                <span v-show="currentMode.inReturn" class="headerIconAbsolute icon icon-arrow-left" @click="backToRiskMode"/>
            </div>
            <lcp-table class="col-md-12 input-container" :config="tableData" responsiveSize='xs'/>
        </div>
    </div>
</div>
</template>

<script>

import assetreturnChart from './chartMarketPerformance.js';
import marketPerformanceAssetTable from './marketPerformanceAssetTable.js';
import moment from 'moment';

const drawingChartString = 'drawingChart';

function getYearToDatePeriod (maxDate) {
    const startDate = getEndOfYear(maxDate, 1);
    const endDate = getDate(moment(maxDate));
    return getDateRange(startDate, endDate);
}

function getlastCalendarYearPeriod (maxDate) {
    const startDate = getEndOfYear(maxDate, 2);
    const endDate = getEndOfYear(maxDate, 1);
    return getDateRange(startDate, endDate);
}

function getlastCalendarYearMinus1Period (maxDate) {
    const startDate = getEndOfYear(maxDate, 3);
    const endDate = getEndOfYear(maxDate, 2);
    return getDateRange(startDate, endDate);
}

function getlastCalendarYearMinus2Period (maxDate) {
    const startDate = getEndOfYear(maxDate, 4);
    const endDate = getEndOfYear(maxDate, 3);
    return getDateRange(startDate, endDate);
}

function getEndOfYear (maxDate, yearOffset) {
    return getDate(moment([maxDate.year() - yearOffset, 11, 31]));
}

function getDateRange (startDate, endDate) {
    return {
        startDate: startDate,
        endDate: endDate
    };
}

function getDate (moment) {
    return moment.format('YYYY-MM-DDTHH:mm:ss');
}

export default {
    data () {
        return {
            endDate: null,
            overrideValue: null,
            currentMode: {
                inReturn: false,
                selectedAssetIds: []
            }
        };
    },
    created () {
        this.config = this.$staticStore.state.marketdata.config;

        this.sliderInput = this.$staticStore.state.marketdata.inputControls[this.config.dateSliderId];
        const dateOptions = this.sliderInput.config.options;
        const maxDate = moment(dateOptions[dateOptions.length - 1]);
        this.endDate = this.sliderInput.value[1];
        this.startDateAsMoment = moment(dateOptions[0]);

        this.previousMonthKey = 'Month';
        this.previousQuarterKey = 'Quarter';
        this.previousYearKey = 'Year';
        this.previous3YearsKey = '3 years';
        this.yearToDateKey = 'Latest YTD';
        this.lastCalendarYearKey = (maxDate.year() - 1).toString();
        this.lastCalendarYearMinus1Key = (maxDate.year() - 2).toString();
        this.lastCalendarYearMinus2Key = (maxDate.year() - 3).toString();

        this.yearToDatePeriod = getYearToDatePeriod(maxDate);
        this.lastCalendarYearPeriod = getlastCalendarYearPeriod(maxDate);
        this.lastCalendarYearMinus1Period = getlastCalendarYearMinus1Period(maxDate);
        this.lastCalendarYearMinus2Period = getlastCalendarYearMinus2Period(maxDate);
    },
    mounted () {
        assetreturnChart.add(this.$refs.marketdatachart, this.currentMode, this.onDrawStart, this.onDrawEnd, this.$store.state.scheme.headerMessages.length * 30);
        this.redrawChart();
    },
    beforeDestroy () {
        assetreturnChart.onDestroy();
    },
    computed: {
        dynamicOutput () {
            return this.$store.state.marketdata.dynamicOutput;
        },
        mappedResults () {
            return marketPerformanceAssetTable.getMappedResults(this.config.assets, this.dynamicOutput.results);
        },
        tableData () {
            return marketPerformanceAssetTable.getTableData(this.currentMode, this.mappedResults, true, this.$mq, this.onAssetSelected);
        },
        validResultsForPeriod () {
            const output = {};

            for (const id in this.config.assets) {
                const assetData = this.mappedResults[id];
                if (assetData.available) output[id] = assetData;
            }

            return output;
        },
        previousMonthPeriod () {
            const startDate = this.getStartOrValnDate(moment(this.endDate).add(-1, 'M'));
            const endDate = this.endDate;
            return getDateRange(startDate, endDate);
        },
        previousQuarterPeriod () {
            const startDate = this.getStartOrValnDate(moment(this.endDate).add(-3, 'M'));
            const endDate = this.endDate;
            return getDateRange(startDate, endDate);
        },
        previousYearPeriod () {
            const startDate = this.getStartOrValnDate(moment(this.endDate).add(-1, 'Y'));
            const endDate = this.endDate;
            return getDateRange(startDate, endDate);
        },
        previous3YearsPeriod () {
            const startDate = this.getStartOrValnDate(moment(this.endDate).add(-3, 'Y'));
            const endDate = this.endDate;
            return getDateRange(startDate, endDate);
        },
        drawing () {
            return this.$store.state.dataState === drawingChartString;
        }
    },
    methods: {
        redrawChart () {
            assetreturnChart.updateData(this.validResultsForPeriod, this.dynamicOutput.startDate, this.dynamicOutput.endDate, this.config.assets);
        },
        onAssetSelected (asset) {
            assetreturnChart.onAssetSelected(asset);
        },
        backToRiskMode () {
            assetreturnChart.selectRiskMode();
        },
        onDrawStart () {
            this.$store.commit('setDataState', drawingChartString);
        },
        onDrawEnd () {
            this.$store.commit('setDataLoaded');
        },
        onPeriodSelect (dateRange) {
            const periodOverride = this.periodOverride;
            return function () {
                periodOverride(dateRange);
            };
        },
        getStartOrValnDate (possibleStartDate) {
            const startDateOrValnDate = moment.max(possibleStartDate, this.startDateAsMoment);
            return getDate(startDateOrValnDate);
        },
        periodOverride (dateRange) {
            this.overrideValue = [dateRange.startDate, dateRange.endDate];

            /* Use promises? */
            setTimeout(() => {
                this.$store.dispatch('updatePageData');
            }, 300);
        },
        updateSliderEndDate (rangeData) {
            this.endDate = rangeData[1];
        }
    },
    watch: {
        dynamicOutput (newVal, oldVal) {
            this.redrawChart();
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
