<template>
    <div class="row">
        <div id="mainCard" class="col-md-12">
            <div origin-left="true" v-masonry transition-duration="1s" item-selector=".item">
                <div v-for="tile in tiles" v-masonry-tile class="item col-md-12" :key="tile.id">
                    <tile-wrapper :id="tile.id" :tileData="tile" :preLinkAction="preLinkAction(tile.id)"/>
                </div>
                <div v-for="note in notes" :key="note" v-masonry-tile class="item col-md-12">
                    <note :id="note" :tileData="null" v-on:newNote="addNote" v-on:removeNote="removeNote"/>
                </div>
            </div>
            <edit-dashboard
                :dashboardTiles="dashboardTiles" @close="$store.state.dashboard.editDashboardOpen = false"
                @redrawTiles="updateTiles"
                v-if="$store.state.dashboard.editDashboardOpen"
            />
        </div>
    </div>
</template>

<script>

import tileWrapper from '@/components/common/tiles/tileWrapper.vue';
import editDashboard from '@/components/common/uiLayouts/editDashboardModal';
import note from '@/components/common/tiles/note.vue';

const climateRiskTileId = 'ClimateRisk';
const riskTileId = 'Risk';

function addDashboardTile (tiles, tilesToAddTo, tileType, design, colour, icon, pageName, pageTypeForLink = null, removeLinkIfMetricsNotAvailable = true, descriptionOverride = null) {
    const tileToOutput = JSON.parse(JSON.stringify(tiles[tileType]));
    tileToOutput.tileDesignType = design;
    tileToOutput.colour = colour;
    tileToOutput.icon = icon;
    tileToOutput.pageForLink = pageName;
    tileToOutput.pageTypeForLink = pageTypeForLink;
    tileToOutput.description = descriptionOverride || tileType;

    if (tileToOutput.metricsNotAvailableText !== null && removeLinkIfMetricsNotAvailable) tileToOutput.pageForLink = null;

    tilesToAddTo[tileType] = tileToOutput;
}

function getDashboardTiles (config, tiles) {
    const tilesOutput = {};

    if (config.includeCovenantModule) addDashboardTile(tiles, tilesOutput, 'Covenant', 'Covenant', window.LCP.colours.SECONDARY, 'Covenant', null);

    if (config.investmentStrategyModule.includeInvestmentStrategyModule || config.historyAndExperienceModule.includeHistoryAndExperienceModule) {
        const pageLinkForInvestment = config.investmentStrategyModule.includeInvestmentStrategyModule ? 'investmentstrategy' : 'historyandexperience';
        addDashboardTile(tiles, tilesOutput, 'Investment', 'Investments', window.LCP.colours.ALTERNATIVE1, 'Investment', pageLinkForInvestment);
    }

    if (config.historyAndExperienceModule.includeHistoryAndExperienceModule) addDashboardTile(tiles, tilesOutput, 'Funding', 'Funding', window.LCP.colours.BRIGHT, 'History', 'historyandexperience');

    if (config.includeRecoveryPlanModule) {
        const recoveryDescriptionOverride = config.recoveryPlanDisplayName !== 'Recovery plan' ? config.recoveryPlanDisplayName : null;
        addDashboardTile(tiles, tilesOutput, 'Recovery', 'StandardMetrics', window.LCP.colours.ALTERNATIVE5, 'Recovery', 'recoveryplan', null, true, recoveryDescriptionOverride);
    }

    if (config.includeResultsSummaryModule) addDashboardTile(tiles, tilesOutput, 'ResultsSummary', 'StandardMetrics', window.LCP.colours.ALTERNATIVE5, 'Review', 'resultssummary', null, true, 'Recovery');

    if (config.investmentStrategyModule.includeInvestmentStrategyModule) {
        const mainRiskDescriptionOverride = config.investmentStrategyModule.includeClimateRisk ? 'Investment risk' : null;
        addDashboardTile(tiles, tilesOutput, 'Risk', 'Risk', window.LCP.colours.ALTERNATIVE8, 'activity', 'investmentstrategy', null, true, mainRiskDescriptionOverride);

        if (config.investmentStrategyModule.includeClimateRisk) {
            const climateRiskPageName = config.includeSensitivitiesModule ? 'sensitivities' : null;
            addDashboardTile(tiles, tilesOutput, 'ClimateRisk', 'ClimateRisk', window.LCP.colours.ALTERNATIVE8, 'activity', climateRiskPageName, config.riskFundingMeasureDisplayName.ToUrlSafeString(), true, 'Climate shock scenario');
        }
    }

    if (config.includeAccountingModule) addDashboardTile(tiles, tilesOutput, 'Accounting', 'Accounting', window.LCP.colours.ALTERNATIVE4, 'Accounting', 'accounting');
    if (config.triggersModule.includeTriggersModule) addDashboardTile(tiles, tilesOutput, 'Triggers', 'Triggers', window.LCP.colours.ALTERNATIVE4, 'Triggers', 'triggers', null, false);
    if (config.insurerPricingModule.includeInsurerPricingModule) addDashboardTile(tiles, tilesOutput, 'InsurerPricing', 'InsurerPricing', window.LCP.colours.ALTERNATIVE6, 'Insurer-pricing', 'insurerpricing', null, true, 'Indicative insurer pricing for pensioners');
    if (config.includeCashflowsModule) addDashboardTile(tiles, tilesOutput, 'SplitByLiability', 'SplitByLiability', config.cashflowChartFundingTargetColour, 'Cashflow', 'cashflows', null, true, 'Liability splits');
    if (config.includeMarketDataModule) addDashboardTile(tiles, tilesOutput, 'Markets', 'Markets', window.LCP.colours.ALTERNATIVE2, 'Markets', 'marketdata');

    return tilesOutput;
}

export default {
    components: {
        tileWrapper,
        editDashboard,
        note
    },
    data () {
        return {
            tiles: [],
            // notes: ['Test note'],
            notes: []
        };
    },
    created () {
        this.config = this.$staticStore.state.dashboard.config;
        const visualiseAiConfig = this.$store.state.scheme.visualiseAiConfig;
        this.dashboardTiles = getDashboardTiles(visualiseAiConfig, this.config.tiles);

        const schemeId = this.$store.state.scheme.id;
        let dashboardPreferences = this.$store.state.session.user.store.preferences.byScheme[schemeId].dashboard;

        if (dashboardPreferences == null) {
            dashboardPreferences = {
                shown: [],
                hidden: []
            };
        }

        const hiddenTileIds = this.getValidatedTileIds(dashboardPreferences.hidden);
        const shownTileIds = this.getValidatedTileIds(dashboardPreferences.shown);

        let dashboardUpdated = hiddenTileIds.length !== dashboardPreferences.hidden.length || shownTileIds.length !== dashboardPreferences.shown.length;

        if (hiddenTileIds.length + shownTileIds.length !== Object.keys(this.dashboardTiles).length) {
            for (const tileId in this.dashboardTiles) {
                if (hiddenTileIds.includes(tileId)) continue;
                if (shownTileIds.includes(tileId)) continue;
                shownTileIds.push(tileId);
            }

            dashboardUpdated = true;
        }

        if (dashboardUpdated) this.$store.dispatch('updateDashboardPreferences', { shown: shownTileIds, hidden: hiddenTileIds });

        this.updateTiles(shownTileIds, false);
    },
    methods: {
        getValidatedTileIds (optionsToValidate) {
            const output = [];

            for (const i in optionsToValidate) {
                const id = optionsToValidate[i];
                if (this.dashboardTiles[id] == null) continue;

                output.push(id);

                if (id !== riskTileId) continue;
                if (this.dashboardTiles[climateRiskTileId] == null) continue;
                if (optionsToValidate.includes(climateRiskTileId)) continue;

                output.push(climateRiskTileId);
            }

            return output;
        },
        updateTiles (newValues, redrawTiles) {
            const output = [];
            for (const i in newValues) {
                output.push(this.dashboardTiles[newValues[i]]);
            }

            this.tiles = output;
            if (redrawTiles === undefined || redrawTiles) this.redrawTiles();
        },
        addNote () {
            this.notes.push(this.notes.length);
            this.redrawTiles();
        },
        removeNote (id) {
            this.notes.splice(this.notes.indexOf(id), 1);
            this.redrawTiles();
        },
        redrawTiles () {
            setTimeout(() => {
                this.$redrawVueMasonry();
            }, 200);
        },
        preLinkAction (tileId) {
            if (tileId === climateRiskTileId) return () => { this.$store.state.sensitivities.selectedScenario = this.config.climateScenarioId; };
            return null;
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.body-adjustment {
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.item {
    padding-left:7.5px!important;
    padding-right:7.5px!important;

    @media (min-width: 0px) and (max-width: 915px) {
        width: 100% !important;
    }

    @media (min-width: 915px) and (max-width: 1450px) {
        width: 50% !important;
    }

    @media (min-width: 1450px) {
        width: 33.33333333% !important;
    }
}

</style>
