<template>

<div>
    <derisking-Table :lockTableData="lockTableData" :tableConfig="dynamicResult.headlineResultsTable"/>
    <highchart class="LCPcontent" :options="chartOptions" :series-options="chartSeries" key="recoveryProgress"/>
</div>

</template>

<script>

import deriskingTable from './deriskingTable.vue';
import lineChart from '@/components/common/charts/lineChart.js';

export default {
    props: ['lockTableData', 'config', 'dynamicResult'],
    components: {
        deriskingTable
    },
    computed: {
        chartOptions () {
            const options = lineChart.chartOptions(window.LCP.con.AMOUNT);
            options.yAxis[0].title.text = this.config.description;
            return options;
        },
        chartSeries () {
            const output = [];
            if (this.progressHistorySeries !== null) output.push(this.progressHistorySeries);
            output.push(this.progressCurrentSeries);
            output.push(lineChart.getSeriesFromChartSeriesDataProjectedXyDate(this.dynamicResult.chosenProjectionSeriesData));
            return output;
        },
        progressHistorySeries () {
            return lineChart.getSeriesFromChartSeriesDataHistory(this.config.historicSurplusSeriesData);
        },
        progressCurrentSeries () {
            return lineChart.getSeriesFromChartSeriesDataProjectedXyDate(this.config.currentProjectionSeriesData);
        }
    }
};

</script>
