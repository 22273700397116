const state = {};

const StaticStore = {
    installed: false,
    state: state,
    install: function (Vue, options) {
        this.installed = true;
        Vue.prototype.$staticStore = {
            state: state
        };
    }
};

export default StaticStore;

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(StaticStore);
    if (StaticStore.installed) {
        StaticStore.installed = false;
    }
}
