<template>
    <div class="card-header-inline" :class="{'onResponsiveViewChange': matchesResponsive(responsiveSize), 'noTitle': title == null && dropdownTitle == null}">
        <h1 v-if="title">{{title}}</h1>
        <dropdown-title v-if="dropdownTitle != null" :dropdownPrefix="dropdownTitle.prefix" :inputTemplate="dropdownTitle.inputTemplate" :value-override="titleValueOverride" @currentvalue="dropdownChange"/>
        <input-wrapper :inputTemplate="tabsInput" @currentvalue="updateToggleView" :value-override="null" v-if="matchesResponsive(responsiveSize)"/>
        <ul class="inline nav nav-tabs" id="myTab-6" role="tablist" v-else>
            <li v-for="tabName in tabs" class="nav-item" :key="tabName">
                <a v-if="showTabs" class="nav-link" :class="(toggleView === tabName ? 'active' : '')" @click="updateToggleView(tabName)">{{tabName}}</a>
            </li>
            <li v-if="includeReset">
                <reset-inputs class="action" :absolute="false"/>
            </li>
        </ul>
    </div>
</template>

<script>

import { responsiveCheck } from '@/jsMixins/mixins';

export default {
    props: {
        tabs: {},
        viewOverride: {},
        title: {},
        dropdownTitle: {},
        titleValueOverride: {},
        showTabsOverride: {},
        includeReset: {},
        responsiveSize: {
            type: String,
            default: 'none'
        }
    },
    mixins: [responsiveCheck],
    data () {
        return {
            toggleView: null,
            tabsInput: {
                id: this.title,
                value: this.toggleView,
                resetValue: null,
                config: {
                    label: '',
                    options: this.tabs
                },
                type: 'dropdown',
                resetLocked: false,
                locked: false,
                align: 'right'
            }
        };
    },
    created () {
        this.updateToggleView(this.viewOverride || this.tabs[0]);
        this.showTabs = this.tabs.length > 1 || this.showTabsOverride;
        this.tabsInput.value = this.toggleView;
    },
    methods: {
        updateToggleView (view) {
            this.toggleView = view;
            this.$emit('currentView', view);
        },
        dropdownChange (value) {
            this.$emit('dropdownChange', value);
        }
    }
};

</script>

<style lang="scss" scoped>

@import 'src/assets/scss/base.scss';

.card-header-inline.onResponsiveViewChange {
    flex-direction: column;
}

.card-header-inline.noTitle {
    justify-content: flex-end;
}

.nav-tabs {
    margin-top:1rem;
    border-bottom: 0;
    float: right;
    user-select: none;
}

.action {
    @extend %headerIcon;
    color: #333333;
}

.nav-item {
    margin-bottom: 0;
}

.nav-link {
    display: block;
    padding: .9rem 1rem;
    border: 1px solid #dee2e6;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    &.active{
        position: relative;
        color: white;
        background-color: $LCPMarine;
        border-color:$LCPMarine;
        &:after{
            position: absolute;
            bottom: -1px;
            left: 50%;
            border: solid transparent;
            content: " ";
            border-bottom-color: white;
            border-width: 6px;
            margin-left: -6px;
        }
    }
}

a {
    cursor: pointer;
    color: $LCPMarine;
    -webkit-transition: color 0.225s ease-in-out, background-color 0.225s ease-in-out;
    -moz-transition: color 0.225s ease-in-out, background-color 0.225s ease-in-out;
    -o-transition: color 0.225s ease-in-out, background-color 0.225s ease-in-out;
    transition: color 0.225s ease-in-out, background-color 0.225s ease-in-out;
}

</style>
