<template>
    <div class="inputMargin">
        <div v-if="config.label">
            {{ config.label }}
        </div>
        <multiselect
            :value="selected"
            :options="config.options"
            :show-labels="false"
            :searchable="false"
            :class="[valueChanged, {'titleHeader': titleHeader, 'removeDropDown': config.options.length < 2}]"
            :allow-empty="false"
            @select="dropdownSelect"
        />
    </div>
</template>

<script>

import Multiselect from 'vue-multiselect';

export default {
    props: {
        inputTemplate: Object,
        valueOverride: String,
        titleHeader: Boolean
    },
    name: 'dropdown',
    components: { Multiselect },
    data () {
        return {
            config: null,
            selected: null
        };
    },
    created () {
        this.updateForNewTemplate();
    },
    methods: {
        updateForNewTemplate () {
            this.config = this.inputTemplate.config;
            this.selected = this.config.options.includes(this.inputTemplate.value) ? this.inputTemplate.value : this.config.options[0];
        },
        dropdownSelect (option) {
            if (option === this.selected) return;
            this.selectValueAndSendUpdate(option, true);
            if (!this.config.suppressPageUpdate) this.$store.dispatch('updatePageData');
        },
        selectValueAndSendUpdate (option, updateDynamicOutput) {
            if (option === this.selected) return;
            this.selected = option;
            this.sendUpdate(updateDynamicOutput);
            this.$emit('currentvalue', option, this.inputTemplate.id);
        },
        sendUpdate (updateDynamicOutput) {
            const data = {
                id: this.inputTemplate.id,
                value: this.selected,
                index: updateDynamicOutput ? window.LCP.fn.arrayFindIndex(this.config.options, this.selected) : null
            };
            this.$store.commit('updateInput', data);
        }
    },
    computed: {
        dataState () {
            return this.$store.state.dataState;
        },
        valueChanged () {
            if (this.inputTemplate.resetValue == null) return '';
            return this.inputTemplate.resetValue === this.selected ? '' : 'value-changed';
        }
    },
    watch: {
        inputTemplate (newVal, oldVal) {
            this.updateForNewTemplate();
        },
        valueOverride (newVal, oldVal) {
            if (newVal !== null) this.selectValueAndSendUpdate(newVal, false);
        },
        dataState (newVal, oldVal) {
            if (newVal === 'resetData' && this.inputTemplate.resetValue != null) {
                this.selectValueAndSendUpdate(this.inputTemplate.resetValue, false);
            }
        }
    },
    getDefaultInputTemplate (id, options, value, resetValue, suppressPageUpdate) {
        return {
            config: {
                options: options,
                suppressPageUpdate: suppressPageUpdate != null ? suppressPageUpdate : true
            },
            id: id,
            value: value,
            resetValue: resetValue
        };
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import 'src/assets/scss/all.scss';

</style>

<style lang="scss">

@import 'src/assets/scss/all.scss';

.multiselect {
    @extend %standardFontSize;
}
.multiselect.titleHeader {
    @extend %headerFont;
    @extend %h1FontSize;
    min-height: 0px;
    margin-right: 40px;
}

.multiselect__option--selected.multiselect__option--highlight {
    background: $SECONDARY !important;
    color: white !important;
}

.multiselect__option--selected {
    background: $SECONDARY !important;
    color: white !important;
    font-weight: normal !important;
}

.multiselect__option--highlight {
    background: $bodyBg !important;
    color: $SECONDARY !important;
}
.titleHeader {
    .multiselect__option--highlight{
        background: $SECONDARY!important;
        color: white!important;
    }
}

.titleHeader {
    .multiselect__option {
        background: $SECONDARY;
        color: white!important;
        font-size: 20px;
        @include responsive ('sm') {
            font-size: $fontSizeStandard !important;
        }
        &:hover{
            background: $SECONDARYTINT1!important;
        }
    }
}

.multiselect__tags {
    background: $PRIMARY !important;
    cursor: pointer;
    font-size: inherit !important;
    .multiselect__single {
        background: transparent;
        color: white;
        font-size: inherit !important;
    }
    &:hover {
        background: $SECONDARY !important;
    }
}
.titleHeader {
    .multiselect__tags {
        background: transparent !important;
        border: none !important;
        min-height: 0px;
        line-height: 0px;
        padding: 0px;
        .multiselect__single {
            color: #333333;
            font-weight: normal !important;
            line-height: 1.1;
            margin-bottom: 0.65rem !important;
            padding-left: 0;
        }
        &:hover {
            .multiselect__single {
                color: $LCPBlue !important;
            }
        }
    }
}

.titleHeader {
    .multiselect__select {
        background: $SECONDARY;
        border-radius: 50%;
        height: 22px;
        width: 22px;
        padding: 5px;
        right: 8px;
        top: 6px;
        @include responsive ('sm') {
            top: 2px;
            padding-top: 12px;
        }
    }
}

.titleHeader.removeDropDown {
    pointer-events: none;
    .multiselect__select {
        display: none !important;
    }
    .multiselect__tags {
        cursor: default;
        &:hover {
            .multiselect__single {
                color: unset !important;
            }
        }
    }
}

.multiselect__select:before {
    border-color: white transparent transparent !important;
}
.titleHeader {
    .multiselect__select:before {
        top: 130% !important;
    }
}

.titleHeader {
    .multiselect__content-wrapper {
        width: fit-content;
    }
}

.value-changed {
    .multiselect__tags {
        .multiselect__single {
            font-weight: bold;
        }
    }
}

</style>
