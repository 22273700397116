import hello from 'hellojs';
import store from '../store/index.js';
import moment from 'moment';
import router from '../router/index.js';

export const auth = {
    initLocal () {
        this.init('local');
    },
    initLcp () {
        this.init('lcp');
    },
    init (type) {
        if (type === 'lcp') this.initSignIn('Azure B2C LCP Login', 'B2C_1A_LEGACYVIS_LCP_SIUPIN', type);
        else if (type === 'local') this.initSignIn('Azure B2C Local Login', 'B2C_1A_AUTHV2_SIUPIN_ALT', type);
        else router.push('/500');
    },
    signIn () {
        hello('b2clogin')
            .login()
            .catch(e => {
                if ('Iframe was blocked' in e.error.message) {
                    this.signIn();
                    return;
                }
                alert('Signin error: ' + e.error.message);
            });
    },
    signOut () {
        if (this.online()) {
            hello.logout('b2clogin', { force: true })
                .catch(e => {
                    alert('Logout error: ' + e.error.message);
                });
        }
    },
    online () {
        const b2cAuthResponse = this.getValidB2CAuthResponse();
        return b2cAuthResponse != null;
    },
    getValidB2CAuthResponse () {
        const authResponse = localStorage.getItem('hello');

        if (authResponse) {
            const b2clogin = JSON.parse(authResponse).b2clogin;
            if (b2clogin && moment.unix(b2clogin.expires) > moment()) return b2clogin;
        }

        return null;
    },
    initSignIn (name, policy, state) {
        hello.init({
            b2clogin: {
                id: process.env.VUE_APP_APPLICATIONID,
                name: name,
                oauth: {
                    version: 2,
                    auth: 'https://' + process.env.VUE_APP_TENANTNAME + '.b2clogin.com/' + process.env.VUE_APP_TENANTNAME + '.onmicrosoft.com/oauth2/v2.0/authorize?p=' + policy,
                    grant: 'https://' + process.env.VUE_APP_TENANTNAME + '.b2clogin.com/' + process.env.VUE_APP_TENANTNAME + '.onmicrosoft.com/oauth2/v2.0/token?p=' + policy
                },
                refresh: true,
                scope_delim: ' ',
                logout: function () {
                    // get id_token from auth response
                    const idToken = hello('b2clogin').getAuthResponse().id_token;
                    // clearing local storage session
                    hello.utils.store('b2clogin', null);

                    store.dispatch('logoutSession');
                    store.dispatch('clearAuthorisation');

                    // redirecting to Azure B2C logout URI
                    window.location = 'https://' + process.env.VUE_APP_TENANTNAME + '.b2clogin.com/' + process.env.VUE_APP_TENANTNAME + '.onmicrosoft.com/oauth2/v2.0/logout?p=' + policy + '&id_token_hint=' +
                        idToken + '&post_logout_redirect_uri=' + process.env.VUE_APP_SITEBASE_URL;
                },
                // Don't even try submitting via form.
                // This means no POST operations in <=IE9
                form: false
            }
        }, {
            display: 'page',
            page_uri: '/',
            redirect_uri: '/',
            scope: 'openid ' + process.env.VUE_APP_SCOPE,
            response_type: 'token id_token',
            state: state
        });
    }
};
