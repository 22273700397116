/* eslint-disable */
import baseChart from './chartSettings.js';

const lineChartOverrides = {
    chart: {
        defaultSeriesType: "line",
    },
    legend: {
        enabled: true
    },
    tooltip: {
        headerFormat: "<b>{point.key}</b><table>",
        shared: true,
        xDateFormat: "%d %b %Y"
    },
    xAxis: [{
        labels: {
            formatter () {
                return baseChart.getAxisDateLabelValue(this.value, this.axis.tickPositions);
            },
        },
        tickPixelInterval: 150,
        type: "datetime"
    }],
    yAxis: [{
        labels: {
            formatter: null
        }
    }]
};

function getTooltipFormatter(valueScalingType) {
    return function () {
        var output = '<b>' + this.points[0].x.ToDateString() + '</b><table>';

        var sortedPoints = this.points.sort(function (a, b) {
            return b.y - a.y;
        });

        sortedPoints.forEach(point => {
            output += '<tr style="color: ' + point.color + '"><td style=\'padding-right: 10px;\'>' + point.series.name + ': </td><td style="text-align: right"><b>' + point.y.toScaledValueString(valueScalingType) + '</b></td></tr>';
        });

        return output;
    }
}

export default {
    chartOptions (scalingType, additionalOptions) {
        var output = baseChart.getChartOptions(lineChartOverrides);
        output.yAxis[0].labels.formatter = baseChart.getAxisLabelFormatter(scalingType);
        output.yAxis[0].title.text = window.LCP.fn.getFormattingOptionsForType(scalingType).axisTitle;
        output.tooltip.formatter = getTooltipFormatter(scalingType);
        if (additionalOptions != null) output = window.LCP.fn.extend(output, additionalOptions);
        return output;
    },
    getSeriesFromChartSeriesDataHistory (chartSeriesDataHistory) {
        if (chartSeriesDataHistory === null) return null;
        var series = this.getSeriesFromDailyData(chartSeriesDataHistory.name, chartSeriesDataHistory.colourString, chartSeriesDataHistory.data);
        series.dashStyle = chartSeriesDataHistory.dashStyle;
        return series;
    },
    getSeriesFromDailyData (name, color, dailyData) {
        return this.getSeries(name, color, dailyData.data, dailyData.startDate, dailyData.endDate);
    },
    getSeries (name, color, data, startDate, endDate) {
        var series = this.getSeriesDataOnly(name, color, data);
        series.pointInterval = 86400000;
        series.pointStart = startDate.ToUtcDate();
        series.pointEnd = endDate.ToUtcDate();
        return series;
    },
    getSeriesDataOnly (name, color, data) {
        return {
            id: name.toSafeString(),
            name: name,
            color: color,
            data: data,
            type: 'line'
        };
    },
    getSeriesFromChartSeriesDataProjectedXyDate (chartSeriesData, dashStyle) {
        var series = this.getSeriesFromXYData(chartSeriesData.name, chartSeriesData.colourString, chartSeriesData.data.xValues, chartSeriesData.data.yValues, true);
        series.dashStyle = dashStyle ? dashStyle : 'ShortDash';
        return series;
    },
    getSeriesFromChartSeriesDataMultipleProjectedXyCollection (chartSeriesDataMultipleProjectedXyCollection, xValuesTimePeriod) {
        var output = [];

        for (let i = 0; i < chartSeriesDataMultipleProjectedXyCollection.length; i++) {
            const chartSeriesDataI = chartSeriesDataMultipleProjectedXyCollection[i];

            for (let j = 0; j < chartSeriesDataI.data.length; j++) {
                let metricData = chartSeriesDataI.data[j];
                var series = this.getSeriesFromXYData(chartSeriesDataI.name, chartSeriesDataI.colourString, metricData.xValues, metricData.yValues, xValuesTimePeriod);
                if (j !== 0) {
                    series.name = series.name + '_' + j;
                    series.showInLegend = false;
                }
                output.push(series);
            };
        }

        return output;
    },
    getSeriesFromXYData (name, color, xData, yData, xValuesTimePeriod) {
        var convertXValuesToDates = xValuesTimePeriod !== undefined && xValuesTimePeriod;
        var data = [];
        for (var i = 0; i < xData.length; i++) {
            var xValue = xData[i];
            if (convertXValuesToDates) xValue = xValue.ToUtcDate();
            data.push([xValue, yData[i]]);
        };
        var series = this.getSeriesDataOnly(name, color, data);
        series.pointStart = xData[0];
        series.pointEnd = xData[xData.length - 1];
        if (convertXValuesToDates) series.pointInterval = 86400000;
        return series;
    }
};
