/* eslint-disable */
import baseChart from './chartSettings.js';

const waterfallChartOverrides = {
    chart: {
        defaultSeriesType: "columnrange",
        inverted: true
    },
    plotOptions: {
        series: {
            groupPadding: 0.05
        }
    },
    legend: {
        enabled: false
    },
    xAxis: [{
        labels: {
            step: 1
        }
    }]
};

const commonChartOverrides = {
    plotOptions: {
        series: {
            groupPadding: 0.05
        }
    },
    legend: {
        enabled: false
    },
    xAxis: [{
        labels: {
            step: 1
        }
    }]
};

function getTooltipFormatter(valueScalingType) {
    return function () {
        return '<table><tr style=\'color:' + this.point.color + '\'><td style=\'padding-right: 10px;\'>' + this.point.category + ': </td><td style=\'text-align: right\'><b>' + (this.point.high - this.point.low).toScaledValueString(valueScalingType) + '</b></td></tr></table>';
    }
}

export default {
    getCommonChartOverrides () {
        return commonChartOverrides;
    },
    chartOptions (scalingType, additionalOptions) {
        var output = baseChart.getChartOptions(window.LCP.fn.extend(waterfallChartOverrides, this.getCommonChartOverrides()));
        output.yAxis[0].labels.formatter = baseChart.getAxisLabelFormatter(scalingType);
        output.yAxis[0].title.text = window.LCP.fn.getFormattingOptionsForType(scalingType).axisTitle;
        output.tooltip.formatter = getTooltipFormatter(scalingType);
        if (additionalOptions != null) output = window.LCP.fn.extend(output, additionalOptions);
        return output;
    },
    getSeriesData (data, colours) {
        return {
            data: data,
            colorByPoint: true,
            colors: colours
        };
    },
    getSeriesDataWithSecondaryLabels (data, colours) {
        var primarySeries = this.getSeriesData(data, colours);
        return baseChart.getSeriesWithDummySeriesForSecondaryAxisLabels(primarySeries);
    }
};
