<template>
    <div>
        <div class="modalBg"/>
        <div class="editModal">
            <div class="modalHeader">
                <div class="closeModal" @click="$emit('close')"><span class="icon icon-x-circle"/></div>Reorder your dashboard layout
            </div>
            <draggable v-model="visibleTileIds" :options="{group: 'tiles', disabled: inTransition }">
                <div v-for="(tile, index) in visibleTiles" class="draggableTile" :key="tile.id" :style="{backgroundColor: tile.colour}">
                    <div class="details">{{tile.description}}</div>
                    <div class="showHide"><img :src="hideIcon" @click="hideTile(index)"/></div>
                </div>
            </draggable>
            <div class="modalHeader">Hidden tiles</div>
            <div v-for="(tile, index) in hiddenTiles" class="draggableTile" :key="tile.id" :style="{backgroundColor: tile.colour}">
                <div class="details">{{tile.description}}</div>
                <div class="showHide"><img :src="showIcon" @click="showTile(index)"/></div>
            </div>
        </div>
    </div>
</template>

<script>

import draggable from 'vuedraggable';
import hideIcon from '@/assets/svg/eye-off.svg';
import showIcon from '@/assets/svg/eye.svg';

export default {
    props: {
        dashboardTiles: {
            type: Object,
            required: true
        }
    },
    components: {
        draggable
    },
    data () {
        return {
            hideIcon,
            showIcon,
            visibleTileIds: null,
            visibleTiles: null,
            hiddenTiles: null,
            inTransition: false
        };
    },
    created () {
        this.schemeId = this.$store.state.scheme.id;

        const dashboard = this.$store.state.session.user.store.preferences.byScheme[this.schemeId].dashboard;
        this.visibleTileIds = dashboard.shown;
        this.hiddenTileIds = dashboard.hidden;

        this.visibleTiles = this.getTileData(this.visibleTileIds);
        this.hiddenTiles = this.getTileData(this.hiddenTileIds);
    },
    methods: {
        getTileData (tileIds) {
            const output = [];

            for (const key in tileIds) {
                const id = tileIds[key];
                output.push(this.dashboardTiles[id]);
            }

            return output;
        },
        updateTiles () {
            this.$store.dispatch('updateDashboardPreferences', { shown: this.visibleTileIds, hidden: this.hiddenTileIds });
            this.$emit('redrawTiles', this.visibleTileIds);

            this.inTransition = true;
            setTimeout(() => { this.inTransition = false; }, 1000);
        },
        hideTile (index) {
            const tileToMove = this.visibleTiles[index];
            this.visibleTileIds.splice(index, 1);
            this.hiddenTileIds.push(tileToMove.id);
            this.hiddenTiles.push(tileToMove);
        },
        showTile (index) {
            const tileToMove = this.hiddenTiles[index];
            this.visibleTileIds.push(tileToMove.id);
            this.hiddenTileIds.splice(index, 1);
            this.hiddenTiles.splice(index, 1);
        }
    },
    watch: {
        visibleTileIds (newVal, oldVal) {
            this.visibleTiles = this.getTileData(this.visibleTileIds);
            if (oldVal != null) this.updateTiles();
        }
    }
};

</script>

<style lang="scss" scoped>

.modalBg{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 2;
}

.editModal{
    position: absolute;
    width: 500px;
    max-height: 550px;
    overflow: auto;
    top: 50px;
    left: calc(50% - 250px);
    background: white;
    box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06) !important;
    border-radius: 6px;
    padding: 10px;
    z-index: 3;
    .modalHeader{
        position: relative;
        margin-bottom:10px;
        font-size: 16px;
        padding: 0 8px;
        .closeModal{
            float: right;
            font-size: 20px;
            cursor: pointer;
        }
    }
    .draggableTile{
        margin-bottom: 5px;
        padding: 8px;
        color: white;
        font-size: 22px;
        .details{
            width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            float: left;
            cursor: move;
        }
        .showHide{
            width: 10%;
            text-align: center;
            display: inline-block;
            cursor: pointer;
        }
    }
}

</style>
