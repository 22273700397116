<template>

<div>
    <h3>Using LCP Visualise</h3>

    <p>Your use of LCP Visualise is subject to these terms and conditions and the privacy policy.  Please read these and on first log-in click "Accept" to confirm your acceptance of them.  If you do not agree to these terms and conditions or the privacy policy, you must not use this website.  By accepting these terms and conditions you indicate your acceptance on behalf of yourself and the Client or any third party on whose behalf you have been given access to this website.</p>
    <p>We may amend these terms and conditions and the privacy policy from time to time.  Please check them periodically so that you can be informed of any changes.  These terms and conditions were last amended on 10 May 2022.</p>

    <h3>Liability and reliance on information</h3>

    <p>We do not guarantee that this website, or any content on it, will be free from errors or omissions.</p>
    <p>Although the information provided on this website is part of the information that we use as a basis for our advice to the Client, you must liaise with us before making any decisions based on the contents of this website or using any content for formal disclosure purposes.</p>
    <p>This is because in many circumstances you will need written advice before using these figures as a basis for making decisions, and there may also be other factors to take into account.  In addition, the accuracy of information, models, calculations and other material on this website relies on data provided to us as at a certain date and it may be affected by changes to this data or external events not reflected in a model.</p>
    <p><strong>Therefore, we accept no liability for any actions or decisions taken using this website unless we have confirmed in writing that LCP Visualise is appropriate for that purpose.</strong></p>
    <p>Access to this website is provided as part of our overall service to the Client and is subject to the limitations and exclusions of liability set out in our appointment letter (and Terms and Conditions of appointment) with the Client, including the liability cap, and any other written agreement with the Client.</p>
    <p>Regarding any person other than the Client (including the individual user of this website), to the extent permitted by law, we exclude all liability for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with use of, or inability to use, this website or use of or reliance on any content on this website.</p>

    <h3>Access to LCP Visualise</h3>

    <p>Access to this website is only for users for whom we have provided a username and password in connection with the Scheme on which we are advising the Client.  If you have been given access to this website by mistake or suspect that an unauthorised person knows your password, you must inform us promptly at visualisesupport@lcp.uk.com.</p>
    <p>You may use this website only as part of your role in connection with the Scheme as permitted by the Client and for no other purpose.</p>
    <p>We may update, modify, restrict access to or close this website.  We do not guarantee uninterrupted use of this website or accept liability for any access delays or interruptions.</p>

    <h3>Definitions</h3>

    <ul>
        <li><strong>"Client"</strong> means the trustees or the sponsoring employer who we are advising in connection with a pension scheme (the <strong>"Scheme"</strong>).</li>
        <li>References to <strong>"we"</strong>, <strong>"us"</strong>, <strong>"our"</strong> or <strong>"LCP"</strong> are to Lane Clark &amp; Peacock LLP.</li>
        <li>References to <strong>"you"</strong> or <strong>"your"</strong> are to a user of this website.</li>
    </ul>

    <h3>Information about us</h3>

    <p>This website is owned and operated by LCP, a limited liability partnership registered in England and Wales with registered number OC301436 and VAT number GB 442 1624 24.  All partners are members of LCP.  A list of members' names is available for inspection at 95 Wigmore Street, London W1U 1DQ, the firm's principal place of business and registered office.</p>
    <p>We are authorised and regulated by the Financial Conduct Authority (FCA).  You can find details of our authorisation on the Financial Services Register which can be accessed via the FCA website at www.register.fca.org.uk.  We are also licensed by the Institute and Faculty of Actuaries (IFoA) for regulated activities to business clients where these are complementary to or arise out of the professional services we provide to you.</p>

    <h3>Intellectual Property Rights</h3>

    <p>We are the owner or the licensee of all Intellectual Property Rights in the website and the Content.  Client Data is owned by the Client (or its licensors).  If applicable, you grant to us on behalf of the Client a non-exclusive, royalty-free worldwide licence to publish or otherwise use Client Data for the purposes of providing this website.</p>
    <p>For these purposes:</p>

    <ul>
        <li><strong>"Client Data"</strong> means all data and other materials used on this website that are provided to us by, or on behalf of, the Client;</li>
        <li><strong>"Content"</strong> means any text, software, database, format, graphic, program code, calculation, formula, model, chart and written work and all other materials developed by, or on behalf of, us which form part of this website; and</li>
        <li><strong>"Intellectual Property Rights"</strong> means:
            <ul>
                <li>copyright, patents, database rights and rights in trademarks, designs, know-how and confidential information (whether registered or not);</li>
                <li>applications for registration and the right to apply for registration for any of these rights; and</li>
                <li>all other intellectual property rights and equivalent or similar forms of protection existing anywhere in the world.</li>
            </ul>
        </li>
    </ul>

    <h3>Online privacy</h3>

    <p>Click <router-link to="/privacy">here</router-link> to access our privacy policy, which sets out the basis on which we will use your personal data in connection with your use of this website.  By accepting these terms and conditions or continuing to use this website, you agree to our privacy policy and our use of cookies.</p>

    <h3>Viruses and security</h3>

    <p>We do not guarantee that this website will be free from bugs or viruses.  You must use your own virus protection software.  We do not accept liability for any damage or loss caused by:</p>

    <ul>
        <li>any virus, malicious code or software, defect or malfunction in connection with use of this website; or</li>
        <li>any breach of security or unauthorised use of the website arising from hacking or otherwise.</li>
    </ul>

    <p>You must not misuse this website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful.</p>

    <h3>Third party links</h3>

    <p>Where this website contains links to other websites and resources provided by third parties, these links are provided for your information only.  We do not endorse any of these websites or accept responsibility for their contents.</p>

    <h3>No conditions, warranties or representations</h3>

    <p>To the extent permitted by law, we exclude all conditions, warranties and representations in respect of this website and its content (whether express or implied).  This does not exclude or limit our duties to the Client in respect of advice that we provide under the terms of our appointment with the Client.</p>

    <h3>Other matters</h3>

    <p>Nothing in these terms and conditions excludes or limits our liability for fraud or reckless disregard of professional obligations or for death or personal injury arising from our negligence or that of our partners, consultants or employees.</p>
    <p>If any of these terms and conditions are held by any judicial or other competent authority to be void, voidable, illegal or otherwise unenforceable the remaining terms and conditions shall remain in full force and effect.</p>
    <p>Any temporary or permanent waiver on our part of any of the terms and conditions shall not affect our right subsequently to enforce all the terms and conditions.</p>
    <p>None of these terms and conditions is intended to be enforceable pursuant to the Contracts (Rights of Third Parties) Act 1999.  Accordingly no third party shall have any right to enforce or rely on any of these terms and conditions.</p>

    <h3>Governing law</h3>

    <p>These terms and conditions shall be governed by and construed in accordance with English law, and we and you each submit to the exclusive jurisdiction of the English Courts.</p>
</div>

</template>

<script>

export default {
    getTermsDate () {
        /* Note that Month must be -1 for this format i.e. June = 5 */
        return new Date(2022, 8, 28, 0, 0, 0);
    }
};

</script>
