<template>
    <div v-if="pageAvailable">
        <div v-if="!childScheme" class="row">
            <div id="mainCard" class="col-md-12 framed">
                <div class="card">
                    <toggle-header :tabs="tabs" :viewOverride="currentView" :dropdownTitle="getDropdownTitle()" @currentView="onCurrentViewChange"/>
                    <lcp-table v-if="currentView === summaryView" class="LCPcontent" :config="dynamicOutput.currentResult.headerResultsTable" :id="summaryView"/>
                    <lcp-table v-if="dynamicOutput.currentResult.accountingResultsTable !== null && currentView == accountingView" class="LCPcontent" :config="dynamicOutput.currentResult.accountingResultsTable" :id="accountingView"/>
                </div>
            </div>
        </div>
        <deriskingPage v-else-if="childScheme" :configProp="childSchemeConfig" :inputControlsProp="inputs" :dynamicOutputProp="dynamicOutput.currentResult" :dropdownTitle="getDropdownTitle()"/>
    </div>
    <div v-else id="mainCard" class="col-md-12 framed">
        <div class="card">
            <div class="card-header">
                <h1>{{header}}</h1>
            </div>
            <vue-markdown class="LCPcontent" :source="pageNotAvailableText"/>
        </div>
    </div>
</template>

<script>

import deriskingPage from '@/components/pages/schemeDisplays/derisking/pageDerisking.vue';

export default {
    data () {
        return {
            currentView: null,
            config: null,
            currentChildScheme: null
        };
    },
    components: {
        deriskingPage
    },
    created () {
        this.summaryView = 'Summary';
        this.accountingView = 'Accounting';

        this.header = 'Derisking';
        this.pageAvailable = this.$staticStore.state.derisking.pageAvailable;
        this.pageNotAvailableText = this.$staticStore.state.derisking.pageNotAvailableText;

        this.config = this.$staticStore.state.derisking.config;
        this.tabs = this.getTabs();

        this.inputs = this.$staticStore.state.derisking.inputControls;

        this.currentView = this.$store.state.derisking.selectedTabConsolidated != null ? this.$store.state.derisking.selectedTabConsolidated : null;
    },
    computed: {
        dynamicOutput () {
            return this.$store.state.derisking.dynamicOutput;
        },
        childScheme () {
            return this.dynamicOutput.isChildScheme;
        },
        childSchemeConfig () {
            if (this.childScheme) return this.config.childSchemeConfigs[this.dynamicOutput.schemeId];
            return null;
        }
    },
    methods: {
        onCurrentViewChange (view) {
            this.currentView = view;
            this.$store.state.derisking.selectedTabConsolidated = this.currentView;
        },
        getTabs () {
            const output = [];
            output.push(this.summaryView);
            if (this.config.includeAccounting === true) output.push(this.accountingView);
            return output;
        },
        getDropdownTitle () {
            return {
                prefix: 'Derisking - ',
                inputTemplate: this.inputs[this.config.deriskingConsolidatedSchemeChoiceId]
            };
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
