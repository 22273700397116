<template>

<div>
    <vue-markdown class="LCPcontent" v-if="config.shockPageTop" :source="config.shockPageTop"/>
    <lcp-table class="LCPcontent" :config="tableConfig"/>
    <vue-markdown class="LCPcontent" v-if="config.shockPageBottom" :source="config.shockPageBottom"/>
</div>

</template>

<script>

import table from '@/components/common/tables/table.vue';

const InterestRateShockKey = 'Interest rate shock';
const InflationShockKey = 'Inflation rate shock';
const CollateralPreShockKey = 'Collateral (pre-shock)';
const CollateralPostShockKey = 'Collateral (post-shock)';

export default {
    props: ['config', 'dynamicResult'],
    computed: {
        headerRow () {
            const headers = Object.keys(this.config.definitionsByScenario);
            headers.unshift('');
            return table.getRowFromValues(headers);
        },
        cellContentByRowId () {
            const interestRatesRow = [InterestRateShockKey];
            const inflationRatesRow = [InflationShockKey];
            const collateralPreShockRow = [CollateralPreShockKey];
            const collateralPostShockRow = [CollateralPostShockKey];

            for (const key in this.config.definitionsByScenario) {
                const item = this.config.definitionsByScenario[key];
                const resultForScenario = this.dynamicResult.resultsByScenario[key];

                interestRatesRow.push(item.interestShock.toPercentPaString(2));
                inflationRatesRow.push(item.inflationShock.toPercentPaString(2));
                collateralPreShockRow.push(resultForScenario.collateralPreShock.toAmountString());
                collateralPostShockRow.push(resultForScenario.collateralPostShock.toAmountString());
            }

            const output = {};
            output[InterestRateShockKey] = table.getRowFromValues(interestRatesRow);
            output[InflationShockKey] = table.getRowFromValues(inflationRatesRow);
            output[CollateralPreShockKey] = table.getRowFromValues(collateralPreShockRow, true);
            output[CollateralPostShockKey] = table.getRowFromValues(collateralPostShockRow, true);
            return output;
        },
        tableConfig () {
            return table.getTableConfig(this.headerRow, this.cellContentByRowId);
        }
    }
};

</script>
