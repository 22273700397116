export const responsiveCheck = {
    methods: {
        matchesResponsive (media) {
            let result = false;
            switch (media) {
            case 'lg':
                if (['lg', 'md', 'sm', 'xs'].indexOf(this.$mq) > -1) result = true;
                break;
            case 'md':
                if (['md', 'sm', 'xs'].indexOf(this.$mq) > -1) result = true;
                break;
            case 'sm':
                if (['sm', 'xs'].indexOf(this.$mq) > -1) result = true;
                break;
            case 'xs':
                if (['xs'].indexOf(this.$mq) > -1) result = true;
                break;
            default:
                result = false;
                break;
            }
            return result;
        }
    }
};
