<template>
<div class="body-adjustment">
    <div origin-left="true" v-masonry transition-duration="1s" item-selector=".item" column-width="#gridSizer" >
        <div id="gridSizer" class="col-md-2"/>
        <div v-masonry-tile v-for="(info,i) in infoArray" :key="i" class="col-md-4 col-sm-12 item">
            <div class="card">
                <div class="card-header">
                    <h1>{{info.name}}</h1>
                </div>
                <div class="card-content">
                    <vue-markdown :source="info.description"/>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    created () {
        this.infoArray = this.$staticStore.state.information.config.descriptions.sort(function (a, b) {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.card {
    margin-bottom: 15px;
}

.body-adjustment {
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.item {
    padding-left:7.5px!important;
    padding-right:7.5px!important;
}

.card-content {
    padding-top:1rem;
}

</style>
