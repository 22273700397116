<template>
    <div>
        <div class="row" style="margin: 0 15px 0 15px">
            <div class="col-xs-6 seriesTitle">Current</div>
            <div class="col-xs-6 seriesTitle">Chosen</div>
        </div>
        <highchart :options="chartOptions" :series-options="chartSeriesPie" :showXsChart="true" key="assetPieChart"/>
    </div>
</template>

<script>

import investmentChartSettings from '@/components/common/tiles/investmentChartSettings.js';

const chartOverrides = {
    chart: {
        margin: [0, 0, 10, 0],
        height: '33%'
    },
    tooltip: {
        formatter () {
            return '<table><tr style=\'color:' + this.color + '\'><td style=\'padding-right: 10px;\'>' + this.key + ': </td><td style=\'text-align: right\'><b>' + this.y.toPercentString() + '</b></td></tr></table>';
        }
    }
};

export default {
    props: ['config', 'dynamicData'],
    created () {
        this.chartOptions = investmentChartSettings.chartOptions(chartOverrides);
    },
    computed: {
        chartSeriesPie () {
            const dict = {
                current: this.config.initialData,
                chosen: this.dynamicData
            };

            let currentEqualsChosen = true;
            Object.keys(dict.current).forEach(key => {
                const currentValue = dict.current[key];
                const chosenValue = dict.chosen[key];
                if (!currentValue.isSameAs(chosenValue)) {
                    currentEqualsChosen = false;
                }
            });

            dict.chosen.applyOpacity = currentEqualsChosen;

            return investmentChartSettings.chartSeriesMultiple(dict, this.config.colours);
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import 'src/assets/scss/base.scss';

.seriesTitle {
    font-weight: bold;
    text-align: center;
    @extend %seriesTitleFontSize;
    color: $ALTERNATIVE2;
}

</style>
