<template>
    <div class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <div class="card-header">
                    <dropdown-title :inputTemplate="input"/>
                </div>
                <highchart class="LCPcontent" :options="chartOptions" :series-options="chartSeries" :showXsChart="true"/>
                <lcp-table class="LCPcontent" :config="tableData"/>
            </div>
        </div>
    </div>
</template>

<script>
import lineChart from '@/components/common/charts/lineChart.js';
import table from '@/components/common/tables/table.vue';

export default {
    created () {
        const config = this.$staticStore.state.triggers.config;
        this.input = this.$staticStore.state.triggers.inputControls[config.availableTriggersId];
    },
    computed: {
        dynamicOutput () {
            return this.$store.state.triggers.dynamicOutput;
        },
        chartOptions () {
            const chart = lineChart.chartOptions(this.dynamicOutput.triggerMetricResult.data.scalingTypeString);
            chart.yAxis[0].title.text = chart.yAxis[0].title.text + this.dynamicOutput.axisSuffix;
            chart.legend.enabled = false;
            return chart;
        },
        chartSeries () {
            const triggerPointResults = this.dynamicOutput.triggerPointResults;
            const triggerMetricResult = this.dynamicOutput.triggerMetricResult;
            const endDate = new Date(triggerMetricResult.data.endDate);

            const output = [];

            for (let i = 0; i < triggerPointResults.length; i++) {
                const triggerPointResult = triggerPointResults[i];
                if (endDate < new Date(triggerPointResult.data.endDate)) continue;

                output.push(lineChart.getSeriesFromChartSeriesDataHistory(triggerPointResult));
            };

            output.push(lineChart.getSeriesFromChartSeriesDataHistory(triggerMetricResult));

            return output;
        },
        tableData () {
            const dynamicData = this.dynamicOutput;
            let headerRow;
            const cellContentByRow = {};
            if ((dynamicData.comparatorResults === true) && (dynamicData.triggerMetricResult.data.endDate !== dynamicData.comparatorDate)) {
                headerRow = table.getRowFromValues(['Trigger point', 'Status as at ' + dynamicData.triggerMetricResult.data.endDate.ToDateString(), 'Status as at ' + dynamicData.comparatorDate.ToDateString(), 'Active from']);
                    
                const bold = [false, true, false, false];

                for (const key in dynamicData.triggerPointResults) {
                    const triggerPointResult = dynamicData.triggerPointResults[key];
                    cellContentByRow[key] = this.getRowContentForResultWithComparator(triggerPointResult, bold);
                }
            } else {
                headerRow = table.getRowFromValues(['Trigger point', 'Status as at ' + dynamicData.triggerMetricResult.data.endDate.ToDateString(), 'Active from']);
                    
                for (const key in dynamicData.triggerPointResults) {
                    const triggerPointResult = dynamicData.triggerPointResults[key];
                    cellContentByRow[key] = this.getRowContentForResult(triggerPointResult);
                }
            }

            return table.getTableConfig(headerRow, cellContentByRow);
        }
    },
    methods: {
        getRowContentForResult (triggerPointResult) {
            return table.getRowFromValues([triggerPointResult.name, triggerPointResult.progressDescription, triggerPointResult.data.startDate.ToDateString()]);
        },
        getRowContentForResultWithComparator (triggerPointResult, bold) {
            return table.getRowFromValues([triggerPointResult.name, triggerPointResult.progressDescription, triggerPointResult.comparatorProgress, triggerPointResult.data.startDate.ToDateString()], bold);
        }
    }
};
</script>
