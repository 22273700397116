<template>
    <div class="row">
        <div id="mainCard" class="col-md-8 framed partialPageCardLeft">
            <div class="card">
                <div class="card-header">
                    <dropdown-title dropdownPrefix="Covenant - " :inputTemplate="inputs[sectionsKey]"/>
                </div>
                <highchart class="LCPcontent" :options="chartOptions" :series-options="chartSeries" :loading-text="watermarkText" :showXsChart="true"/>
                <vue-markdown class="LCPcontent" v-if="description" :source="description"/>
            </div>
        </div>
        <div class="col-md-4 partialPageCardRight">
            <div class="card">
                <div class="card-header">
                    <h1>Display options</h1>
                </div>
                <div class="col-md-12 input-container">
                    <lcp-button v-for="(scenario, key, i) in dynamicOutput" :key="i" :id="key" :text="key" :handleClick="setScenario" :active="activeScenario === key"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import baseChart from '@/components/common/charts/chartSettings.js';

export default {
    data () {
        return {
            activeScenario: null,
            chartSeries: []
        };
    },
    created () {
        const config = this.$staticStore.state.covenantmonitoring.config;
        this.inputs = this.$staticStore.state.covenantmonitoring.inputControls;
        this.sectionsKey = config.sectionsKey;
        this.watermarkText = config.exampleData ? 'example data' : null;
        this.updateActiveScenario();
    },
    methods: {
        setScenario (scenario) {
            this.activeScenario = scenario;
        },
        updateActiveScenario () {
            const keys = Object.keys(this.dynamicOutput);
            this.setScenario(keys[0]);
        }
    },
    computed: {
        dynamicOutput () {
            return this.$store.state.covenantmonitoring.dynamicOutput;
        },
        chartOptions () {
            if (this.activeScenario == null) return baseChart.getChartOptions();
            return window.LCP.fn.parseFunctionOnly(baseChart.getChartOptions(this.dynamicOutput[this.activeScenario].chartData));
        },
        description () {
            const description = this.dynamicOutput[this.activeScenario].description;
            return description != null ? description : '';
        }
    },
    watch: {
        dynamicOutput () {
            this.updateActiveScenario();
        }
    }
};

</script>
