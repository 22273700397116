import Vue from 'vue';
import Router from 'vue-router';
import dashboard from '@/components/pages/schemeDisplays/dashboard/pageDashboard';
import sensitivities from '@/components/pages/schemeDisplays/sensitivities/pageSensitivities';
import investmentstrategy from '@/components/pages/schemeDisplays/investmentStrategy/pageInvestmentStrategy';
import recoveryPlan from '@/components/pages/schemeDisplays/recoveryPlan/pageRecoveryPlan';
import historyandexperience from '@/components/pages/schemeDisplays/fundingHistory/pageFundingHistory';
import triggers from '@/components/pages/schemeDisplays/triggers/pageTriggers';
import insurerPricing from '@/components/pages/schemeDisplays/insurerPricing/pageInsurerPricing';
import cashflows from '@/components/pages/schemeDisplays/cashflows/pageCashflows';
import irm from '@/components/pages/schemeDisplays/irm/pageIrm';
import accounting from '@/components/pages/schemeDisplays/accounting/pageAccounting';
import resultssummary from '@/components/pages/schemeDisplays/resultsSummary/pageResultsSummary';
import recoveryrequirementsbyscheme from '@/components/pages/schemeDisplays/recoveryRequirementsByScheme/pageRecoveryRequirementsByScheme';
import marketdata from '@/components/pages/schemeDisplays/marketPerformance/pageMarketPerformance';
import reporting from '@/components/pages/schemeDisplays/reporting/pageReporting';
import contacts from '@/components/pages/schemeDisplays/contacts/pageContacts';
import schemeDisplay from '@/components/pages/schemeDisplays/page';
import userSchemes from '@/components/pages/schemes/pageUserSchemes';
import manageSchemes from '@/components/common/uiLayouts/manageSchemes.vue';
import _404 from '@/components/pages/404/page404';
import _500 from '@/components/pages/500/page500';
import error from '@/components/pages/schemeDisplays/error/pageError';
import information from '@/components/pages/schemeDisplays/information/pageInformation';
import landing from '@/components/pages/landingPage/';
import termsLanding from '@/components/pages/legal/termsLanding';
import privacyLanding from '@/components/pages/legal/privacyLanding';
import termsInScheme from '@/components/pages/legal/termsInScheme';
import privacyInScheme from '@/components/pages/legal/privacyInScheme';
import swal from '@/components/common/swalWrapper.js';
import store from '@/store/index.js';
import localStorageManager from '@/store/localStorageManager.js';
import axios from 'axios';
import { auth } from '@/services/authentication.js';

Vue.use(Router);

const schemePages = [
    {
        path: 'dashboard',
        component: dashboard,
        meta: {
            storeCallPre: 'loadPage',
            name: 'dashboard'
        }
    },
    {
        path: 'sensitivities/:pagetype?',
        component: sensitivities,
        meta: {
            storeCallPre: 'loadPage',
            name: 'sensitivities'
        }
    },
    {
        path: 'investmentstrategy',
        component: investmentstrategy,
        meta: {
            storeCallPre: 'loadPage',
            name: 'investmentstrategy'
        }
    },
    {
        path: 'recoveryplan',
        component: recoveryPlan,
        meta: {
            storeCallPre: 'loadPage',
            name: 'recoveryplan'
        }
    },
    {
        path: 'cashflows',
        component: cashflows,
        meta: {
            storeCallPre: 'loadPage',
            name: 'cashflows'
        }
    },
    {
        path: 'historyandexperience',
        component: historyandexperience,
        meta: {
            storeCallPre: 'loadPage',
            name: 'historyandexperience'
        }
    },
    {
        path: 'marketdata',
        component: marketdata,
        meta: {
            storeCallPre: 'loadPage',
            name: 'marketdata'
        }
    },
    {
        path: 'error',
        component: error,
        meta: {
            storeCallPre: 'loadPage',
            name: 'error'
        }
    },
    {
        path: 'information',
        component: information,
        meta: {
            storeCallPre: 'loadPage',
            name: 'information'
        }
    },
    {
        path: 'triggers',
        component: triggers,
        meta: {
            storeCallPre: 'loadPage',
            name: 'triggers'
        }
    },
    {
        path: 'irm',
        component: irm,
        meta: {
            storeCallPre: 'loadPage',
            name: 'irm'
        }
    },
    {
        path: 'insurerpricing',
        component: insurerPricing,
        meta: {
            storeCallPre: 'loadPage',
            name: 'insurerpricing'
        }
    },
    {
        path: 'accounting',
        component: accounting,
        meta: {
            storeCallPre: 'loadPage',
            name: 'accounting'
        }
    },
    {
        path: 'resultssummary',
        component: resultssummary,
        meta: {
            storeCallPre: 'loadPage',
            name: 'resultssummary'
        }
    },
    {
        path: 'recoveryrequirementsbyscheme',
        component: recoveryrequirementsbyscheme,
        meta: {
            storeCallPre: 'loadPage',
            name: 'recoveryrequirementsbyscheme'
        }
    },
    {
        path: 'reporting',
        component: reporting,
        meta: {
            storeCallPre: 'loadPage',
            name: 'reporting'
        }
    },
    {
        path: 'contacts',
        component: contacts,
        meta: {
            storeCallPre: 'loadPage',
            name: 'contacts'
        }
    },
    {
        path: 'terms',
        component: termsInScheme,
        meta: {
            storeCallPre: 'loadPage',
            name: 'terms'
        }
    },
    {
        path: 'privacy',
        component: privacyInScheme,
        meta: {
            storeCallPre: 'loadPage',
            name: 'privacy'
        }
    },
    {
        path: ':pageName?',
        meta: {
            storeCallPre: 'loadPage',
            name: 'default'
        }
    },
    {
        path: ':pageName?/:pagetype?',
        meta: {
            storeCallPre: 'loadPage',
            name: 'default'
        }
    }
];

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: 'landing'
        },
        {
            path: '/landing',
            name: 'landing',
            component: landing
        },
        {
            path: '/landinglcp',
            name: 'landinglcp',
            redirect: {
                name: 'landing',
                query: 'lcp'
            }
        },
        {
            path: '/landinglocal',
            name: 'landinglocal',
            redirect: {
                name: 'landing',
                query: 'local'
            }
        },
        {
            path: '/userschemes',
            name: 'userschemes',
            component: userSchemes,
            meta: {
                storeCallPre: 'loadSession'
            }
        },
        {
            path: '/manageschemes',
            name: 'manageschemes',
            component: manageSchemes,
            meta: {
                storeCallPre: 'loadInternalSessionData'
            }
        },
        {
            path: '/:schemeid/:fileSystem/:calculationdate',
            component: schemeDisplay,
            name: 'schemeRoute',
            children: schemePages,
            meta: {
                storeCallPre: 'loadPage'
            }
        },
        {
            path: '/404',
            name: '404',
            component: _404
        },
        {
            path: '/500',
            name: '500',
            component: _500,
            props: true
        },
        {
            path: '/privacy',
            component: privacyLanding,
            name: 'privacy'
        },
        {
            path: '/terms',
            component: termsLanding,
            name: 'terms'
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.redirectedFrom != null && to.redirectedFrom.includes('#state')) return;

    swal.close();
    if (to.matched.length !== 0 && to.matched[0].name !== 'schemeRoute') store.commit('wipeSchemeInfo');

    const requestedQuery = typeof to.query === 'string' ? to.query : Object.assign({}, to.query);

    if (auth.online()) {
        if (!store.getters.getAuthState) {
            const b2cAuthResponse = auth.getValidB2CAuthResponse();
            if (b2cAuthResponse != null) {
                store.commit('setAuthState', b2cAuthResponse);
                axios.defaults.headers.common.Authorization = 'Bearer ' + b2cAuthResponse.access_token;
            }
        }

        if (to.name === 'landing') {
            const directRoute = localStorageManager.getDirectRoute();
            if (directRoute) router.push(directRoute);
            else router.push('/userschemes');
            return;
        }
    } else {
        let authInitString = null;

        if (typeof requestedQuery === 'string') {
            authInitString = requestedQuery;
        } else if (requestedQuery != null) {
            authInitString = requestedQuery.ath;
            localStorageManager.storeDirectRoute(to.path);
        }

        if (authInitString != null) {
            auth.init(authInitString);
            auth.signIn();
            return;
        }
    }

    if (requestedQuery) delete requestedQuery.ath;

    localStorageManager.clearDirectRoute();

    let storedQuery;
    if (Object.keys(requestedQuery).length !== 0) {
        store.commit('setStoredQuery', requestedQuery);
        router.push({ path: to.path, query: {} });
        return;
    } else {
        storedQuery = store.getters.getStoredQuery;
        store.commit('setStoredQuery', null);
    }

    if (to.meta.name === undefined && to.name == null) {
        next('/landing');
    } else if (to.meta.storeCallPre !== undefined) {
        const requestedSession = {
            sessionId: store.state.session.sessionId,
            fileSystem: to.params.fileSystem,
            schemeId: to.params.schemeid,
            calculationDate: to.params.calculationdate && to.params.calculationdate !== 'null' ? to.params.calculationdate.FromUrlSafeDateString() : null,
            pageName: to.meta.name,
            pageType: to.params.pagetype ? to.params.pagetype : null,
            pageParams: storedQuery
        };

        const localStore = store;

        store.dispatch(to.meta.storeCallPre, requestedSession)
            .then(() => {
                next();
            })
            .catch((error) => {
                if (error.response && error.response.data === 'UserSessionNotFound') {
                    localStorageManager.clearSession();
                    next(to.fullPath);
                } else if (error === 'CancelledToken') {
                    next();
                } else if (error.response && (error.response.data.type === 'SchemeTimeStampChange')) {
                    swal.showRefreshUrl({ text: error.response.data.schemeMessage });
                } else if (error.response && (error.response.data === 'FileSystemNotFound' || error.response.data === 'SchemeNotFound')) {
                    if (localStore.state.session.user.isLcp) next('/manageSchemes');
                    else next('/userSchemes');
                } else if (error.response && (error.response.status === 401 || error.StatusCode === 401)) {
                    localStorageManager.storeDirectRoute(to.path);
                    window.location = process.env.VUE_APP_SITEBASE_URL;
                } else {
                    next('/500');
                }
            });
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    store.commit('setReady');
});

export default router;
