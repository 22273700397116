<template>
    <div class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <div class="card-header">
                    <h1>Integrated risk overview</h1>
                </div>
                <vue-markdown class="LCPcontent" v-if="description" :source="description"/>
                <highchart class="LCPcontent" :options="chartOptions" :series-options="chartSeries" :showXsChart="true"/>
            </div>
        </div>
    </div>
</template>

<script>
import lineChart from '@/components/common/charts/lineChart.js';

const chartOptionsOverrides = {
    plotOptions: {
        line: {
            enableMouseTracking: false
        }
    },
    tooltip: {
        enabled: false
    },
    xAxis: [{
        title: {
            text: ''
        }
    }]
};

export default {
    created () {
        const config = this.$staticStore.state.irm.config;
        this.description = config.description;
        this.chartOptions = lineChart.chartOptions(window.LCP.con.AMOUNT, chartOptionsOverrides);
        this.chartSeries = this.getChartSeries(config);
    },
    methods: {
        getChartSeries (config) {
            let output = [];
            if (config.covenantMetrics !== null) output = lineChart.getSeriesFromChartSeriesDataMultipleProjectedXyCollection(config.covenantMetrics, true);

            output.push(lineChart.getSeriesFromChartSeriesDataHistory(config.deficitHistory));
            output.push(this.getDeficitProjectionSeries(config.deficitProjection));
            output.push(this.getVarProjectionSeries(config.varProjection, false));
            output.push(this.getVarProjectionSeries(config.varProjection, true));
            return output;
        },
        getCovenantMetricSeries (covenantMetricData) {
            const output = [];

            for (let j = 0; j < covenantMetricData.data.length; j++) {
                const metricData = covenantMetricData.data[j];
                const series = lineChart.getSeriesFromXYData(covenantMetricData.name, covenantMetricData.colourString, metricData.xValues, metricData.yValues, true);
                if (j !== 0) {
                    series.name = series.name + '_' + j;
                    series.showInLegend = false;
                }
                output.push(series);
            };

            return output;
        },
        getDeficitProjectionSeries (deficitProjectionData) {
            const series = lineChart.getSeriesFromXYData(deficitProjectionData.name, deficitProjectionData.colourString, deficitProjectionData.data.xValues, deficitProjectionData.data.yValues, true);
            series.dashStyle = 'dash';
            return series;
        },
        getVarProjectionSeries (varProjectionData, lower) {
            const data = lower ? varProjectionData.lower : varProjectionData.upper;
            const series = lineChart.getSeriesFromXYData(varProjectionData.name, varProjectionData.colourString, data.xValues, data.yValues, true);
            series.showInLegend = !lower;
            return series;
        }
    }
};
</script>
