<template>

<div class="row">
    <div id="mainCard" class="col-md-12 framed">
        <div class="card">
            <div class="card-header">
                <h1>Reporting</h1>
            </div>
            <div class="col-md-12 LCPcontent">
                <div v-for="(report, index) in availableReports" :key="index">
                    <download-report :btnText="report" :reportType="report"/>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>

export default {
    created () {
        this.availableReports = this.$staticStore.state.reporting.config.availableReports;
    }
};

</script>
