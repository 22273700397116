<template>

<div>
    <derisking-Table :lockTableData="lockTableData" :tableConfig="dynamicResult.headlineResultsTable"/>
    <risk-attribution-chart class="LCPcontent" :config="config.attributionConfig" :initialData="config.attributionInitialData" :dynamicData="dynamicResult.riskAttributionResult"/>
    <vue-markdown class="LCPcontent" v-if="config.description" :source="config.description"/>
</div>

</template>

<script>

import deriskingTable from './deriskingTable.vue';
import riskAttributionChart from '@/components/common/charts/riskAttributionChart.vue';

export default {
    props: ['lockTableData', 'config', 'dynamicResult'],
    components: {
        deriskingTable,
        riskAttributionChart
    }
};

</script>
