
<template>

<div id="loaderWrapper">
    <div class="centralised">
        <img class="animatedLogo" v-bind:src="logo"/>
        <h1 class="schemeTitle">{{ loadingText }}</h1>
    </div>
</div>

</template>

<script>

import logo from '@/assets/svg/visualise_animated.svg';

export default {
    data () {
        return {
            logo
        };
    },
    computed: {
        loadingText () {
            let text = '';

            if (this.$store.state.scheme.name) text = text + this.$store.state.scheme.name + ' ';
            text = text + 'loading...';

            return text;
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import 'src/assets/scss/base.scss';

#loaderWrapper{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: $bodyBg;
    .centralised {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .animatedLogo{
            text-align: center;
            width: 750px;
            @include responsive('sm') {
                width: 300px;
            }
        }
        .schemeTitle{
            text-align: center;
        }
    }
}

</style>
