<template>

<div>
    <div class="messageRow" :style="{'background': message.backColour, 'color': message.foreColour}" v-for="(message, index) in navMessages" :key="index">
        <div v-if="message.message" class="message">{{message.message}}</div>
        <div class="actionGroup">
            <lcp-button v-for="(action, key) in message.actions" :key="key" class="action" :text="action.label" :handleClick="() => $store.dispatch(`${action.storeAction}`, message.id).then(() => {}).catch(() => {})"/>
        </div>
    </div>
    <div class="headerRow">
        <dropdown-icon class="showMobile" :class="{'hasNoScheme': schemeName == null}" tool-tip="Show navigation" icon="menu" :actionIfNoSubItems="toggleMenu"/>
        <div class="middle">
            <div class="logoWrapper">
                <img :src="logo"/>
            </div>
            <div v-if="schemeName" class="schemeName documenta">
                <div><span>{{schemeName}}</span></div>
                <date-picker :inputTemplate="datePickerInputTemplate" :valueOverride="calculationDate" @currentvalue="dateChanged"/>
            </div>
        </div>
        <div v-show="$store.getters.getAuthState"  :class="{'hasNoAuth': $store.getters.getAuthState == null}"  class="user">
            <dropdown-icon class="hideMobile" v-if="showBackToSchemes & termsUpToDate" tool-tip="Back to schemes" icon="home" :actionIfNoSubItems="backToSchemes"/>
            <dropdown-icon class="hideMobile"  v-if="lcpUser & termsUpToDate" tool-tip="Go to scheme management" icon="settings" :actionIfNoSubItems="backToManageSchemes"/>
            <dropdown-icon class="hideMobile" v-if="termsUpToDate" icon="info" :subItems="infoDropdown"/>
            <dropdown-icon class="logout" v-if="user != null" tool-tip="Logout" icon="Logout" :actionIfNoSubItems="signOut"/>
        </div>
    </div>
</div>

</template>

<script>

import datePicker from '@/components/common/uiInputs/datepicker.vue';
import standardLogo from '@/assets/images/logo_LCPVisualise.png';
import prideLogo from '@/assets/images/LCP Visualise_PRIDE.svg';
import dropdownIcon from '../uiInputs/dropdownIcon.vue';

export default {
    components: {
        datePicker,
        dropdownIcon
    },
    data () {
        return {
            standardLogo,
            prideLogo
        };
    },
    created () {
        this.logo = this.getLogo();
        this.datePickerInputTemplate = datePicker.getDefaultInputTemplate('DatePicker', null, this.$store.state.scheme.maxCalcDateAllowed, this.$store.state.scheme.minCalcDateAllowed, this.calculationDate, null, null, 'none');
    },
    computed: {
        user () {
            return this.$store.state.session.user;
        },
        termsUpToDate () {
            return this.$store.getters.termsUpToDate;
        },
        navMessages () {
            return this.$store.state.scheme.headerMessages;
        },
        schemeName () {
            return this.$store.state.scheme.name;
        },
        calculationDate () {
            return this.$store.state.scheme.calculationDate;
        },
        lcpUser () {
            return this.user.isLcp;
        },
        showBackToSchemes () {
            if (this.lcpUser) return true;

            const liveSchemeDictionary = this.$store.getters.getLiveSchemeDictionary;
            return Object.keys(liveSchemeDictionary).length !== 1;
        },
        infoDropdown () {
            let r = [];
            if (this.schemeName) {
                r = [
                    { text: 'Key information', action: () => { this.routeToSchemePage('information'); } },
                    { text: 'Contacts', action: () => { this.routeToSchemePage('contacts'); } },
                    { text: 'Terms', action: () => { this.routeToSchemePage('terms'); } },
                    { text: 'Privacy', action: () => { this.routeToSchemePage('privacy'); } }
                ];
            } else {
                r = [
                    { text: 'Terms', action: () => { this.$router.push('terms'); } },
                    { text: 'Privacy', action: () => { this.$router.push('privacy'); } }
                ];
            }
            return r;
        }
    },
    methods: {
        getLogo () {
            if (1 + new Date().getMonth() === 6) return this.prideLogo;
            return this.standardLogo;
        },
        toggleMenu () {
            this.$store.commit('toggleMenu');
        },
        backToSchemes () {
            this.$router.push('/userschemes');
        },
        backToManageSchemes () {
            this.$router.push('/manageschemes');
        },
        routeToSchemePage (route) {
            this.$store.dispatch('routeToSchemePage', route).then(() => {}).catch(() => {});
        },
        signOut () {
            this.$store.dispatch('signout');
        },
        dateChanged (val) {
            this.$store.dispatch('updateDate', val).then(() => {}).catch(() => {});
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import 'src/assets/scss/all.scss';

.messageRow {
    text-align: center;

    .message {
        display: inline-block;
        padding: 4px;
    }

    .actionGroup {
        display: inline-block;

        .action {
            display: inline-block;
            overflow: inherit;
            padding-top: 1px;
            padding-bottom: 1px;
            margin-left: 6px;
            margin-right: 6px;
        }

        @include responsive('sm') {
            display: none;
        }
    }
}

.headerRow {
    background-color: white;
    box-shadow: $boxShadow;
    font-size: $fontSizeHeader;
    padding: 15px 0px;
    position: relative;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    z-index:1;
    @include responsive('md') {
        align-items: center;
        padding: 0;
        width: 100%;
        font-size: $fontSizeHeaderSm;
    }
    .middle {
        display: flex;
        @include responsive('md') {
            display: flex;
            flex-direction: column;
        }
        .logoWrapper {
            float: left;
            margin: -8px 20px;
            img {
                height: 40px;
            }
            @include responsive('md') {
                float: unset;
                height: 30px;
                margin: 0;
                img {
                    float: none;
                    height: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                }
            }
        }
    }

    .showMobile {
        display:none;
    }

    @include responsive('md') {
        .hideMobile {
            display: none;
        }
        .showMobile {
            display: block;
            &.hasNoScheme {
                visibility: hidden;
            }
        }
        .hasNoAuth{
            display: block !important;
            visibility: hidden;
        }
    }

    .schemeName {
        display:flex;
        @include responsive('md') {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        @include responsive('xs') {
            flex-direction: column;
            >div{
                text-align: center;
            }
        }

        .inputMargin {
            margin-bottom: 0px;
            @include responsive('mdUp') {
                margin-left: 20px;
            }
        }
    }

    .user {
        display: flex;
        padding-left: 20px;
        @include responsive('md') {
            margin-top: 0px;
        }
        >div {
            float: left;
        }
    }
}

</style>
