<template>
    <div class="row">
        <div id="mainCard" class="col-md-8 framed partialPageCardLeft">
            <div class="card">
                <div class="card-header">
                    <dropdown-title :inputTemplate="fundingMeasureDropdownInput" @currentvalue="routeToFundingMeasure"/>
                </div>
                <lcp-table class="LCPcontent" :config="assetLiabilityTableData"/>
                <highchart class="LCPcontent" :options="chartOptions" :series-options="chartSeries" key="sensitivityChart"/>
                <vue-markdown class="LCPcontent" v-show="description" :source="description"/>
                <div class="LCPcontent" v-if="scenarioId === config.climateScenarioId">
                    Climate risk may occur over longer time horizons than your usual risk metrics consider, but could affect the scheme very rapidly at any point and could dwarf other risks.
                    This scenario shows the potential impact of climate risk on the funding position over the longer term (eg through a rapid “pricing in” as asset prices respond to sudden changes in perceptions of climate transition and physical risks, and where economic norms no longer apply).
                    It is hard to estimate when the risks might crystallise, but recent events suggest that climate impacts are unfolding more quickly than previously expected. These risks cannot be diversified away, so to avoid them we would need to limit climate change.
                    Please see <a href="https://www.lcp.com/media/wmapxk2p/ri-policy-asks-for-clients.pdf" target="_blank">here</a> to see how LCP is using its influence to do this, and how you can add your support to this.
                </div>
            </div>
        </div>
        <div class="col-md-4 partialPageCardRight">
            <div class="card">
                <div class="card-header-with-action">
                    <dropdown-title dropdownPrefix="Assumptions - " :inputTemplate="scenarioDropdownInput" :value-override="scenarioId" @currentvalue="scenarioUpdated"/>
                    <reset-inputs/>
                </div>
                <div class="col-md-12 input-container">
                    <input-wrapper :inputTemplate="input" @currentvalue="inputsChanged" :value-override="inputOverrides(key)" :key="key" v-for="(input, key) in inputs"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import table from '@/components/common/tables/table.vue';
import barChart from '@/components/common/charts/barChart.js';
import dropdownTitle from '@/components/common/uiInputs/dropdownTitle.vue';
import swal from '@/components/common/swalWrapper.js';
import localStorageManager from '@/store/localStorageManager.js';

const chartOptionsOverrides = {
    chart: {
        height: '300px'
    },
    legend: {
        enabled: false
    },
    tooltip: {
        formatter () {
            let s = '<b>' + this.x + '</b><table>';
            for (const n in this.points) {
                s += '<tr style=\'color:' + this.points[n].series.color + '\'><td style=\'padding-right: 10px;\'>' + this.points[n].series.name + ': </td><td style=\'text-align: right\'><b>' + this.points[n].point.y.toAmountString() + '</b></td></tr>';
            }
            s += '</table>';
            return s;
        },
        shared: true
    }
};

const assetsHeaderText = 'Assets';
const fundingLevelHeaderText = 'Funding level';
const whatIfHeaderText = 'What-if?';
const currentHeaderText = 'Current';
const customScenarioId = 'Custom';

function getChartSeries (name, colour, assetValue, liabilityValue) {
    return {
        name: name,
        color: colour,
        data: [assetValue, liabilityValue]
    };
}

function getHeaderRow (fundingTargetName) {
    return [
        table.getHeaderCellNoColour(''),
        table.getHeaderCellNoColour(assetsHeaderText),
        table.getHeaderCellNoColour(fundingTargetName),
        table.getHeaderCellNoColour(fundingLevelHeaderText)
    ];
}

function getResultRow (header, result, colour) {
    return [
        table.getRowCellWithColour(header, colour),
        table.getRowCellWithColour(result.assetValue.toAmountString(), colour),
        table.getRowCellWithColour(result.liabilityValue.toAmountString(), colour),
        table.getRowCellWithColour((result.assetValue / result.liabilityValue).toPercentString(), colour)
    ];
}

export default {
    data () {
        return {
            scenarioId: null
        };
    },
    created () {
        this.config = this.$staticStore.state.sensitivities.config;
        this.inputs = this.$staticStore.state.sensitivities.inputControls;

        this.tableHeader = getHeaderRow(this.config.fundingTargetName);
        this.currentTableRow = getResultRow(currentHeaderText, this.config.initialResult, this.config.currentColour);

        this.chartOptions = barChart.chartOptions(chartOptionsOverrides, window.LCP.con.AMOUNT);
        this.chartOptions.xAxis[0].categories = [assetsHeaderText, this.config.fundingTargetName];

        this.initialResultChartSeries = getChartSeries(currentHeaderText, this.config.currentColour, this.config.initialResult.assetValue, this.config.initialResult.liabilityValue);

        const fundingMeasureOptions = Object.keys(this.config.availableFundingMeasureDisplayNamesWithRoute);
        this.fundingMeasureDropdownInput = dropdownTitle.getDefaultInputTemplate('fundingMeasureDropdown', fundingMeasureOptions, this.config.fundingTargetName);

        const scenarioOptions = [customScenarioId].concat(Object.keys(this.config.profCoData));
        this.scenarioDropdownInput = dropdownTitle.getDefaultInputTemplate('scenarioDropdown', scenarioOptions, customScenarioId, customScenarioId);
    },
    mounted () {
        if (this.$store.state.sensitivities.selectedScenario === null) return;
        
        this.scenarioUpdated(this.$store.state.sensitivities.selectedScenario);
    },
    methods: {
        inputOverrides (key) {
            if (this.scenarioId === null || this.scenarioId === customScenarioId) return null;
            return this.config.profCoData[this.scenarioId].inputValues[key];
        },
        scenarioUpdated (val) {
            this.scenarioBeingUpdated = true;
            this.setScenarioId(val);

            if (this.scenarioId !== customScenarioId) this.$store.dispatch('updateDynamicOutput', this.config.profCoData[this.scenarioId]);

            this.$nextTick(() => {
                this.scenarioBeingUpdated = false;
            });
        },
        inputsChanged () {
            if (!this.scenarioBeingUpdated) this.setScenarioId(customScenarioId);

            if (localStorageManager.getStoredValue(localStorageManager.sensitivitiesPagePopUpStorageKey) === 'true') return;

            swal.showPopup({
                title: 'Integrated Risk Management',
                text: 'In addition to considering how different economic conditions affect the scheme\'s finances, it is also important to consider whether and how this could affect the sponsor\'s covenant.'
            });

            localStorageManager.storeValue(localStorageManager.sensitivitiesPagePopUpStorageKey, true);
        },
        setScenarioId (val) {
            this.scenarioId = this.$store.state.sensitivities.selectedScenario = val;
        },
        routeToFundingMeasure (val) {
            this.$store.dispatch('routeToSchemePage', { pageName: 'sensitivities', pageType: this.config.availableFundingMeasureDisplayNamesWithRoute[val] }).then(() => {}).catch(() => {});
        }
    },
    computed: {
        dynamicOutput () {
            return this.$store.state.sensitivities.dynamicOutput;
        },
        chartSeries () {
            return [
                getChartSeries(whatIfHeaderText, this.config.chosenColour, this.dynamicOutput.assetValue, this.dynamicOutput.liabilityValue),
                this.initialResultChartSeries
            ];
        },
        description () {
            if (!this.scenarioId || this.scenarioId === customScenarioId) return '';
            return this.config.profCoData[this.scenarioId].description;
        },
        assetLiabilityTableData () {
            const cellContentByRow = {};
            cellContentByRow.chosen = getResultRow(whatIfHeaderText, this.dynamicOutput, this.config.chosenColour);
            cellContentByRow.current = this.currentTableRow;

            return table.getTableConfig(this.tableHeader, cellContentByRow);
        }
    }
};

</script>
