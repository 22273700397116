<template>
    <div class="modalBg">
        <div class="editModal">
            <div class="modalHeader">
                {{headerText}}
                <div v-if="receivedData" class="closeModal" @click="$emit('close')">
                    <span class="icon icon-x-circle"/>
                </div>
            </div>
            <mini-loader v-if="!receivedData" class="loading"/>
            <div v-else>
                <div class="LCPcontent">
                    <table>
                        <tr>
                            <td><checkbox id="enableEmails" :inputTemplate="enableEmailsTemplate" v-on:toggled="updateEmailEnabled"/></td>
                        </tr>
                        <tr v-if="showReplyEmailAddress">
                            <td>Reply to email address</td>
                            <td>
                                <text-input
                                    id="emailAddressInput"
                                    :value="replyEmailAddress"
                                    displayType='none'
                                    v-on:valueUpdated="updateReplyEmailAddress"
                                    :textMode="true"
                                />
                            </td>
                            <td v-if="!validEmail" class="invalidEmail">Emails won't be sent without a valid email address</td>
                        </tr>
                    </table>
                </div>
                <radio :id="schemeName" :inputTemplate="getRadioInputTemplate()" @currentvalue="onUserSelect"/>
                <lcp-table v-if="tableData" class="LCPcontent" :config="tableData" @currentvalueAndId="updateUserMonthlyEmailFlags"/>
                <vue-markdown v-else class="LCPcontent" source="No client login history to show."/>
            </div>
        </div>
    </div>
</template>

<script>

import table from '@/components/common/tables/table.vue';
import radio from '@/components/common/uiInputs/radio.vue';
import checkbox from '@/components/common/uiInputs/checkbox.vue';
import textInput from '@/components/common/uiInputs/textInput.vue';
import miniLoader from '@/components/common/uiLayouts/miniLoader.vue';

const LcpLabel = 'LCP';
const ClientLabel = 'Client';

function getCellDefinition (value, width, inputTemplate) {
    const cell = table.getCellDefinition(value, null, null, null, inputTemplate);
    cell.styleOverrides = {
        width: width
    };
    return cell;
}

const widthByColumn = ['auto', '205px', '205px', '205px'];

export default {
    props: ['schemeInfo'],
    created () {
        this.schemeId = this.schemeInfo.id;
        this.schemeName = this.schemeInfo.name;
        
        this.$store.dispatch('getUserStats', this.schemeInfo.id).then(response => {
            this.showReplyEmailAddress = response.data.emailEnabled;
            this.enableEmailsTemplate = this.getCheckBoxInputTemplate('enableEmails', 'Enable email distribution', this.showReplyEmailAddress);
            this.replyEmailAddress = response.data.replyEmailAddress;
            this.loginList = response.data.userLatestStats;
            this.receivedData = true;
        });
    },
    data () {
        return {
            lcpLoginTable: false,
            showReplyEmailAddress: false,
            replyEmailAddress: null,
            receivedData: false
        };
    },
    components: {
        textInput,
        miniLoader
    },
    computed: {
        headerText () {
            let header = 'Login history and email functionality';
            if (this.schemeName) header += ' for ' + this.schemeName;
            return header;
        },
        tableData () {
            return this.getTableData(this.lcpLoginTable);
        },
        validEmail () {
            return window.LCP.fn.validateEmail(this.replyEmailAddress);
        }
    },
    methods: {
        onUserSelect (value) {
            this.lcpLoginTable = value === LcpLabel;
        },
        updateEmailEnabled  (value) {
            this.showReplyEmailAddress = value.state;
            if (this.validEmail) this.updateEmailStore(this.showReplyEmailAddress);
        },
        updateReplyEmailAddress (value) {
            this.replyEmailAddress = value;
            this.updateEmailStore(this.validEmail);
        },
        updateEmailStore (emailEnabledForStore) {
            this.$store.dispatch('updateSchemeEmailSettings', { schemeId: this.schemeId, emailEnabled: emailEnabledForStore, replyEmailAddress: this.replyEmailAddress });
        },
        updateUserMonthlyEmailFlags (value, id) {
            this.updateLoginData(value, id);
            this.$store.dispatch('updateUserMonthlyEmailFlag', { schemeId: this.schemeId, userEmailAddress: id, receiveMonthlyEmail: value });
        },
        updateLoginData (value, id) {
            const array = this.loginList;

            for (let i = 0; i < array.length; i++) {
                const login = array[i];
                if (login.emailAddress === id) {
                    login.receiveEmail = value;
                    break;
                }
            }
        },
        getTableData (lcpLoginTable) {
            const array = this.loginList;

            const cellContentByRow = {};

            for (let i = 0; i < array.length; i++) {
                const login = array[i];

                if (login.isLcp === lcpLoginTable) {
                    const row = [];
                    row.push(getCellDefinition(login.emailAddress, widthByColumn[row.length]));
                    row.push(getCellDefinition(login.lastLoginDate ? login.lastLoginDate.ToDateTimeString() : 'n/a', widthByColumn[row.length]));
                    row.push(getCellDefinition(login.schemeLastViewed ? login.schemeLastViewed.ToDateTimeString() : 'n/a', widthByColumn[row.length]));
                    if (this.showReplyEmailAddress) row.push(getCellDefinition(null, widthByColumn[row.length], this.getCheckBoxInputTemplate(login.emailAddress, '', login.receiveEmail)));

                    cellContentByRow[i] = row;
                }
            }

            if (Object.keys(cellContentByRow).length === 0) return null;

            return table.getTableConfig(this.getHeaderRow(), cellContentByRow);
        },
        getCheckBoxInputTemplate (id, label, checked) {
            const output = checkbox.getDefaultInputTemplate(id, label, checked, null, null, null, null, false);
            output.type = 'checkbox';
            return output;
        },
        getRadioInputTemplate () {
            const options = [ClientLabel, LcpLabel];
            return radio.getDefaultInputTemplate('UserView', 'User login history', ClientLabel, null, true, options);
        },
        getHeaderRow () {
            const headerRow = [];
            headerRow.push(getCellDefinition('Email address', widthByColumn[headerRow.length]));
            headerRow.push(getCellDefinition('Last logged into Visualise', widthByColumn[headerRow.length]));
            headerRow.push(getCellDefinition('Last viewed this scheme', widthByColumn[headerRow.length]));
            if (this.showReplyEmailAddress) headerRow.push(getCellDefinition('Add to email list', widthByColumn[headerRow.length]));
            return headerRow;
        }
    }
};

</script>

<style lang="scss" scoped>

.modalBg{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,.4);
    z-index: 10;
}

.editModal{
    position: absolute;
    width: 900px;
    max-height: 75%;
    overflow: auto;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    color: black;
    box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06) !important;
    border-radius: 6px;
    padding: 10px;
    z-index: 3;
    .modalHeader{
        position: relative;
        margin-bottom:10px;
        font-size: 16px;
        padding: 0 8px;
        .closeModal{
            float: right;
            font-size: 20px;
            cursor: pointer;
        }
    }
    .loading {
        position: relative;
        height: 120px;
        margin: 3rem;
        background: transparent !important;
    }
}

.invalidEmail {
    padding-left: 5px;
    color: red;
    font-weight: bold;
}

</style>
