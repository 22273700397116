<template>
    <div class="input--div" :style="divStyles">
        <template v-if="type === 'text'">
            <input class="input--box" ref="text" type="text" :id="id" :class="{'input--center': center, 'input--error': incorrectInput, 'large': large}" :value="inputValue" @input="emitValue($event.target.value)" :style="inputStyles" @focus="setStyle()" @blur="setStyle();">
        </template>
        <template v-else-if="type === 'number'">
            <input class="input--box" ref="number" type="text" :id="id" :class="{'input--center': center, 'input--error': incorrectInput, 'large': large}" :value="inputValue" @input="emitValue($event.target.value)" @keyup="convertToLocaleString($event.target.value);" @blur="setStyle();" @focus="setStyle()" :style="inputStyles">
        </template>
        
        <label class="input--label" :for="id" :class="{'input--center': center, 'not-empty': notEmpty, 'hover': labelHover || notEmpty, 'large': large}" :style="labelStyles" v-if="label !== undefined">
            {{label}}
        </label>

        <label class="input--icon" :for="id" :style="iconStyles" v-if="icon !== undefined" :class="{'large': large}">
            <i :class="['icon', 'icon-' + icon]"></i>
        </label>
        
        <span class="input--error" :class="{'input--center': center}" v-show="incorrectInput">{{errorStatement}}</span>
    </div>
</template>
<script>
import Vue from 'vue';

export default {
    props: {
        id: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            },
            required: true
        },
        value: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        center: {
            type: Boolean,
            validator: function (value) {
                if (typeof value !== 'boolean') {
                    Vue.util.warn('Invalid value: Value should be boolean.');
                    return false;
                } else {
                    return true;
                }
            },
            default: false
        },
        labelHover: {
            type: Boolean,
            validator: function (value) {
                if (typeof value !== 'boolean') {
                    Vue.util.warn('Invalid value: Value should be boolean.');
                    return false;
                } else {
                    return true;
                }
            },
            default: false
        },
        label: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        type: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        fontColor: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        iconColor: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        underlineColor: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        defaultUnderline: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        width: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        icon: {
            type: String,
            validator: function (value) {
                if (typeof value !== 'string') {
                    Vue.util.warn('Invalid value: Value should be string.');
                    return false;
                } else {
                    return true;
                }
            }
        },
        large: {
            type: Boolean,
            validator: function (value) {
                if (typeof value !== 'boolean') {
                    Vue.util.warn('Invalid value: Value should be boolean.');
                    return false;
                } else {
                    return true;
                }
            },
            default: false
        },
        autofocus: {
            type: Boolean,
            validator: function (value) {
                if (typeof value !== 'boolean') {
                    Vue.util.warn('Invalid value: Value should be boolean.');
                    return false;
                } else {
                    return true;
                }
            },
            default: false
        }
    },
    data: function () {
        return {
            divStyles: {
                width: this.width
            },
            inputStyles: {
                color: this.fontColor,
                borderBottomColor: this.defaultUnderline
            },
            labelStyles: {
                color: this.fontColor
            },
            iconStyles: {
                color: this.iconColor
            },
            incorrectInput: false
        };
    },
    mounted: function () {
        if (this.autofocus) {
            this.$refs[this.type].focus();
        }
    },
    methods: {
        convertToLocaleString: function (value) {
            this.inputValue = value;
        },
        emitValue: function (val) {
            this.$emit('input', val);
        },
        setStyle: function () {
            if (this.inputStyles.borderBottomColor !== this.defaultUnderline) {
                this.$set(this.inputStyles, 'borderBottomColor', this.defaultUnderline);
            } else {
                this.$set(this.inputStyles, 'borderBottomColor', this.underlineColor);
            }
        }
    },
    computed: {
        notEmpty: function () {
            let retBool;
            this.inputValue === '' ? retBool = false : retBool = true;
            return retBool;
        },
        errorStatement: function () {
            return 'Incorrect input please enter a valid value for: ' + this.type;
        },
        inputValue: {
            get: function () {
                return this.value;
            },
            set: function (newValue) {
                if (this.type === 'number') {
                    newValue = newValue.replace(/,/g, '');
                    if (!isNaN(newValue) && newValue !== '') {
                        this.incorrectInput = false;
                        newValue = parseFloat(newValue).toLocaleString();
                    } else if (isNaN(newValue)) {
                        this.incorrectInput = true;
                    } else if (newValue === '') {
                        this.incorrectInput = false;
                    }
                    this.$emit('input', newValue);
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">

@import 'src/assets/scss/base.scss';

$font-family: "Helvetica","Arial",sans-serif;

%largeFontSize {
    font-size: 32px;
    @include responsive('sm') {
        font-size: $fontSizeHeaderSm;
    }
}

%inputLabelTransform {
    &.large {
        transform: scale(0.5);
        @include responsive('sm') {
            transform: scale(0.70);
        }
    }
    transform: scale(0.75);
    transform-origin: left -60px;
    transition: transform 0.3s ease-in-out;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input--div {
    margin-bottom: 15px;
    width: 100%;
    position: relative;

    .input--box {
        &.large {
            @extend %largeFontSize;
        }
        &:focus {
            & + .input--label {
                &.hover {
                    @extend %inputLabelTransform;
                }
                &.disappear {
                    display: none;
                }
            }
            outline: none;
        }
        border: none;
        border-bottom: 2px solid rgba(0,0,0,.12);
        display: block;
        font-size: 16px;
        margin: 0;
        padding-top: 10px;
        width: 100%;
        background: 0 0;
        text-align: left;
        transition: border-bottom-color 500ms ease-out;
    }
    .input--label {
        margin-bottom:0;
        max-width: unset;
        font-weight: normal;
        bottom: 0;
        font-size: 16px;
        left: 0;
        right: 0;
        top: 5px;
        pointer-events: none;
        position: absolute;
        display: block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        transform: scale(1);
        transform-origin: left -60px;
        padding-top: 5px;
        &.input--center {
            transform-origin: center -60px!important;
        }
        &.large {
            @extend %largeFontSize;
        }
        &.not-empty {
            @extend %inputLabelTransform;
        }
        transition: transform 0.3s ease-in-out;
    }
    .input--icon {
        margin-bottom:0;
        max-width: unset;
        font-weight: normal;
        bottom: 0;
        color: rgba(0,0,0,.26);
        font-size: 16px;
        left: -20px;
        right: 0;
        top: 12px;
        pointer-events: none;
        position: absolute;
        display: block;
        overflow: hidden;
        text-align: left;

        &.large {
            left: -40px;
            top: 8px;
            @extend %largeFontSize;
        }
    }
    .input--error {
        color: #d50000;
        font-size: 12px;
        padding-top: 10px;
        display: block;
        &.input--box {
            color: #d50000!important;
        }
    }
    .input--center {
        text-align: center;
    }

}
</style>
