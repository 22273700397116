<template>

 <div class="viewNoScheme">
    <div class="card-header lightBorder">
        <h1>LCP Visualise terms &amp; conditions</h1>
    </div>
    <terms-wording  class="LCPcontent"/>
    <copyright colorTheme="dark"/>
</div>

</template>

<script>

import termsWording from './termsWording.vue';
import copyright from '@/components/common/uiLayouts/copyright.vue';

export default {
    components: {
        termsWording,
        copyright
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import 'src/assets/scss/colors.scss';

.lightBorder {
    border-color: $PRIMARY;
}

</style>
